import React from "react";
import PropTypes from "prop-types";

export const CalendarTitle = ({ children }) => (
  <div
    style={{
      color: "#484848",
      fontSize: "0.875rem",
      fontWeight: "600",
      padding: "20px 24px 10px 24px"
    }}
  >
    {children}
  </div>
);

CalendarTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node
  ]).isRequired
};

export default CalendarTitle;