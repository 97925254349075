import React from "react";
import PropTypes from "prop-types";
import {
  Grid as RFGGrid,
  Row as RFGRow,
  Col as RFGCol
} from "react-flexbox-grid";

import styles from "./styles.scss";

const getStyles = (fixedWidth, maxWidth) => {
  if (fixedWidth) {
    return {
      maxWidth: "none",
      flexBasis: fixedWidth
    };
  }
  if (maxWidth) {
    return {
      flexBasis: maxWidth
    };
  }
  return undefined;
};

/** Grid component wrapper */
export const Grid = ({ children, ...props }) => (
  <RFGGrid className={styles.Grid} {...props}>
    {children}
  </RFGGrid>
);

Grid.propTypes = {
  children: PropTypes.node.isRequired
};

/** Row grid component wrapper */
export const Row = ({ children, ...props }) => (
  <RFGRow {...props}>{children}</RFGRow>
);

Row.propTypes = {
  children: PropTypes.node.isRequired
};

/** Column grid component wrapper */
export const Col = ({
  children,
  className,
  maxWidth,
  fixedWidth,
  ...props
}) => (
  <RFGCol
    className={className}
    style={getStyles(fixedWidth, maxWidth)}
    {...props}
  >
    {children}
  </RFGCol>
);

Col.propTypes = {
  children: PropTypes.node,
  /* Column classname */
  className: PropTypes.string,
  /**
   * Set a max width to the column.
   * Note that in order to work the column size should be higher
   * than the maxWidth value.
   */
  maxWidth: PropTypes.string,
  /* Set a fixed width to the column */
  fixedWidth: PropTypes.string
};

Col.defaultProps = {
  className: null,
  maxWidth: undefined,
  fixedWidth: undefined,
  children: undefined
};
