import {Field} from "redux-form";
import React from "react";
import {Grid, Row, Col} from "Grid";
import Textarea from "Textarea";
import Select from "Select";
import styles from "../styles.scss";
import {transformUndisScore} from "../utils";

export const CriterionRow = ({itemName, disableLabels, formName, readOnly}) => (
  <div className="form-row">
    <Grid fluid>
      <Row>
        <Col xs={5}>
          <div className={styles.criteriaColumn}>
            <Field
              name={`${itemName}.criterion.name`}
              label={disableLabels ? false : "Criterion Name"}
              formName={formName}
              readOnly
              rows={2}
              component={Textarea}
              tabIndex="-1"
            />
          </div>
        </Col>
        <Col xs={1} fixedWidth="150px">
          <div>
            <Field
              name={`${itemName}.score`}
              label={disableLabels ? false : "Score"}
              formName={formName}
              component={Select}
              readOnly={readOnly}
              options={['Yes', 'No', 'NA'].map(transformUndisScore)}
            />
          </div>
        </Col>
      </Row>
    </Grid>
  </div>
)