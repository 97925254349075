import React from "react";

import { Icon } from "@wfp/ui";
import { iconEdit, iconDelete } from "@wfp/icons";
import Toggle from "../../components/Toggle";

const columnsGenerator = (setItem, setItemToDelete, canEdit, canDelete, canActivate, toggleActive) => [
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "LTA",
    accessor: "is_lta",
    maxWidth: 100,
    Cell: row => (row.value ? <span>Yes</span> : <span>No</span>)
  },
  {
    Header: "Services",
    accessor: "services",
    Cell: field => <span>{field.value.map(s => s.name).join(", ")}</span>
  },
  {
    Header: "LTA number",
    accessor: "lta_number"
  },
  {
    Header: "Signature Date",
    accessor: "signature_date"
  },
  {
    Header: "Expiry Date",
    accessor: "expiry_date"
  },
  {
    Header: "Location",
    accessor: "location"
  },
  {
    Header: "Active",
    accessor: "is_deprecated",
    // eslint-disable-next-line react/prop-types
    Cell: ({ row }) => (<Toggle id={row.id.toString()} disabled={!canActivate} onToggle={(value) => toggleActive(row, value)} value={!row.is_deprecated} />),
    sortable: false,
  },
  {
    Header: "",
    accessor: "id",
    /* eslint-disable */
    Cell: row =>
      canEdit ? (
        <Icon
          icon={iconEdit}
          width="19"
          height="19"
          description="Edit item"
          onClick={() => setItem(row.value)}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Icon
          icon={iconEdit}
          width="19"
          height="19"
          description="Edit item"
          style={{ cursor: "pointer", opacity: "0.5" }}
        />
      ),
    /* eslint-enable */
    resizable: false,
    sortable: false,
    width: 50,
    style: { justifyContent: "center" }
  },
  {
    Header: "",
    accessor: "id",
    /* eslint-disable */
    Cell: row =>
      canDelete ? (
        <Icon
          icon={iconDelete}
          width="19"
          height="19"
          description="Delete item"
          onClick={() => setItemToDelete(row.value)}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Icon
          icon={iconDelete}
          width="19"
          height="19"
          description="Delete item"
          style={{ cursor: "pointer", opacity: "0.5" }}
        />
      ),
    /* eslint-enable */
    resizable: false,
    sortable: false,
    width: 50,
    style: { justifyContent: "center" }
  }
];

export default columnsGenerator;
