import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import CRUDLayout, {CRUDMapStateToPropsPropTypes, CRUDMapDispatchToProps, CRUDMapDispatchToPropsPropTypes, CRUDMapStateToProps} from "CRUDLayout";
import activityActions from "../../actions/teamRoles";
import TableWithModalsMixin from "../../components/TableWithModalsMixin";
import RoleModal from "./TeamRoleModal";
import columnsGenerator from "./tableColumns";


export class TeamRolesListComponent extends TableWithModalsMixin {
    render = () => (
      <CRUDLayout
        {...this.props}
        title='Team Roles'
        entityName='Team Roles'
        columnsGenerator={columnsGenerator}
        addModalComponent={RoleModal}
        getItem={this.getItem}
        onSave={this.saveItem}
        handleDragEnd={this.handleDragEnd}
      />
    );
}

TeamRolesListComponent.propTypes = {
  ...CRUDMapStateToPropsPropTypes,
  ...CRUDMapDispatchToPropsPropTypes,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      isDeprecated: PropTypes.bool
    })
  ).isRequired,
  selectedItem: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    isDeprecated: PropTypes.bool
  }),
};

export const mapStateToProps = state => {

    return (
        CRUDMapStateToProps(
            state,
            'teamRoles',
            'actors.add_role',
            'actors.change_role'
        )
    )
}

export const mapDispatchToProps = dispatch => (
  CRUDMapDispatchToProps(dispatch, activityActions, 'teamRoles')
);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TeamRolesListComponent)
);
