

export const manipulateInputValue = (inputValue) => {
    let manipulatedValue = '';
    const splitValue = inputValue
        .replace(/[/.]/g, '-')
        .replace(/[^0-9-]/g, '')
        .replace(/--/g, '-')
        .split('-');

    manipulatedValue += (splitValue[0] || '').padStart(splitValue[1] !== undefined ? 2 : 0, '0').slice(0, 2);

    if (manipulatedValue.length === 2)
      manipulatedValue += '-';

    manipulatedValue += (splitValue[1] || '').padStart(splitValue[2] !== undefined ? 2 : 0, '0').slice(0, 2);

    if (manipulatedValue.length === 5)
      manipulatedValue += '-';

    manipulatedValue += (splitValue[2] || '').slice(0, 4);

    return manipulatedValue;
};

// InputEvent event
const dateFillHelper = (event) => {
    const input = event.target;

    // Not when deleting characters.
    if (!event.data)
      return;

    // Not when editing inside.
    if (input.selectionStart !== input.value.length)
      return;

    // Not when in selection.
    if (input.selectionStart !== input.selectionEnd)
      return;

    const manipulateValue = manipulateInputValue(input.value);

    if (input.value === manipulateValue)
      return;

    input.value = manipulateValue;
    // Notify React about the changed value.
    input.dispatchEvent(new Event('input'));
  }

export default dateFillHelper;
