import { combineReducers } from "redux";
import { togglable, items } from "./resource";

const projectInfo = (state = { appLabel: "", changelog: "" }, action) => {
  if (action.type === "SET_PROJECT_INFO") {
    return {
      appLabel: action.appLabel,
      changelog: action.changelog
    };
  }
  return state;
};

export default combineReducers({
  user: items("user"),
  projectInfo,
  menu: togglable("HEADER_MENU"),
  mainNavSubmenu: togglable("MAIN_NAV_SUBMENU"),
  userBoxSubmenu: togglable("USER_BOX_SUBMENU")
});
