import moment from "moment";
import {DEFAULT_DATE_FORMAT} from "./index";


const dateFormatter = (date) => ({
    toString: () => {
        let stringValue = null;

        if (typeof date === 'string' && date !== '') {
            stringValue = moment(date, DEFAULT_DATE_FORMAT, true)
            if (!stringValue.isValid()) {
                stringValue = moment(date)
            }
            stringValue = stringValue.format(DEFAULT_DATE_FORMAT);
        }

        if (date instanceof moment) {
            stringValue = date.format(DEFAULT_DATE_FORMAT);
        }

        return stringValue;
    },
    toMoment: () => {
        let momentValue = null;

        if (typeof date === 'string' && date !== '') {
            momentValue = moment(date, DEFAULT_DATE_FORMAT)
            if (!momentValue.isValid()) {
                momentValue = moment(date);
            }
        }

        if (date instanceof moment) {
            momentValue = date.clone();
        }

        return momentValue;
    },
    addDays: (numberOfDays) => {
        const shiftedDate = dateFormatter(date).toMoment();

        if (shiftedDate) {
            shiftedDate.add(numberOfDays, "days");
        }

        return dateFormatter(shiftedDate);
    },
    isSame: (otherDate) => {
        if (!date) {
            return date === otherDate;
        }

        return dateFormatter(date).toMoment().isSame(dateFormatter(otherDate).toMoment())
    }
})

export default dateFormatter;
