import React from "react";
import PropTypes from "prop-types";
import { fieldInputPropTypes } from "redux-form";

import Tooltip from "Tooltip";

import Cleave from "cleave.js/react";
import Styles from "./styles.scss";

const validationStyles = {
  display: "block",
  maxHeight: "12.5rem"
};

class TextInputNumber extends React.Component {
  /** Clean the returned values on blur */
  onBlur = e => this.clean(e.target.value);

  /** Clean the returned values on change */
  onChange = e => {
    this.clean(e.target.value);
    if (this.props.onChangeExtra) {
      this.props.onChangeExtra(e.target.value.replace(/,/g, ""));
    }
  };

  /** Removes commas from `value` */
  clean = value => this.props.input.onChange(value.replace(/,/g, ""));

  labelToRender = () => {
    let label =
      this.props.label !== false ? (
        <label
          className={
            this.props.disabled
              ? "wfp--label wfp--label--disabled"
              : "wfp--label"
          }
          htmlFor={this.props.input.name}
        >
          {this.props.label}
        </label>
      ) : null;

    if (!this.props.isTestMode && this.props.helpText !== "") {
      label = (
        <Tooltip
          className={this.props.disabled ? "wfp--label--disabled" : ""}
          message={this.props.helpText}
          label={this.props.label}
          formIndex={this.props.input.name}
        />
      );
    }

    return label;
  };

  render() {
    const showPrependLabel =
      this.props.prependLabel &&
      (!this.props.readOnly || this.props.input.value !== "");

    return (
      <div
        className={`wfp--form-item ${Styles.inputNumber} ${this.props.cssClass}`}
      >
        {this.labelToRender()}
        {showPrependLabel ? (
          <span
            className={Styles.prependLabel}
            // Relocate the prepend label when a validation message is shown
            style={{ bottom: this.props.invalid && "2.15rem" }}
          >
            {this.props.prependLabel}
          </span>
        ) : null}
        <Cleave
          className="wfp--text-input"
          style={{ borderColor: this.props.invalid && "#c5192d" }}
          {...this.props.input}
          id={this.props.input.name}
          disabled={this.props.disabled}
          readOnly={this.props.readOnly}
          type="text"
          placeholder={this.props.readOnly ? null : this.props.placeholder}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          value={this.props.input.value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          options={{
            numeral: true,
            numeralThousandsGroupStyle: this.props.numberFormat,
            numeralPositiveOnly: this.props.positiveNumbersOnly
          }}
        />
        {this.props.invalid && (
          <div className="wfp--form-requirement" style={validationStyles}>
            {this.props.invalidText}
          </div>
        )}
      </div>
    );
  }
}

TextInputNumber.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  prependLabel: PropTypes.string,
  cssClass: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  invalid: PropTypes.bool,
  invalidText: PropTypes.string,
  onChangeExtra: PropTypes.func,
  helpText: PropTypes.string,
  isTestMode: PropTypes.bool,
  numberFormat: PropTypes.string,
  positiveNumbersOnly: PropTypes.bool
};

TextInputNumber.defaultProps = {
  label: "Default label for input field",
  placeholder: "",
  prependLabel: "",
  cssClass: "",
  disabled: false,
  readOnly: false,
  invalid: false,
  invalidText: "A valid value is required",
  onChangeExtra: null,
  helpText: "",
  isTestMode: false,
  positiveNumbersOnly: true,
  numberFormat: "thousand"
};

export default TextInputNumber;
