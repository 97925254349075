import { combineReducers } from "redux";

import enhancedActivity from "./enhancedActivity";
import companies from "./companies";
import countriesInfo from "./countriesInfo";
import coverage from "./coverage";
import deMembers from "./deMembers";
import donors from "./donors";
import types from "./types";
import evaluations from "./evaluations";
import evaluation from "./evaluation";
import gender from "./gender";
import header from "./header";
import leftColumn from "./leftColumn";
import people from "./people";
import phases from "./phases";
import phqaStandards from "./phqaStandards";
import undises from "./undises";
import products from "./products";
import statusValidationSchemas from "./statusValidationSchemas";
import sustainableDevelopmentGoals from "./sustainableDevelopmentGoals";
import reasonsForCancellation from "./reasonsForCancellation";
import requirementConditions from "./requirementConditions";
import requirements from "./requirements";
import milestones from "./milestones";
import users from "./users";
import crosscuttingPriorities from "./crosscuttingPriorities";
import topics from "./topics";
import activityCategories from "./activityCategories";
import productTypes from "./productTypes";
import teamRoles from "./teamRoles";
import Windows from "./Windows";
import partners from "./partners";

export default combineReducers({
  enhancedActivity,
  companies,
  coverage,
  countriesInfo,
  deMembers,
  donors,
  crosscuttingPriorities,
  topics,
  activityCategories,
  productTypes,
  teamRoles,
  types,
  evaluations,
  evaluation,
  gender,
  header,
  leftColumn,
  people,
  phases,
  phqaStandards,
  undises,
  products,
  reasonsForCancellation,
  requirementConditions,
  requirements,
  statusValidationSchemas,
  sustainableDevelopmentGoals,
  milestones,
  users,
  Windows,
  partners,
});
