exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__container___3UYki{display:flex;flex-direction:row;margin-bottom:20px}.styles__titleContainer___28DyO{text-align:center;width:150px}.styles__title___3A9So{padding:10px;text-transform:capitalize}.styles__contentContainer___1KmqT{border:1px solid #bbbbbb;background-color:#eeeeee;padding:20px;width:100%}.styles__headerContainer___2MSFo{margin:10px;display:flex}.styles__operationHeader___D4ldZ{width:50px;text-align:center;background-color:#f9fbfc}.styles__buttonContainer___3525Q{flex-direction:row-reverse;display:flex;margin:10px}.styles__addButtonContainer___3AplB{width:50px;text-align:center}\n", ""]);

// Exports
exports.locals = {
	"container": "styles__container___3UYki",
	"titleContainer": "styles__titleContainer___28DyO",
	"title": "styles__title___3A9So",
	"contentContainer": "styles__contentContainer___1KmqT",
	"headerContainer": "styles__headerContainer___2MSFo",
	"operationHeader": "styles__operationHeader___D4ldZ",
	"buttonContainer": "styles__buttonContainer___3525Q",
	"addButtonContainer": "styles__addButtonContainer___3AplB"
};