exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__inlineForm___15raP{align-items:flex-end;display:flex}.styles__inlineForm___15raP fieldset{width:100%}.styles__inlineForm___15raP .styles__button___1ssPN{margin-left:1rem;position:relative;top:-1.3rem}.styles__memberCreateForm___3IVI2{border:1px solid #dfe3e6;border-radius:3px;margin:1rem 0;padding:1.5em}.styles__memberCreateForm___3IVI2>h7{background-color:#fbfcfc;bottom:0.875em;left:0.3rem;padding:0 0.875em;position:relative}.styles__memberCreateForm___3IVI2 .styles__personForm___3HQvT{margin-top:-0.5rem}.styles__memberCreateForm___3IVI2 .styles__buttonContainer___3uXHR{display:flex;justify-content:flex-end;padding-top:0.5em;width:100%}\n", ""]);

// Exports
exports.locals = {
	"inlineForm": "styles__inlineForm___15raP",
	"button": "styles__button___1ssPN",
	"memberCreateForm": "styles__memberCreateForm___3IVI2",
	"personForm": "styles__personForm___3HQvT",
	"buttonContainer": "styles__buttonContainer___3uXHR"
};