import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";

import MainNavigation from "MainNavigation";
import fetchProject from "../../actions/project";
import fetchUser from "../../actions/user";
import { logoutUser } from "../../actions/auth";
import { toggle, hide } from "../../actions/togglable";
import { getIsUserSupport } from "../../selectors/user";
import {navItems} from "./helpers";
import {fetchFeatureFlags} from "../../actions/featureFlag";


const navigationItems = [
    {
      title: 'Evaluations',
      href: '/evaluations/',
    },
  {
     title: "Other",
     href: null,
     children: [
        { title: "Team members", href: "/people", permission: null },
        { title: "Companies", href: "/companies", permission: null},
        { title: "Activity", href: "/enhanced-activity", permission: null},
     ]
  },
  {
     title: "Setup",
     href: null,
     children: [
        { title: "Countries", href: "/countries-info", permission: "actors.add_countryinfo" },
        { title: "SDGs", href: "/sustainable-development-goals", permission: "exercise.add_sustainabledevelopmentgoal" },
        { title: "RFCs", href: "/reasons-for-cancellation", permission: "exercise.add_reasonforcancellation" },
        { title: "Milestones", href: "/milestones", permission: "exercise.add_milestone" },
        { title: "SVS", href: "/status-validation-schema", permission: "exercise.add_statusvalidationschema" },
        { title: "Donors", href: "/donors", permission: "exercise.add_donor" },
        { title: "CCP", href: "/crosscutting-priorities", permission: "exercise.add_crosscuttingpriority" },
        { title: "Types", href: "/types", permission: "exercise.add_kind" },
        // TODO: use correct permission to manage users
        { title: "Users", href: "/users", permission: "wfp_auth.add_wfpuser" },
        { title: "Topics", href: "/topics", permission: "exercise.add_topic" },
        { title: "Activity Category", href: "/activity-categories", permission: "exercise.add_activitycategory" },
        { title: "Window", href: "/windows", permission: "exercise.add_window" },
        { title: "Team Roles", href: "/team-roles", permission: "actors.delete_role" },
        { title: "Product Type", href: "/product-types", permission: "exercise.add_producttype" },
        { title: "Partners", href: "/partners", permission: "actors.add_partner" },
    ]
  }
]

export class HeaderContainer extends React.Component {
  componentDidMount() {
    this.props.fetchProject();
    this.props.fetchUser();
    this.props.fetchFeatureFrags();
  }

  logUserOutAndCleanLocalStorage() {
    this.props.logUserOut();
    localStorage.removeItem("userHadPermissions");
  }

  render() {
    return (
      <MainNavigation
        userUsername={this.props.userUsername}
        userName={this.props.userName}
        navItems={navItems(navigationItems, this.props.permissions)}
        logoutAction={this.logUserOutAndCleanLocalStorage}
        appLabel={this.props.appLabel}
        isUserSupport={this.props.isUserSupport}
      />
    );
  }
}

HeaderContainer.propTypes = {
  appLabel: PropTypes.string,
  fetchUser: PropTypes.func.isRequired,
  fetchProject: PropTypes.func.isRequired,
  fetchFeatureFrags: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
  userName: PropTypes.string,
  userUsername: PropTypes.string,
  logUserOut: PropTypes.func.isRequired,
  isUserSupport: PropTypes.bool.isRequired
};

HeaderContainer.defaultProps = {
  appLabel: "",
  permissions: [],
  userName: "",
  userUsername: "user"
};

// Exported for tests
export const mapStateToProps = state => {
  const page = state.pages.header;
  const userId = page.user && page.user[0] && page.user[0].user;
  const user =
    userId && state.entities.user[userId] ? state.entities.user[userId] : {};

  const permissions = get(state, "user[0].permissions.permission", []);

  return {
    appLabel: page.projectInfo.appLabel,
    isUserSupport: getIsUserSupport(state),
    permissions,
    userEmail: user.email,
    userName: user.first_name,
    userUsername: user.username
  };
};

// Exported for tests
export const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUser()),
  logUserOut: () =>
    Promise.all([
      dispatch(logoutUser()),
      dispatch({ type: "CLEAR_ITEMS", resource: "user" })
    ]),
  fetchProject: () => dispatch(fetchProject()),
  fetchFeatureFrags: () => dispatch(fetchFeatureFlags()),
  toggleMenu: () =>
    Promise.all([
      dispatch(toggle("HEADER_MENU")),
      dispatch(hide("USER_BOX_SUBMENU")),
      dispatch(hide("MAIN_NAV_SUBMENU"))
    ]),
  toggleMainNavSubmenu: () =>
    Promise.all([
      dispatch(toggle("MAIN_NAV_SUBMENU")),
      dispatch(hide("USER_BOX_SUBMENU"))
    ]),
  toggleUserBoxSubmenu: () =>
    Promise.all([
      dispatch(toggle("USER_BOX_SUBMENU")),
      dispatch(hide("MAIN_NAV_SUBMENU"))
    ]),
  handleResize: () => dispatch({ type: "UPDATE_WINDOW_STATE" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderContainer);
