import React from "react";
import PropTypes from "prop-types";

const Container = ({ color, children }) => (
  <div style={{ backgroundColor: color, width: "100%", height: "100%" }}>
    {children}
  </div>
);

Container.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired
};

export default Container;
