import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { Button } from "@wfp/ui";

import { Grid, Row, Col } from "Grid";
import ModalForm from "ModalForm";
import TextInputNumber from "TextInputNumber";
import Select from "Select";
import MemberCreateForm from "./MemberCreateForm";
import FirmCreateForm from "./FirmCreateForm";

import Styles from "./styles.scss";

const onFormSubmit = props => e => {
  e.preventDefault();
  props.onSubmit();
};
const Form = props => (
  <form
    style={props.style}
    className={`${props.className} wfp-form`}
    onSubmit={onFormSubmit(props)}
  >
    {props.children}
  </form>
);

Form.propTypes = {
  style: PropTypes.shape({}),
  className: PropTypes.string,
  children: PropTypes.any // eslint-disable-line
};

Form.defaultProps = {
  style: {},
  className: "",
  children: undefined
};

const MemberAddModal = props => {
  let personFormLabel = "New";
  if (props.personFormVisible) {
    personFormLabel = "Cancel";
  } else if (props.personFormEdit) {
    personFormLabel = "Edit";
  }

  let companyFormLabel = "New";
  if (props.firmFormVisible) {
    companyFormLabel = "Cancel";
  } else if (props.firmFormEdit) {
    companyFormLabel = "Edit";
  }

  return (
    <ModalForm
      isModalForm={false}
      title={props.title}
      isVisible={props.show}
      onClose={props.toggleModal}
      testId={props.testId}
      onSubmit={e => {
        e.preventDefault();
        props.onSubmit();
      }}
    >
      <Form onSubmit={props.onSubmit}>
        <div className={Styles.inlineForm}>
          <Field
            name="person"
            label="Name"
            placeholder="Type 3 characters to search..."
            component={Select}
            options={props.people}
            onInputChange={props.onSearch}
            openMenuOnClick
          />
          <Button
            small
            kind="secondary"
            type="button"
            onClick={props.togglePersonForm}
            className={Styles.button}
          >
            {personFormLabel}
          </Button>
        </div>
      </Form>
      {props.personFormVisible && (
        <Form onSubmit={props.onPersonFormSubmit} className={props.className}>
          <MemberCreateForm
            initialValues={props.personFormInitialValues}
            togglePersonForm={props.togglePersonForm}
            sex={props.sex}
            countries={props.countries}
          />
        </Form>
      )}
      {!props.isSimplifiedVersion && (
      <>
        <Form onSubmit={props.onSubmit}>
          <div className={Styles.inlineForm}>
            <Field
              name="company"
              label="Company"
              options={props.companies || []}
              disabled={props.firmFormVisible}
              component={Select}
              testId="company-selector"
              openMenuOnClick
          />
            <Button
              small
              kind="secondary"
              type="button"
              onClick={props.toggleFirmForm}
              className={Styles.button}
          >
              {companyFormLabel}
            </Button>
          </div>
        </Form>
        {props.firmFormVisible && (
          <Form onSubmit={props.onFirmFormSubmit} className={props.className}>
            <FirmCreateForm
              initialValues={props.firmFormInitialValues}
              toggleFirmForm={props.toggleFirmForm}
              services={props.services}
              inModal
            />
          </Form>
        )}
      </>)}
      <Form onSubmit={props.onSubmit}>
        <Grid>
          {props.isSimplifiedVersion && (
            <Row>
              <Col xs>
                <Field
                  name="partner"
                  options={props.partners}
                  component={Select}
                  label="Organization"
                  openMenuOnClick
                />
              </Col>
            </Row>
          )}
          {!props.isSimplifiedVersion && (
            <Row>
              <Col xs>
                <Field
                  name="contract_type"
                  options={props.contractTypes}
                  component={Select}
                  label="Contract"
                  onChangeExtra={props.contractSelect}
                  openMenuOnClick
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs>
              <Field
                name="role"
                options={props.teamRoles}
                component={Select}
                label="Role"
                openMenuOnClick
              />
            </Col>
          </Row>
          {!props.isSimplifiedVersion && (
            <Row>
              <Col xs>
                <Field
                  name="expertises"
                  options={props.expertises}
                  component={Select}
                  label="Expertise"
                  helpText="Expertise of the team member applied in this evaluation"
                  openMenuOnClick
                  allowMultiple
                />
              </Col>
            </Row>
          )}
          {!props.isSimplifiedVersion && (
            <Row>
              <Col xs>
                <Field
                  name="daily_rate"
                  component={TextInputNumber}
                  label="Daily Rate"
                  prependLabel="US $"
                  onChangeExtra={props.changeDailyRate}
                />
              </Col>
              <Col xs>
                <Field
                  name="days"
                  component={TextInputNumber}
                  label="Number of Days"
                  onChangeExtra={props.changeDays}
                />
              </Col>
              <Col xs>
                <Field
                  name="total"
                  component={TextInputNumber}
                  label="Total Fees"
                  prependLabel="US $"
                  readOnly
                />
              </Col>
            </Row>
          )}
        </Grid>
      </Form>
    </ModalForm>
  );
};

MemberAddModal.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool,
  contractTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      is_non_lta: PropTypes.bool,
      services: PropTypes.array,
      lta_number: PropTypes.string,
      expiry_date: PropTypes.string,
      signature_date: PropTypes.string,
      location: PropTypes.string
    })
  ).isRequired,
  teamRoles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ).isRequired,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ).isRequired,
  expertises: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ).isRequired,
  people: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ).isRequired,
  toggleModal: PropTypes.func.isRequired,
  togglePersonForm: PropTypes.func,
  toggleFirmForm: PropTypes.func,
  personFormVisible: PropTypes.bool,
  firmFormVisible: PropTypes.bool,
  onPersonFormSubmit: PropTypes.func,
  onFirmFormSubmit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  className: PropTypes.string,
  sex: PropTypes.arrayOf(PropTypes.object),
  countries: PropTypes.arrayOf(PropTypes.object),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ),
  personFormEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  firmFormEdit: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  personFormInitialValues: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.number
  ]),
  firmFormInitialValues: PropTypes.shape({}),
  changeDailyRate: PropTypes.func.isRequired,
  changeDays: PropTypes.func.isRequired,
  contractSelect: PropTypes.func.isRequired,
  testId: PropTypes.string,
  isSimplifiedVersion: PropTypes.bool,
};

MemberAddModal.defaultProps = {
  show: false,
  className: undefined,
  personFormVisible: false,
  firmFormVisible: false,
  togglePersonForm: undefined,
  toggleFirmForm: undefined,
  onPersonFormSubmit: undefined,
  onFirmFormSubmit: undefined,
  firmFormEdit: undefined,
  personFormEdit: undefined,
  sex: [],
  countries: [],
  services: [],
  personFormInitialValues: {},
  firmFormInitialValues: {},
  testId: undefined,
  isSimplifiedVersion: false,
};

export default reduxForm({
  form: "evMembers",
  enableReinitialize: true
})(MemberAddModal);
