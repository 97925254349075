import React from "react";
import PropTypes from "prop-types";
import Tooltip from "Tooltip";

import Styles from "../styles.scss";

const Phase = ({ name, id, color }) => (
  <li>
    <Tooltip message={name} color={color}>
      <p style={{ backgroundColor: color }}>{id}</p>
    </Tooltip>
  </li>
);

Phase.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  color: PropTypes.string.isRequired
};

const Legend = props => (
  <ul className={Styles.legend}>
    <li>Legend</li>
    {props.phases.map(item => (
      <Phase key={item.id} name={item.name} id={item.id} color={item.color} />
    ))}
  </ul>
);

Legend.propTypes = {
  phases: PropTypes.arrayOf(PropTypes.shape(Phase.propTypes)).isRequired
};

export default Legend;
