// Types of offices
export const OFFICE_TYPE_HQ = 1;
export const OFFICE_TYPE_REGIONAL = 2;
export const OFFICE_TYPE_COUNTRY = 3;
export const OFFICE_TYPE_SUB = 4;
export const OFFICE_TYPE_SPECIAL = 5;
export const OFFICE_TYPE_LIAISON = 6;
export const OFFICE_TYPE_SUPPORT = 7;
export const OFFICE_TYPE_WAREHOUSE = 8;
export const OFFICE_TYPE_CLUSTER = 9;
export const OFFICE_TYPE_RESPONSE = 10;
export const OFFICE_TYPE_UNHRD = 11;

// Colors
export const COLOR_SUCCESS_TOAST = "rgb(82, 170, 0);";

export const BUREAU_HQ_DIVISIONS = 'non_oev';
export const BUREAU_OEV = 'OEV';
export const BUREAU_LIAISON_OFFICES = 'liaison_offices';
