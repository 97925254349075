import React from "react";
import PropTypes from "prop-types";

import Styles from "./styles.scss";

const Filter = props => (
  <div
    className={
      props.active ? `${Styles.Filter} ${Styles.isActive}` : `${Styles.Filter}`
    }
    onClick={props.onClick}
  >
    {props.label}
  </div>
);

Filter.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

Filter.defaultProps = {
  active: false
};

export default Filter;
