const buildDict = _prefix => {
  const prefix = _prefix.toUpperCase();
  return {
    base: `${prefix}`,
    pending: `${prefix}_PENDING`,
    fulfilled: `${prefix}_FULFILLED`,
    rejected: `${prefix}_REJECTED`
  };
};

class ResourceTypes {
  constructor(name) {
    this.name = name;
    this.detailOptions = buildDict(`${this.name}_DETAIL_OPTIONS`);
    this.options = buildDict(`${this.name}_OPTIONS`);
    this.list = buildDict(`${this.name}_LIST`);
    this.create = buildDict(`${this.name}_CREATE`);
    this.detail = buildDict(`${this.name}_DETAIL`);
    this.delete = buildDict(`${this.name}_DELETE`);
    this.update = buildDict(`${this.name}_UPDATE`);
    this.orderingPosition = buildDict(`${this.name}_ORDERING_POSITION`);
  }
}

export default ResourceTypes;
