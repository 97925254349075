import React from "react";
import {Button} from "@wfp/ui";
import {iconAddGlyph} from "@wfp/icons";
import PropTypes from "prop-types";
import Loading from "Loading";
import SecondaryNavigation from "SecondaryNavigation";
import ListComponent from "ListComponent";
import DeleteModal from "Modal/DeleteModal";

export const CRUDLayout = (props) => {
    if (props.isFetching) {
        return <Loading />
    }

    return (
      <div>
        <SecondaryNavigation
          additional={
            <Button
              type="submit"
              icon={iconAddGlyph}
              iconDescription={`Add a new ${props.entityName}`}
              disabled={!props.canAdd}
              onClick={() => props.getItem(null)}
            >
              New {props.entityName}
            </Button>
          }
          title={props.title}
        />
        {props.addModalComponent && <props.addModalComponent
          title={`${props.selectedItem?.id ? 'Edit' : 'New' } ${props.entityName}`}
          initialValues={props.selectedItem}
          toggleModal={props.toggleAddModal}
          show={props.showAddModal}
          onSubmit={props.onSave}
          disabled={!(props.canAdd || props.canEdit)}
          {...props.extraModalParams}
        />}
        {props.onDelete && <DeleteModal
          title={`Are you sure you want to delete this ${props.entityName}?`}
          show={props.showDeleteModal}
          toggleModal={props.toggleDeleteModal}
          onDelete={props.onDelete}
          disabled={!props.canDelete}
          danger
        />}
        <ListComponent
          fetching={props.isFetching}
          tableData={props.data}
          columns={props.columnsGenerator(props.getItem, props.canEdit, props.setItemToDelete, props.canDelete)}
          noSearch={props.noSearch}
          enableDragAndDropSorting={Boolean(props.handleDragEnd)}
          handleDragEnd={(results) => props.handleDragEnd(results)}
        />
      </div>
    );
}

CRUDLayout.propTypes = {
    // Required params
    isFetching: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    entityName: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    columnsGenerator: PropTypes.func.isRequired,

    // Add and edit entity params
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool,
    addModalComponent: PropTypes.shape({}),
    showAddModal: PropTypes.bool,
    toggleAddModal: PropTypes.func,
    getItem: PropTypes.func,
    selectedItem: PropTypes.shape({}),
    onSave: PropTypes.func,

    // Delete entity params
    canDelete: PropTypes.bool,
    showDeleteModal: PropTypes.bool,
    toggleDeleteModal: PropTypes.func,
    setItemToDelete: PropTypes.func,
    onDelete: PropTypes.func,

    // Additional params
    handleDragEnd: PropTypes.func,
    extraModalParams: PropTypes.shape({}),
    noSearch: PropTypes.bool,
};

CRUDLayout.defaultProps = {
    // Add and edit entity params
    canAdd: false,
    canEdit: false,
    addModalComponent: null,
    showAddModal: false,
    toggleAddModal: null,
    getItem: null,
    selectedItem: {},
    onSave: null,

    // Delete entity params
    canDelete: false,
    showDeleteModal: false,
    toggleDeleteModal: null,
    setItemToDelete: null,
    onDelete: null,

    // Additional params
    handleDragEnd: null,
    extraModalParams: {},
    noSearch: true,
};

export default CRUDLayout;
export { CRUDMapStateToPropsPropTypes, CRUDMapDispatchToProps, CRUDMapDispatchToPropsPropTypes, CRUDMapStateToProps } from './helpers'