import React, { Component } from "react";
import { reset, change } from "redux-form";
import { get } from "lodash";
import ReactRouterPropTypes from "react-router-prop-types";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button } from "@wfp/ui";
import { Loading, HelpMessageBox } from 'components'
import { toggle } from "actions/togglable";
import { getEvaluationActions } from "actions/evaluations";
import { dePhasesActions, phasesActions} from "actions/phases";
import { deMilestonesActions, milestonesActions } from "actions/milestones";
import productsActions from "actions/products";
import { Categories } from "enums";
import { customToastError, getMessages, isCreate } from "utils";
import dateFormatter from "utils/dateFormatter";
import phaseShifter from "actions/phases/shiftPhasesRanges/phaseShifter";
import {Legend, PhasesModal as PhasesModalComponent, Calendar as PhasesCalendar } from "./components";
import {getPreviousPhaseEndDate, getNextPhaseStartDate } from "./utils";
import PhaseFormTransformer from "./PhaseFormTransformer";
import ShiftingConfirmationModal from "./components/ShiftingConfirmationModal";


/** Component that shows all phases of an evaluation with detail for each related milestone */
export class PhasesComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showShiftingConfirmationModal: false,
      predictedShiftedPhases: null,
      shiftingConfirmationCallback: null,
    }
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    this.props.fetchPhases();
    this.props.fetchMilestones();
    this.props.fetchDePhases(this.props.match.params.evaluationId);
    this.props.fetchDeMilestones(this.props.match.params.evaluationId);
    this.props.fetchEvaluationProducts(this.props.match.params.evaluationId);
  };

  setDatesRangePhase = phase => {
    // to block the previous date in RangeDatePicker
    // we retrieve the last end date based on the phase clicked
    const values = PhaseFormTransformer.transformToValue(this.props.phasesFormValues)

    const startDate = getPreviousPhaseEndDate(values.phases, phase);
    const endDate = getNextPhaseStartDate(values.phases, phase);

    return [startDate, endDate];
  };

  /**
   * Given a phase obj, return its previous phase's end date.
   * Be mindful that the phase object in the argument is not
   * the same type as the phase object in phasesFormValues
   */
  setInitialVisibleMonthPhase = phase => {
    // Form not ready
    if (
      this.props.phasesFormValues.phases === null ||
      this.props.phasesFormValues.phases === undefined
    ) {
      return null;
    }

    // `phase` argument not ready
    if (phase === undefined || typeof phase.id !== "number") {
      return null;
    }

    // Find current phase's index inside phasesFormValues
    const currentPhaseIndex = phase.id - 1;

    // If current phase's startDate is defined, use it (fixes OEV-980)
    const currentPhase = this.props.phasesFormValues.phases[currentPhaseIndex];
    const currentPhaseStartDate = get(currentPhase, "dates_range.startDate");

    if (
      currentPhaseStartDate !== null &&
      currentPhaseStartDate !== undefined &&
      currentPhaseStartDate !== ""
    ) {
      return dateFormatter(currentPhaseStartDate).toString();
    }

    // Iterate backwards over previous phases, use the first endDate found
    for (let i = currentPhaseIndex - 1; i >= 0; i -= 1) {
      const prevPhase = this.props.phasesFormValues.phases[i];
      const prevPhaseEndDate = get(prevPhase, "dates_range.endDate");
      if (prevPhaseEndDate) {
        return dateFormatter(prevPhaseEndDate).toString();
      }
    }

    // No endDate was defined on previous phases
    return null;
  };

  /** Dates are related to phase dates range */
  setDatesRangeMilestone = phase => {
    const ph = this.props.phasesFormValues.phases.find(
      p => phase.id === p.phase
    );
    return [
      dateFormatter(ph.dates_range?.startDate).toString(),
      dateFormatter(ph.dates_range?.endDate).toString(),
    ];
  };

  /**
   * Toggle the phase modal when the user clicks the button,
   * in the modal also milestones are shown
   * if a milestone is passed, the related form is shown
   */
  togglePhaseModal = (phase, milestone) => {
    // to reset form, phase must be passed as null
    if (!phase) {
      this.props.resetPhasesForm();
    } else {
      this.props.selectPhase(phase);
    }
    // if a milestone is passed, the form of milestones related to its phase is shown
    if (!milestone) {
      this.props.toggleMilestones(0);
    } else {
      this.props.toggleMilestones(0);
      this.props.toggleMilestones(milestone.phase.id);
    }
    // the phases model is opened
    this.props.togglePhaseModal();
  };

  phasesModalErrors = () => {
    // validate phase dates
    const errors = [];
    // retrieve only phases with start_date and end_date
    const data = PhaseFormTransformer.transformToValue(this.props.phasesFormValues).phases.filter(
      ph => ph.start_date && ph.end_date
    );
    // if the dates are just 1 we can skip the validation
    if (data.length > 1) {
      data.forEach((ph, i) => {
        const nextPhase = data[i + 1];
        if (nextPhase !== undefined) {
          if (dateFormatter(nextPhase.start_date).toMoment().isBefore(dateFormatter(ph.end_date).toMoment())) {
            errors.push({
              displayName: ph.phase_display,
              nextPhaseName: nextPhase.phase_display
            });
          }
        }
      });
    }
    return errors;
  };

  /** Check that phaseModal has no errors, then save phases and milestones or show errors in toast */
  checkPhaseDates = () => {
    const errors = this.phasesModalErrors();
    if (errors.length === 0) {
       this.props.savePhasesAndMilestones(
        this.props.match.params.evaluationId,
        this.props.phasesFormValues.phases,
        this.props.category
      );
    }
    errors.forEach(e => {
      customToastError(`${e.nextPhaseName} must start after ${e.displayName}`, {
        autoClose: 5000
      });
    });
  };

  /** Strip "Please add phase" from messages */
  filteredPhaseMessages = () => {
    let msg = "Please add phase: ";
    const messages = this.props.messages.filter(m =>
      m.includes("Please add phase")
    );
    if (messages.length > 0) {
      // slice 16 takes phase name after "Please add phase"
      // then we remove the backticks (`)
      msg += messages.map(m => m.slice(16).replace(/`/g, "")).join(", ");
      return msg;
    }
    return null;
  };

  /** if isDone != true, user must not be able to set an event date */
  selectIsDone = (phase, milestone, value) => {
    if (!value) {
      this.props.resetEventDate(`phases[${phase}].milestones[${milestone}]`);
      this.props.resetDatesRange(`phases[${phase}].milestones[${milestone}]`);
    }
  };

  onOverLapping = (selectedPhaseDate, index, startDayDiff, endDayDiff, callback) => {
    const phaseId = selectedPhaseDate.phase;
    const milestones = this.props.milestones.reduce((acc, curr) => ({...acc, [curr.id]: curr}), {});
    const shiftedPhases = phaseShifter(this.props.phasesFormValues.phases, milestones).shiftPhasesRanges(phaseId, startDayDiff, endDayDiff)

    this.setState({
      showShiftingConfirmationModal: true,
      predictedShiftedPhases: shiftedPhases,
      shiftingConfirmationCallback: () => {
        this.props.shiftPhasesRanges(phaseId, startDayDiff, endDayDiff);
        callback()
      },
    })
  }

  shiftingConfirmation = () => {
    this.state.shiftingConfirmationCallback();
    this.setState({ showShiftingConfirmationModal: false });
  }

  render() {
    return (
      <div>
        <Legend phases={this.props.phases} />
        <Button
          kind="secondary"
          type="submit"
          icon={{
            viewBox: "0 0 16 16",
            width: "16",
            height: "16",
            svgData: {
              paths: [
                {
                  d:
                    "M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM5.7 12H4v-1.7l4.9-4.9 1.7 1.7L5.7 12zm6.2-6.2l-.8.8-1.7-1.7.8-.8c.2-.2.5-.2.6 0l1 1c.2.2.2.5.1.7z"
                }
              ]
            }
          }}
          iconDescription="Edit phases and milestones"
          disabled={
            !this.props.canEdit ||
            isCreate(this.props.match.params.evaluationId) ||
            this.props.isFetching
          }
          data-testid="edit-phase-and-milestone-button"
          onClick={() => this.togglePhaseModal(null)}
          style={{
            opacity: !this.props.canEdit && "0",
            visibility: !this.props.canEdit && "hidden"
          }}
        >
          Edit phases and milestones
        </Button>
        <PhasesModalComponent
          initialValues={this.props.initialPhases}
          phases={this.props.phases}
          milestones={this.props.milestones}
          show={this.props.showPhaseModal}
          onOverLapping={this.onOverLapping}
          toggleModal={this.props.togglePhaseModal}
          onSubmit={this.checkPhaseDates}
          toggleMilestones={this.props.toggleMilestones}
          milestoneVisible={this.props.milestoneVisible}
          isFetching={this.props.isFetching}
          setDatesRangePhase={this.setDatesRangePhase}
          setDatesRangeMilestone={this.setDatesRangeMilestone}
          setInitialVisibleMonthPhase={this.setInitialVisibleMonthPhase}
          selectIsDone={this.selectIsDone}
          isDoneMilestones={this.props.isDoneMilestones}
        />
        {this.props.isFetching ? (
          <Loading />
        ) : (
          <div>
            {this.props.canEdit && (
              <div style={{ marginTop: "30px" }}>
                <HelpMessageBox message={this.filteredPhaseMessages()} />
              </div>
            )}
            <PhasesCalendar
              phases={this.props.dePhases}
              milestones={this.props.deMilestones}
              onMilestoneClick={milestone =>
                this.props.canEdit &&
                this.togglePhaseModal(milestone.phase, milestone)
              }
            />
          </div>
        )}
        <ShiftingConfirmationModal
          show={this.state.showShiftingConfirmationModal}
          predictedShiftedPhases={this.state.predictedShiftedPhases}
          phasesFormValues={this.props.phasesFormValues?.phases}
          milestones={this.props.milestones}
          onRequestClose={() => this.setState({ showShiftingConfirmationModal: false })}
          onRequestSubmit={this.shiftingConfirmation}
        />
      </div>
    );
  }
}

// props related to a single phase
const dePhasePropTypes = {
  id: PropTypes.number,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  phase: PropTypes.number,
  phase_display: PropTypes.string,
  canEdit: PropTypes.bool
};

// props related to the list of phases
PhasesComponent.propTypes = {
  category: PropTypes.string,
  match: ReactRouterPropTypes.match.isRequired,
  isFetching: PropTypes.bool.isRequired,
  initialPhases: PropTypes.shape({ phases: PropTypes.array }),
  selectPhase: PropTypes.func.isRequired,
  togglePhaseModal: PropTypes.func.isRequired,
  fetchValidation: PropTypes.func.isRequired,
  fetchPhases: PropTypes.func.isRequired,
  fetchDePhases: PropTypes.func.isRequired,
  fetchDeMilestones: PropTypes.func.isRequired,
  fetchMilestones: PropTypes.func.isRequired,
  fetchEvaluationProducts: PropTypes.func.isRequired,
  showPhaseModal: PropTypes.bool.isRequired,
  dePhases: PropTypes.arrayOf(PropTypes.object).isRequired,
  deMilestones: PropTypes.arrayOf(PropTypes.object).isRequired,
  phases: PropTypes.arrayOf(PropTypes.object).isRequired,
  milestones: PropTypes.arrayOf(PropTypes.object).isRequired,
  resetPhasesForm: PropTypes.func.isRequired,
  phasesFormValues: PropTypes.shape(dePhasePropTypes),
  messages: PropTypes.arrayOf(PropTypes.string),
  canEdit: PropTypes.bool.isRequired,
  toggleMilestones: PropTypes.func.isRequired,
  milestoneVisible: PropTypes.number.isRequired,
  savePhasesAndMilestones: PropTypes.func.isRequired,
  resetEventDate: PropTypes.func.isRequired,
  resetDatesRange: PropTypes.func.isRequired,
  isDoneMilestones: PropTypes.arrayOf(PropTypes.number).isRequired,
  shiftPhasesRanges: PropTypes.func.isRequired,
};

/** Default values when creating new evaluation */
PhasesComponent.defaultProps = {
  category: undefined,
  messages: ["Please add all phases"],
  initialPhases: { phases: [] },
  phasesFormValues: undefined
};

export const mapStateToProps = (state, ownProps) => {
  const page = state.pages.phases;
  const {
    entities,
    messages: { phases: phasesMsg, milestones: milestonesMsg }
  } = state;

  const messages = getMessages(phasesMsg).concat(getMessages(milestonesMsg));

  // Phases
  const phasesFormValues = state.form.evPhases
    ? state.form.evPhases.values
    : {};
  const dePhases =
    page.items.map(id => {
      const phase = entities.dePhases[id];
      if (phase.is_deprecated) {
        return null;
      }

      return {
        ...phase,
        color: get(
          entities.phases,
          `[${get(entities.dePhases[id], "phase")}].color`
        )
      };
    }) || [];

  // retrieve data related to phases and milestones that will be used in the phases modal
  const initialPhases = PhaseFormTransformer.transformToFormValueWithMilestone(
      entities.phases,
      dePhases,
      entities.milestones,
      page.milestones.map(id => entities.deMilestones[id]),
      ownProps.category,
  );

  const productsByType = Object.values(entities?.products ?? {}).filter(product => state.pages.products.items.includes(product.id)).reduce((acc, curr) => ({
    ...acc, [curr.kind] : curr,
  }), {});

  // milestones are filtered, deleted milestones must not be shown
  // and user can only use milestones related to a specific category
  const milestones = Object.values(entities.milestones)
    .filter(m => !m.deleted)
    .filter(m => {
      if (ownProps.category === Categories.DECENTRALIZED) {
        return m.isForDe;
      }
      if (ownProps.category === Categories.CENTRALIZED) {
        return m.isForCe;
      }
      if (ownProps.category === Categories.IMPACT) {
        return m.isForIe;
      }
      return true;
    })
    .map((m) => ({...m, product: productsByType?.[m.productType] ?? null }))
    .sort((firstEl, secondEl) => firstEl.position - secondEl.position);

  const isMilestoneOutOfCalendar = (milestone, deMilestone) =>
    (!milestone.hasDate && !milestone.hasPeriod) || !deMilestone.isDone;

  const deMilestones = page.milestones.map(id => {
    const deMilestone = entities.deMilestones[id];
    const milestone = entities.milestones[deMilestone.milestone];
    const phase =
      milestone && milestone.phase && entities.phases[milestone.phase];
    return {
      ...deMilestone,
      color: phase && phase.color,
      phase,
      calendarDisplay: milestone ? milestone.name : "",
      outOfCalendar: milestone
        ? isMilestoneOutOfCalendar(milestone, deMilestone)
        : true,
      hasTickbox: true,
      hasPeriod: milestone && milestone.hasPeriod,
      hasDate: milestone && milestone.hasDate,
      order: milestone && milestone.order
    };
  });

  const existingMilestonesId = page.milestones.map(milestone => entities.deMilestones?.[milestone]?.milestone);

  const phases = Object.values(entities.phases)
    .filter(p => !p.deleted && !p.is_deprecated)
    .filter(p => {
      if (ownProps.category === Categories.DECENTRALIZED) {
        return p.is_for_de;
      }
      if (ownProps.category === Categories.CENTRALIZED) {
        return p.is_for_ce;
      }
      if (ownProps.category === Categories.IMPACT) {
        return p.is_for_ie;
      }
      return true;
    })
     .sort((p1, p2) => p1.ordering - p2.ordering)
    .map(ph => ({
      ...ph,
      milestones: Object.values(milestones).filter(m => {
        return m.phase === ph.id && (!m.isDeprecated || existingMilestonesId.includes(m.id))
      })
    }));

  /** List of done milestones (a milestone that not is done can't have dates) */
  const isDoneMilestones = [];
  if (phasesFormValues.phases) {
    phasesFormValues.phases.map(phase => {
      phase.milestones.map((milestone, key) => {
        if (milestone.isDone && milestone.isDone.value) {
          isDoneMilestones.push(key);
        }
        return null;
      });
      return null;
    });
  }

  return {
    // messages: messagesList,
    messages,
    isFetching: page.isFetching,
    // Phase
    phasesFormValues,
    // dePhases
    dePhases,
    initialPhases,
    phases,
    // deMilestones
    deMilestones,
    milestones,
    // Modal
    showPhaseModal: page.phaseAddModal,
    showMilestoneModal: page.milestoneAddModal,
    milestoneVisible: page.toggleMilestones.milestoneVisible,
    isDoneMilestones
  };
};

export const mapDispatchToProps = dispatch => ({
  // Fetch
  fetchValidation: (evaluationId, category) =>
    dispatch(getEvaluationActions(category).validation(evaluationId)),
  fetchDePhases: evaluationId =>
    dispatch(
      dePhasesActions.list(
        {},
        { pathParams: { evaluationId }, paginated: false }
      )
    ),
  fetchPhases: () => dispatch(phasesActions.list({}, { paginated: false })),
  fetchDeMilestones: evaluationId =>
    dispatch(
      deMilestonesActions.list(
        {},
        { pathParams: { evaluationId }, paginated: false }
      )
    ),
  fetchMilestones: () =>
    dispatch(milestonesActions.list({}, { paginated: false })),
  fetchEvaluationProducts: evaluationId =>
    dispatch(
      productsActions.list(
        {},
        { paginated: false, pathParams: { evaluationId } }
      )
    ),
  savePhasesAndMilestones: (evaluationId, phases, category) =>
    dispatch(dePhasesActions.savePhasesAndMilestones(evaluationId, phases, category)),
  // Select
  selectPhase: id => dispatch({ type: "SELECT_PHASE", id }),
  selectMilestone: id => dispatch({ type: "SELECT_MILESTONE", id }),
  // Modal
  togglePhaseModal: () => dispatch(toggle("PHASE_ADD_MODAL")),
  toggleMilestoneModal: () => dispatch(toggle("MILESTONE_ADD_MODAL")),
  // Form
  resetPhasesForm: () => dispatch(reset("evPhases")),
  toggleMilestones: milestoneVisible =>
    dispatch({ type: "TOGGLE_MILESTONES", milestoneVisible }),
  resetEventDate: index =>
    dispatch(change("evPhases", `${index}.eventDate`, null)),
  shiftPhasesRanges: (startPhaseId, forwardShiftingDays, backwardShiftingDays) =>
      dispatch(dePhasesActions.shiftPhasesRanges(startPhaseId, forwardShiftingDays, backwardShiftingDays)),
  resetDatesRange: index =>
    dispatch(
      change("evPhases", `${index}.datesRange`, {
        startDate: null,
        endDate: null
      })
    )
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PhasesComponent)
);
