import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Tooltip from "Tooltip";

import WeekRow from "./WeekRow";
import Line from "./Line";

import Styles from "./styles.scss";

import { DEFAULT_DATE_FORMAT } from "../../../../../../../../utils";

const PhaseTooltip = ({ label, startDate, endDate, backgroundColor }) => {
  const info = (
    <p className={Styles.phaseInfoMessage}>
      Phase: <span>{label}</span> <br />
      Start date: <span>{startDate}</span> - End date: <span>{endDate}</span>
    </p>
  );

  return <Tooltip message={info} color={backgroundColor} iconType="info" />;
};

PhaseTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired
};

const findWeekRow = date => {
  let clonedDate = date;

  if (!(clonedDate instanceof moment)) {
    clonedDate = moment(date, DEFAULT_DATE_FORMAT);
  }
  const result = Math.ceil(clonedDate.date() / 7);
  const row = result > 4 ? 4 : result;
  return row;
};

/** Checks if the given month (0-11) is the current one */
const checkMonthCurrent = value => moment().month() === value;

const monthLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const Month = ({ phases, milestones, month, year, onMilestoneClick }) => {
  const weekColors = phases.map(ph => {
    let startWeek = 1;
    let endWeek = 4;

    const startDate = moment(ph.start_date, DEFAULT_DATE_FORMAT);
    const endDate = moment(ph.end_date, DEFAULT_DATE_FORMAT);

    if (endDate.month() === month - 1 && `${endDate.year()}` === year) {
      endWeek = findWeekRow(endDate);
    }

    if (startDate.month() === month && `${startDate.year()}` === year) {
      startWeek = findWeekRow(startDate);
    }

    if (endDate.month() === month && `${endDate.year()}` === year) {
      endWeek = findWeekRow(endDate);
    }

    return { color: ph.color, startWeek, endWeek };
  });

  /** Used to position each milestone in the correct phase row */
  const orderedMilestones = {
    1: [],
    2: [],
    3: [],
    4: []
  };

  milestones.map(milestone => {
    if (milestone.startDate !== null) {
      const result = findWeekRow(milestone.startDate);
      orderedMilestones[result].push(milestone);
    }

    if (milestone.eventDate !== null) {
      const result = findWeekRow(milestone.eventDate);
      orderedMilestones[result].push(milestone);
    }

    // if the milestone has no eventDate or neither a startDate
    // we use its phase start date to give a right positioning
    if (milestone.eventDate === null && milestone.startDate === null) {
      const result = findWeekRow(milestone.phase.start_date);
      orderedMilestones[result].push(milestone);
    }

    return null;
  });

  const getColor = week =>
    weekColors.find(wc => wc.startWeek <= week && wc.endWeek >= week) || {
      color: "transparent"
    };

  const color1 = getColor(1).color;
  const color2 = getColor(2).color;
  const color3 = getColor(3).color;
  const color4 = getColor(4).color;

  // check if the current phase is starting this year
  const phasesStartWeeks = {
    1: null,
    2: null,
    3: null,
    4: null
  };
  phases.map(ph => {
    const startDate = moment(ph.start_date, DEFAULT_DATE_FORMAT);
    if (`${startDate.year()}` === year && startDate.month() === month) {
      phasesStartWeeks[findWeekRow(startDate)] = (
        <PhaseTooltip
          label={ph.phase_display}
          startDate={ph.start_date}
          endDate={ph.end_date}
          backgroundColor={ph.color}
        />
      );
    }
    return null;
  });

  return (
    <li className={Styles.month}>
      <div className={Styles.monthLabel}>
        <span
          className={checkMonthCurrent(month) ? `${Styles.freschezza}` : ""}
        >
          {monthLabels[month]}
        </span>
      </div>
      <div className={Styles.monthWeeks}>
        <WeekRow
          phaseBgColor={color1}
          phaseInfo={phasesStartWeeks[1]}
          milestones={orderedMilestones[1]}
          onMilestoneClick={onMilestoneClick}
        />
        <WeekRow
          phaseBgColor={color2}
          phaseInfo={phasesStartWeeks[2]}
          milestones={orderedMilestones[2]}
          onMilestoneClick={onMilestoneClick}
        />
        <WeekRow
          phaseBgColor={color3}
          phaseInfo={phasesStartWeeks[3]}
          milestones={orderedMilestones[3]}
          onMilestoneClick={onMilestoneClick}
        />
        <WeekRow
          phaseBgColor={color4}
          phaseInfo={phasesStartWeeks[4]}
          milestones={orderedMilestones[4]}
          onMilestoneClick={onMilestoneClick}
        />
      </div>
      {moment().month() === month && `${moment().year()}` === year && <Line />}
    </li>
  );
};

Month.propTypes = {
  phases: PropTypes.arrayOf(PropTypes.object).isRequired,
  milestones: PropTypes.arrayOf(PropTypes.object).isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.string.isRequired,
  onMilestoneClick: PropTypes.func.isRequired
};

export default Month;
