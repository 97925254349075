import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import CRUDLayout, {CRUDMapStateToPropsPropTypes, CRUDMapDispatchToProps, CRUDMapDispatchToPropsPropTypes, CRUDMapStateToProps} from "CRUDLayout";
import topicActions from "../../actions/topics";
import TableWithModalsMixin from "../../components/TableWithModalsMixin";
import TopicModal from "./TopicModal";
import columnsGenerator from "./tableColumns";


export class TopicListComponent extends TableWithModalsMixin {
    render = () => (
      <CRUDLayout
        {...this.props}
        title='Topics'
        entityName='Topic'
        columnsGenerator={columnsGenerator}
        addModalComponent={TopicModal}
        getItem={this.getItem}
        onSave={this.saveItem}
        handleDragEnd={this.handleDragEnd}
      />
    );
}

TopicListComponent.propTypes = {
  ...CRUDMapStateToPropsPropTypes,
  ...CRUDMapDispatchToPropsPropTypes,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      isDeprecated: PropTypes.bool
    })
  ).isRequired,
  selectedItem: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    isDeprecated: PropTypes.bool
  }),
};

export const mapStateToProps = state => (
  CRUDMapStateToProps(
    state,
    'topics',
    'exercise.add_topic',
    'exercise.change_topic'
  )
)

export const mapDispatchToProps = dispatch => (
  CRUDMapDispatchToProps(dispatch, topicActions, 'topics')
);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TopicListComponent)
);
