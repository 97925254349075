import React from "react";

import { Footer as FooterUIKit } from "@wfp/ui";

import { MIS_EMAIL, MIS_MAIL_TO } from "../../utils";

const Footer = () => (
  <FooterUIKit
    labelOne="2019 World Food Programme"
    linkTextOne={MIS_EMAIL}
    linkHrefOne={MIS_MAIL_TO}
    labelTwo=""
    linkTextTwo=""
  />
);

export default Footer;
