import { schema } from "normalizr";
import { productTypesTypes } from "../types";
import Resource from "./resource";

const productTypesSchema = new schema.Entity("productTypes");
const productTypesActions = new Resource(
  "/product-types",
  productTypesTypes,
  productTypesSchema
);

export default productTypesActions;