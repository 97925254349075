import ResourceTypes from "./resource";
//
// Please respect alphabetical order to find things faster
//

export const activityCategoriesTypes = new ResourceTypes("activityCategories");
export const WindowsTypes = new ResourceTypes("Windows");
export const enhancedActivityTypes = new ResourceTypes("enhancedActivity");
export const bureauxTypes = new ResourceTypes("bureaux");
export const centralizedEvaluationTypes = new ResourceTypes(
  "centralizedEvaluations"
);
export const ceStatusTypes = new ResourceTypes("centralizedStatuses");
export const ieStatusTypes = new ResourceTypes("impactStatuses");
export const companiesTypes = new ResourceTypes("companies");
export const contractTypesTypes = new ResourceTypes("contractTypes");
export const countriesInfoTypes = new ResourceTypes("countriesInfo");
export const countriesTypes = new ResourceTypes("countries");
export const coverageTypes = new ResourceTypes("coverage");
export const deMilestonesTypes = new ResourceTypes("deMilestones");
export const dePhasesTypes = new ResourceTypes("dePhases");
export const deStatusTypes = new ResourceTypes("decentralizedStatuses");
export const deviationTypes = new ResourceTypes("deviations");
export const donorsTypes = new ResourceTypes("donors");
export const crosscuttingPrioritiesTypes = new ResourceTypes("crosscuttingPriorities");
export const epiframeworkTypes = new ResourceTypes("epiframeworks");
export const evaluationTypes = new ResourceTypes("evaluations");
export const expertisesTypes = new ResourceTypes("expertises");
export const genderTypes = new ResourceTypes("gender");
export const listEvaluationTypes = new ResourceTypes("listEvaluations");
export const membersTypes = new ResourceTypes("members");
export const milestonesTypes = new ResourceTypes("milestones");
export const partnersCategoriesTypes = new ResourceTypes("partnersCategories");
export const partnersTypes = new ResourceTypes("partners");
export const peopleTypes = new ResourceTypes("people");
export const phasesTypes = new ResourceTypes("phases");
export const productsTypes = new ResourceTypes("products");
export const productTypesTypes = new ResourceTypes("productTypes");
export const reasonsForCancellationTypes = new ResourceTypes(
    "reasonsForCancellation"
);
export const requirementConditionsTypes = new ResourceTypes('requirementConditions')
export const requirementsTypes = new ResourceTypes('requirements')
export const teamRolesTypes = new ResourceTypes("teamRoles");
export const servicesTypes = new ResourceTypes("services");
export const standardsTypes = new ResourceTypes("standards");
export const standardsFrameworkTypes = new ResourceTypes("standardsFrameworks");
export const undisesTypes = new ResourceTypes("undises");
export const undisFrameworkTypes = new ResourceTypes("undisFrameworks");
export const statusValidationSchemasTypes = new ResourceTypes("statusValidationSchemas");
export const sustainableDevelopmentGoalsTypes = new ResourceTypes(
  "sustainableDevelopmentGoals"
);
export const titlesTypes = new ResourceTypes("titles");
export const topicsTypes = new ResourceTypes("topics");
export const typesTypes = new ResourceTypes("types");
export const usersTypes = new ResourceTypes("users");
export const userTypes = new ResourceTypes("user");
export const wfpDivisionsTypes = new ResourceTypes("wfpDivisions");
export const wfpOfficesTypes = new ResourceTypes("wfpOffices");
export const wfpUsersTypes = new ResourceTypes("wfpUsers");
