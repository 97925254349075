import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import TextInput from "TextInput";
import ModalForm from "ModalForm";
import Checkbox from "Checkbox";
import Select from "Select";

const MilestoneModal = props => (
  <ModalForm
    data-testid="rfc-modal-container"
    title={props.title}
    isVisible={props.show}
    onClose={props.toggleModal}
    onSubmit={e => {
      e.preventDefault();
      props.onSubmit();
    }}
    onFormSubmit={e => {
      e.preventDefault();
      props.onSubmit();
    }}
    buttonDisabled={props.disabled}
    hiddenButtonDisabled={props.disabled}
  >
    <Field name="name" label="Name" component={TextInput} />
    <Field name="code" label="Code" component={TextInput} />
    <Field
      name="phase"
      label="Phase"
      component={Select}
      options={props.phaseOptions}
      closeMenuOnSelect
      openMenuOnClick
    />
    <Field
      name="productType"
      label="Product"
      component={Select}
      options={props.productTypeOptions}
      closeMenuOnSelect
      openMenuOnClick
    />
    <Field name="isForCe" label="Centralized" component={Checkbox} />
    <Field name="isForDe" label="Decentralized" component={Checkbox} />
    <Field name="isForIe" label="Impact" component={Checkbox} />
    <Field name="hasPeriod" label="Requires period" component={Checkbox} />
    <Field name="hasDate" label="Has date" component={Checkbox} />
    <Field name="isDeprecated" label="Deprecated" component={Checkbox} />
    <Field name="isValidationDisabled" label="Validation disabled" component={Checkbox} />
  </ModalForm>
);

MilestoneModal.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({ id: PropTypes.any }), // eslint-disable-line
  options: PropTypes.object, // eslint-disable-line
  phaseOptions: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  productTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
};

MilestoneModal.defaultProps = {
  show: false,
  initialValues: {},
  disabled: false,
  phaseOptions: [],
};

export default reduxForm({
  form: "milestones",
  enableReinitialize: true
})(MilestoneModal);
