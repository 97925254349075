import React from "react";
import moment from "moment";

import Styles from "./styles.scss";

const Line = () => (
  <div
    className={Styles.redLine}
    style={{
      top: `${moment().date() * (100 / moment().daysInMonth())}%`
    }}
  />
);

export default Line;
