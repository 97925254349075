import React from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Button } from "@wfp/ui";
import { iconAddGlyph } from "@wfp/icons";
import SecondaryNavigation from "SecondaryNavigation";
import Loading from "Loading";
import ListComponent from "ListComponent";
import sdgActions from "../../actions/sustainableDevelopmentGoals";
import { toggle } from "../../actions/togglable";
import SDGModal from "./SDGModal";
import columnsGenerator from "./tableColumns";
import TableWithModalsMixin from "../../components/TableWithModalsMixin";


export class SDGListComponent extends TableWithModalsMixin {
  componentDidMount() {
    this.props.fetchData();
  }

  render() {
    return this.props.isFetching ? (
      <Loading />
    ) : (
      <div>
        <SecondaryNavigation
          additional={
            <Button
              type="submit"
              icon={iconAddGlyph}
              iconDescription="Add a new SDG"
              disabled={!this.props.canAdd}
              onClick={() => this.getItem(null)}
            >
              New Sustainable Development Goal
            </Button>
          }
          title="Sustainable Development Goals"
        />

        <SDGModal
          title={`${this.props.selectedItem?.id ? 'Edit' : 'New' } Sustainable Development Goal`}
          initialValues={this.props.selectedItem}
          toggleModal={this.props.toggleAddModal}
          show={this.props.showSDGModal}
          onSubmit={this.saveItem}
          canEdit={this.props.canEdit}
        />

        <ListComponent
          fetching={this.props.isFetching}
          tableData={this.props.data}
          columns={columnsGenerator(this.getItem, this.props.canEdit)}
          noSearch
          enableDragAndDropSorting
          handleDragEnd={(results) => this.handleDragEnd(results)}

        />
      </div>
    );
  }
}

SDGListComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      is_deprecated: PropTypes.bool
    })
  ).isRequired,
  canEdit: PropTypes.bool,
  canAdd: PropTypes.bool,
  selectedItem: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    is_deprecated: PropTypes.bool
  }),
  formValues: PropTypes.shape({}),
  showSDGModal: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired
};

export const mapStateToProps = state => {
  const userPermissions = get(state, "user[0].permissions.permission", []);
  const sdgEntitities = get(state, "entities.sustainableDevelopmentGoals", {});

  const page = get(state, "pages.sustainableDevelopmentGoals");
  const data =
    page &&
    page.items &&
    page.items
      .map(id => sdgEntitities[id])
      .sort((a, b) => a.position - b.position);

  const formValues = state.form.sustainableDevelopmentGoals
    ? state.form.sustainableDevelopmentGoals.values
    : {};

  const canEdit = userPermissions.includes(
    "exercise.change_sustainabledevelopmentgoal"
  );

  const canAdd = userPermissions.includes(
    "exercise.add_sustainabledevelopmentgoal"
  );

  const selectedSDG = sdgEntitities[page.selectedSDG];

  return {
    data,
    canAdd,
    canEdit,
    selectedItem: selectedSDG,
    formValues,
    isFetching: page.isFetching,
    showSDGModal: page.addModal
  };
};

export const mapDispatchToProps = dispatch => ({
  saveItem: item => dispatch(sdgActions.save(item)),
  fetchData: () => dispatch(sdgActions.list({}, { paginated: false })),
  selectItem: id => dispatch({ type: "SELECT_SDG", id }),
  saveOrderingPosition: (items) => dispatch(sdgActions.saveOrderingPosition(items)),
  toggleAddModal: () => dispatch(toggle("SDG_ADD_MODAL")),
  resetForm: () => dispatch(reset("sustainableDevelopmentGoals"))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SDGListComponent)
);
