import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ReactRouterPropTypes from "react-router-prop-types";
import { Route, Prompt, Redirect, Switch } from "react-router-dom";
import { isDirty } from "redux-form";

/* Components */
import { isCreate } from "../../../../utils";
import EnhancedActivity from "./components/EnhancedActivity";
import Info from "./components/Info";
import Coverage from "./components/Coverage";
import Phases from "./components/Phases";
import Members from "./components/Members";
import Products from "./components/Products";

import styles from "./styles.scss";
import {PHQA} from "./components/PHQA";
import {useGetCategory} from "../../hooks";

// Exported for tests
export const promptMessage = (location, evaluationId, isFormDirty) => {
  if (
    // If we are in creation phase
    isCreate(evaluationId) &&
    // And next url is a detail evaluations (starts with /evaluations/)
    location.pathname.startsWith("/evaluations/") &&
    // And the next detail page is not a creation one
    !location.pathname.startsWith("/evaluations/0/")
  ) {
    // That means the user clicked on save and is changing url through
    // that action so we should allow the user to go ahead without warnings
    return location;
  }

  // Otherwise just check if any of the forms is dirty
  return isFormDirty
    ? "Are you sure? \nThere are unsaved changes that will be lost!"
    : location;
};

const ContentComponent = ({canEdit, isFormDirty, match: {path, params}}) => {
    const category = useGetCategory();
    return (
      <div className={styles.mainContent}>
        <Prompt
          when
          message={location =>
            promptMessage(
              location,
              params.evaluationId,
              isFormDirty
            )
          }
        />
        <Switch>
          <Route
            path={`${path}enhanced-activity`}
            component={EnhancedActivity}
          />
          <Route
            path={`${path}info`}
            render={() => <Info canEdit={canEdit} category={category} />}
          />
          <Route
            path={`${path}coverage`}
            render={() => <Coverage canEdit={canEdit} category={category} />}
          />
          <Route
            path={`${path}phases`}
            render={() => <Phases canEdit={canEdit} category={category} />}
          />
          <Route
            path={`${path}members`}
            render={() => <Members canEdit={canEdit} category={category} />}
          />
          <Route
            path={`${path}products`}
            render={() => <Products canEdit={canEdit} category={category} />}
          />
          <Route
            path={`${path}phqa*`}
            render={() => <PHQA canEdit={canEdit} category={category} />}
          />
          <Route
            exact
            path="/evaluations/:evaluationId"
            render={props => (
              <Redirect to={`${props.location.pathname}/info`} />
            )}
          />
        </Switch>
      </div>
    );
}

ContentComponent.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

ContentComponent.defaultProps = {};

export const mapStateToProps = state => {
  const isFormDirty =
    isDirty("evInfo")(state) ||
    isDirty('evPHQAStandards')(state) ||
    isDirty('evPHQAGender')(state) ||
    isDirty('evUndis')(state)

  return {
    isFormDirty, // If we are creating an evaluation it always returns false
    isSectionOpen: state.pages.leftColumn.section
  };
};

const mapDispatchToProps = () => {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContentComponent)
);
