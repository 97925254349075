import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import DraggableTrComponent from './components/DraggableTrComponent'
import columnsGenerator from "./tableColumns";

export class SortableReactTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
    this.columns = columnsGenerator(props.columns);
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.data !== this.props?.data) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ data: this.props.data })
    }
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  handleDragEnd = result => {
    if (!result.destination) {
      return;
    }

    this.setState((prevState) => {
      const data = this.reorder(
        prevState.data,
        result.source.index,
        result.destination.index
      );

      if (this.props.handleDragEnd) {
        this.props.handleDragEnd(data);
      }

      return { data }
    });
  };

  render() {
    return (
      <DragDropContext onDragEnd={(result) => this.handleDragEnd(result)}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div ref={provided.innerRef}>
              <ReactTable
                {...this.props}
                columns={columnsGenerator(this.props.columns)}
                TrComponent={DraggableTrComponent}
                getTrProps={(state, rowInfo) => ({rowInfo, getDraggableId: this.props.getDraggableId})}
                data={this.state.data}
              />
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

SortableReactTable.propTypes = {
  ACTIVITYPAGE: PropTypes.bool, // Temporary prop currently used only for activity page
  // Table visualization management
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  getTdProps: PropTypes.func,
  // Modals props
  // Filters
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      active: PropTypes.bool,
      onClick: PropTypes.func.isRequired
    })
  ),
  // Params only used for testing purposes
  mocked: PropTypes.bool,
  noSearch: PropTypes.bool,
  page: PropTypes.number,
  pages: PropTypes.number,
  totalItems: PropTypes.number,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  pageSizesDisabled: PropTypes.bool,
  searchOnChange: PropTypes.func,
  enableDragAndDropSorting: PropTypes.bool,
  getDraggableId: PropTypes.func,
  handleDragEnd: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

SortableReactTable.defaultProps = {
  ACTIVITYPAGE: false,
  filters: [],
  mocked: false,
  noSearch: false,
  getTdProps: undefined,

  page: undefined,
  pages: undefined,
  pageSize: undefined,
  pageSizesDisabled: false,
  totalItems: undefined,
  onPageChange: undefined,
  enableDragAndDropSorting: false,
  getDraggableId: (item) => item.id.toString(),
  searchOnChange: () => {},
  handleDragEnd: undefined,
};
