export default {
  REQUIRED_FIELD: 'RequiredField',
  COMMISSIONING: 'Commissioning',
  TOPICS: 'Topics',
  ACTIVITY_CATEGORIES: 'ActivityCategories',
  COVERAGE: 'Coverage',
  TEAM_LEADER: 'TeamLeader',
  TEAM_CONTRACT: 'TeamContract',
  TEAM_MEMBERS: 'TeamMembers',
  PHASES: 'Phases',
  PHASES_DATES: 'PhasesDates',
  MILESTONE: 'Milestone',
  PRODUCT: 'Product',
  PHQA_STANDARDS: 'PhqaStandards',
  PHQA_EPI: 'PhqaEPI',
  SUSTAINABLE_DEVELOPMENT_GOALS: 'SustainableDevelopmentGoals',
  READ_ONLY_FIELD: 'ReadOnlyField',
  READ_ONLY_REQUIRED_FIELD: 'ReadOnlyRequiredField',
}
