import Select from "Select";
import {Field} from "redux-form";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {adaptInputValues, sortByPosition} from "../../utils";
import {advancedFilterActions} from "../../../../actions/filters";


const Topics = ({label, fieldName, isDisabled}) => {
  const dispatch = useDispatch();

  const topics = Object.values(useSelector(state => state.entities.topics))
    .sort(sortByPosition)
    .map(t => ({value: t.id, label: t.name}));

  const onChange = inputs => {
    if (inputs !== null && inputs.length > 0) {
      dispatch(advancedFilterActions.addFilter(fieldName, inputs, {
        topics: adaptInputValues(inputs)
      }));
    } else {
      dispatch(advancedFilterActions.removeFilter(fieldName));
    }
  }
  useEffect(() => {
     // There is a topics fetch at app level
     // TODO: maybe we should check the topics are empty or not and if empty fetch?
  }, [])


  return (
    <Field
      name={fieldName}
      label={label}
      component={Select}
      options={topics}
      onChangeExtra={onChange}
      openMenuOnClick
      disabled={isDisabled}
      allowMultiple
    />
  )
}

export default Topics;