import { schema } from "normalizr";
import Resource from "./resource";
import { epiframeworkTypes } from "../types";

const epiFrameworkSchema = new schema.Entity("epiframeworks");

export default new Resource(
  "/epi-frameworks",
  epiframeworkTypes,
  epiFrameworkSchema
);
