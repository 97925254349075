import React from "react";
import PropTypes from "prop-types";

import { Blockquote } from "@wfp/ui";

/**
 * Standard component for displaying help messages about the whole tab
 *
 * if you pass `message` it displays a simple blockquote
 * if you pass `messageList` it displays a blockquote with a list of messages
 * If you pass both, `message` overrides `messagesList`
 *
 */
const HelpMessageBox = ({ message, messageList, title, messageType }) => {
  if (!message && messageList.length === 0) {
    return null;
  }

  if (message) {
    return (
      <Blockquote kind={messageType} title={title}>
        <p>{message}</p>
      </Blockquote>
    );
  }

  return (
    <div style={{ marginBottom: "24px" }}>
      <Blockquote kind={messageType} title={title}>
        <ul style={{ listStyleType: "disc", paddingLeft: "1.1rem" }}>
          {messageList && messageList.map((m, i) => <li key={`messagebox-${i}`}>{m}</li>)}
        </ul>
      </Blockquote>
    </div>
  );
};

HelpMessageBox.propTypes = {
  message: PropTypes.string,
  messageList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
  title: PropTypes.string,
  messageType: PropTypes.string
};

HelpMessageBox.defaultProps = {
  title: undefined,
  message: undefined,
  messageList: [],
  messageType: "warning"
};

export default HelpMessageBox;
