const destinations = {
  wfp_go: "WFPgo",
  wfp_org: "wfp.org",
  uneg_plans: "UNEG Plans",
  uneg_evaluations: "UNEG Evaluations",
  uneg_blog: "UNEG Blog",
  alnap: "ALNAP"
};

export default destinations;
