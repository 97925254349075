import React from "react";
import { Icon } from "@wfp/ui";
import {iconDraggable } from "@wfp/icons";

const columnsGenerator = (columns) => [
  {
    Header: "",
    accessor: "id",
    Cell: () => (
      <Icon
        icon={iconDraggable}
        description="Draggable item"
        style={{ cursor: "pointer" }}
      />
    ),
    resizable: false,
    sortable: false,
    width: 30,
    style: { justifyContent: "center" }
  },
  ...columns,
]

export default columnsGenerator;
