import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Loading} from "@wfp/ui";
import {change} from "redux-form";
import {useGetCategory, useGetDetailedEvaluation} from "../../../../../hooks";
import standardsActions, {standardsFramework} from "../../../../../../../actions/standards";
import {getEvaluationActions} from "../../../../../../../actions/evaluations";
import {generateFormCriteria} from "../utils";
import {Framework} from "../components/Framework";
import {CriterionForm} from "../components/CriterionForm";
import {CriterionRow} from "./CriterionRow";
import {getMessages, isCreate} from "../../../../../../../utils";


const PHQAStandards = ({canEdit}) => {
    const evaluation = useGetDetailedEvaluation();
    const category = useGetCategory();
    const dispatch = useDispatch();

    const page = useSelector(store => store.pages?.phqaStandards)

    const frameworks = useSelector((store) => store.entities.standardsFrameworks);
    const selectedFramework = useSelector((store) => store.form.evPHQAStandardFramework?.values?.framework);
    const standards = useSelector((store) => store.entities.standards);
    const messages = useSelector(store => store.messages.phqastandards);
    const memorizedMessages = useMemo(() => getMessages(messages), [messages]);

    const filteredStandards = Object.values(standards).filter(standard => page.items.includes(standard.id))
    const criteria = generateFormCriteria(filteredStandards, frameworks?.[selectedFramework?.id]);

    useEffect(() => {
        if (evaluation) {
            dispatch(standardsFramework.list({}, {paginated: false}));
            dispatch(standardsActions.list({}, {pathParams: {evaluationId: evaluation.id}, paginated: false}));
            dispatch(getEvaluationActions(category).standardAssessmentScoreAndRating(evaluation.id))
        }
    }, [evaluation?.id]);

    const onFrameworkSave = async (framework) => {
        await dispatch(getEvaluationActions(category).setStandardFramework(evaluation.id, { standardFramework: framework.value }));
        await dispatch(standardsActions.clear());

        // Force total score update
        await dispatch(getEvaluationActions(category).saveTotalScore(evaluation.id));
    }

    const onCriterionSave = async ({items, totalScore}) => {
         await dispatch(standardsActions.savePHQAStandards(evaluation.id, items));

         // Force total score update
         await dispatch(getEvaluationActions(category).saveTotalScore(evaluation.id, totalScore));
    }

    useEffect(() => {
        dispatch(change('evPHQAStandards', 'totalScore', page.totalScore.totalScore));
        dispatch(change('evPHQAStandards', 'totalRating', page.totalScore.totalRating));
    }, [page.totalScore]);

    const readOnly = !canEdit || isCreate(evaluation?.id);

    if (!frameworks || !evaluation) {
        return <Loading />;
    }

    return (
      <div>
        <Framework
          // eslint-disable-next-line camelcase
          initialFramework={frameworks?.[evaluation?.standard_framework]}
          frameworks={frameworks}
          readOnly={readOnly}
          formName='evPHQAStandardFramework'
          onChange={onFrameworkSave}
          isScoresFilled={filteredStandards.length > 0}
      />
        <CriterionForm
          form='evPHQAStandards'
          messages={memorizedMessages}
          initialValues={{
            ...page.totalScore,
            items: criteria,
          }}
          readOnlyTotalScore={readOnly}
          save={onCriterionSave}
          readOnly={readOnly}
          rowComponent={CriterionRow}
          defaultMessage='Please add score % on all criteria'
      />
      </div>
    )
}


export default PHQAStandards;


