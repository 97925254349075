import {setEvaluationPage} from "./evaluations";

export const filterActions = {
  toggleFilter: (filterName) => dispatch => {
    dispatch({type: "FILTER_TOGGLE", data: {id: filterName}});
    // TODO: which set page is working?
    dispatch(setEvaluationPage(0));
  },
  setFilter: (filterName, value) => dispatch => {
    dispatch({type: "SET_FILTER", filterName, value});
    // TODO: which set page is working?
    dispatch(setEvaluationPage(0));
  }
}

export const advancedFilterActions = {
  addFilter: (fieldName, inputs, queryParams) => dispatch => {
    dispatch({type: "ADD_FILTER", filterName: fieldName, filter: queryParams, inputs});
    dispatch({type: "SET_PAGE", data: 0});
  },
  removeFilter: (filterName) => dispatch => {
    dispatch({type: "REMOVE_FILTER", filterName});
    dispatch({type: "SET_PAGE", data: 0});
  },
  showAdvancedFilter: (filterName) => dispatch => {
    dispatch({type: "SHOW_ADVANCED_FILTER", filterName})
  },
  hideAdvancedFilter: (filterName) => dispatch => {
    dispatch({type: "HIDE_ADVANCED_FILTER", filterName})
  },

}