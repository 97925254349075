import React from "react";

import { Icon } from "@wfp/ui";
import { iconEdit, iconDelete } from "@wfp/icons";

import { capitalize, sortDates } from "../../../../../../utils";

const columnsGenerator = (setItem, setItemToDelete, canEdit) => [
  {
    Header: "RB / LO / HQ / Country",
    accessor: "label",
    Cell: row =>
      row &&
      row.original &&
      (row.original.office_display || row.original.country_display),
    minWidth: 200
  },
  {
    Header: "Type Of Coverage",
    accessor: "type_of_coverage_display"
  },
  {
    Header: "Phase",
    accessor: "phase_display",
    Cell: row => row && row.value && capitalize(row.value.toLowerCase())
  },
  {
    Header: "Start date",
    accessor: "start_date",
    sortMethod: sortDates
  },
  {
    Header: "End date",
    accessor: "end_date",
    sortMethod: sortDates
  },
  {
    Header: "Status",
    accessor: "status_display",
    // Last column must not be resizable
    resizable: canEdit
  },
  ...(!canEdit
    ? []
    : [
        {
          Header: "",
          accessor: "id",
          /* eslint-disable */
          Cell: row => (
            <Icon
              icon={iconEdit}
              width="19"
              height="19"
              description="Edit item"
              onClick={canEdit ? () => setItem(row.value) : undefined}
              style={
                canEdit
                  ? { cursor: "pointer" }
                  : { opacity: "0.5", cursor: "pointer" }
              }
            />
          ),
          /* eslint-enable */
          resizable: false,
          sortable: false,
          width: 50,
          style: { justifyContent: "center" }
        },
        {
          Header: "",
          accessor: "id",
          /* eslint-disable */
          Cell: row => (
            <Icon
              icon={iconDelete}
              width="19"
              height="19"
              description="Delete item"
              onClick={canEdit ? () => setItemToDelete(row.value) : undefined}
              style={
                canEdit
                  ? { cursor: "pointer" }
                  : { opacity: "0.5", cursor: "pointer" }
              }
            />
          ),
          /* eslint-enable */
          resizable: false,
          sortable: false,
          width: 50,
          style: { justifyContent: "center" }
        }
      ])
];

export default columnsGenerator;
