import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field, FieldArray } from "redux-form";
import { Blockquote } from "@wfp/ui";

import { Grid, Row, Col } from "Grid";
import RangeDatePickerField from "DatePicker/RangeDatePicker";
import SingleDatePickerField from "DatePicker/SingleDatePicker";
import HiddenField from "HiddenField";
import Loading from "Loading";
import ModalForm from "ModalForm";
import Select from "Select";
import TextInput from "TextInput";

import {toast} from "react-toastify";
import Styles from "../styles.scss";


// Exported for test
export const ProductMilestone = ({milestone}) => (
  <div className={Styles.milestoneFields}>
    <Grid>
      <Row middle="xs">
        <Col xs={4} maxWidth="225px">
          <fieldset>
            <p className={Styles.nameLabel}>{milestone.name}</p>
          </fieldset>
        </Col>
        <Col xs={2} fixedWidth="130px">
          {/* eslint-disable-next-line camelcase */}
          <TextInput readOnly input={{ value: milestone?.product?.release_date ? 'Yes' : '' }} />
        </Col>
        <Col md={3}>
          {/* eslint-disable-next-line camelcase */}
          <TextInput readOnly input={{ value: milestone?.product?.release_date ?? '' }} />
        </Col>
      </Row>
    </Grid>
  </div>
)

// Exported for test
export const MilestoneLine = ({
                                phase,
                                milestones,
                                setDatesRangeMilestone,
                                initialVisibleMonth,
                                selectIsDone,
                                isDoneMilestones,
                                isTestMode
                              }) => (
                                <div className={Styles.milestoneLine}>
                                  <Grid>
                                    <Row>
                                      <Col xs={4}>
                                        <fieldset>
                                          <b htmlFor="milestone">Milestone</b>
                                        </fieldset>
                                      </Col>
                                      <Col xs={2} fixedWidth="130px">
                                        <fieldset>
                                          <b htmlFor="done">Done</b>
                                        </fieldset>
                                      </Col>
                                      <Col xs={6}>
                                        <fieldset>
                                          <b htmlFor="dates">&nbsp;</b>
                                        </fieldset>
                                      </Col>
                                    </Row>
                                  </Grid>
                                  {milestones.map(milestone => {

        if (milestone.productType) {
          return <ProductMilestone milestone={milestone} />
        }
        let milestoneDateRange = setDatesRangeMilestone;

        if (milestone.isValidationDisabled) {
            milestoneDateRange = undefined;
        }

        return (
          <div
            className={Styles.milestoneFields}
            key={`milestone_container_phases[${phase}].milestones_${milestone.id}`}
            >
            <Grid>
              <Row middle="xs">
                <Col xs={4} maxWidth="225px">
                  <fieldset>
                    <p name={`${milestone}`} className={Styles.nameLabel}>
                      {milestone.name}
                    </p>
                  </fieldset>
                </Col>
                <Col xs={2} fixedWidth="130px">
                  <Field
                    name={`phases[${phase}].milestones[${milestone.id}].isDone`}
                    options={[
                          {value: true, label: "Yes"},
                          {value: false, label: "No"}
                        ]}
                    component={Select}
                    testId={`phases[${phase}].milestones[${milestone.id}].isDone`}
                    openMenuOnClick
                    onChange={e =>
                            selectIsDone(phase, milestone.id, e ? e.value : null)
                        }
                    />
                </Col>
                <Col xs={0}>
                  <Field
                    name={`phases[${phase}].milestones[${milestone.id}].id`}
                    value={milestone.id}
                    cssClass="hidden"
                    component={HiddenField}
                    />
                </Col>
                {milestone.hasDate && (
                  <Col md={3}>
                    <Field
                      name={`phases[${phase}].milestones[${milestone.id}].eventDate`}
                      component={SingleDatePickerField}
                      isTestMode={isTestMode}
                      setDatesRange={milestoneDateRange}
                      initialVisibleMonth={() => initialVisibleMonth()}
                      index={`${milestone.id}`}
                      renderCalendarInfo={milestone.name}
                      inModal
                      disabled={
                                isDoneMilestones.find(id => milestone.id === id) ===
                                undefined
                            }
                        />
                  </Col>
                  )}
                {milestone.hasPeriod && (
                  <Col xs={5} fixedWidth="315px">
                    <div className={Styles.milestoneDateRangeField}>
                      <Field
                        name={`phases[${phase}].milestones[${milestone.id}].datesRange`}
                        component={RangeDatePickerField}
                        testId={`phases[${phase}].milestones[${milestone.id}].datesRange`}
                        setDatesRange={milestoneDateRange}
                        enableStrictRange={Boolean(milestoneDateRange)}
                        isTestMode={isTestMode}
                        initialVisibleMonth={() => initialVisibleMonth()}
                        index={milestone.id}
                        minimumNights={0}
                        inModal
                        disabled={
                        isDoneMilestones.find(id => milestone.id === id) ===
                        undefined
                      }
                    />
                    </div>
                  </Col>
              )}
              </Row>
            </Grid>
          </div>
      )
    })}
                                </div>
);

// Exported for test
export const PhaseLine = ({
  fields,
  phases,
  toggleMilestones,
  milestoneVisible,
  setDatesRangePhase,
  setDatesRangeMilestone,
  setInitialVisibleMonthPhase,
  selectIsDone,
  isDoneMilestones,
  onOverLapping,
}) => (
  <div>
    <div className={Styles.infoBar}>
      <Blockquote
        kind="warning"
        title="Please update the following information"
      >
        <ul style={{ listStyleType: "disc", paddingLeft: "1.1rem" }}>
          <li>
            Phases: please enter the exact start and end dates. If the exact
            date is not available enter an approximate date. These fields can be
            updated throughout the evaluation process.
          </li>
          <li>
            Milestones: when the milestone has a date, please enter the exact
            date.
          </li>
          <li>
            Overlapping of phases is not allowed. If you select Start date or End date of a
            phase that overlaps with other phases, the system will help you shifting the previous and/or
            following phases the necessary days to avoid overlapping.
          </li>
        </ul>
      </Blockquote>
    </div>
    {fields.map((phase, i) => (
      <div key={`phase_container_${phase}`}>
        <div key={phase} className={Styles.phaseLine}>
          <div className={Styles.colColor}>
            <div
              className={Styles.colorBox}
              style={{ backgroundColor: phases[i].color }}
            >
              {fields.get(i).start_date === undefined ||
              fields.get(i).end_date === undefined ||
              phases[i].milestones.length === 0 ? (
                <div>&nbsp;</div>
              ) : (
                <div onClick={() => toggleMilestones(phases[i].id)}>
                  <b>{milestoneVisible === phases[i].id ? "-" : "+"}</b>
                </div>
              )}
            </div>
          </div>
          <div className={Styles.colPhase}>
            <Field
              name={`${phase}.phase_display`}
              component={TextInput}
              readOnly
              className={Styles.phase}
            />
          </div>
          <div>
            <Field
              name={`${phase}.dates_range`}
              index={i}
              testId={`${phase}.dateRange`}
              onOverLapping={(...params) => onOverLapping(fields.get(i), i, ...params)}
              enableLegends
              enableHighlight
              component={RangeDatePickerField}
              setDatesRange={() => setDatesRangePhase(phases[i])}
              initialVisibleMonth={() => setInitialVisibleMonthPhase(phases[i])}
              inModal
              renderCalendarInfo={phases[i].name}
            />
          </div>
        </div>
        <div className={milestoneVisible === phases[i].id ? "" : "hidden"}>
          <div className={Styles.subsection}>
            <FieldArray
              name={`phases[${i}].milestones`}
              component={MilestoneLine}
              milestones={phases[i].milestones}
              phase={i}
              setDatesRangeMilestone={() => setDatesRangeMilestone(phases[i])}
              initialVisibleMonth={() => fields.get(i).start_date}
              selectIsDone={selectIsDone}
              isDoneMilestones={isDoneMilestones}
            />
          </div>
        </div>
      </div>
    ))}
  </div>
);

MilestoneLine.propTypes = {
  phase: PropTypes.number.isRequired,
  milestones: PropTypes.arrayOf(PropTypes.object).isRequired,
  setDatesRangeMilestone: PropTypes.func.isRequired,
  initialVisibleMonth: PropTypes.func.isRequired,
  selectIsDone: PropTypes.func.isRequired,
  isDoneMilestones: PropTypes.arrayOf(PropTypes.number).isRequired,
  isTestMode: PropTypes.bool
};

MilestoneLine.defaultProps = {
  isTestMode: false
};

PhaseLine.propTypes = {
  fields: PropTypes.shape({
    get: PropTypes.func,
    map: PropTypes.func,
    phases: PropTypes.array
  }).isRequired,
  phases: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleMilestones: PropTypes.func.isRequired,
  milestoneVisible: PropTypes.number.isRequired,
  setDatesRangePhase: PropTypes.func,
  setDatesRangeMilestone: PropTypes.func.isRequired,
  setInitialVisibleMonthPhase: PropTypes.func.isRequired,
  selectIsDone: PropTypes.func.isRequired,
  isDoneMilestones: PropTypes.arrayOf(PropTypes.number).isRequired,
  onOverLapping: PropTypes.func.isRequired,
};

PhaseLine.defaultProps = {
  setDatesRangePhase: undefined
};

// Exported for test
export const PhasesModal = props => {
  let content;

  if (props.isFetching) {
    content = <Loading />;
  } else {
    content = (
      <FieldArray
        name="phases"
        component={PhaseLine}
        onOverLapping={props.onOverLapping}
        phases={props.phases}
        rerenderOnEveryChange
        toggleMilestones={props.toggleMilestones}
        milestoneVisible={props.milestoneVisible}
        setDatesRangePhase={props.setDatesRangePhase}
        setDatesRangeMilestone={props.setDatesRangeMilestone}
        setInitialVisibleMonthPhase={props.setInitialVisibleMonthPhase}
        selectIsDone={props.selectIsDone}
        isDoneMilestones={props.isDoneMilestones}
      />
    );
  }

  return (
    <ModalForm
      title="Phases and milestones"
      isVisible={props.show}
      testId="phases-and-milestones-modal"
      onClose={props.toggleModal}
      onSubmit={e => {
        e.preventDefault();
        toast.dismiss();
        props.onSubmit();
      }}
      onFormSubmit={e => {
        e.preventDefault();
        toast.dismiss();
        props.onSubmit();
      }}
      buttonDisabled={!props.dirty}
      hiddenButtonDisabled={!props.dirty}
      overflowVisible
    >
      {content}
    </ModalForm>
  );
};

PhasesModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  phases: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleMilestones: PropTypes.func.isRequired,
  milestoneVisible: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  setDatesRangePhase: PropTypes.func,
  setDatesRangeMilestone: PropTypes.func,
  setInitialVisibleMonthPhase: PropTypes.func.isRequired,
  selectIsDone: PropTypes.func.isRequired,
  isDoneMilestones: PropTypes.arrayOf(PropTypes.number).isRequired,
  onOverLapping: PropTypes.func.isRequired,
};

PhasesModal.defaultProps = {
  setDatesRangePhase: undefined,
  setDatesRangeMilestone: undefined
};

const PhasesModalComponent = reduxForm({
  form: "evPhases",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PhasesModal);

export default PhasesModalComponent;
