exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__input___3_EuO{padding-right:40px !important;text-overflow:ellipsis;white-space:nowrap}.styles__input___3_EuO::placeholder{color:#8c9ba5 !important}.styles__clearButton___2VK1F{color:#ccc;display:flex;padding:9px;position:absolute;right:0;top:calc(50% + 14px);transform:translateY(-50%);transition:color 150ms ease 0s}.styles__clearButton___2VK1F:hover{color:#999}.styles__clearButton___2VK1F svg{fill:currentColor}.styles__dropdown___25blr{background:#fff;border-radius:4px;box-shadow:0 0 5px rgba(0,0,0,0.2);display:none;font-size:.875rem;margin:8px 0;max-height:200px;overflow-y:auto;padding:4px 0;position:absolute;width:100%;z-index:2}.styles__dropdown___25blr li{align-items:center;display:flex;padding:8px 12px;position:relative}.styles__dropdown___25blr li:hover{background:#deebff;cursor:default}.styles__dropdownOpen___3Z9nr{display:block !important}.styles__optionsLabel___2DRwm{font-weight:600;text-transform:uppercase}.styles__optionsLabel___2DRwm:not(:first-child){border-top:1px solid #dfe3e6;margin-top:0.5rem;padding-top:1rem}.styles__avatarContainer___3Txr_{border-radius:50%;flex-shrink:0;height:30px;margin-right:0.75rem;overflow:hidden;width:30px}.styles__avatarContainer___3Txr_ .styles__avatar___37GnN{background-size:cover;background-position:center;height:100%;width:100%}.styles__avatarContainer___3Txr_ svg{height:auto;margin-right:0;width:100%}\n", ""]);

// Exports
exports.locals = {
	"input": "styles__input___3_EuO",
	"clearButton": "styles__clearButton___2VK1F",
	"dropdown": "styles__dropdown___25blr",
	"dropdownOpen": "styles__dropdownOpen___3Z9nr",
	"optionsLabel": "styles__optionsLabel___2DRwm",
	"avatarContainer": "styles__avatarContainer___3Txr_",
	"avatar": "styles__avatar___37GnN"
};