import React from "react";
import PropTypes from "prop-types";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { tooltipStyle, Icon } from "@wfp/ui";
import { iconHelp, iconInfo } from "@wfp/icons";
import styled from "styled-components";

import Styles from "./styles.scss";

/** Returns an icon type and its styles */
const getIcon = iconType => {
  switch (iconType) {
    case "info":
      return {
        icon: iconInfo,
        className: Styles.iconInfo
      };
    default:
      return {
        icon: iconHelp,
        className: Styles.iconHelp
      };
  }
};

/**
 * Tooltip is used to display informative text.
 * It can be associated to a form field or to any text or component.
 */
const Tooltip = ({
  className,
  formIndex,
  label,
  message,
  color,
  maxWidth,
  iconType,
  children
}) => {
  let StyledTippy = Tippy;

  /**
   * Use styled-component syntax to override default Tippy theme
   * with custom styles where needed (e.g. in Phases & Milestones)
   */
  if (color) {
    StyledTippy = styled(Tippy)`
      background-color: ${color} !important;
      color: #031c2d !important;

      & .tippy-arrow::before {
        border-top-color: ${color} !important;
      }
    `;
  }

  const icon = getIcon(iconType);

  return (
    <div className={`${className} ${Styles.Tooltip}`}>
      {label && (
        <label className="wfp--label" htmlFor={formIndex}>
          {label}
        </label>
      )}
      <StyledTippy
        maxWidth={maxWidth}
        content={<div className={Styles.TooltipMessage}>{message}</div>}
        theme="light"
        {...tooltipStyle}
      >
        <div className={Styles.TooltipIcon}>
          {children || (
            <Icon
              icon={icon.icon}
              className={icon.className}
              width="13"
              height="13"
              description=""
            />
          )}
        </div>
      </StyledTippy>
    </div>
  );
};

Tooltip.propTypes = {
  /** Tooltip classname */
  className: PropTypes.string,
  /** Tooltip message */
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** Refers to the field label the tooltip can be associated with */
  label: PropTypes.string,
  /** Refers to the id of the field the tooltip can be associated with */
  formIndex: PropTypes.string,
  /** Icon type */
  iconType: PropTypes.oneOf(["info", "help"]),
  /** Tooltip popup's background color */
  color: PropTypes.string,
  /** Tooltip popup's width */
  maxWidth: PropTypes.number,
  /**
   * Custom element that triggers the tooltip.
   * When undefined the info icon is rendered.
   */
  children: PropTypes.node
};

Tooltip.defaultProps = {
  className: "",
  label: undefined,
  formIndex: undefined,
  iconType: "help",
  color: undefined,
  maxWidth: 350,
  children: undefined
};

export default Tooltip;
