import React from "react";
import PropTypes from "prop-types";
import { Button } from "@wfp/ui";
import { iconOverflowMenu } from "@wfp/icons";

import ClickOutside from "react-click-outside";

import Styles from "./styles.scss";

const DropdownMenu = ({ onClick, isOpen, isDisabled, options }) => {
  const outsideClick = isOpen ? onClick : () => null;
  return (
    <ClickOutside onClickOutside={outsideClick}>
      <div
        className={`${Styles.dropdownMenu} ${
          isDisabled ? Styles.disabled : ""
        }`}
      >
        <Button
          className={isOpen ? Styles.isOpen : undefined}
          kind="secondary"
          icon={iconOverflowMenu}
          iconDescription="Show dropdown menu"
          onClick={!isDisabled ? onClick : undefined}
          disabled={isDisabled}
        />
        <ul className={isOpen ? Styles.dropdownOpen : undefined}>
          {options.map(opt => (
            <li
              key={opt.label}
              style={!opt.disabled ? { color: opt.color } : undefined}
              className={opt.disabled ? Styles.optionDisabled : undefined}
              onClick={opt.disabled ? undefined : opt.action}
            >
              {opt.label}
            </li>
          ))}
        </ul>
      </div>
    </ClickOutside>
  );
};

DropdownMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string,
      disabled: PropTypes.bool
    })
  )
};

DropdownMenu.defaultProps = {
  isOpen: false,
  isDisabled: false,
  options: []
};

export default DropdownMenu;
