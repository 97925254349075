import React, {useState} from "react";
import PropTypes from "prop-types";

import Modal from "Modal";
import Select from "Select";

import styles from "../styles.scss";

const CancelEvaluationModal = props => {
  const [reason, setReason] = useState(null);

  return (
    <Modal
      title="Are you sure you want to cancel the evaluation?"
      confirmLabel="Yes, cancel the evaluation"
      cancelLabel="No, keep the evaluation"
      buttonDisabled={!reason}
      isVisible={props.isVisible}
      onClose={props.onClose}
      onSubmit={() => props.onSubmit(reason)}
      danger
      >
      <p className={styles.CancelEvaluationMessage}>
          Warning: this action can only be undone by support users.
      </p>
      <Select
        label="Please provide a reason for cancellation in order to proceed"
        options={props.reasonForCancellationOptions}
        openMenuOnClick
        input={{
            value: reason,
            onChange: setReason,
            name: "reasonForCancellation",
        }}
        placeholder="Select a reason for cancellation"
        />
    </Modal>
  );
}
CancelEvaluationModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  reasonForCancellationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ).isRequired
};

CancelEvaluationModal.defaultProps = {};

export default CancelEvaluationModal;