import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {change} from "redux-form";
import {
  ApprovalYears,
  Bureaux,
  Commissioners,
  Coverages,
  JointEvaluations,
  JointPartners,
  PlannedCompletionYears,
  StartYears,
  Statuses,
  Types
} from "./FilterFields";
import Topics from "./FilterFields/Topics";
import {advancedFilterActions, filterActions} from "../../../actions/filters";

const advancedFilters = [
   {
    label: "Regional Bureau / Liason Office / HQ",
    fieldName: "bureaux",
    component: (props) => <Bureaux {...props} />
  },
  {
    label: "Commissioner",
    fieldName: "commissioners",
    component: (props) => <Commissioners {...props} />
  },
  {
    label: "Geographical Coverage",
    fieldName: "coverages",
    component: (props) => <Coverages {...props} />
  },
  {
    label: "Approval Years",
    fieldName: 'approvalYears',
    component: (props) => <ApprovalYears {...props} />,
  },
  {
    label: "Topics",
    fieldName: "topics",
    component: (props) => <Topics {...props} />,
  },
  {
    label: "Joint Partners",
    fieldName: "jointPartners",
    component: (props) => <JointPartners {...props} />,
  },
  {
    label: "Statuses",
    fieldName: "statuses",
    onHide: (dispatch) => {
      dispatch(filterActions.setFilter("includeCancelled", false));
    },
    component: (props) => <Statuses {...props} />,
  },
  {
    label: "Types",
    fieldName: "types",
    component: (props) => <Types {...props} />,
  },
  {
    label: "Start Years",
    fieldName: "startYears",
    component: (props) => <StartYears {...props} />,
  },
  {
    label: "Planned Completion Years",
    fieldName: "plannedCompletedYears",
    component: (props) => <PlannedCompletionYears {...props} />,
  },
  {
    label: "Joint Evaluations",
    fieldName: "jointEvaluations",
    component: (props) => <JointEvaluations {...props} />,
    onHide: (dispatch) => {
      dispatch(filterActions.setFilter("isJoint", false));
    },
  }
]

export const useAdvancedFilter = () => {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.pages?.evaluations?.activeAdvancedFilters);
  const evaluationPage = useSelector(state => state.pages?.evaluations);
  const isDisabled = evaluationPage?.isFetching || Boolean(evaluationPage?.backgroundListRequests)

  const defaultOnHide = (fieldName) => {
    dispatch(advancedFilterActions.hideAdvancedFilter(fieldName));
    dispatch(advancedFilterActions.removeFilter(fieldName));
    dispatch(change("newEvSearch", fieldName, null));
  }
  const defaultOnShow = (fieldName) => dispatch(advancedFilterActions.showAdvancedFilter(fieldName));

  const extendedAdvancedFilters = advancedFilters.map(filter => {
    return {
      ...filter,
      isVisible: filters?.[filter.fieldName]?.show ?? false,
      isDisabled,
      onHide: () => {
        if (filter.onHide) {
          filter.onHide(dispatch);
        }
        defaultOnHide(filter.fieldName);
      },
      onShow: () => filter?.onShow ? filter?.onShow(dispatch) : defaultOnShow(filter.fieldName),
    }
  })

  return {
    advancedFilters: extendedAdvancedFilters,
  }

}
