import React from "react";

import get from "lodash/get";

import { Icon } from "@wfp/ui";
import { iconLink, iconEdit, iconDelete } from "@wfp/icons";

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
export const link = (field, openLink, disabled) => (
  <Icon
    icon={iconLink}
    width="19"
    height="19"
    description="Link"
    onClick={!disabled ? () => openLink(field.value) : undefined}
    style={
      disabled ? { opacity: "0.5", cursor: "pointer" } : { cursor: "pointer" }
    }
  />
);
/* eslint-enable */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
export const editItem = (field, setMember, disabled) => (
  <Icon
    icon={iconEdit}
    width="19"
    height="19"
    description="Edit item"
    onClick={!disabled ? () => setMember(field.value) : undefined}
    style={
      disabled ? { opacity: "0.5", cursor: "pointer" } : { cursor: "pointer" }
    }
  />
);
/* eslint-enable */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
export const deleteItem = (field, setMemberToDelete, disabled) => (
  <Icon
    icon={iconDelete}
    width="19"
    height="19"
    description="Delete item"
    onClick={!disabled ? () => setMemberToDelete(field.value) : undefined}
    style={
      disabled ? { opacity: "0.5", cursor: "pointer" } : { cursor: "pointer" }
    }
  />
);
/* eslint-enable */

export const getDestinations = rowInfo =>
  rowInfo.value.map((item, i, arr) =>
    i !== arr.length - 1 ? (
      <span key={`${item.label}`} style={{ whiteSpace: "nowrap" }}>
        {item.label},{" "}
      </span>
    ) : (
      <span key={`${item.label}`} style={{ whiteSpace: "nowrap" }}>
        {item.label}
      </span>
    )
  );

/** Generate columns for Products table */
const columnsGenerator = (
  openLink,
  setMember,
  setMemberToDelete,
  canEdit,
  headers
) => [
  {
    Header: headers.get("kind_display"),
    accessor: "kind_display",
    maxWidth: 400
  },
  {
    Header: headers.get("release_date"),
    accessor: "release_date",
    maxWidth: 200
  },
  {
    Header: "Destinations",
    accessor: "product_destinations",
    Cell: getDestinations,
    style: {
      display: "inline-block",
      alignSelf: "center"
    }
  },
  {
    Header: "",
    accessor: "url",
    Cell: field => {
      const value = get(field, "value", null);
      if (value !== null && value !== "") {
        return link(field, openLink, false); // never disabled
      }
      return value;
    },
    resizable: false,
    sortable: false,
    width: 50,
    style: { justifyContent: "center" }
  },
  ...(!canEdit
    ? []
    : [
        {
          Header: "",
          accessor: "id",
          Cell: field => editItem(field, setMember, !canEdit),
          resizable: false,
          sortable: false,
          width: 50,
          style: { justifyContent: "center" }
        },
        {
          Header: "",
          accessor: "id",
          Cell: field => deleteItem(field, setMemberToDelete, !canEdit),
          resizable: false,
          sortable: false,
          width: 50,
          style: { justifyContent: "center" }
        }
      ])
];

export default columnsGenerator;
