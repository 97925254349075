import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactRouterPropTypes from "react-router-prop-types";
import ActivityTable from "../../../../../../components/ActivityTable";
import {EvaluationEnhancedActivityActions} from "../../../../../../actions/enhancedActivity";

export class EnhancedActivityComponent extends React.Component {
  componentDidMount() {
    this.props.fetchActivities(this.props.match.params.evaluationId);
  }

  render() {
    return <ActivityTable
      items={this.props.items}
      showGroup={false}
      isFetching={this.props.isFetching}
    />
  }
}

EnhancedActivityComponent.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  fetchActivities: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  match: ReactRouterPropTypes.match.isRequired
};

EnhancedActivityComponent.defaultProps = {
  items: []
};

export const mapStateToProps = state => {
  const page = state.pages.enhancedActivity;
  const { entities } = state;

  const items = page.items.map(i => entities.enhancedActivity[i]);

  return {
    items,
    isFetching: page.isFetching
  };
};

export const mapDispatchToProps = dispatch => ({
  fetchActivities: evaluationId =>
    dispatch(
      EvaluationEnhancedActivityActions.list(
        {},
        { pathParams: { evaluationId }, paginated: false }
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnhancedActivityComponent);
