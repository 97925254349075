exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__SplashScreen___3u2kT{align-items:center;background-color:#0a6eb4;display:flex;flex-direction:column;height:100%;justify-content:center;left:0;position:fixed;transition:opacity 0.2s ease;top:0;width:100%;z-index:99999}.styles__wfpLogo___3u-of{height:121px}.styles__wfpLogo___3u-of>img{height:100%}.styles__appName___1amAH{color:#fbfcfc;font-size:1rem;margin-top:0.5rem}.styles__loading___3h-et{animation:styles__rotate___3pcsG 690ms linear infinite forwards;margin-top:1rem}.styles__spinner___OJfj8 svg circle{stroke:#fbfcfc}@keyframes styles__rotate___3pcsG{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}\n", ""]);

// Exports
exports.locals = {
	"SplashScreen": "styles__SplashScreen___3u2kT",
	"wfpLogo": "styles__wfpLogo___3u-of",
	"appName": "styles__appName___1amAH",
	"loading": "styles__loading___3h-et",
	"rotate": "styles__rotate___3pcsG",
	"spinner": "styles__spinner___OJfj8"
};