import { schema } from "normalizr";
import { requirementConditionsTypes } from "../types";
import Resource from "./resource";
import {client} from "../store";

export const requirementConditionsSchema = new schema.Entity(
    "requirementConditions",
  {},
  { idAttribute: 'code' }
);

export default new Resource(
    "/requirement-conditions",
    requirementConditionsTypes,
    requirementConditionsSchema,
);

client.enableAutoCamelCase('/requirement-conditions');
