import { schema } from "normalizr";
import { sustainableDevelopmentGoalsTypes } from "../types";
import Resource from "./resource";

export const sustainableDevelopmentGoalsSchema = new schema.Entity(
  "sustainableDevelopmentGoals"
);

export default new Resource(
  "/sustainable-development-goals",
  sustainableDevelopmentGoalsTypes,
  sustainableDevelopmentGoalsSchema
);

