import {Button} from "@wfp/ui";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { submit } from 'redux-form'
import {SecondaryNavigationActions} from "SecondaryNavigation";
import DropdownMenu from "DropdownMenu";
import styles from "../styles.scss";
import {OptionTransformer} from "../OptionTransformer";
import rfcActions from "../../../../../../../actions/reasonsForCancellation";
import CancelEvaluationModal from "./CancelEvaluationModal";
import {getEvaluationActions} from "../../../../../../../actions/evaluations";

export const ActionButtons = ({evaluationId, isDisabled, category}) => {
    const dispatch = useDispatch();
    const reasonsForCancellation = useSelector(store => new OptionTransformer(store?.entities.reasonsForCancellation).transform())

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (showModal) {
            dispatch(rfcActions.list({}, {paginated: false}));
        }
    }, [showModal]);

    const onModalClose = () => {
        setIsDropdownOpen(false);
        setShowModal(false);
    }

    const onCancelEvaluation = ({value}) => {
        const detailAction = getEvaluationActions(category);
        dispatch(detailAction.cancel(evaluationId, {
            reasonForCancellation: value
        }));

        onModalClose();
    }

    const onSave = () => {
        dispatch(submit('evInfo'));
    }

    return (
      <>
        <SecondaryNavigationActions>
          <div className={styles.mainActions}>
            <Button disabled={isDisabled} onClick={onSave}>
                Save Evaluation
            </Button>
            <DropdownMenu
              isOpen={isDropdownOpen}
              onClick={() => setIsDropdownOpen(prevState => !prevState)}
              options={[{
                action: () => setShowModal(true),
                label: "Cancel Evaluation",
                color: "#c5192d",
              }]}
              isDisabled={isDisabled}
            />
          </div>
        </SecondaryNavigationActions>
        <CancelEvaluationModal
          reasonForCancellationOptions={reasonsForCancellation}
          onClose={() => setShowModal(false)}
          onSubmit={onCancelEvaluation}
          isVisible={showModal}
        />
      </>
    )
}