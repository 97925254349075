import React from "react";
import PropTypes from "prop-types";
import { Modal as ModalForm } from "@wfp/ui";

/**
 * Modal props
 * @prop {isVisible} bool
 * @prop {title} str
 * @prop {type} str - if type of prompt no height is set in the CSS and no
 *                    scrollbar appears (not the perfect solutions but fixes the issue atm)
 * @prop {isTextCentered} bool
 * @prop {onClose} func - function to be declared in the parent component
 *
 * ### Usage ###
 *  The parent component using the Modal component should have a key-value pair
 *  in its state to set the initial visibility of the modal, e.g. modalIsVisible: false/true.
 *  It should also pass a function to change the value of this key-value pair to the exact opposite
 *  in order to toggle the visibility of the modal component.
 *
 *  Example:
 *
 *  {...}
 *
 *  constructor(props) {
 *    super(props);
 *    this.state = {
 *      modalIsVisible: false
 *    }
 *  }
 *
 *  {...}
 *
 *  toggleModal = () => {
 *    this.setState({
 *      modalIsVisible: !this.state.modalIsVisible
 *    })
 *  }
 *
 *  {...}
 *
 *  render() {
 *    return (
 *      <Modal
 *        isVisible={ this.state.modalIsVisible }
 *        isTextCentered={ true }
 *        onClose={ this.toggleModal }
 *        title="Which kind of evaluation you want to create?"
 *      />
 *    )
 *  }
 *
 */
const Modal = props => {
  if (!props.isVisible) {
    return null;
  }

  return (
    <ModalForm
      open={props.isVisible}
      modalHeading={props.title}
      onRequestSubmit={props.onSubmit}
      onRequestClose={(evt, trigger) => {
        if (trigger !== "background") {
          return props.onClose(evt, trigger);
        }
        return null;
      }}
      primaryButtonText={props.confirmLabel}
      primaryButtonDisabled={props.buttonDisabled}
      secondaryButtonText={props.cancelLabel}
      shouldSubmitOnEnter={props.shouldSubmitOnEnter}
      passiveModal={props.passiveModal}
      danger={props.danger}
    >
      <div
        className="wfp--modal-content__text"
        style={
          props.contentCentered && { display: "flex", justifyContent: "center" }
        }
      >
        {props.children}
      </div>
    </ModalForm>
  );
};

/*
  Props and PropTypes to be
  passed to this component
*/
Modal.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  shouldSubmitOnEnter: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  contentCentered: PropTypes.bool,
  children: PropTypes.any, // eslint-disable-line
  passiveModal: PropTypes.bool,
  danger: PropTypes.bool,
};

/*
  Default values for
  component props
  */
Modal.defaultProps = {
  isVisible: false,
  shouldSubmitOnEnter: false,
  buttonDisabled: false,
  confirmLabel: "Save",
  cancelLabel: "Cancel",
  title: "Please insert a valid title",
  contentCentered: undefined,
  children: undefined,
  onSubmit: () => {},
  passiveModal: false,
  danger: false,
};

export default Modal;
