import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import ModalForm from "ModalForm";

import { Grid, Row, Col } from "Grid";
import SelectAsync from "SelectAsync";

const UserImportModal = props => (
  <ModalForm
    title={props.title}
    isVisible={props.show}
    onClose={props.toggleModal}
    onSubmit={e => {
      e.preventDefault();
      props.onSubmit();
    }}
    onFormSubmit={e => {
      e.preventDefault();
      props.onSubmit();
    }}
    buttonDisabled={props.isSaveButtonDisabled}
    hiddenButtonDisabled={props.isSaveButtonDisabled}
  >
    <fieldset>
      <Grid fluid>
        <Row>
          <Col xs>
            <Field
              name="user"
              component={SelectAsync}
              label="User"
              placeholder="Please type at least 4 characters to search"
              loadOptions={props.loadAGWUserOptions}
            />
          </Col>
        </Row>
      </Grid>
    </fieldset>
  </ModalForm>
);

UserImportModal.propTypes = {
  initialValues: PropTypes.shape({ id: PropTypes.any }), // eslint-disable-line
  isSaveButtonDisabled: PropTypes.bool,
  loadAGWUserOptions: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  options: PropTypes.object, // eslint-disable-line
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired
};

UserImportModal.defaultProps = {
  initialValues: {},
  isSaveButtonDisabled: true,
  show: false
};

export default reduxForm({
  form: "users",
  enableReinitialize: true
})(UserImportModal);
