import React from "react";
import { Draggable } from "react-beautiful-dnd";
import ReactTable from "react-table";
import PropTypes from "prop-types";

export default class DraggableTrComponent extends React.Component {
  renderTrComponent = () => (
    <ReactTable.defaultProps.TrComponent className={this.props.className} style={{...this.props?.style, width: '100%' }}>
      {this.props.children}
    </ReactTable.defaultProps.TrComponent>
  )

  render() {
    const rowInfo = this.props?.rowInfo;
    if (rowInfo && this.props.getDraggableId) {
      const draggableId = this.props.getDraggableId(rowInfo.original);
      return (
        <Draggable key={draggableId} index={rowInfo.index} draggableId={draggableId}>
          {(provider) => (
            <div ref={provider.innerRef} {...provider.draggableProps} {...provider.dragHandleProps}>
              {this.renderTrComponent()}
            </div>
          )}
        </Draggable>
      );
    } return this.renderTrComponent();
  }
}

DraggableTrComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  getDraggableId: PropTypes.func,
  rowInfo: PropTypes.shape({}),
  style: PropTypes.shape({}),
  className: PropTypes.string,
};

DraggableTrComponent.defaultProps = {
  rowInfo: null,
  style: null,
  getDraggableId: null,
  className: null,
};
