import React from "react";
import PropTypes from "prop-types";
import { Button } from "@wfp/ui";
import { iconArrowRight, iconChevronRight } from "@wfp/icons";

import DoubleButtonIcon from "../DoubleButtonIcon";

const NavNext = ({
  onNavigateYear,
}) => (
  <div
    style={{
      right: '8px',
      top: '9px',
      position: 'absolute',
    }}
  >
    <Button
      data-testid="next-year-button"
      kind="secondary"
      icon={iconArrowRight}
    />
    <Button
      data-testid="next-month-button"
      kind="secondary"
      onClick={(event) => onNavigateYear(event, 1)}
    >
      <DoubleButtonIcon
        icon={iconChevronRight}
      />
    </Button>
  </div>
);

NavNext.propTypes = {
  onNavigateYear: PropTypes.func.isRequired,
};

export default NavNext;
