import { combineReducers } from "redux";
import {
  fetching,
  items,
  options,
  selectable,
  submitting,
  togglable
} from "./resource";

export default combineReducers({
  selectedMember: selectable("member"),
  isFetching: fetching("members"),
  isSubmitting: submitting("members"),
  items: items("members", false),
  people: items("people", false),
  options: options("people"),
  companies: items("companies"),
  countries: items("countries"),
  services: items("services"),
  expertises: items("expertises"),
  contractTypes: items("contractTypes"),
  addModal: togglable("MEMBER_ADD_MODAL"),
  deleteModal: togglable("MEMBER_DELETE_MODAL"),
  personForm: togglable("PEOPLE_EXTRA_FORM"),
  firmForm: togglable("FIRM_EXTRA_FORM")
});
