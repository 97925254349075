import React from 'react';
import SelectAsync from "SelectAsync";
import UserLabel from "UserLabel";
import {Categories} from "enums";
import {partition} from "lodash";

// eslint-disable-next-line camelcase
import debounce_promise from "debounce-promise";
import {client} from "../../../../../../../store";


export const AGWUserSelect = ({category, ...props}) => {

    /** Search WFPUsers by querying our /agwusers endpoint, returns an array of options */
    const loadAGWUserOptions = (value) => {
        if (value && value.length >= 4) {
            // fetch AGW users
            return client
                .get("/agwusers", {params: {search: value, category}})
                .then(response => {
                    let users = response.data.map(user => ({
                        label: <UserLabel {...user} />,
                        value: user
                    }))

                    if ([Categories.CENTRALIZED, Categories.IMPACT].includes(category)) {
                        users = partition(users, user => user.value.org_unit?.substring(0, 3) === 'OEV');
                        users = [
                            {
                                label: "OEV staff",
                                options: users[0],
                            },
                            {
                                label: "Other staff",
                                options: users[1],
                            },
                        ]
                    }

                    return users;
                });
        }
        return [];
    };

    /** Search WFPUsers by querying our /agwusers endpoint, returns an array of options */
    const debouncedLoadAGWUserOptions = debounce_promise(loadAGWUserOptions, 300);

    return (<SelectAsync
      loadOptions={debouncedLoadAGWUserOptions}
      placeholder="Please type at least 4 characters to search"
      {...props}
    />)
}