import React from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import { withRouter } from "react-router";
import ReactRouterPropTypes from "react-router-prop-types";
import PropTypes from "prop-types";

import get from "lodash/get";
// eslint-disable-next-line camelcase
import debounce_promise from "debounce-promise";

import { Button } from "@wfp/ui";
import { iconAddGlyph } from "@wfp/icons";
import SecondaryNavigation from "SecondaryNavigation";
import Loading from "Loading";
import ListComponent from "ListComponent";
import UserLabel from "UserLabel";

import wfpUserActions from "../../actions/wfpUsers";
import { toggle } from "../../actions/togglable";
import { client } from "../../store";
import { capitalize } from "../../utils";

import UserEditModal from "./UserEditModal";
import UserImportModal from "./UserImportModal";
import columnsGenerator from "./tableColumns";

const filterUsersBySearchText = (users, text) => {
  const text_ = text.toLowerCase();
  return users.filter(
    user =>
      (user.display_name || "").toLowerCase().includes(text_) ||
      (user.first_name || "").toLowerCase().includes(text_) ||
      (user.last_name || "").toLowerCase().includes(text_) ||
      ((user.role && user.role.label) || "").toLowerCase().includes(text_) ||
      (user.email || "").toLowerCase().includes(text_)
  );
};

/* mapWFPUserEntityToFormValue adapts current user data to the one accepted by the form */
export const mapWFPUserEntityToFormValues = user => ({
  display_name: user.display_name,
  email: user.email,
  first_name: user.first_name,
  id: user.id,
  is_active: {
    label: capitalize(user.is_active.toString()),
    value: user.is_active
  },
  last_name: user.last_name,
  role: user.role ? { label: user.role, value: user.role } : null,
  username: user.username
});

/* mapFromValuesToQueryJSON adapts current user data to the one accepted by the API */
export const mapFormValuesToQueryJSON = formValues => ({
  id: formValues.id,
  email: formValues.email,
  is_active: formValues.is_active ? formValues.is_active.value : null,
  role: formValues.role ? formValues.role.value : undefined
});

/** Search WFPUsers by querying our /agwusers endpoint, returns an array of options */
const loadAGWUserOptions = value => {
  if (!value && !value.length >= 4) {
    return [];
  }

  // fetch AGW users
  return client.get("/agwusers", { params: { search: value } }).then(response =>
    response.data.map(user => ({
      label: <UserLabel {...user} />,
      value: user.email
    }))
  );
};

/** Search WFPUsers by querying our /agwusers endpoint, returns an array of options */
const debouncedLoadAGWUserOptions = debounce_promise(loadAGWUserOptions, 300);

export class UserListComponent extends React.Component {
  componentDidMount() {
    this.props.fetchUsers().then(() => {
      // Fetch something else?
    });
    this.props.fetchOevmisRoleOptions();
  }

  /** Opens UserImportModal */
  startImportingUser = () => {
    this.props.resetImportForm();
    this.props.toggleImportModal();
  };

  /** Opens UserEditModal with the selected user id */
  startEditingUser = id => {
    this.props.resetEditForm();
    this.props.selectUser(id);
    this.props.toggleEditModal();
  };

  saveUser = () => {
    const values = mapFormValuesToQueryJSON(this.props.formValues);
    this.props
      .saveUser(values, this.props.match.params.evaluationId)
      .then(() => this.props.toggleEditModal());
  };

  importUser = () => {
    const values = { email: this.props.formValues.user.value };
    this.props
      .saveUser(values, this.props.match.params.evaluationId)
      .then(response => {
        this.props.toggleImportModal();
        const userId = get(response, "value.data.id");
        if (userId) {
          this.startEditingUser(userId);
        }
      });
  };

  render() {
    return this.props.isFetching ? (
      <Loading />
    ) : (
      <div>
        <SecondaryNavigation
          additional={
            this.props.canImport ? (
              <Button
                type="submit"
                icon={iconAddGlyph}
                iconDescription="Import User"
                onClick={this.startImportingUser}
              >
                Import User
              </Button>
            ) : (
              undefined
            )
          }
          title="Users"
        />

        <UserImportModal
          title="Import User"
          toggleModal={this.props.toggleImportModal}
          show={this.props.showImportModal}
          onSubmit={this.importUser}
          canEdit={this.props.canEdit}
          isSaveButtonDisabled={this.props.isSaveButtonDisabled}
          loadAGWUserOptions={debouncedLoadAGWUserOptions}
        />

        <UserEditModal
          canEdit={this.props.canEdit}
          initialValues={this.props.selectedUser}
          loadAGWUserOptions={debouncedLoadAGWUserOptions}
          oevmisRoleOptions={this.props.oevmisRoleOptions}
          onSubmit={this.saveUser}
          show={this.props.showEditModal}
          title="Edit User"
          toggleModal={this.props.toggleEditModal}
        />

        <ListComponent
          fetching={this.props.isFetching}
          tableData={this.props.data}
          columns={columnsGenerator(this.startEditingUser, this.props.canEdit)}
          searchOnChange={input => this.props.setUsersSearchText(input.query)}
        />
      </div>
    );
  }
}

const userShape = PropTypes.shape({
  id: PropTypes.number,
  email: PropTypes.string
  // TODO: the rest of user fields
});

const optionShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string
});

UserListComponent.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  canImport: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(userShape).isRequired,
  fetchOevmisRoleOptions: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  isCreate: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSaveButtonDisabled: PropTypes.bool.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  oevmisRoleOptions: PropTypes.arrayOf(optionShape).isRequired,
  resetEditForm: PropTypes.func.isRequired,
  resetImportForm: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  selectedUser: userShape.isRequired,
  selectUser: PropTypes.func.isRequired,
  setUsersSearchText: PropTypes.func.isRequired,
  showEditModal: PropTypes.bool.isRequired,
  showImportModal: PropTypes.bool.isRequired,
  toggleEditModal: PropTypes.func.isRequired,
  toggleImportModal: PropTypes.func.isRequired
};

export const mapStateToProps = state => {
  const page = get(state, "pages.users");

  const userPermissions = get(state, "user[0].permissions.permission");

  const canEdit =
    userPermissions && userPermissions.includes("wfp_auth.change_wfpuser");
  const canImport =
    userPermissions && userPermissions.includes("wfp_auth.add_wfpuser");

  const userEntities = get(state, "entities.wfpUsers");

  const { usersSearchText } = page;

  const data =
    page &&
    page.items &&
    page.items
      .map(id => userEntities[id])
      .map(user => mapWFPUserEntityToFormValues(user));

  const searchResults =
    usersSearchText === ""
      ? data
      : filterUsersBySearchText(data, usersSearchText);

  const user = userEntities && userEntities[page.selectedUser];
  const selectedUser = user && mapWFPUserEntityToFormValues(user);

  const formValues = get(state, "form.users.values", {});
  const isSaveButtonDisabled = formValues && !formValues.user;

  return {
    canEdit,
    canImport,
    data: searchResults,
    formValues,
    isSaveButtonDisabled,
    isFetching: page.isFetching,
    oevmisRoleOptions: page.oevmisRoleOptions,
    usersSearchText,
    selectedUser,
    showEditModal: page.showEditModal,
    showImportModal: page.showImportModal
  };
};

export const mapDispatchToProps = dispatch => ({
  fetchOevmisRoleOptions: () =>
    dispatch(wfpUserActions.fetchOevmisRoleOptions()),
  fetchUsers: () => dispatch(wfpUserActions.list({}, { paginated: false })),
  resetEditForm: () => dispatch(reset("users")),
  resetImportForm: () => dispatch(reset("users")),
  saveUser: id => dispatch(wfpUserActions.save(id)),
  selectUser: id => dispatch({ type: "SELECT_WFPUSER", id }),
  setUsersSearchText: text => dispatch({ type: "SET_USERS_SEARCH_TEXT", text }),
  toggleEditModal: () => dispatch(toggle("WFPUSERS_EDIT_MODAL")),
  toggleImportModal: () => dispatch(toggle("WFPUSERS_IMPORT_MODAL"))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserListComponent)
);
