import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import TextInput from "TextInput";
import ModalForm from "ModalForm";
import Checkbox from "Checkbox";
import Select from "Select";

const PartnersModal = props => (
  <ModalForm
    data-testid="partners-modal-container"
    title={props.title}
    isVisible={props.show}
    onClose={props.toggleModal}
    onSubmit={e => {
      e.preventDefault();
      props.onSubmit();
    }}
    onFormSubmit={e => {
      e.preventDefault();
      props.onSubmit();
    }}
    buttonDisabled={props.disabled}
    hiddenButtonDisabled={props.disabled}
  >
    <Field name="name" label="Name" component={TextInput} />
    <Field name="isDeprecated" label="Deprecated" component={Checkbox} />
    <Field
      name="category"
      label="Category"
      component={Select}
      options={props.productTypeOptions}
      closeMenuOnSelect
      openMenuOnClick
    />
  </ModalForm>
);

PartnersModal.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({ id: PropTypes.any }), // eslint-disable-line
  options: PropTypes.object, // eslint-disable-line
  disabled: PropTypes.bool,
  partnerCategoriesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
      ).isRequired,
};

PartnersModal.defaultProps = {
  show: false,
  initialValues: {},
  disabled: false
};

export default reduxForm({
  form: "partners",
  enableReinitialize: true
})(PartnersModal);
