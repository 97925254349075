import React from "react";

const EditColumnsIcon = () => (
  <svg width={16} height={15} viewBox="0 0 16 15">
    <path
      fill="#0073EE"
      d="M15 10.4h1v.9h-1zm0 1.8h1v.9h-1zm0-7.3h1v.9h-1zm0 1.8h1v.9h-1zm0 1.8h1v.9h-1zm0 5.5h1v1h-1zm-1.6 0h.8v1h-.8zm-1.6 0h.8v1h-.8zM10 4.9h1v.9h-1zm0 1.8h1v.9h-1zm0 5.5h1v.9h-1zm0-1.8h1v.9h-1zm0-1.9h1v.9h-1z" // eslint-disable-line
    />
    <path
      fill="#0073EE"
      d="M0 0v15h11v-1H6V4h10V0H0zm5 14H1V4h4v10zM15 3H1V1h14v2z"
    />
  </svg>
);

export default EditColumnsIcon;
