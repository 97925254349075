import React from "react";
import EditColumns from "./EditColumns";
import {
    buildColumn,
    compareThousandsCommas,
    defaultColumnsSort,
    setThousandsCommas,
    sortByPosition,
    sortExercises,
    sortStatuses
} from "./utils";
import { sortDates } from "../../utils";

import Styles from "./styles.scss";

/** Decide whether a column should by rendered as a checkbox */
export const IsBoolComponent = row => (
  <input type="checkbox" checked={row.value} disabled />
);

const editColumnsOptions = options =>
  options.map(column => {
    // fields with a custom header
    if (column.value === "commissioning") {
      return {
        value: column.value,
        label: "Commissioner"
      };
    }
    if (column.value === "commissioning_parent") {
      return {
        value: column.value,
        label: "Commissioning Parent"
      };
    }
    if (column.value === "last_group_modified_date") {
      return {
        value: column.value,
        label: "Last modification date"
      };
    }
    if (column.value === "last_group_modified_user") {
      return {
        value: column.value,
        label: "Last modified by"
      };
    }
    if (column.value === "errors_number") {
      return {
        value: column.value,
        label: "EN"
      };
    }

    return column;
  });

/** Generates the list of columns and sort them by column disposition defined previously */
const columnsGenerator = (options, isVisible, extraParams = {}) => {
  // used to show/hide errors number column
  const { isUserSupport } = extraParams.editColumnsProps;

  if (typeof options === "undefined") {
    return {};
  }

  return options
    .map(option => {
      switch (option.value) {
        // Special cases
        case "category_code":
          // represents the ``category_code`` column which acts as a selector
          // it's right to build `<EditColumns />` component
          return buildColumn(
            option,
            {
              header: () => (
                <EditColumns
                  {...extraParams.editColumnsProps}
                  options={editColumnsOptions(
                    extraParams.editColumnsProps.options
                  )}
                />
              ),
              cell: row => (
                <span className={Styles.dot}>
                  {{ CENTRALIZED: "CE", DECENTRALIZED: "DE", IMPACT:"IE"}[`${row.value}`] ||
                    "--"}
                </span>
              ),
              width: 50,
              sortable: false
            },
            isVisible,
            option.value
          );
        case "commissioning":
          return {
            id: "commissioning",
            Header: "Commissioner",
            accessor: d =>
              (d.commissioning_office && `[OFF] ${d.commissioning_office}`) ||
              (d.commissioning_division && `[DIV] ${d.commissioning_division}`),
            show: isVisible("commissioning"),
            code: "commissioning"
          };
        case "commissioning_parent":
          return {
            id: "commissioning_parent",
            Header: "Commissioning Parent",
            accessor: d =>
              (d.commissioning_office__parent &&
                `[OFF] ${d.commissioning_office__parent}`) ||
              (d.commissioning_division__parent &&
                `[DIV] ${d.commissioning_division__parent}`),
            show: isVisible("commissioning_parent"),
            code: "commissioning_parent"
          };
        case "internal_code":
          return buildColumn(
            option,
            {
              cell: row => <strong>{`${row.value}`}</strong>,
              maxWidth: 120,
              code: option.value
            },
            isVisible,
            option.value
          );
        // use a custom representation
        case "title":
          return buildColumn(
            option,
            {
              cell: row => (
                <strong
                  className={
                    row.original.status_code === "CANCELLED"
                      ? Styles.cancelledEvaluationLink
                      : Styles.evaluationLink
                  }
                >
                  {`${row.value}`}
                </strong>
              ),
              maxWidth: 500
            },
            isVisible,
            option.value
          );
        case "planned_budget":
        case "cef_allocation_requested":
        case "cef_granted":
        case "actual_expenditure":
        case "subject_funded_value":
          return buildColumn(
            option,
            {
              cell: row => setThousandsCommas(row.value),
              maxWidth: 120,
              sortMethod: compareThousandsCommas,
              code: option.value
            },
            isVisible,
            option.value
          );
        case "entire_project_funding":
          return buildColumn(
            option,
            {
              cell: row => setThousandsCommas(row.value),
              maxWidth: 120,
              sortMethod: compareThousandsCommas,
              code: option.value
            },
            isVisible,
            option.value
          );
        // use custom sort method and `maxWidth`
        case "status":
          return buildColumn(
            option,
            {
              sortMethod: sortStatuses,
              maxWidth: 200,
              accessor: d => d.status_code,
              cell: row => `${row.value}`
            },
            isVisible,
            option.value
          );
        // use custom sort method, maxWidth and accessor
        case "status_prev":
          return buildColumn(
            option,
            {
              sortMethod: sortStatuses,
              header: "Previous status",
              maxWidth: 200,
              accessor: d => d.status_code_prev
            },
            isVisible,
            option.value
          );
        // Shorter columns
        case "publication_reference":
        case "kind":
             return buildColumn(
            option,
            {
              sortMethod: sortStatuses,
              maxWidth: 200
            },
            isVisible,
            option.value
          );
        case "start_date":
          return buildColumn(
            option,
            {
              sortMethod: sortDates,
              maxWidth: 200
            },
            isVisible,
            option.value
          );
        // use custom sort method and `maxWidth`
        case "exercise":
          return buildColumn(
            option,
            {
              sortMethod: sortExercises,
              maxWidth: 200,
              accessor: d => d.exercise
            },
            isVisible,
            option.value
          );
        // use a custom sort method and a custom representation
        case "approval_date":
        case "planned_completed_date":
          return buildColumn(
            option,
            {
              maxWidth: 200,
              sortMethod: sortDates
            },
            isVisible,
            option.value
          );
        // use a custom label
        case "last_group_modified_date":
          return buildColumn(
            { label: "Last modification date", value: option.value },
            {
              maxWidth: 200,
              sortMethod: sortDates
            },
            isVisible,
            option.value
          );
        // use a custom label
        case "last_group_modified_user":
          return buildColumn(
            { label: "Last modified by", value: option.value },
            {},
            isVisible,
            option.value
          );
        // this field is shown if the current has the correct permission
        // and it also has a custom label.
        case "errors_number":
          return isUserSupport
            ? buildColumn(
                { label: "EN", value: option.value },
                {
                  maxWidth: 200,
                  cell: row => row.value
                },
                isVisible,
                option.value
              )
            : undefined;
        // these fields are array of strings
        // we want a custom representation
        case "activity_categories_names":
        case "joint_partners_names":
        case "sustainable_development_goals_names":
        case "request_by_donors_names":
        case "funded_by_donors_names":
        case "windows_names":
          return buildColumn(
            option,
            {
              maxWidth: 200,
              cell: row => row.value && row.value.join(", ")
            },
            isVisible,
            option.value
          );
        case "crosscutting_priorities_names":
          return buildColumn(
            option,
            {
              maxWidth: 200,
              cell: row => row.value && row.value.join(", ")
            },
            isVisible,
            option.value
          );
        case "topics_names":
          return buildColumn(
            option,
            {
              maxWidth: 200,
              cell: row => row.value && row.value.join(", ")
            },
            isVisible,
            option.value
          );
        case "reason_for_cancellation":
          return buildColumn(
            option,
            {
              sortMethod: sortByPosition,
              maxWidth: 200,
              cell: row => row?.value?.name
            },
            isVisible,
            option.value
          );
        // use a custom component ``IsBool``
        case "is_migrated":
        case "is_joint":
        case "is_legacy":
          return buildColumn(
            option,
            { cell: IsBoolComponent },
            isVisible,
            option.value
          );
        default:
          return buildColumn(option, {}, isVisible, option.value);
      }
    })
    .sort((a, b) => defaultColumnsSort(a.code, b.code));
};

export default columnsGenerator;
