import { combineReducers } from "redux";
import {fetching, items } from "./resource";

const scoreReducer = (state = {}, action) => {
  if (action.type === "SET_UNDIS_TOTAL_SCORE") {
    return action.data;
  }
  return state;
};

export default combineReducers({
  totalScore: scoreReducer,
  isFetching: fetching("undises") || fetching("evaluations") || fetching("undisFrameworks"),
  undisFramework: items("undisFrameworks"),
  items: items("undises"),
});
