import React from "react";
import PropTypes from "prop-types";

import { withRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { notificationStyle } from "@wfp/ui";

import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { getIsUserSupport } from "./selectors/user";

import Container from "./components/Container";
import Footer from "./components/Footer";
import Header from "./pages/Header";
import ChangelogPage from "./pages/Changelog";
import EvaluationList from "./pages/EvaluationList";
import EvaluationDetail from "./pages/EvaluationDetail";
import Logout from "./pages/Logout";
import CompanyList from "./pages/CompanyList";
import PeopleList from "./pages/PeopleList";
import NotAuthorized from "./pages/NotAuthorized";
import NotAuthorizedHeader from "./pages/NotAuthorizedHeader";
import CountryInfoList from "./pages/CountryInfoList";
import SDGList from "./pages/SustainableDevelopmentGoalsList";
import RFCList from "./pages/ReasonsForCancellationList";
import TopicList from "./pages/TopicList";
import ActivityCategoryList from "./pages/ActivityCategoryList";
import WindowsList from "./pages/WindowList";
import RolesList from "./pages/TeamRolesList";
import ProductTypeList from "./pages/ProductTypeList";
import DonorList from "./pages/Donors";
import CrosscuttingPrioritiesList from "./pages/CrosscuttingPriorities";
import TypeList from "./pages/TypeList";
import MilestoneList from "./pages/Milestones";
import PartnersList from "./pages/PartnersList";
import StatusValidationSchema from "./pages/StatusValidationSchema";
import SplashScreen from "./components/SplashScreen";
import UserList from "./pages/Users";

import { Boundary } from "./utils";

import fetchUser from "./actions/user";
import topicsActions from "./actions/topics";
import EnhancedActivityList from "./pages/EnhancedActivityList";


export const isUrlPublic = url => {
  if (url.includes("/logout")) return true;
  if (url.includes("/not-authorized")) return true;
  return false;
};

/**
 * Wrapper for main application pages.
 *
 * It wraps them with:
 * - a full width & full height container with white background color
 * - a Sentry boundary for error reporting
 * - a toast dismisser that activates on componentDidMount (like when a route changes)
 */
const wrapPage = component => (
  <Container color="white">
    <Boundary>{component}</Boundary>
  </Container>
);

// We wrap the following pages outside of App's render() method,
// so that they only get wrapped _once_.
//
// For refefence: http://codeassist.wfp.org/jira/browse/OEV-1052

const WrappedEvaluationList = () =>
  wrapPage(<EvaluationList title="Evaluations" />);

const WrappedPeopleList = () => wrapPage(<PeopleList title="People" />);

const WrappedCompanyList = () => wrapPage(<CompanyList title="Companies" />);

const WrappedEnhancedActivityList = () => wrapPage(<EnhancedActivityList title="EnhancedActivities" />);

const WrappedCountryInfoList = () =>
  wrapPage(<CountryInfoList title="CountryInfo" />);

const WrappedChangelogPage = () =>
  wrapPage(<ChangelogPage title="Changelog" />);

const WrappedSDGList = () =>
  wrapPage(<SDGList title="SustainableDevelopmentGoals" />);

const WrappedRFCList = () =>
  wrapPage(<RFCList title="ReasonsForCancellation" />);

const WrappedTopicList = () =>
  wrapPage(<TopicList title="Topic" />);

const WrappedActivityList = () =>
  wrapPage(<ActivityCategoryList title="Activity Category" />);

const WrappedWindowList = () =>
  wrapPage(<WindowsList title="Window" />);

const WrappedRolesList = () =>
  wrapPage(<RolesList title="Roles" />);

const WrappedProductTypeList = () =>
  wrapPage(<ProductTypeList title="Product Type" />);

const WrappedPartnersList = () =>
  wrapPage(<PartnersList title="Partners" />);

const WrappedMilestoneList = () => wrapPage(<MilestoneList title="Milestones" />);

const WrappedDonorList = () => wrapPage(<DonorList title="Donor" />);
const WrappedCrosscuttingPrioritiesList = () => wrapPage(<CrosscuttingPrioritiesList title="WFP cross-cutting priorities" />);
const WrappedTypeList = () => wrapPage(<TypeList title="Type" />);

const WrappedStatusValidationSchema = () => wrapPage(<StatusValidationSchema title="Status Validation Schema" />);

const WrappedUserList = () => wrapPage(<UserList title="Users" />);

export class AppComponent extends React.Component {
  componentDidMount() {
    // fetches previously handled by EvaluationList
    // NICE TODO: http://codeassist.wfp.org/jira/browse/OEV-1033
    this.props.fetchUser();
    this.props.fetchTopics();

    // On ESC keydown, close all modals
    document.onkeydown = evt => {
      if (evt.key === "Escape" || evt.key === "Esc" || evt.keyCode === 27) {
        this.props.closeModals();
      }
    };
  }

  componentDidUpdate(prevProps) {
    // Dismiss all toasts on URL changes
    if (this.props.location.pathname !== prevProps.location.pathname) {
      /* In the future (when we rewrite App as a function component?) we could `useLocation`
       * https://reacttraining.com/blog/react-router-v5-1/?fbclid=IwAR1WHJKKeF0rO_-jW31mRCatWq5o143OvgyURn6R3uGlHNQ_dqs3QQ4ddLs
       */
      toast.dismiss();
    }

    // Warn the user if the backend's version metadata has changed
    if (
      this.props.shouldRefresh === true &&
      prevProps.shouldRefresh === false
    ) {
      toast.warning("A new version is available, please refresh the page!");
    }
  }

  headerToRender = () => {
    if (!this.props.userHasPermissions) {
      return <NotAuthorizedHeader />;
    }

    return <Header />;
  };

  /*
   * "Content" part of the view.
   *
   * Using a Switch here ensures that we only ever display a single content.
   * Be careful not to over-match, preventing lower Routes to render.
   *
   * (Switch behavior: only the first matching Route renders)
   */
  contentToRender = () => (
    <Switch>
      <Route exact path="/logout" component={Logout} />

      <Route
        path="/evaluations/:evaluationId/:tab?"
        component={EvaluationDetail}
      />

      <Route exact path="/evaluations" component={WrappedEvaluationList} />

      <Route path="/people" component={WrappedPeopleList} />

      <Route path="/companies" component={WrappedCompanyList} />

      <Route path="/enhanced-activity" component={WrappedEnhancedActivityList} />

      <Route path="/countries-info" component={WrappedCountryInfoList} />

      <Route path="/donors" component={WrappedDonorList} />

      <Route path="/crosscutting-priorities" component={WrappedCrosscuttingPrioritiesList} />

      <Route path="/types" component={WrappedTypeList} />

      <Route path="/sustainable-development-goals" component={WrappedSDGList} />

      <Route path="/reasons-for-cancellation" component={WrappedRFCList} />

      <Route path="/topics" component={WrappedTopicList} />

      <Route path="/activity-categories" component={WrappedActivityList} />

      <Route path="/windows" component={WrappedWindowList} />

      <Route path="/team-roles" component={WrappedRolesList} />

      <Route path="/product-types" component={WrappedProductTypeList} />

      <Route path="/partners" component={WrappedPartnersList} />

      <Route path="/milestones" component={WrappedMilestoneList} />

      <Route path="/status-validation-schema" component={WrappedStatusValidationSchema} />

      <Route path="/users" component={WrappedUserList} />

      {/* A route for testing Sentry integration */}
      <Route
        path="/test_error"
        component={() => {
            if (this.props.isUserSupport) {
              throw new Error('You got the UserTestException error. I hope you are testing the error handling!')
            }
            return null
          }
        }
      />

      {this.props.isUserSupport && (
        <Route path="/changelog" component={WrappedChangelogPage} />
      )}

      <Route
        exact
        path="/"
        component={() => <Redirect exact from="/" to="/evaluations" />}
      />

      <Route path="/not-authorized" component={NotAuthorized} />
    </Switch>
  );

  /** Flicker-less UX flow: SplashPage -> Login or NotAuthorized or EvaluationList */
  render() {
    const { isSessionExpired, userHasPermissions, userIsLoggedIn } = this.props;

    if (!isUrlPublic(window.location.pathname)) {
      /** used to skip SplashScreen if user had previously been logged in and had permissions */
      const userHadPermissions =
        localStorage.getItem("userHadPermissions") === "true";

      if (isSessionExpired) {
        window.location = process.env.LOGIN_URL; // Redirect to offsite login
      }
      if (!userIsLoggedIn && !userHadPermissions) {
        return <SplashScreen />;
      }
      if (userIsLoggedIn && !userHadPermissions && !userHasPermissions) {
        return <Redirect push to="/not-authorized" />;
      }
    }

    return (
      <div className="wfp-container">
        {this.headerToRender()}
        {this.contentToRender()}
        <Footer />
        <ToastContainer
          {...notificationStyle}
          position="top-right"
          autoClose={false}
        />
      </div>
    );
  }
}

AppComponent.propTypes = {
  closeModals: PropTypes.func.isRequired,
  isSessionExpired: PropTypes.bool,
  fetchTopics: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  shouldRefresh: PropTypes.bool,
  userHasPermissions: PropTypes.bool.isRequired,
  userIsLoggedIn: PropTypes.bool.isRequired,
  isUserSupport: PropTypes.bool.isRequired
};

AppComponent.defaultProps = {
  isSessionExpired: false,
  shouldRefresh: false
};

export const mapStateToProps = state => {
  const user = state.user && state.user[0];

  // `!isEmpty` because /userprofile responds (200, {}) to unauth'ed users
  const userIsLoggedIn = typeof user === "object" && !isEmpty(user);

  const userHasPermissions =
    userIsLoggedIn &&
    user.permissions.permission &&
    user.permissions.permission.length > 0;

  return {
    isSessionExpired: state.auth.isSessionExpired,
    isUserSupport: getIsUserSupport(state),
    next: state.next,
    shouldRefresh: state.app.shouldRefresh,
    userHasPermissions,
    userIsLoggedIn
  };
};

export const mapDispatchToProps = dispatch => ({
  closeModals: () => dispatch({ type: "HIDE_ALL_TOGGLABLES" }),

  //
  // Fetches previously handled by EvaluationList
  //
  // At least one is needed here: if it receives 401, it triggers login.
  // More would be better, to have more data ready by the time we can display EvaluationList
  //
  fetchUser: () => dispatch(fetchUser()),
  fetchTopics: () => dispatch(topicsActions.list({}, { paginated: false }))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppComponent)
);
