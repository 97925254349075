import React from "react";
import PropTypes from "prop-types";

import { Button } from "@wfp/ui";

import PersonFormComponent from "../../../../../PeopleList/PersonForm";

import Styles from "./styles.scss";

const MemberCreateForm = props => (
  <div className={Styles.memberCreateForm}>
    <h6>Add Team member</h6>
    <PersonFormComponent
      initialValues={props.initialValues}
      className={Styles.personForm}
      sex={props.sex}
      countries={props.countries}
    />
    <div className={Styles.buttonContainer}>
      <Button
        small
        kind="secondary"
        type="submit"
        className={Styles.button}
        onClick={props.togglePersonForm}
      >
        Cancel
      </Button>
      <Button small type="submit" className={Styles.button}>
        Save
      </Button>
    </div>
  </div>
);

MemberCreateForm.propTypes = {
  sex: PropTypes.arrayOf(PropTypes.object).isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  togglePersonForm: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({})
};

MemberCreateForm.defaultProps = {
  initialValues: {}
};

export default MemberCreateForm;
