import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import PropTypes from "prop-types";

/**
 * This class is used to generalize components with:
 * - a table of data
 * - a modal to add a new element
 * - a modal to confirm the deletion of an element
 *
 * Component extending this class need to have ALL the required props.
 * See Coverage, Members or Products for example usage
 */
class TableWithModalsMixin extends React.Component {
  componentDidMount() {
    // First, fetch table data
    if (this.props.fetchItems) {
       this.props.fetchItems(this.props.match.params.evaluationId);
    }
    // Then, everything else
    if (this.props.fetchData) {
      this.props.fetchData();
    }
  }

  /** Reset the form, select the item and open the modal */
  getItem = id => {
    this.props.resetForm();
    this.props.selectItem(id);
    this.props.toggleAddModal();
  };

  /** Select item to delete, open deletion modal */
  setItemToDelete = id => {
    this.props.selectItem(id);
    this.props.toggleDeleteModal();
  };

  /** Delete item, select null, reset form and toggle modal */
  deleteItem = (extraParam = null) => {
    this.props.deleteItem(
      this.props.selectedItem.id,
      this.props.match.params.evaluationId,
      extraParam
    );
    this.props.selectItem(null);
    this.props.resetForm();
    this.props.toggleDeleteModal();
  };

  handleDragEnd = (results) => {
    const data = results.map((item, index) => ({
      ...item,
      position: index,
    }));

    if(this.props.saveOrderingPosition) {
      this.props.saveOrderingPosition(data);
    }
  }

  saveItem = (extraParam = null) => {
    const adapter =
      typeof this.valuesAdapter === "function"
        ? this.valuesAdapter
        : values => values;
    const values = adapter(this.props.formValues);
    // Avoid sending undefined as the instance, then toggle modal
    this.props
      .saveItem(values || {}, this.props.match.params.evaluationId, extraParam)
      .then(() => this.props.toggleAddModal());
  };
}

TableWithModalsMixin.propTypes = {
  /** This comes from react router */
  match: ReactRouterPropTypes.match.isRequired,
  /** Function used to tell redux store which element is the selected one
   * should return an instance of the element with an 'id' field */
  selectItem: PropTypes.func.isRequired,
  /** Function used to fetch table data */
  fetchItems: PropTypes.func.isRequired,
  /** If needed, this can be a Promise.all() dispatching all other
   * actions needed to retrieve the data */
  fetchData: PropTypes.func.isRequired,
  /** Function to dispatch the toggle action for the add modal */
  toggleAddModal: PropTypes.func.isRequired,
  /** Function to dispatch the toggle action for the delete modal */
  toggleDeleteModal: PropTypes.func.isRequired,
  /** Function used to dispatch save action */
  saveItem: PropTypes.func.isRequired,
  /** Function used to dispatch delete action */
  deleteItem: PropTypes.func.isRequired,
  /** Selected item (to be deleted or shown) */
  selectedItem: PropTypes.shape({ id: PropTypes.any.isRequired }),
  /** Actual form values, from redux form */
  formValues: PropTypes.shape({}),
  /** Function that dispatches the action to reset the form */
  resetForm: PropTypes.func.isRequired,
  /** Function that dispatches the action to reorder the given list */
  saveOrderingPosition: PropTypes.func,
};

TableWithModalsMixin.defaultProps = {
  formValues: {},
  selectedItem: { id: null },
  saveOrderingPosition: null,
};

export default TableWithModalsMixin;
