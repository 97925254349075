import { schema } from "normalizr";
import Resource from "./resource";
import { partnersCategoriesTypes } from "../types";

const partnersCategoriesSchema = new schema.Entity("partnersCategories");

export default new Resource(
  "/partners_categories",
  partnersCategoriesTypes,
  partnersCategoriesSchema
);
