import React, {useState} from "react";
import SecondaryNavigation from "SecondaryNavigation";
import {Button} from "@wfp/ui";
import {iconAddGlyph} from "@wfp/icons";
import {useHistory} from "react-router-dom";
import {usePermission} from "hooks";
import EvaluationChoiceModal from "../EvaluationChoiceModal";

export const ListHeader = ({DECount, CECount, IECount}) => {
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const hasAnyAddPermission = usePermission(
        'exercise.add_centralizedevaluation', 'exercise.add_decentralizedevaluation', 'exercise.add_impactevaluation',
    )
    const history = useHistory();

    const onClickHandler = (selectedCategoryCode) => {
        history.push(`/evaluations/${selectedCategoryCode}/info`);
    };

    const subtitle = () => {
        if (DECount === undefined) {
            return "Loading evaluations..."
        }
        return `${CECount} Centralized, ${DECount} Decentralized, ${IECount} Impact`
    }

    return (
      <div>
        <EvaluationChoiceModal
          onSelect={onClickHandler}
          modalIsVisible={modalIsVisible}
          onClose={() => setModalIsVisible(false)}
        />
        <SecondaryNavigation
          additional={
            <Button
              type="submit"
              icon={iconAddGlyph}
              iconDescription="Create a new evaluation"
              disabled={!hasAnyAddPermission}
              onClick={() => setModalIsVisible(true)}
                >
                  New Evaluation
            </Button>
          }
          title="Evaluations"
          subtitle={subtitle()}
        />
      </div>
    );
}