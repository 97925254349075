import { schema } from "normalizr";
import { statusValidationSchemasTypes } from "../types";
import Resource from "./resource";
import {client} from "../store";

export class StatusValidationResource extends Resource {
  static LATEST_ID = 'last'

  fetchLast = () => dispatch =>
    dispatch(this.detail(StatusValidationResource.LATEST_ID));
}


export const statusValidationSchemas = new schema.Entity(
    "statusValidationSchemas",
    {},
    {
      idAttribute: () => StatusValidationResource.LATEST_ID,
    }
);


export default new StatusValidationResource(
    "/status-validation-schemas",
    statusValidationSchemasTypes,
    statusValidationSchemas,
);

client.enableAutoCamelCase('/status-validation-schemas');
