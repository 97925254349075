import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import { Icon } from "@wfp/ui";
import { iconSearch } from "@wfp/icons";

import TextInput from "TextInput";

import Styles from "./styles.scss";

const SearchBar = ({ placeholder }) => (
  <div className={["wfp-form", Styles.searchBar].join(" ")}>
    <Field
      name="query"
      component={TextInput}
      type="text"
      placeholder={placeholder}
    />
    <Icon icon={iconSearch} width="19" height="19" description="Edit item" />
  </div>
);

SearchBar.propTypes = {
  placeholder: PropTypes.string
};

SearchBar.defaultProps = {
  placeholder: "Search"
};

export default reduxForm({
  form: "search",
  keepDirtyOnReinitialize: false
})(SearchBar);
