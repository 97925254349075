import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import CRUDLayout, {CRUDMapStateToPropsPropTypes, CRUDMapDispatchToProps, CRUDMapDispatchToPropsPropTypes, CRUDMapStateToProps} from "CRUDLayout";
import TableWithModalsMixin from "../../components/TableWithModalsMixin";
import CrosscuttingPrioritiesModal from "./CrosscuttingPrioritiesModal";
import columnsGenerator from "./tableColumns";
import crosscuttingPrioritiesActions from "../../actions/crosscuttingPriorities";

export class CrosscuttingPrioritiesListComponent extends TableWithModalsMixin {
    render = () => (
      <CRUDLayout
        {...this.props}
        title='WFP cross-cutting priorities'
        entityName='crosscuttingPriorities'
        columnsGenerator={columnsGenerator}
        addModalComponent={CrosscuttingPrioritiesModal}
        getItem={this.getItem}
        handleDragEnd={this.handleDragEnd}
        onSave={this.saveItem}
      />
    );
}



CrosscuttingPrioritiesListComponent.propTypes = {
    ...CRUDMapStateToPropsPropTypes,
    ...CRUDMapDispatchToPropsPropTypes,
    data: PropTypes.arrayOf(
      PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          code: PropTypes.string,
          isDeprecated: PropTypes.bool
      })
    ).isRequired,
    selectedItem: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        code: PropTypes.string,
        isDeprecated: PropTypes.bool
    }),
    formValues: PropTypes.shape({}),
};

export const mapStateToProps = state => (
  CRUDMapStateToProps(state, 'crosscuttingPriorities', 'exercise.add_crosscuttingpriority', 'exercise.change_crosscuttingpriority')
)

export const mapDispatchToProps = dispatch => (
  CRUDMapDispatchToProps(dispatch, crosscuttingPrioritiesActions, 'crosscuttingPriorities')
);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CrosscuttingPrioritiesListComponent)
);
