import { client } from "../store";

export const authUser = data => ({
  type: "AUTH_USER",
  payload: client.post("/api-token-auth/", data)
});

export const authUserRefresh = data => ({
  type: "AUTH_USER",
  payload: client.post("/api-token-refresh/", data)
});

export const logoutUser = () => dispatch => {
  dispatch({ type: "AUTH_USER_LOGOUT" });
};
