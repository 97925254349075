import React from "react";
import PropTypes from "prop-types";

import { Icon } from "@wfp/ui";
import { iconCloseGlyph } from "@wfp/icons";

import Styles from "../styles.scss";

const NumberComponent = props => {
  const activeStyles = props.active ? Styles.isActive : "";
  const currentStatusStyles = props.isCurrentStatus
    ? Styles.isCurrentStatus
    : "";

  return (
    <div
      className={`${Styles.numberComponent} ${activeStyles} ${currentStatusStyles}`}
    >
      {props.number && <span>{props.number}</span>}
      {!props.number && (
        <Icon
          icon={iconCloseGlyph}
          width="19"
          height="19"
          description="Delete item"
          className={Styles.cancelledIcon}
        />
      )}
    </div>
  );
};

NumberComponent.propTypes = {
  number: PropTypes.number,
  active: PropTypes.bool,
  /* Whether the Number belongs to a current status */
  isCurrentStatus: PropTypes.bool
};

NumberComponent.defaultProps = {
  number: undefined,
  active: false,
  isCurrentStatus: false
};

export default NumberComponent;
