import React from "react";
import PropTypes from "prop-types";
import {reduxForm, FieldArray, Field} from "redux-form";

import {Button} from "@wfp/ui";

import {Grid, Row, Col} from "Grid";
import TextInput from "TextInput";
import {SecondaryNavigationActions} from "SecondaryNavigation";
import HelpMessageBox from "HelpMessageBox";

const Criterion = props => {

    const renderRows = ({fields}) => {
        const RowComponent = props.rowComponent;

        return (
          <>
            {fields.map((item, index) => (
              <RowComponent
                formName={props.form}
                key={item}
                itemName={item}
                item={fields.get(index).criterion}
                disableLabels={index > 0}
                readOnly={props.readOnly}
                />)
                )}
          </>)
    }


    return (
      <div>
        <SecondaryNavigationActions>
          <Button
            type="submit"
            disabled={props.readOnly}
            onClick={props.handleSubmit(props.save)}
                >
                    Save Evaluation
          </Button>
        </SecondaryNavigationActions>
        <div>
          <form className="wfp-form wfp--form-long relative">
            {props.messages && props.messages.length > 0 && !props.readOnly && (
            <HelpMessageBox message={props.defaultMessage} />
            )}
            <FieldArray
              name="items"
              component={renderRows}
                    />
            <div className="form-row">
              <Grid fluid>
                <Row>
                  <Col xs={5} />
                  <Col xs={1} fixedWidth={`${props.totalScoreWidth}px`}>
                    <div>
                      <Field
                        name="totalScore"
                        label="Total Score"
                        component={TextInput}
                        readOnly={props.readOnlyTotalScore}
                        tabIndex="-1"
                                        />
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div>
                      <Field
                        name="totalRating"
                        label="Total Rating"
                        readOnly
                        component={TextInput}
                        tabIndex="-1"
                                        />
                    </div>
                  </Col>
                </Row>
              </Grid>
            </div>
          </form>
        </div>
      </div>
    );
}


Criterion.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    messages: PropTypes.arrayOf(PropTypes.string).isRequired,
    rowComponent: PropTypes.func.isRequired,
    readOnlyTotalScore: PropTypes.bool,
    totalScoreWidth: PropTypes.number,
    defaultMessage: PropTypes.string.isRequired,
};

Criterion.defaultProps = {
    readOnlyTotalScore: true,
    totalScoreWidth: 98,
};


export const CriterionForm = reduxForm({
    enableReinitialize: true // Form will reinitialize every time the initialValues changes
})(Criterion);

CriterionForm.propTypes = {
    form: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    save: PropTypes.func.isRequired,
    messages: PropTypes.arrayOf(PropTypes.string).isRequired,
    rowComponent: PropTypes.func.isRequired,
    readOnlyTotalScore: PropTypes.bool,
    initialValues: PropTypes.shape({
        totalScore: PropTypes.number,
        totalRating: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    defaultMessage: PropTypes.string.isRequired,
    totalScoreWidth: PropTypes.number,
};

CriterionForm.defaultProps = {
    readOnlyTotalScore: true,
    totalScoreWidth: 98,
};