import { schema } from "normalizr";
import { membersTypes } from "../types";
import Resource from "./resource";

const membersSchema = new schema.Entity("members");

export default new Resource(
  "/evaluations/<evaluationId>/team",
  membersTypes,
  membersSchema
);
