import { schema } from "normalizr";
import Resource from "./resource";
import { productsTypes } from "../types";

const productSchema = new schema.Entity("products");

export default new Resource(
  "/evaluations/<evaluationId>/products",
  productsTypes,
  productSchema
);
