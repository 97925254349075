import { combineReducers } from "redux";
import {
  fetching,
  submitting,
  options,
  items,
  togglable,
  pagination,
  selectable
} from "./resource";

export default combineReducers({
  selectedCompany: selectable("company"),
  isFetching: fetching("companies"),
  isSubmitting: submitting("companies"),
  options: options("companies"),
  services: items("services"),
  items: items("companies"),
  addModal: togglable("COMPANY_ADD_MODAL"),
  deleteModal: togglable("COMPANY_DELETE_MODAL"),
  page: pagination("companies")
});
