import {useSelector} from "react-redux";

const cleanArg = (arg) => {
    if (!Array.isArray(arg)) {
        return [arg];
    }
    if (!arg) {
        return [];
    }
    return arg;
}

export const usePermission = (...args) => {
    const userPermissions = useSelector(state => state.user?.[0]?.permissions?.permission ?? []);

    return args.some(permissions =>
        cleanArg(permissions).every(permission => userPermissions.includes(permission))
    )
}
