import { schema } from "normalizr";
import {crosscuttingPrioritiesTypes} from "../types";
import Resource from "./resource";
import {client} from "../store";

export const CrosscuttingPrioritiesSchema = new schema.Entity(
    "crosscuttingPriorities"
);

export default new Resource(
    "/crosscutting-priority",
    crosscuttingPrioritiesTypes,
    CrosscuttingPrioritiesSchema,
);

client.enableAutoCamelCase('/crosscutting-priority');
