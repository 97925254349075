import React from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

import Tooltip from "Tooltip";

export const validationStyles = {
  display: "block",
  maxHeight: "12.5rem"
};

export const reactSelectStyles = props => ({
  // hide the dropdown indicator if read-only
  dropdownIndicator: base => {
    if (props.readOnly) {
      return { display: "none" };
    }
    if (props.disabled) {
      return {
        ...base,
        pointerEvents: "none"
      };
    }
    return base;
  },
  // hide the indicator separator if read-only
  indicatorSeparator: base => (props.readOnly ? { display: "none" } : base),
  // hide the clear button if read-only or multi-select
  clearIndicator: base =>
    props.allowMultiple || props.readOnly
      ? {
          display: "none"
        }
      : base,
  // hide the input border if read-only
  control: base => {
    if (props.readOnly) {
      return {
        ...base,
        borderColor: "#eff2f5 !important",
        cursor: "text !important",
        opacity: "1 !important",
        pointerEvents: "auto"
      };
    }
    if (props.disabled) {
      return {
        ...base,
        cursor: "not-allowed !important",
        pointerEvents: "none"
      };
    }
    if (props.invalid) {
      return {
        ...base,
        borderColor: "#c5192d !important"
      };
    }
    return base;
  },
  singleValue: base => ({
    ...base,
    fontSize: "0.875rem",
    color: "#333333"
  }),
  placeholder: base => ({
    ...base,
    color: "#8c9ba5",
    fontSize: "0.875rem"
  }),
  menu: base => ({
    ...base,
    maxHeight: "320px",
    overflowY: "auto"
  }),
  menuList: base => ({
    ...base,
    // If !smallMenuList a valid maxHeight should be set
    // (300px is the default) in order to prevent issues with scrolling
    maxHeight: props.smallMenuList ? "118px" : "300px"
  }),
  option: (base, state) => ({
    ...base,
    cursor: state.isDisabled ? "not-allowed" : "default",
    fontSize: "0.875rem"
  }),
  multiValueRemove: base => {
    if (props.readOnly || props.disabled) {
      return {
        ...base,
        display: "none"
      };
    }
    return base;
  }
});

const Select = props => (
  <div className={`wfp--form-item ${props.cssClass}`} data-testid={props.testId}>
    {!props.helpText && props.label && (
      <label
        htmlFor={props.inputId}
        className="wfp--label"
        style={props.disabled ? { opacity: 0.5 } : {}}
      >
        {props.label}
      </label>
    )}
    {props.helpText && (
      <Tooltip
        message={props.helpText}
        label={props.label}
        formIndex={props.input.name}
      />
    )}
    <ReactSelect
      className="wfp--react-select-container"
      classNamePrefix="wfp--react-select"
      onFocus={props.input.onFocus}
      value={props.input.value}
      onChange={event => {
        if (props.onUncontrolledChange) {
          props.onUncontrolledChange(event)
        } else {
          props.input.onChange(event);
          props.onChangeExtra(event);
        }
      }}
      onInputChange={props.onInputChange}
      noOptionsMessage={() =>
        props.noOptionsMessage ||
        (props.isLoading ? "Loading..." : "No results")
      }
      invalid={props.invalid}
      invalidText={props.invalidText}
      isLoading={props.isLoading}
      isMulti={props.allowMultiple}
      closeMenuOnSelect={props.closeMenuOnSelect}
      options={props.options}
      isOptionDisabled={props.isOptionDisabled}
      isReadOnly={props.readOnly}
      isDisabled={props.readOnly || props.disabled} // Underlying component has only disabled state
      inputId={props.inputId}
      placeholder={props.placeholder || ""}
      openMenuOnClick={props.openMenuOnClick}
      openMenuOnFocus={props.openMenuOnFocus}
      isClearable={props.isClearable}
      hideLabel
      styles={reactSelectStyles(props)}
      isOptionSelected={props.isOptionSelected}
    />
    {props.invalid && (
      <div className="wfp--form-requirement" style={validationStyles}>
        {props.invalidText}
      </div>
    )}
  </div>
);

Select.propTypes = {
  allowMultiple: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  cssClass: PropTypes.string,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  inlineStyle: PropTypes.shape({}),
  input: PropTypes.shape({
      checked: PropTypes.bool,
      name: PropTypes.string,
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      value: PropTypes.any
  }).isRequired,
  inputId: PropTypes.string,
  invalid: PropTypes.bool,
  invalidText: PropTypes.string,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  label: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  onUncontrolledChange: PropTypes.func,
  onChangeExtra: PropTypes.func, // Extra callback after onChange
  onInputChange: PropTypes.func,
  openMenuOnClick: PropTypes.bool,
  openMenuOnFocus: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string,
  smallMenuList: PropTypes.bool,
  isClearable: PropTypes.bool,
  testId: PropTypes.string,
  isOptionSelected: PropTypes.func,
};

Select.defaultProps = {
  allowMultiple: false,
  closeMenuOnSelect: true,
  isClearable: true,
  cssClass: undefined,
  disabled: false,
  readOnly: false,
  helpText: "",
  inlineStyle: undefined,
  inputId: undefined,
  invalid: false,
  invalidText: "A valid value is required",
  isLoading: false,
  isOptionDisabled: undefined,
  label: "",
  noOptionsMessage: undefined,
  onChangeExtra: () => null,
  onUncontrolledChange: undefined,
  onInputChange: undefined,
  openMenuOnClick: false,
  openMenuOnFocus: false,
  placeholder: "",
  smallMenuList: false,
  testId: undefined,
  isOptionSelected: undefined,
};

export default Select;
