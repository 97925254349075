/* eslint-disable import/order */
import * as Sentry from "@sentry/browser";

import registerServiceWorker from "./registerServiceWorker";

import React from "react";
import ReactDOM from "react-dom";
import { Provider, connect } from "react-redux";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";

import { store, history } from "./js/store";
import App from "./js/App";

import "./assets/css/wfp.css";
import "./assets/scss/main.scss";

Sentry.init({
  dsn: "https://9fb75705671a48b8969e061631c3a585@sentry.io/5168756",
  // eslint-disable-next-line no-undef
  environment: GLOBAL_ENV?.sentryEnvironment ?? process.env.ENV?.toUpperCase() // LOCAL, DEV, QA, PROD
});

// eslint-disable-next-line react/prefer-stateless-function
class RootComponent extends React.Component {
  render() {
    return (
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    );
  }
}

const Root = connect()(RootComponent);

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById("root")
);

Provider.propTypes = {
  store: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};

registerServiceWorker();
