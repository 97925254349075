import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import { Grid, Row, Col } from "Grid";
import RangeDatePickerField from "DatePicker/RangeDatePicker";
import ModalForm from "ModalForm";
import Select from "Select";
import CoverageLocationSelect from "CoverageLocationSelect";

const AddModal = props => (
  <ModalForm
    title={props.title}
    isVisible={props.show}
    onClose={props.toggleModal}
    onSubmit={props.handleSubmit(props.onSubmit)}
    onFormSubmit={props.handleSubmit(props.onSubmit)}
    buttonDisabled={props.disabled}
    hiddenButtonDisabled={props.disabled}
  >
    <Grid fluid>
      <Row>
        <Col xs>
          <Field
            name="rblohqc"
            label="Regional Bureau/Liaison Office/HQ/Country"
            component={CoverageLocationSelect}
            openMenuOnClick
          />
        </Col>
      </Row>
      <Row>
        <Col xs>
          <Field
            name="type_of_coverage"
            label="Type Of Coverage"
            component={Select}
            options={props.coverageTypesOptions}
            openMenuOnClick
          />
        </Col>
      </Row>
      <Row>
        <Col xs>
          <Field
            name="phase"
            label="Phase"
            component={Select}
            options={props.phaseOptions}
            openMenuOnClick
          />
        </Col>
      </Row>
      <Row>
        <Col xs={2} />
        <Col xs={10}>
          <Field
            name="dates_range"
            index={1}
            component={RangeDatePickerField}
            minimumNights={0}
            initialVisibleMonth={() => null}
            renderCalendarInfo="Phase"
          />
        </Col>
      </Row>
      <Row>
        <Col xs>
          <Field
            name="status"
            label="Status"
            component={Select}
            options={props.statusesOptions}
            openMenuOnClick
          />
        </Col>
      </Row>
    </Grid>
  </ModalForm>
);

AddModal.propTypes = {
  coverageTypesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  phaseOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ),
  statusesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired
};

AddModal.defaultProps = {
  coverageTypesOptions: [],
  phaseOptions: [],
  statusesOptions: [],
  show: false,
  disabled: undefined
};

export default reduxForm({
  form: "evCoverage",
  enableReinitialize: true
})(AddModal);
