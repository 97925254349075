import Select from "Select";
import {Field} from "redux-form";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {adaptInputValues} from "../../utils";
import {advancedFilterActions} from "../../../../actions/filters";
import Styles from "../../styles.scss";
import typesActions from "../../../../actions/types";


const Types = ({label, fieldName, isDisabled}) => {
  const dispatch = useDispatch();

  const types = Object.values(useSelector(state => state.entities.types))
    .map(t => ({label: t.name, value: t.code}));

  const onChange = inputs => {
    if (inputs !== null && inputs.length > 0) {
      dispatch(advancedFilterActions.addFilter(fieldName, inputs, {
        types: adaptInputValues(inputs)
      }));
    } else {
      dispatch(advancedFilterActions.removeFilter(fieldName));
    }
  }
  useEffect(() => {
    dispatch(typesActions.list({}, {paginated: false}))
  }, [])


  return (
    <Field
      name={fieldName}
      label={label}
      component={Select}
      options={types}
      onChangeExtra={onChange}
      openMenuOnClick
      cssClass={Styles.noPaddingWithSpace}
      disabled={isDisabled}
      allowMultiple
    />
  )
}

export default Types;