exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__container___1EmRb{flex:1;padding-right:10px}.styles__titleContainer___1r9r1{text-align:center;width:150px}.styles__title___t90X1{padding:10px;text-transform:capitalize}.styles__childContainer___qnK2f{border:1px solid #bbbbbb;background-color:#eeeeee;padding:20px;width:100%}.styles__addButtonContainer___3zjrV{padding-right:15px;flex-direction:row-reverse;display:flex}.styles__readonlyTextPlaceholder___1wsrg{flex:1;line-height:40px;padding:0 5px;min-height:40px;vertical-align:center;font-size:16px;background-color:#f9fbfc}\n", ""]);

// Exports
exports.locals = {
	"container": "styles__container___1EmRb",
	"titleContainer": "styles__titleContainer___1r9r1",
	"title": "styles__title___t90X1",
	"childContainer": "styles__childContainer___qnK2f",
	"addButtonContainer": "styles__addButtonContainer___3zjrV",
	"readonlyTextPlaceholder": "styles__readonlyTextPlaceholder___1wsrg"
};