import { schema } from "normalizr";
import {Categories} from "enums";
import Resource from "./resource";
import { deStatusTypes, ceStatusTypes, ieStatusTypes } from "../types";
import { isCreate } from "../utils";

const decentralizedStatusSchema = new schema.Entity(
  "decentralizedStatuses",
  {},
  { idAttribute: "code" }
);
const centralizedStatusSchema = new schema.Entity(
  "centralizedStatuses",
  {},
  { idAttribute: "code" }
);
const impactStatusSchema = new schema.Entity(
  "impactStatuses",
  {},
  { idAttribute: "code" }
);

/** Query the appropriate statuses list endpoint */
const getStatuses = (params, category, evaluationId = null) => {
  let url;
  let types;
  let resourceSchema;
  if (evaluationId !== null && !isCreate(evaluationId)) {
    url = "/evaluations/<evaluationId>/workflow";
  }
  if (category === Categories.DECENTRALIZED) {
    types = deStatusTypes;
    resourceSchema = decentralizedStatusSchema;
    url = url || "/statuses/decentralized";
  } else if (category === Categories.CENTRALIZED) {
    types = ceStatusTypes;
    resourceSchema = centralizedStatusSchema;
    url = url || "/statuses/centralized";
  } else if (category === Categories.IMPACT) {
    types = ieStatusTypes;
    resourceSchema = impactStatusSchema;
    url = url || "/statuses/impact";
  }
  const actions = new Resource(url, types, resourceSchema);
  return actions.list(params, {
    paginated: false,
    pathParams: { evaluationId },
    customAction: "SET_STATUSES"
  });
};

export default getStatuses;
