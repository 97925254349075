import { combineReducers } from "redux";
import {
  fetching,
  submitting,
  items,
  togglable,
  selectable,
} from "./resource";

export default combineReducers({
  selectedMilestone: selectable("milestones"),
  isFetching: fetching("milestones"),
  isSubmitting: submitting("milestones"),
  items: items("milestones"),
  addModal: togglable("MILESTONE_ADD_MODAL"),
});
