import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from "react-flexbox-grid";
import Select from "Select";
import rfcActions from "../../../../../../../actions/reasonsForCancellation";

export const ReasonForCancellation = ({reasonId}) => {
    const dispatch = useDispatch();
    const reason = useSelector(state => state.entities.reasonsForCancellation?.[reasonId]);

    useEffect(() => {
        if (reasonId && !reason) {
            dispatch(rfcActions.list({}, {paginated: false}))
        }
    }, [reasonId]);

    if (!reason) {
        return null;
    }

    return (
      <Row>
        <Col md={12}>
          <Select
            label="Reason for cancellation"
            placeholder={reason.name}
            input={{}}
            readOnly
          />
        </Col>
      </Row>
    )
};