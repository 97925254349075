exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__container___1G7t1{display:flex;flex-direction:row}.styles__titleContainer___2HkTT{text-align:center;width:150px;margin-bottom:20px}.styles__title___3nAjy{padding:10px;text-transform:capitalize}.styles__childContainer___1J2Am{flex-grow:4}\n", ""]);

// Exports
exports.locals = {
	"container": "styles__container___1G7t1",
	"titleContainer": "styles__titleContainer___2HkTT",
	"title": "styles__title___3nAjy",
	"childContainer": "styles__childContainer___1J2Am"
};