import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import Styles from "./styles.scss";

const Logout = ({ loginUrl }) => (
  <div>
    <div className={Styles.wfpBoxCentered}>
      <div className="wfp-form">
        <p>You are logged out</p>
        <a href={loginUrl || process.env.LOGIN_URL}>Sign In</a>
      </div>
    </div>
  </div>
);

Logout.propTypes = {
  loginUrl: PropTypes.string.isRequired
};

export default withRouter(connect()(Logout));
