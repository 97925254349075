export default class fieldManipulator {
  /**
   * Config which tells, which kind of status requirements need to merge into a single one.
   *
   * E.g: all phase_date requirement type need to merge and replace with phase_date__all.
   * @type {[{newRequirementKey: string, requirementClass: string}]}
   */
  static combineFieldsConfig = [
    {
      newRequirementKey: 'phases__dates',
      requirementClass: 'PhaseDate',
      label: 'Phases Dates',
    }
  ]

  /**
   * Add here those fields that need to be validated when a status is applied but they have to be hidden.
   *
   * @type {[string, string]}
   */
  static fieldsToHide = ["joint_partners", "phases__all"];


  /**
   * Hide not required field and merge requirements if needed
   *
   * @param {array} statuses
   * @param {object} requirements
   * @param {object} validation
   *
   * @returns {array} statuses
   */
  static manipulate(statuses, requirements, validation) {
    const hideStatuses = this.hideFields(statuses);

    return this.combineFields(hideStatuses, requirements, validation);
  }

  /**
   * Here we filter for each status its requirements
   * in order to find the field that need to be hidden from the left column
   *
   * @param {array} statuses
   *
   * @returns {array} statuses
   */
  static hideFields = statuses => {
    return statuses?.map(s => ({
      ...s,
      requirements_delta: s.requirements_delta.filter(
        r => !this.fieldsToHide.includes(r)
      )
    }));
  }

  /**
   * There are fields which have to shown as one requirement in statuses.
   * This function merge these requirements into a single one. To achive this we also need to merge them in requirements
   * and validations.
   *
   * @param {array} statuses
   * @param {object} requirements
   * @param {object} validation
   *
   * @returns {array} statuses
   */
  static combineFields = (statuses, requirements, validation) => {
    // Reduce the config to use easier
    const reducedConfig = fieldManipulator.combineFieldsConfig.reduce(
      (objects, object) => Object.assign(objects, {[object.requirementClass]: object}),
      {}
    );

    const combinedStatuses = fieldManipulator.combineFieldsInStatuses(statuses, requirements, reducedConfig);
    const combinedValidation = fieldManipulator.combineFieldsInValidation(validation, requirements, reducedConfig);
    const combinedRequirements = fieldManipulator.combineFieldsInRequirements(requirements);

    return [combinedStatuses, combinedRequirements, combinedValidation];
  }

  static combineFieldsInStatuses = (statuses, requirements, reducedConfig) => {
    return statuses?.map(status => ({
      ...status,
      requirements: fieldManipulator.combineSingleStatusField(status, 'requirements', requirements, reducedConfig),
      requirements_delta: fieldManipulator.combineSingleStatusField(status, 'requirements_delta', requirements, reducedConfig),
    }))
  }

  /**
   * This helper function makes easier to merge requirements multiple times.
   * Remove the undesirable requirements and add the required one to the given status and field.
   *
   * @param status
   * @param field
   * @param requirements
   * @param reducedConfig
   * @returns {[]}
   */
  static combineSingleStatusField = (status, field, requirements, reducedConfig) => {
    const values = [];

    for (let i = 0; i < status[field].length; i += 1) {
      const value = status[field][i];
      const requirementClass = requirements[value]?.requirementClass;
      const config = reducedConfig?.[requirementClass];
      if (!config) {
        values.push(value)
      } else if (!values.includes(config.newRequirementKey)) {
        values.push(config.newRequirementKey)
      }
    }

    return values;
  }


  static combineFieldsInValidation = (validation, requirements, reducedConfig) => {
    const combinedValidation = []

    if (!validation) {
       return {};
    }

    Object.keys(validation).forEach((statusKey) => {
      combinedValidation[statusKey] = Object.keys(validation[statusKey]).reduce((errors, fieldKey) => {
        const requirementClass = requirements[fieldKey]?.requirementClass;
        const config = reducedConfig?.[requirementClass];
        if (!config) {
          return Object.assign(errors, {
            [fieldKey]: validation[statusKey][fieldKey],
          })
        }

        return Object.assign(errors, {
          [config.newRequirementKey]: validation[statusKey][fieldKey],
        })
      }, {})
    });

    return combinedValidation;
  }

  static combineFieldsInRequirements = (requirements) => {
    if (!requirements) {
      return {};
    }

    return fieldManipulator.combineFieldsConfig.reduce(
      (reqs, config) =>
        Object.assign(reqs, {[config.newRequirementKey]: config}),
      requirements
    )
  }
}
