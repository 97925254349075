import React from "react";
import PropTypes from "prop-types";

const Legend = ({ color, label }) => (
  <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
    <div style={{ backgroundColor: color, width: 20, height: 20, marginRight: 10, marginLeft: 10, }} />
    { label }
  </div>
);

Legend.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Legend;
