import { combineReducers } from "redux";
import { fetching, items, pagination, pageSize } from "./resource";


const pageCountReducer = (state = 0, action) => {
  if (action.type === "SET_PAGE_COUNT") {
    return action.count;
  }
  return state;
};

const totalItemsReducer = (state = 0, action) => {
  if (action.type === "SET_TOTAL_ITEMS") {
    return action.total;
  }
  return state;
};

export default combineReducers({
  isFetching: fetching("enhancedActivity"),
  items: items("enhancedActivity"),
  page: pagination("enhancedActivity"),
  pageSize: pageSize("enhancedActivity"),
  pageCount: pageCountReducer,
  totalItems: totalItemsReducer,
});
