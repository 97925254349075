import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@wfp/ui";

const DeleteModal = props => (
  <Modal
    data-testid={props.testId}
    open={props.show}
    modalHeading={props.title}
    onRequestSubmit={props.onDelete}
    onRequestClose={props.toggleModal}
    primaryButtonText={props.confirmLabel}
    secondaryButtonText={props.cancelLabel}
    primaryButtonDisabled={props.disabled}
    danger={props.danger}
    selectorPrimaryFocus={false}
  >
    <p className="wfp--modal-content__text">{props.message}</p>
    {props.disclaimer && (
      <p className="wfp--modal-content__text">{props.disclaimer}</p>
    )}
  </Modal>
);

DeleteModal.propTypes = {
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  show: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  disclaimer: PropTypes.string,
  disabled: PropTypes.bool,
  danger: PropTypes.bool,
  testId: PropTypes.string,
};

DeleteModal.defaultProps = {
  cancelLabel: "No",
  confirmLabel: "Yes",
  show: false,
  disabled: false,
  danger: false,
  title: undefined,
  message: undefined,
  disclaimer: undefined,
  testId: undefined,
};

export default DeleteModal;
