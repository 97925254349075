import { schema } from "normalizr";
import { enhancedActivityTypes } from "../types";
import Resource from "./resource";
import {client} from "../store";
import { setPage, setPageSize } from "./pagination";

const activitySchema = new schema.Entity("enhancedActivity");

export const EvaluationEnhancedActivityActions = new Resource(
  "/evaluations/<evaluationId>/new_activity",
  enhancedActivityTypes,
  activitySchema
);

export const setActivityPage = page => setPage(page, "enhancedActivity");
export const setActivityPageSize = payload => setPageSize(payload, "enhancedActivity");
export default new Resource("/new-activity", enhancedActivityTypes, activitySchema);

client.enableAutoCamelCase('/new-activity');
client.enableAutoCamelCase('/new_activity');
