import { combineReducers } from "redux";
import {
  statuses,
  fetching,
  submitting,
  items,
  options,
  togglable
} from "./resource";

// Exported for test
export const customFetch = baseName => {
  const rejectActions = [`${baseName}_REJECTED`, `${baseName}_FULFILLED`];
  return (state = false, action) => {
    if (action.type === `${baseName}_PENDING`) return true;
    if (rejectActions.includes(action.type)) return false;
    return state;
  };
};

export default combineReducers({
  activityCategories: items("activityCategories"),
  detailOptions: options("evaluations", true),
  exerciseOptions: items("exerciseOptions"),
  fetchingActivityCategories: fetching("activityCategories"),
  fetchingEvaluationsDetailOptions: customFetch("EVALUATIONS_DETAIL_OPTIONS"),
  fetchingEvaluations: fetching("evaluations"),
  fetchingRequirements: customFetch("FETCH_REQUIREMENTS"),
  fetchingListEvaluations: fetching("listEvaluations"),
  fetchingPartners: fetching("partners"),
  fetchingPartnersCategories: fetching("partnersCategories"),
  fetchingProjectInfo: customFetch("PROJECT_INFO"),
  fetchingSustainableDevelopmentGoals: fetching("sustainableDevelopmentGoals"),
  fetchingDonors: fetching("donors"),
  fetchingCrosscuttingPriorities: fetching("crosscuttingPriorities"),
  fetchingWindows: fetching("Windows"),
  fetchingReasonsForCancellation: fetching('reasonsForCancellation'),
  fetchingTitles: fetching("titles"),
  fetchingTopics: fetching("topics"),
  fetchingTypes: fetching("types"),
  fetchingUser: fetching("user"),
  fetchingUsers: fetching("users"),
  fetchingValidation: fetching("validation"),
  fetchingWfpDivisions: fetching("wfpDivisions"),
  fetchingWfpOffices: fetching("wfpOffices"),
  fetchingWfpUsers: fetching("wfpUsers"),
  isSubmitting: submitting("evaluations"),
  listEvaluation: items("listEvaluations"),
  options: options("evaluations"),
  partners: items("partners"),
  partnersCategories: items("partnersCategories"),
  showDeleteModal: togglable("EVALUATION_DELETE_MODAL"),
  showOptionsDropdown: togglable("EVALUATION_OPTIONS_DROPDOWN"),
  showWarningModal: togglable("EVALUATION_WARNING_MODAL"),
  standards: items("standards"),
  standardsOptions: options("standards"),
  statuses,
  sustainableDevelopmentGoals: items("sustainableDevelopmentGoals"),
  donors: items("donors"),
  topics: items("topics"),
  titles: items("titles")
});
