import { get, isEmpty } from "lodash";

import { createSelector } from "reselect";

export const getUser = state => {
  return state.user ? state.user[0] : null;
};

export const getAuthenticatedUser = state => {
  const user = getUser(state);
  // `!isEmpty` because /userprofile responds (200, {}) to unauth'ed users
  if (typeof user === "object" && !isEmpty(user)) {
    return user;
  }
  return null;
};

/**
 * "Can the user manage evaluations?"
 *
 * Checks for `exercise.change_deviation` inclusion in user's permissions.
 */
export const getIsUserSupport = createSelector(
  [getAuthenticatedUser],
  user => {
    const permissions = get(user, "permissions.permission", []);
    return (
      permissions.length > 0 &&
      permissions.includes("exercise.change_deviation")
    );
  }
);
