import { combineReducers } from "redux";
import {
  fetching,
  items,
  options,
  selectable,
  submitting,
  togglable
} from "./resource";

export default combineReducers({
  selectedProduct: selectable("product"),
  isFetching:
    fetching("products") || fetching("evaluations") || fetching("dePhases"),
  phases: items("dePhases"),
  isSubmitting: submitting("products"),
  items: items("products"),
  options: options("products"),
  addModal: togglable("PRODUCT_ADD_MODAL"),
  deleteModal: togglable("PRODUCT_DELETE_MODAL")
});
