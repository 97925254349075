import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {Button } from "@wfp/ui";
import {iconEditGlyph, iconSave} from "@wfp/icons";
import SecondaryNavigation from "SecondaryNavigation";
import StatusValidationSchemaEditor from "StatusValidationSchemaEditor";
import get from "lodash/get";
import Loading from "Loading";
import PropTypes from "prop-types";
import JSONEditorModal from "./JSONEditorModal";
import statusValidationSchemasAction, { StatusValidationResource } from "../../actions/statusValidationSchemas";
import {milestonesActions} from "../../actions/milestones";
import requirementConditionsActions from "../../actions/requirementConditions";
import requirementsActions from "../../actions/requirements";


class StatusValidationSchema extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showJsonEditor: false,
      schema: props.schema,
    }
  }

  async componentDidMount() {
    await this.props.fetchMilestones();
    await this.props.fetchRequirementConditions();
    await this.props.fetchRequirements();
    await this.props.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.schema !== this.props.schema) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ schema: this.props.schema})
    }
  }

  render() {
    return (
      <div className="wfp-container">
        {this.props.loading ? <Loading withOverlay /> : null}
        <SecondaryNavigation
          title="Status Validation Schema"
          additional={
            <div style={{ flexDirection: 'row', display: 'flex'}}>
              <Button
                type="submit"
                icon={iconSave}
                iconDescription="Save schema"
                onClick={() => this.props.saveItem(this.state.schema)}
              >
                Save
              </Button>
              <Button
                type="submit"
                icon={iconEditGlyph}
                iconDescription="AValidation Schema Editor"
                onClick={() => this.setState({ showJsonEditor: true })}
              >
                Json Editor
              </Button>
            </div>
          }
        />
        <JSONEditorModal
          onClose={() => this.setState({ showJsonEditor: false })}
          onApply={(json) => this.setState({schema: json })}
          show={this.state.showJsonEditor}
          data={this.state.schema}
        />

        <StatusValidationSchemaEditor
          schema={this.state.schema}
          setSchema={(schema) => this.setState({schema})}
          requirements={this.props.requirements}
          milestoneOptions={this.props.milestoneOptions}
          conditionOptions={this.props.conditionOptions}
        />
      </div>
    );
  }
}


export const mapStateToProps = state => {
  const page = get(state, "pages.statusValidationSchemas");
  const latestSchemaEntity = get(state, `entities.statusValidationSchemas.${StatusValidationResource.LATEST_ID}`)
  const milestoneOptions = Object.values(get(state, "entities.milestones", {})).map(
    milestone => ({ value: milestone.code, label: milestone.name })
  )
  const conditionOptions = Object.values(get(state, "entities.requirementConditions", {})).map(
    item => ({ value: item.code, label: item.verboseName })
  )

  return {
    milestoneOptions,
    conditionOptions,
    requirements: get(state, "entities.requirements", {}),
    schema: latestSchemaEntity?.schema ?? [],
    loading: page.isFetching || page.isSubmitting || Boolean(state.page?.requirementConditions?.isFetching),
  };
};

export const mapDispatchToProps = dispatch => ({
  saveItem: item => dispatch(statusValidationSchemasAction.save({
    schema: item,
  })),
  fetchData: () => dispatch(statusValidationSchemasAction.fetchLast()),
  fetchMilestones: () => dispatch(milestonesActions.list({}, { paginated: false })),
  fetchRequirementConditions: () => dispatch(requirementConditionsActions.list({}, { paginated: false })),
  fetchRequirements: () => dispatch(requirementsActions.list({}, { paginated: false })),
});

StatusValidationSchema.propTypes = {
  schema: PropTypes.arrayOf(PropTypes.shape({})),
  fetchData: PropTypes.func.isRequired,
  fetchMilestones: PropTypes.func.isRequired,
  fetchRequirementConditions: PropTypes.func.isRequired,
  fetchRequirements: PropTypes.func.isRequired,
  saveItem: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  milestoneOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  conditionOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  requirements: PropTypes.shape({}).isRequired,
};

StatusValidationSchema.defaultProps = {
  schema: {},
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StatusValidationSchema)
);
