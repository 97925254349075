import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "@wfp/ui";

import Loading from "Loading";
import SecondaryNavigation from "SecondaryNavigation";
import {getCanEdit} from "../../utils";
import {toggle} from "../../actions/togglable";

import LeftCol from "./components/LeftColumn";
import Content from "./components/Content";

import styles from "./styles.scss";
import {
    useFetchEvaluationDetail,
    useGetCategory,
    useGetDetailPermission,
} from "./hooks";
import {Categories} from "../../enums";

export default () => {
    const evaluation = useFetchEvaluationDetail();
    const category = useGetCategory();

    const userPermissions = useSelector(store => store?.user?.[0]?.permissions?.permission) ?? [];
    const detailPermissions = useGetDetailPermission();

    const dispatch = useDispatch();

    // eslint-disable-next-line camelcase
    const isCancelled = evaluation?.status_code === "CANCELLED";

    const canEdit = (getCanEdit(category, userPermissions, detailPermissions) && !isCancelled) || !evaluation;

    /** URL-based tab data */
    const navItems = () => {
        const disabled = !evaluation;
        const isPHQATabDisabled = disabled || evaluation?.exercise === "Baseline";

        return [
            {url: "info", label: "Evaluation info"},
            {url: "coverage", label: "Geographical coverage", disabled, hide: category === Categories.IMPACT},
            {url: "phases", label: "Phases & Milestones", disabled},
            {url: "members", label: "Team members", disabled},
            {url: "products", label: "Products", disabled},
            {url: "phqa", label: "PHQA", disabled: isPHQATabDisabled, subUrlRegexPatter: 'phqa-[a-z]+$'},
            {url: "enhanced-activity", label: "Activity", disabled},
        ].filter(item => !item.hide);
    };
    const toggleStatus = () => dispatch(toggle("LEFTCOLUMN_STATUS"));
    return (
      <div className="wfp-container">
        <SecondaryNavigation
          title={`${!evaluation ? 'Create': 'Edit' } ${category?.toLowerCase()} evaluation`}
          subtitle={evaluation?.title}
          showTabs
          navItems={navItems()}
            >
          <div className={styles.evaluationStatusButton}>
            <Button kind="secondary" onClick={toggleStatus}>
                        Evaluation Status
            </Button>
          </div>
        </SecondaryNavigation>
        <div
          className={[
                    "flex-container flex-direction-row",
                    styles.wfpContainerFixed,
                    styles.wfpMainContainer
                ].join(" ")}
            >
          {category && (
            <LeftCol
              category={category}
              canEdit={canEdit}
              />
             )}
          {category ? (
            <Content
              canEdit={canEdit}
              />
             ) : ( 
               <Loading />
             )}
        </div>
      </div>
    )
}