import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {Categories} from "enums";
import {getEvaluationActions, listEvaluationsActions} from "../../actions/evaluations";


export const useGetDetailedEvaluation = () => {
    const {evaluationId} = useParams();

    return useSelector((store) => store.entities.evaluations?.[evaluationId]);
}

export const useFetchEvaluationDetail = () => {
    const {evaluationId} = useParams();
    const dispatch = useDispatch();
    const listEvaluation = useSelector((store) => store.entities.listEvaluations?.[evaluationId]);

    useEffect(() => {
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(evaluationId)) {
            dispatch(listEvaluationsActions.options());
            dispatch(listEvaluationsActions.detail(evaluationId));
        }
    }, [evaluationId]);

    useEffect(() => {
        if (listEvaluation) {
            dispatch(getEvaluationActions(listEvaluation.category_code).options(listEvaluation.id));
            dispatch(getEvaluationActions(listEvaluation.category_code).detail(listEvaluation.id));
            dispatch(getEvaluationActions(listEvaluation.category_code).detailOptions(listEvaluation.id));
        }
    }, [listEvaluation?.id]);

    return useGetDetailedEvaluation();
}


export const useGetCategory = () => {
    const {evaluationId} = useParams();
    const evaluation = useGetDetailedEvaluation();

    // eslint-disable-next-line no-restricted-globals
    if (!evaluation && isNaN(evaluationId)) {
        switch (evaluationId) {
            case "newCE":
                return Categories.CENTRALIZED;
            case "newDE":
                return Categories.DECENTRALIZED;
            case "newIE":
                return Categories.IMPACT;
            default:
                return null;
        }
    }

    // eslint-disable-next-line camelcase
   return evaluation?.category_code;
}

export const useGetDetailPermission = () => {
    // eslint-disable-next-line camelcase
    return useSelector(store => store?.pages?.evaluation?.detailOptions?.object_permissions) ?? [];
}