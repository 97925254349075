import { normalize } from "normalizr";

const mergeItemsEntities = (data, resource) => ({
  type: "MERGE_ITEMS_ENTITIES",
  resource,
  data
});

const merge = (data, resource) => ({
  type: "MERGE_ENTITIES",
  resource,
  data
});

const mergeNoArray = (data, resource) => ({
  type: "MERGE_ENTITIES_NOARRAY",
  resource,
  data
});

const setItems = (data, resource) => ({
  type: "SET_ITEMS",
  resource,
  data
});

const mergeNoArrayNoItems = (data, resource) => ({
  type: "MERGE_ENTITIES_NOARRAY_NOITEMS",
  resource,
  data
});

const append = (data, resource) => ({
  type: "APPEND_ITEMS",
  resource,
  data
});

const mergeItems = (data, resource) => ({
  type: "MERGE_ITEMS",
  resource,
  data
});

const markDeleted = (id, schema, resource) => {
  const data = normalize(
    {
      [schema._idAttribute]: id, // eslint-disable-line no-underscore-dangle
      deleted: true
    },
    schema
  );
  return {
    type: "MARK_DELETED",
    resource,
    data
  };
};

export {
  merge,
  mergeNoArray,
  mergeNoArrayNoItems,
  mergeItems,
  mergeItemsEntities,
  markDeleted,
  append,
  setItems,
};
