import React, {useState} from "react";
import PropTypes from "prop-types";
import ClickOutside from "react-click-outside";
import Styles from "./styles.scss";
import {useAdvancedFilter} from "./useAdvancedFilter";

const arrowActiveStyles = `${Styles.arrow} ${Styles.is_open}`;
const buttonActiveStyles = `${Styles.AdvancedFilter} ${Styles.is_open}`;
const dropdownActiveStyles = `${Styles.AdvancedFiltersDropdown} ${Styles.is_open}`;

const Arrow = ({open}) => (
  <svg
    className={`${open ? arrowActiveStyles : Styles.arrow}`}
    viewBox="0 0 50 50"
  >
    <path d="M44.3 10l5.7 5.5L25 40 0 15.5 5.7 10 25 28.9z" />
  </svg>
);

const AdvancedFiltersDropdown = ({active, options}) => {

  const onClick = (option) => {
    if (option.isVisible) {
      option.onHide();
    } else {
      option.onShow();
    }
  }

  return (
    <div className={active ? dropdownActiveStyles : Styles.AdvancedFiltersDropdown}>
      <ul>
        {options.map(option => (
          <li key={`${option.fieldName}-dropdown`}>
            <div onClick={() => onClick(option)}>
              {!option.isVisible ? (
                <div className={Styles.addFilterIcon}>+</div>
              ) : (
                <span className={Styles.removeFilterIcon} />
              )}
              <span>{option.label}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

const AdvancedFilterSelector = () => {
  const [active, setActive] = useState(false);
  const {advancedFilters} = useAdvancedFilter();

  return (
    <ClickOutside onClickOutside={() => setActive(false)}>
      <div
        className={active ? buttonActiveStyles : Styles.AdvancedFilter}
        onClick={() => setActive(prevState => !prevState)}
      >
        More Filters <Arrow open={active} />
        <AdvancedFiltersDropdown active={active} options={advancedFilters} />
      </div>
    </ClickOutside>
  );
};

AdvancedFiltersDropdown.propTypes = {
  active: PropTypes.bool.isRequired, // Whether the dropdown is active
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      fieldName: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
      isVisible: PropTypes.bool.isRequired,
      isDisabled: PropTypes.bool.isRequired,
      onHide: PropTypes.func.isRequired,
      onShow: PropTypes.func.isRequired,
    })
  ).isRequired,
};
AdvancedFiltersDropdown.defaultProps = {};

Arrow.propTypes = {
  open: PropTypes.bool
};

Arrow.defaultProps = {
  open: false
};

export default AdvancedFilterSelector;
