import React from "react";
import moment from "moment";


export class DatePicker extends React.Component {
    onYearSelect = null;

    /** Get current month of DayPicker from HTML element. */
    currentViewMonth = changeYearButtonElement => {
        const picker = changeYearButtonElement.closest('.DayPicker')
        const firstMonth = picker.querySelector('[data-date]');
        return moment(parseInt(firstMonth.dataset.date, 10));
    }

    onNavigateYear = (event, delta) => {
        // react-dates places our year navigation button (@navNext/@navPrev) inside
        // an invisible month navigation button. It uses nice transitioned month
        // navigation that combined with year navigation causes inconsistency issues
        // (e.g. disabled days do not work). It must be disabled.
        event.stopPropagation();

        const currentMonth = this.currentViewMonth(event.target);
        currentMonth.subtract(
            // react-dates' onYearSelect() is implemented as follows.
            //
            // src/components/CalendarMonthGrid.jsx:
            //
            // ```
            // onYearSelect(currentMonth, newYearVal) {
            //   const newMonth = currentMonth.clone();
            //   ...
            //   const { months } = this.state;
            //   const withoutTransitionMonths = orientation === VERTICAL_SCROLLABLE;
            //   let initialMonthSubtraction = months.indexOf(currentMonth);
            //   if (!withoutTransitionMonths) {
            //     initialMonthSubtraction -= 1;
            //   }
            //   newMonth.set('year', newYearVal).subtract(initialMonthSubtraction, 'months');
            //   onYearChange(newMonth);
            // }
            // ```
            //
            // Before all, onYearSelect() is not intended to be used outside
            // @renderMonthElement -- where we have taken its reference from. It is
            // probably the result of bad engineering because there are very good
            // usecases that would require access to onYearSelect() externally.
            //
            // As it can be seen in the code snippet above, currentMonth is
            // expected to be an internal date object -- that would be otherwise
            // passed to us by @renderMonthElement({ month }) under normal
            // circumstances. However, because we cannot easily decide (impossible)
            // inside renderMonthElement() which months is being currently rendered
            // we cannot determine the first visible month in the calendar.
            //
            // To overcome this limitation, date value of all rendered month is
            // seralized into @data-date. Then later on this.currentViewMonth()
            // picks the first @data-date that will give us the first visible
            // month.
            //
            // It was not too hard but this roundabout means that we have lost the
            // original reference to the date object because we have created a
            // brand new moment() inside this.currentViewMonth(). It
            // will not match any of stored this.state.months, of course, meaning
            // that:
            //
            //   let initialMonthSubtraction = months.indexOf(currentMonth);
            //
            // will always return -1:
            //
            //   let initialMonthSubtraction = -1;
            //
            // Moving on, we are always horizontal so withoutTransitionMonths is
            // always false.
            //
            //   if (!withoutTransitionMonths) {
            //     initialMonthSubtraction -= 1;
            //   }
            //
            // Resulting:
            //
            //   initialMonthSubtraction = -2;
            //
            // This means that -2 months will be subtracted from our carefully crafted date:
            //
            //   newMonth.set('year', newYearVal).subtract(initialMonthSubtraction, 'months');
            //
            // To counterweight this, we subtract +2 months.
            2,
            'month',
        );

        this.onYearSelect(currentMonth, currentMonth.add(delta, 'years').year());
    }

    renderMonthElement = ({month, onYearSelect, isVisible}) => {
        this.onYearSelect = onYearSelect;
        return (
          <strong data-date={isVisible ? month.valueOf() : null}>
            {month.format('MMMM YYYY')}
          </strong>
        );
    }
}
