import React from "react";
import PropTypes, {any} from "prop-types";
import get from "lodash/get";
import styled from "styled-components";

import { Checkbox as WFPCheckbox } from "@wfp/ui";

// Fix checkmark alignment
const StyledWrapper = styled.div`
  .wfp--checkbox-label::after {
    left: 0.25rem;
    margin-top: -0.25rem;
  }
`;

const Checkbox = ({ input, isDisabled, label }) => (
  <StyledWrapper>
    <WFPCheckbox
      id={`checkbox-${label}`}
      disabled={isDisabled}
      labelText={label}
      onChange={input.onChange}
      checked={get(input, "value") === true}
    />
  </StyledWrapper>
);

Checkbox.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: any,
  }),
  onChangeHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
  label: PropTypes.string
};

Checkbox.defaultProps = {
  onChangeHandler: () => null,
  input: undefined,
  isDisabled: false,
  label: "I am the label"
};

export default Checkbox;
