import React from "react";
import PropTypes from "prop-types";
import StatusRequirements, {statusRequirementDefaultProps, statusRequirementPropTypes} from "./index";


const MilestoneStatusRequirements = props => {
  const getRowIndices = (categoryCode, statusCode) => {
    const categoryIndex = props.schema.findIndex(item => item.categoryCode === categoryCode);
    const statusIndex = props.schema[categoryIndex].statuses.findIndex(item => item.statusCode === statusCode);
    return [categoryIndex, statusIndex]
  }

  const handleFieldChange = (data, categoryCode, statusCode, index) => {
      const [categoryIndex, statusIndex] = getRowIndices(categoryCode, statusCode);

    // eslint-disable-next-line no-param-reassign
      props.schema[categoryIndex].statuses[statusIndex].requirements[index] = {
        ...props.schema[categoryIndex].statuses[statusIndex].requirements[index],
        'requirementName': data.label,
        'args': [data.value],
      }

      props.setSchema(props.schema)
  }

  const dynamicMilestonesOptions = (categoryCode, statusCode) => {
    const [categoryIndex] = getRowIndices(categoryCode, statusCode);

    // Collect already selected milestones
    const usedMilestoneCodes = props.schema[categoryIndex].statuses.map(status => {
      return status.requirements.reduce((milestonesCodes, requirement) => {
        if (requirement.requirementClass !== 'Milestone' || !requirement.args[0]) {
          return milestonesCodes;
        }

        milestonesCodes.push(requirement.args[0]);
        return milestonesCodes;
      }, [])
    }).flat(1)

    return props.milestoneOptions.filter(milestone => !usedMilestoneCodes.includes(milestone.value));
  }

  return (
    <StatusRequirements
      {...props}
      enableDelete
      fieldExtraProps={{
          readOnly: Boolean(props.requirement.requirementName),
          dynamicOptions: () => dynamicMilestonesOptions(props.categoryCode, props.statusCode, props.index),
          onChange: (item) => handleFieldChange(item, props.categoryCode, props.statusCode, props.index),
        }}
      />
  )
}


MilestoneStatusRequirements.propTypes = {
  ...statusRequirementPropTypes,
  index: PropTypes.number.isRequired,
  categoryCode: PropTypes.string.isRequired,
  statusCode: PropTypes.string.isRequired,
  setSchema: PropTypes.func.isRequired,
  milestoneOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

MilestoneStatusRequirements.defaultProps = {
  ...statusRequirementDefaultProps,
};

export default MilestoneStatusRequirements;
