import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { fieldInputPropTypes } from "redux-form";

import Tooltip from "Tooltip";
import { validationStyles, reactSelectStyles } from "Select";

import Styles from "./styles.scss";

export const doubleValuesStyles = props => ({
  ...reactSelectStyles(props),
  control: base => ({
    ...base,
    borderColor: props.readOnly ? "#eff2f5 !important" : "#8ca4b5",
    cursor: props.disabled ? "not-allowed !important" : "text !important",
    minHeight: "57px !important",
    opacity: props.readOnly ? "1 !important" : "1",
    pointerEvents: "auto"
  }),
  valueContainer: base => ({
    ...base,
    overflow: "visible"
  }),
  input: base => ({
    ...base,
    fontSize: "0.875rem"
  }),
  indicatorSeparator: base => ({
    ...base,
    display: "none"
  }),
  dropdownIndicator: base => ({
    ...base,
    display: "none"
  }),
  clearIndicator: base => ({
    ...base,
    cursor: "default"
  })
});

const SelectAsync = props => {
  // Only show placeholder text if the field can be interacted with
  let placeholder = "";
  if (!props.readOnly && !props.disabled && props.placeholder) {
    placeholder = props.placeholder;
  }
  return (
    <div className={`wfp--form-item ${props.cssClass}`}>
      {!props.helpText && (
        <label
          htmlFor={props.inputId}
          className="wfp--label"
          style={props.disabled ? { opacity: 0.5 } : {}}
        >
          {props.label}
        </label>
      )}
      {props.helpText && (
        <Tooltip
          message={props.helpText}
          label={props.label}
          formIndex={props.input.name}
        />
      )}
      <AsyncSelect
        className={`wfp--react-select-container ${Styles.select} ${props.input.value ? Styles.shiftCursor : ``}`}
        classNamePrefix="wfp--react-select"
        closeMenuOnSelect={props.closeMenuOnSelect}
        hideLabel
        inputId={props.inputId}
        invalid={props.invalid}
        invalidText={props.invalidText}
        isClearable={props.isClearable}
        isDisabled={props.readOnly || props.disabled} // Underlying component has only disabled state
        isLoading={props.isLoading}
        isMulti={props.allowMultiple}
        isOptionDisabled={props.isOptionDisabled}
        isReadOnly={props.readOnly}
        loadOptions={props.loadOptions}
        onChange={event => {
          props.input.onChange(event);
          props.onChangeExtra(event);
        }}
        onFocus={props.input.onFocus}
        onInputChange={props.onInputChange}
        openMenuOnClick={props.openMenuOnClick}
        openMenuOnFocus={props.openMenuOnFocus}
        options={props.options}
        noOptionsMessage={() =>
          props.noOptionsMessage ||
          (props.isLoading ? "Loading..." : "No results")
        }
        placeholder={placeholder}
        styles={doubleValuesStyles(props)}
        value={props.input.value}
      />
      {props.invalid && (
        <div className="wfp--form-requirement" style={validationStyles}>
          {props.invalidText}
        </div>
      )}
    </div>
  );
};

SelectAsync.propTypes = {
  allowMultiple: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  cssClass: PropTypes.string,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  inlineStyle: PropTypes.shape({}),
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  inputId: PropTypes.string,
  invalid: PropTypes.bool,
  invalidText: PropTypes.string,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  label: PropTypes.string,
  loadOptions: PropTypes.func.isRequired,
  noOptionsMessage: PropTypes.string,
  onChangeExtra: PropTypes.func, // Extra callback after onChange
  onInputChange: PropTypes.func,
  openMenuOnClick: PropTypes.bool,
  openMenuOnFocus: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  smallMenuList: PropTypes.bool,
};

SelectAsync.defaultProps = {
  allowMultiple: false,
  closeMenuOnSelect: true,
  isClearable: true,
  cssClass: undefined,
  disabled: false,
  readOnly: false,
  helpText: "",
  inlineStyle: undefined,
  inputId: undefined,
  invalid: false,
  invalidText: "A valid value is required",
  isLoading: false,
  isOptionDisabled: undefined,
  label: "",
  noOptionsMessage: undefined,
  onChangeExtra: () => null,
  onInputChange: undefined,
  openMenuOnClick: false,
  openMenuOnFocus: false,
  placeholder: "",
  smallMenuList: false,
  options: [],
};

export default SelectAsync;
