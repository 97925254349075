import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button, Icon } from "@wfp/ui";
import { iconCaretRight } from "@wfp/icons";

import { getIsUserSupport } from "../../../../../selectors/user";

import Number from "./Number";

import styles from "../styles.scss";

export const TitleComponent = props => {
  const currentStatusStyles = props.isCurrentStatus
    ? styles.isCurrentStatus
    : "";
  const expandedStatusStyles = props.isStatusExpanded ? styles.isExpanded : "";

  let liClassName = `${styles.title || ""} ${styles.active || ""}`;

  if (!props.hasErrors && !props.isStatusReached) {
    liClassName = `${styles.title || ""} ${
      props.isCurrentStatus ? styles.active : ""
    }`;
  }

  if (props.hasErrors && !props.isStatusReached) {
    liClassName = `${styles.title || ""}`;
  }

  /**
   * Never show apply for current status.
   *
   * For previous statuses, only show Apply if user is Support.
   */
  const showApplyButton =
    props.onApply !== undefined &&
    !props.isCurrentStatus &&
    (!props.isStatusReached || props.isUserSupport);

  /** Only show restore for current status if user is Support. */
  const showRestoreButton =
    props.isUserSupport && props.onRestore !== undefined;

  return (
    <li
      className={liClassName}
      onClick={() =>
        props.onToggleRequirements && props.onToggleRequirements(props.code)
      }
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
    >
      {// onToggleRequirements undefined -> don't show caret for toggling requirements
      props.onToggleRequirements !== undefined &&
        !props.isCurrentWorkingStatus && (
          <Icon
            className={`${styles.caretIcon} ${expandedStatusStyles}`}
            description="Expand/Collapse Status"
            icon={iconCaretRight}
          />
        )}
      <Number
        number={props.number}
        active={props.isStatusReached}
        isCurrentStatus={props.isCurrentStatus}
      />
      <span
        className={
          props.isStatusReached
            ? `${styles.statusName} ${currentStatusStyles}`
            : ""
        }
      >
        {props.name}
      </span>
      {showApplyButton && (
        <Button
          type="button"
          kind={props.isStatusReached ? "secondary" : "primary"}
          disabled={props.hasErrors}
          onClick={e => e.stopPropagation() || props.onApply(props.code)}
          small
        >
          Apply
        </Button>
      )}
      {showRestoreButton && (
        <Button
          type="button"
          kind="ghost"
          onClick={e => e.stopPropagation() || props.onRestore(props.code)}
          small
        >
          Restore
        </Button>
      )}
    </li>
  );
};

TitleComponent.propTypes = {
  code: PropTypes.string,
  isUserSupport: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  number: PropTypes.number,
  isCurrentStatus: PropTypes.bool,
  isCurrentWorkingStatus: PropTypes.bool,
  /** Whether the status is expanded */
  isStatusExpanded: PropTypes.bool,
  /** Expand/collapse status requirements */
  onToggleRequirements: PropTypes.func,
  onApply: PropTypes.func,
  onRestore: PropTypes.func,
  hasErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isStatusReached: PropTypes.bool
};

TitleComponent.defaultProps = {
  code: "",
  isCurrentStatus: false,
  isCurrentWorkingStatus: false,
  hasErrors: true,
  isStatusReached: false,
  isStatusExpanded: false,
  number: undefined,
  onApply: undefined,
  onRestore: undefined,
  onToggleRequirements: undefined
};

export const mapStateToProps = state => ({
  isUserSupport: getIsUserSupport(state)
});

export default connect(mapStateToProps)(TitleComponent);
