import { combineReducers } from "redux";
import { statuses, fetching, items, togglable } from "./resource";

/** `state` shape: { <status_code:str>: <is_expanded:bool>, ... } */
const expandedStatuses = (state = {}, action) => {
  if (action.type === "SET_EXPANDED_STATUSES") {
    return action.expandedStatuses;
  }

  if (action.type === "TOGGLE_STATUS_EXPANSION") {
    return {
      ...state,
      [action.statusCode]: !state[action.statusCode] // May evaluate to `!undefined` a.k.a. `true`
    };
  }

  return state;
};

export default combineReducers({
  isFetchingStatuses: fetching("decentralizedStatuses"),
  expandedStatuses,
  fetchingValidation: fetching("validation"),
  statuses,
  status: togglable("LEFTCOLUMN_STATUS"),
  section: togglable("SECTION_DROPDOWN"),
  editableFields: togglable("EDITABLE_FIELDS"),
  deviations: items("deviations")
});
