export class OptionTransformer {

    constructor(options) {
        this.options = options ? this.transformToArray(options) : [];
    }

    transform = () => {
        if (this.options.length) {
            this
              .removeDeprecations()
              .mapping()
              .sortingByPosition();
        }

        return this.options;
    }

    transformToArray = (options) => {
        return Object.values(options);
    }

    removeDeprecations = () => {
        if ('is_deprecated' in this.options[0]) {
            this.options = this.options.filter(option => !option.is_deprecated)
        }

        if ('isDeprecated' in this.options[0]) {
            this.options = this.options.filter(option => !option.isDeprecated)
        }

        return this;
    }

    sortingByPosition = () => {
        if ('position' in this.options[0]) {
            this.options = this.options.sort((a, b) => a.position - b.position);
        }

        if ('order_by' in this.options[0]) {
            this.options = this.options.sort((a, b) => a.order_by - b.order_by);
        }

        return this;
    }

    mapping = () => {
        this.options = this.options.map(option => {
            const value = {
                value: option.id,
                label: option.name,
            }

            if ('position' in option) {
                value.position = option.position
            }

            return value
        })

        return this;
    }

    customFilter = (customFilterFunction) => {
        this.options = this.options.filter(customFilterFunction)

        return this;
    }
}