import { schema, normalize } from "normalizr";
import {Categories} from "enums";
import { client } from "../store";
import { merge } from "./entities";

import Resource from "./resource";
import { setPage } from "./pagination";
import { toggle } from "./togglable";
import { evaluationTypes, listEvaluationTypes } from "../types";
import {customToastSuccess} from "../utils";

// Export various actions used in the page
export const setEvaluationPage = page => setPage(page, "evaluations");
// TODO: do we really need to store it in global stete?
export const toggleAdvancedFilters = () => toggle("SHOW_ADVANCED_FILTERS");

const requirementsSchema = new schema.Entity(
  "requirements",
  {},
  { idAttribute: "slug" }
);
const evaluationSchema = new schema.Entity("evaluations");
const listEvaluationSchema = new schema.Entity("listEvaluations");

// Extend the class to add some custom actions
class EvaluationActions extends Resource {
  // Fetch validation for this evaluation
  validation = (evaluationId, notifyFetching = false) => dispatch =>
    dispatch({
      // Since the fetching reducer expects an action ending with '_LIST_PENDING'
      // to notify that the resource is being fetched, just using 'VALIDATION'
      // as action type will avoid notifying the fetchstatus
      type: notifyFetching ? "VALIDATION_LIST" : "VALIDATION",
      payload: client
        .get(`${this.path}/${evaluationId}/validation/`)
        .then(response =>
          dispatch({ type: "SET_VALIDATION", data: response.data })
        )
        .catch(error => {
          this.handleError(error, "Error retrieving validation data");
        })
    });

  saveTotalScore = (evaluationId, totalScore) => dispatch =>
    dispatch({
      type: "SAVE_TOTAL_SCORE",
      payload: client.patch(`${this.path}/${evaluationId}/update_standard_assessment_score/`, { totalScore: totalScore || null })
        .then((response) => {
            dispatch({ type: "SET_PHQA_STANDARD_TOTAL_SCORE", data: {
                // eslint-disable-next-line camelcase
                totalScore: response.data?.total_score,
                // eslint-disable-next-line camelcase
                totalRating: response.data?.total_rating,
            }});
            dispatch(this.validation(evaluationId));
            dispatch(this.detail(evaluationId));
        })
        .catch(error => {
          this.handleError(error);
        })
    });

  standardAssessmentScoreAndRating = evaluationId => dispatch =>
    dispatch({
      type: "RETRIEVE_STANDARD_ASSESSMENT_SCORE_AND_RATING",
      payload: client
        .get(
          `${this.path}/${evaluationId}/standard_assessment_score_and_rating/`
        )
        .then(response => {
            dispatch({ type: "SET_PHQA_STANDARD_TOTAL_SCORE", data: {
                totalScore: response.data?.score,
                totalRating: response.data?.rating,
            }})
        })
        .catch(error => {
          this.handleError(error);
        })
    });

    fetchScoreAndRating = evaluationId => dispatch =>
        dispatch({
          type: "RETRIEVE_UNDIS_ASSESSMENT_SCORE_AND_RATING",
          payload: client
            .get(
              `${this.path}/${evaluationId}/undis_assessment_score_and_rating/`
            )
            .then(response => {
                dispatch({ type: "SET_UNDIS_TOTAL_SCORE", data: {
                    totalScore: response.data?.score,
                    totalRating: response.data?.rating,
                }})
            })
            .catch(error => {
              this.handleError(error);
            })
        });

  cancel = (evaluationId, params) => dispatch =>
    dispatch({
      type: "CANCEL",
      payload: client
        .post(`${this.path}/${evaluationId}/status_cancel/`, params)
        .then(() => {
            customToastSuccess(`Evaluation cancelled successfully`);
            dispatch(this.detail(evaluationId));
        })
        .catch(error => {
          this.handleError(error);
        })
    });

  /**
   * restore from Cancelled status
   *
   */
  statusRestore = evaluationId => dispatch =>
    dispatch({
      type: "STATUS_RESTORE",
      payload: client
        .post(`${this.path}/${evaluationId}/status_restore/`)
        .then(response => {
          const data = normalize(
            [
              {
                id: evaluationId,
                status_code: response.data.status_code,
                status_code_prev: response.data.status_code_prev,
                reason_for_cancellation: null,
              }
            ],
            [evaluationSchema]
          );
          dispatch(merge(data, "statusRestore"));
          return response;
        })
        .catch(error => {
          this.handleError(error);
        })
    });

  statusAdvance = (evaluationId, params) => dispatch =>
    dispatch({
      type: "STATUS_ADVANCE",
      payload: client
        .post(`${this.path}/${evaluationId}/status_advance/`, params)
        .then(response => {
          const data = normalize(
            [{ id: evaluationId, status_code: response.data.status_code }],
            [evaluationSchema]
          );
          dispatch(merge(data, "status_code"));
          return response;
        })
        .catch(error => {
          this.handleError(error);
        })
    });

  statusRollback = (evaluationId, params) => dispatch =>
    dispatch({
      type: "STATUS_ROLLBACK",
      payload: client
        .post(`${this.path}/${evaluationId}/status_rollback/`, params)
        .then(response => {
          const data = normalize(
            [{ id: evaluationId, status_code: response.data.status_code }],
            [evaluationSchema]
          );
          dispatch(merge(data, "status_code"));
          return response;
        })
        .catch(error => {
          this.handleError(error);
        })
    });

  setEpiFramework = (evaluationId, params) => dispatch =>
    dispatch({
      type: "SET_EPI_FRAMEWORK",
      payload: client
        .post(`${this.path}/${evaluationId}/set_epi_framework/`, params)
        .then(response => {
          const data = normalize(
            [{ id: evaluationId, epi_framework: response.data.id }],
            [evaluationSchema]
          );
          dispatch(merge(data, "epi_framework"));
          return response;
        })
        .catch(error => {
          this.handleError(error);
        })
    });

  setStandardFramework = (evaluationId, params) => dispatch =>
    dispatch({
      type: "SET_STANDARD_FRAMEWORK",
      payload: client
        .post(`${this.path}/${evaluationId}/set_standard_framework/`, params)
        .then(response => {
          const data = normalize(
            [{ id: evaluationId, standard_framework: response.data.id }],
            [evaluationSchema]
          );
          dispatch(merge(data, "standard_framework"));
          return response;
        })
        .catch(error => {
          this.handleError(error);
        })
    });

    setUndisFramework = (evaluationId, params) => dispatch =>
    dispatch({
      type: "SET_UNDIS_FRAMEWORK",
      payload: client
        .post(`${this.path}/${evaluationId}/set_undis_framework/`, params)
        .then(response => {
          const data = normalize(
            [{ id: evaluationId, undis_framework: response.data.id }],
            [evaluationSchema]
          );
          dispatch(merge(data, "undis_framework"));
          return response;
        })
        .catch(error => {
          this.handleError(error);
        })
    });

  filtersData = () => dispatch =>
    dispatch({
      type: "FETCH_FILTERS_DATA",
      payload: client
        .get(`${this.path}/filters_data/`)
        .then(response => {
          dispatch({
            resource: "filtersData",
            type: "MERGE_ITEMS_NOARRAY",
            data: { result: response.data }
          });
          return response;
        })
        .catch(error => {
          this.handleError(error);
        })
    });

  // Fetch validation for this evaluation
  resetValidation = () => dispatch =>
    dispatch({ type: "SET_VALIDATION", data: {} });

  // Evaluation Requirements
  evaluationRequirements = (id) => dispatch =>
    dispatch({
      type: "FETCH_REQUIREMENTS",
      payload: client
        .get(`${this.path}/${id}/requirement_definitions/`)
        .then(response => {
          const adaptedData = Object.entries(response.data).map(r => ({
            slug: r[0],
            label: r[1].verbose_name,
            requirementClass: r[1].requirement_class,
          }));
          const data = normalize(adaptedData, [requirementsSchema]);
          dispatch(merge(data, "requirements"));
          return response;
        })
        .catch(error => {
          this.handleError(error, "Error retrieving requirements data");
        })
    });

  // Requirements
  requirements = () => dispatch =>
    dispatch({
      type: "FETCH_REQUIREMENTS",
      payload: client
        .get("/evaluationrequirements/")
        .then(response => {
          const adaptedData = Object.entries(response.data).map(r => ({
            slug: r[0],
            label: r[1].verbose_name,
            requirementClass: r[1].requirement_class,
          }));
          const data = normalize(adaptedData, [requirementsSchema]);
          dispatch(merge(data, "requirements"));
          return response;
        })
        .catch(error => {
          this.handleError(error, "Error retrieving requirements data");
        })
    });

  exerciseOptions = () => dispatch =>
    dispatch({
      type: "FETCH_EXERCISE_OPTIONS",
      payload: client
        .get(`${this.path}/exercise_options/`)
        .then(response => {
          dispatch({
            resource: "exerciseOptions",
            type: "MERGE_ITEMS_NOARRAY",
            data: { result: response.data }
          });
          return response;
        })
        .catch(error => {
          this.handleError(error);
        })
    });
}

export const getEvaluationActions = category => {
  // if category is not specified `/evaluations` will be used
  let url = "/evaluations";
  if (category === Categories.CENTRALIZED) {
    url = "/centralized_evaluations";
  } else if (category === Categories.DECENTRALIZED) {
    url = "/decentralized_evaluations";
  } else if (category === Categories.IMPACT) {
    url = "/impact_evaluations";
  }
  return new EvaluationActions(url, evaluationTypes, evaluationSchema);
};

// Since resources returned by the /evaluations endpoint and /decentralized_evaluations
// are serialized differently, I won't be adding resources retrieved through /evaluations
// to avoid having inconsistent data in the store
class ListEvaluationResource extends Resource {
  listInBackground = (parameters, options) => dispatch => {
    dispatch(this.list(parameters, options))
    dispatch({ type: 'LISTEVALUATIONS_HIDE_FETCHING' })
  }
}
export const listEvaluationsActions = new ListEvaluationResource(
  "/evaluations",
  listEvaluationTypes,
  listEvaluationSchema
);


client.enableAutoCamelCase(/\/evaluations\/\d+\/update_standard_assessment_score\/$/);
