import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import { Grid, Row, Col } from "Grid";
import Checkbox from "Checkbox";
import SingleDatePicker from "DatePicker/SingleDatePicker";
import HiddenField from "HiddenField";
import ModalForm from "ModalForm";
import Select from "Select";
import TextInput from "TextInput";

import destinations from "./destinations";

const ProductAddModal = props => (
  <ModalForm
    title={props.title}
    isVisible={props.show}
    onClose={props.toggleModal}
    onSubmit={props.handleSubmit(props.onSubmit)}
    onFormSubmit={props.handleSubmit(props.onSubmit)}
  >
    <Grid fluid>
      <Row>
        <Col xs>
          <Field
            name="kind"
            label="Product Type"
            options={props.types}
            component={Select}
            openMenuOnClick
          />
        </Col>
      </Row>
      <Field name="kind" component={HiddenField} />
      <Row>
        <Col xs>
          <Field
            name="release_date"
            label="Product Upload Date"
            component={SingleDatePicker}
            setDatesRange={props.datesRange}
            inModal
          />
        </Col>
      </Row>
      <Row>
        <Col xs>
          <Field name="url" label="Product URL" component={TextInput} inModal />
        </Col>
      </Row>
      <Row>
        <Col xs>
          <fieldset className="wfp--fieldset">
            <legend className="wfp--label">Destinations</legend>
            <Grid fluid>
              {Object.entries(destinations).map(dest => (
                <Row key={dest[0]}>
                  <Col xs>
                    <div key={dest[0]}>
                      <Field
                        name={`${dest[0]}`}
                        label={dest[1]}
                        id={`${dest[0]}-field`}
                        component={Checkbox}
                      />
                    </div>
                  </Col>
                </Row>
              ))}
            </Grid>
          </fieldset>
        </Col>
      </Row>
    </Grid>
  </ModalForm>
);

ProductAddModal.propTypes = {
  title: PropTypes.string.isRequired,
  datesRange: PropTypes.func.isRequired,
  show: PropTypes.bool,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      is_release_date_mandatory: PropTypes.bool,
      ordering: PropTypes.number
    })
  ).isRequired,
  toggleModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

ProductAddModal.defaultProps = {
  show: false
};

export default reduxForm({
  form: "evProduct",
  enableReinitialize: true
})(ProductAddModal);
