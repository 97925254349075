import React from "react";
import PropTypes from "prop-types";


export const MemberTotalCost = (props) => {
  const total = props.members.reduce((accumulator, member) => {
      if (!member.total) {
         return accumulator;
      }

      return accumulator + parseFloat(member.total);
    }, 0);

    return <h3>Team members total fees: US$ {total.toLocaleString()}</h3>
}

MemberTotalCost.propTypes = {
    members: PropTypes.arrayOf(PropTypes.shape({
        total: PropTypes.string
    })).isRequired
}