import { schema } from "normalizr";
import {WindowsTypes} from "../types";
import Resource from "./resource";
import {client} from "../store";

export const WindowsSchema = new schema.Entity("Windows");

export default new Resource(
  "/windows",
  WindowsTypes,
  WindowsSchema
);

client.enableAutoCamelCase("/windows");
