import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import { Grid, Row, Col } from "Grid";
import Checkbox from "Checkbox";
import SingleDatePicker from "DatePicker/SingleDatePicker";
import Select from "Select";
import TextInput from "TextInput";

import Styles from "./styles.scss";

const FirmForm = props => (
  <>
    <Grid fluid>
      <Row>
        <Col xs>
          <Field
            name="name"
            label="Name"
            component={TextInput}
            invalid={Boolean(props.fieldErrors.name)}
            invalidText={props.fieldErrors.name}
          />
        </Col>
      </Row>
      <Row middle="xs">
        <Col xs={2}>
          <Field name="is_lta" label="LTA" component={Checkbox} />
        </Col>
        <Col xs={10}>
          <Field name="lta_number" label="LTA Number" component={TextInput} />
        </Col>
      </Row>
      <Row>
        <Col xs>
          <Field
            name="services"
            label="Services"
            component={Select}
            invalid={Boolean(props.fieldErrors.services)}
            invalidText={props.fieldErrors.services}
            options={props.services}
            allowMultiple
            closeMenuOnSelect={false}
            openMenuOnClick
          />
        </Col>
      </Row>
      <Row>
        <Col xs>
          <Field
            name="signature_date"
            label="Signature Date"
            index="signature_date"
            component={SingleDatePicker}
            inModal={props.inModal}
            cssClass={Styles.inlineFields}
          />
        </Col>
        <Col xs>
          <Field
            name="expiry_date"
            label="Expiry Date"
            index="expiry_date"
            component={SingleDatePicker}
            inModal={props.inModal}
            cssClass={Styles.inlineFields}
          />
        </Col>
      </Row>
      <Row>
        <Col xs>
          <Field name="location" label="Location" component={TextInput} />
        </Col>
      </Row>
      {/* eslint-disable-next-line camelcase */}
      {props.initialValues?.is_deprecated &&
        <Row>
          <Col xs>
            This company is <span style={{ fontWeight: 'bold' }}>inactive</span>!
          </Col>
        </Row>
      }
    </Grid>
  </>
);

FirmForm.propTypes = {
  fieldErrors: PropTypes.shape({
    name: PropTypes.array,
    services: PropTypes.array
  }).isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired,
  inModal: PropTypes.bool,
  initialValues: PropTypes.shape({}),
};

FirmForm.defaultProps = {
  inModal: false,
  initialValues: {},
};

export default reduxForm({
  form: "companies",
  enableReinitialize: true
})(FirmForm);
