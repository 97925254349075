import React from "react";
import {Modal} from "@wfp/ui";
import PropTypes from "prop-types";
import JSONInput from 'react-json-editor-ajrm/index';
import locale    from 'react-json-editor-ajrm/locale/en';

class JSONEditorModal extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      modifiedJson: null,
    }
  }
  
  render() {
    return (  
      <Modal
        open={this.props.show}
        modalHeading={this.props.title}
        onRequestSubmit={() => {
          this.props.onApply(this.state.modifiedJson);
          this.props.onClose();
        }}
        onRequestClose={this.props.onClose}
        primaryButtonText="Apply"
        primaryButtonDisabled={!this.state.modifiedJson}
        secondaryButtonText="Close"
        onSecondarySubmit={this.props.onClose}
      >
        <p className="wfp--modal-content__text">Status Validation Schema</p>
        {this.props.show && <JSONInput
          placeholder={this.props.data} // data to display
          theme="light_mitsuketa_tribute"
          colors={{
            string: "#DAA520", // overrides theme colors with whatever color value you want
            keys: "#0a6eb4"
          }}
          locale={locale}
          height="550px"
          width="700px"
          onChange={(json) => {this.setState({ modifiedJson: json.error ? null : json.jsObject })}}
        />
        }
      </Modal>
    )
  }
  
}

JSONEditorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string,
};

JSONEditorModal.defaultProps = {
  title: null,
};

export default JSONEditorModal;
