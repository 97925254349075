import React from 'react';
import PropTypes from "prop-types";
import {dateFormatter} from "utils";

const PredictedMilestoneMessageBox = (phaseMilestone, milestone, shiftedMilestone = {}) => {
    const differences = [];
    const { eventDate: shiftedEventDate, datesRange: { startDate: shiftedStartDate, endDate: shiftedEndDate } = {}} = shiftedMilestone ?? {};
    const { eventDate, datesRange: { startDate, endDate } = {} } = phaseMilestone ?? {};

    if (shiftedEventDate && !dateFormatter(eventDate).isSame(shiftedEventDate)) {
        differences.push(
          <p>Event date will be changed from
            <strong> {dateFormatter(eventDate).toString()}</strong> to
            <strong> {dateFormatter(shiftedEventDate).toString()}</strong>
          </p>
        )
    }

    if (shiftedStartDate && !dateFormatter(startDate).isSame(shiftedStartDate)) {
        differences.push(
          <p>Start date will be changed from
            <strong> {dateFormatter(startDate).toString() ?? '<empty>'}</strong> to
            <strong> {dateFormatter(shiftedStartDate).toString()}</strong>
          </p>
        )
    }

    if (shiftedEndDate && !dateFormatter(endDate).isSame(shiftedEndDate)) {
        differences.push(
          <p>End date will be changed from
            <strong> {dateFormatter(endDate).toString() ?? '<empty>'}</strong> to
            <strong> {dateFormatter(shiftedEndDate).toString()}</strong>
          </p>
        )
    }


    if (!differences.length) {
        return null;
    }

    return (
      <p>
        <strong>Milestone:</strong> {milestone?.name}
        <ul style={{
            listStyleType: 'square',
            paddingLeft: '2.2rem',
        }}>
          {differences.map((m, i) => <li key={`phaseMilestone-messagebox-${i}`}>{m}</li>)}
        </ul>
      </p>
    )
}

PredictedMilestoneMessageBox.propTypes = {
    milestone: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
    phaseMilestone: PropTypes.shape({
        eventDate: PropTypes.string,
        datesRange: PropTypes.shape({
            startDate: PropTypes.shape({}),
            endDate: PropTypes.shape({}),
        })
    }).isRequired,
    shiftedMilestone: PropTypes.shape({
        eventDate: PropTypes.string,
        datesRange: PropTypes.shape({
            startDate: PropTypes.shape({}),
            endDate: PropTypes.shape({}),
        })
    }).isRequired,

};

PredictedMilestoneMessageBox.defaultProps = {
};

export default PredictedMilestoneMessageBox
