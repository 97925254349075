import React from "react";

import { toast } from "react-toastify";
import lodashMerge from "lodash/merge";
import { normalize } from "normalizr";
import {FeatureFlags} from "enums";
import {
  merge,
  mergeNoArray,
  mergeNoArrayNoItems,
  mergeItems,
  append,
  markDeleted,
  mergeItemsEntities, setItems
} from "./entities";
import { client, store } from "../store";
import { customToastError } from "../utils";
import defaultErrorsMap from "../utils/defaultErrorsMap";
import { COLOR_SUCCESS_TOAST } from "../utils/constants";
import ErrorToastBody from "../components/ErrorToastBody";
import { addFieldErrors } from "./fieldErrors";

class Resource {
  constructor(path, types, schema, notifySuccess = true, notifyFailure = true) {
    this.types = types; // should be an instance of resourceTypes defined in ./types.js
    this.path = path;
    this.schema = schema;
    this.defaultOptions = {
      altPath: undefined,
      errorsMap: undefined,
      mergeItems: true,
      setItems: false,
      paginated: true,
      partial: false,
      pathParams: undefined,
      successMessage: undefined,
      mergeNoArray: false,
      mergeNoArrayNoItems: false,
      appendItems: false,
      notifySuccess,
      notifyFailure
    };
  }

  /**
   * Error handling:
   * 1) show notifications
   * 2) throw the error for further processing
   *
   * @param _errorsMap an object with error's field name as key and a property
   *                   named label containing the label
   */
  handleError = (error, defaultMessage, _errorsMap, _options, dispatch) => {
    const customError = error; // TODO make this a proper error instead of a ref copy

    const state = store.getState();

    const requirementsErrorsMap = state.entities.requirements;
    const errorsMap = {
      ...defaultErrorsMap,
      ...requirementsErrorsMap,
      ..._errorsMap
    };
    const options = _options === undefined ? this.defaultOptions : _options;
    const errorMessages = [];

    if (error.response && error.response.data) {
      /**
       * errors has this shape:
       *
       * {
       *   "joint_partners": [
       *     "this field is required when Joint is Yes"
       *   ]
       * }
       *
       */

      const errors = {};

      // Make a copy of `error.response.data` while merging some error categories.
      Object.entries(error.response.data).forEach(([label, descriptions]) => {
        if (label === "team__contract" || label === "team__members") {
          // team__contract errors are joined into team__members
          if (errors.team__members === undefined) {
            errors.team__members = [];
          }
          errors.team__members = errors.team__members.concat(descriptions);
        } else {
          errors[label] = descriptions;
        }
      });

      if (dispatch && state.featureFlags[FeatureFlags.FIELD_ERRORS]) {
        // TODO: we may actually want to add some extra information about
        // which resource provoked the error (`this.types.name`?)
        dispatch(addFieldErrors(errors));
      }

      Object.entries(errors).forEach(([fieldName, errorDescriptions]) => {
        // Improve error label
        let errorLabel = fieldName;
        if (errorsMap && errorsMap[fieldName] && errorsMap[fieldName].label) {
          errorLabel = errorsMap[fieldName].label;
        }
        if (fieldName === "__all__") {
          errorLabel = "Error";
        }
        if (options.notifyFailure) {
          customToastError(
            <ErrorToastBody
              label={errorLabel}
              descriptions={errorDescriptions}
            />,
            // horrible string + array operation, it works but be careful when editing
            { toastId: errorLabel + errorDescriptions }
          );
        }
        const errorMessage = `${errorLabel}: ${errorDescriptions}`;
        errorMessages.push(errorMessage);
      });
    } else if (error.message && options.notifyFailure) {
      customToastError(error.message || defaultMessage || "Error");
    }

    customError.errorMessages = errorMessages;
    if (error?.response?.status === 401) {
        // The authorization mechanism is handled by the App.js
        customToastError(`Unauthorized: ${defaultMessage}`);
    } else {
      throw customError;
    }
  };

  addParamsToPath = params => {
    // params should be an object.
    // Substrings in 'this.path' surrounded by '<>' (like <paramName>)
    // will be replaced by params.paramName's value
    // EG: suppose this.path is defined as:
    //   rest/evaluations/<id>/validation
    // passing { id: 12 } as params will replace the url with
    //   rest/evaluations/12/validation
    let finalPath = this.path;

    // Object.entries returns a list of tuples with the first element
    // being the key and second one being the value
    Object.entries(params).forEach(p => {
      finalPath = finalPath.replace(`<${p[0]}>`, p[1]);
    }); // eslint-disable-line no-return-assign

    return finalPath;
  };

  list = (params, _options) => {
    const options = _options
      ? lodashMerge(this.defaultOptions, _options)
      : this.defaultOptions;
    let path = options.altPath ? options.altPath : this.path;
    let additionalData = {};
    if (options.pathParams) {
      path = this.addParamsToPath(options.pathParams);
      additionalData = options.pathParams;
    }
    return dispatch =>
      dispatch({
        type: this.types.list.base,
        payload: client
          .get(`${path}/`, { params })
          .then(response => {
            const data = normalize(
              (options.paginated ? response.data.results : response.data).map(
                r => ({ ...r, ...additionalData })
              ),
              [this.schema]
            );
            if (options.setItems === true) {
              dispatch(setItems(data, this.types.name));
            } else if (options.mergeNoArray === true) {
              dispatch(mergeNoArray(data, this.types.name));
              dispatch(mergeItems(data, this.types.name));
            } else if (options.mergeItems === false) {
              dispatch(merge(data, this.types.name));
            } else if (options.customAction) {
              dispatch({ type: options.customAction, data: response.data });
            }  else if (options.appendItems === true) {
                dispatch(merge(data, this.types.name));
                dispatch(append(data, this.types.name));
            } else {
              dispatch(mergeItemsEntities(data, this.types.name));
            }
            return response;
          })
          .catch(error =>
            this.handleError(
              error,
              `Error retrieving ${this.types.name} data`,
              {},
              options
            )
          )
      });
  };

  detail = (id, _options) => {
    const options = _options
      ? lodashMerge(this.defaultOptions, _options)
      : this.defaultOptions;
    let path = options.altPath ? options.altPath : this.path;
    let additionalData = {};
    if (options.pathParams) {
      path = this.addParamsToPath(options.pathParams);
      additionalData = options.pathParams;
    }
    if (id !== undefined && id !== null && id !== "") {
      path = `${path}/${id}`;
    }
    return dispatch =>
      dispatch({
        type: this.types.detail.base,
        payload: client
          .get(`${path}/`)
          .then(response => {
            const data = normalize(
              { ...response.data, ...additionalData },
              this.schema
            );
            dispatch(mergeItems(data, this.types.name));
            dispatch(merge(data, this.types.name));
            return response;
          })
          .catch(error =>
            this.handleError(
              error,
              `Error retrieving ${this.types.name} data`,
              {},
              options
            )
          )
      });
  };

  create = (instance, _options) => {
    const options = _options
      ? lodashMerge(this.defaultOptions, _options)
      : this.defaultOptions;
    // First, populate path with params if needed
    const path = options.pathParams
      ? this.addParamsToPath(options.pathParams)
      : this.path;

    return dispatch =>
      dispatch({
        type: this.types.create.base,
        payload: client
          .post(`${path}/`, instance)
          .then(res => {
            if ([200, 201, 204].includes(res.status)) {
              if (options.notifySuccess) {
                toast.success("Creation successful!", {
                  autoClose: 1000,
                  className: { background: COLOR_SUCCESS_TOAST }
                });
                setTimeout(() => toast.dismiss(), 1500);
              }
              // We now add the just created resource to our store
              // and append the result to our items by default
              const data = normalize(res.data, this.schema);
              dispatch(merge(data, this.types.name));
              dispatch(append(data, this.types.name));
            }
            return res;
          })
          // Here we make an options call to the endpoint so we can retrieve human readable names
          .catch(error =>
            client
              .options(`${path}/`)
              .then(
                optionsRes =>
                  this.handleError(
                    error,
                    `Error creating ${this.types.name}!`,
                    optionsRes.data.actions.POST,
                    options,
                    dispatch
                  ),
                () =>
                  this.handleError(
                    error,
                    `Error creating ${this.types.name}!`,
                    {},
                    options
                  )
              )
          )
      });
  };

  update = (id, instance, _options) => {
    const options = _options
      ? lodashMerge(this.defaultOptions, _options)
      : this.defaultOptions;
    // First, populate path with params if needed
    const path = options.pathParams
      ? this.addParamsToPath(options.pathParams)
      : this.path;
    // Check if we want a PUT or a PATCH
    const method = options.partial ? "patch" : "put";

    return dispatch =>
      dispatch({
        type: this.types.update.base,
        payload: client[method](`${path}/${id}/`, instance)
          .then(res => {
            // Both 200 and 204 should be valid states returned by update endpoints
            // (see https://www.w3.org/Protocols/rfc2616/rfc2616-sec9.html)
            if ([200, 204].includes(res.status)) {
              if (options.notifySuccess) {
                toast.success(options.successMessage || "Update successful!", {
                  autoClose: 1000,
                  className: { background: COLOR_SUCCESS_TOAST }
                });
                setTimeout(() => toast.dismiss(), 1500);
              }
              // We update our resource in the store
              const data = normalize(res.data, this.schema);
              if (options.mergeNoArray) {
                dispatch(mergeNoArray(data, this.types.name));
              } else if (options.mergeNoArrayNoItems) {
                dispatch(mergeNoArrayNoItems(data, this.types.name));
              } else {
                dispatch(merge(data, this.types.name));
              }
            }
            return res;
          })
          // Here we make an options call to the endpoint so we can retrieve human readable names
          .catch(error =>
            client
              .options(`${path}/`)
              .then(
                optionsRes =>
                  this.handleError(
                    error,
                    `Error updating ${this.types.name}!`,
                    optionsRes.data.actions?.POST,
                    options,
                    dispatch
                  ),
                () =>
                  this.handleError(
                    error,
                    `Error updating ${this.types.name}!`,
                    {},
                    options
                  )
              )
          )
      });
  };

  /** Executes `update` or `creating` depending on whether instance has an `id` */
  save = (instance, _options) => {
    if (instance.id) {
      return this.update(instance.id, instance, _options);
    }
    return this.create(instance, _options);
  };

  destroy = (id, _options) => {
    const options = _options
      ? lodashMerge(this.defaultOptions, _options)
      : this.defaultOptions;
    // First, populate path with params if needed
    const path = options.pathParams
      ? this.addParamsToPath(options.pathParams)
      : this.path;

    return dispatch =>
      dispatch({
        type: this.types.delete.base,
        payload: client
          .delete(`${path}/${id}/`)
          .then(res => {
            // 200, 202 and 204 are valid states returned by DELETE endpoints
            // (see https://www.w3.org/Protocols/rfc2616/rfc2616-sec9.html)
            if ([200, 202, 204].includes(res.status)) {
              if (options.notifySuccess) {
                toast.success("Deletion successful!", {
                  autoClose: 1000,
                  className: { background: COLOR_SUCCESS_TOAST }
                });
                setTimeout(() => toast.dismiss(), 1500);
              }
              dispatch(markDeleted(id, this.schema, this.types.name));
            }
            return res;
          })
          .catch(error =>
            this.handleError(
              error,
              `Error deleting ${this.types.name}!`,
              {},
              options
            )
          )
      });
  };

  options = _options => {
    const options = _options
      ? lodashMerge(this.defaultOptions, _options)
      : this.defaultOptions;
    // First, populate path with params if needed
    const path = options.pathParams
      ? this.addParamsToPath(options.pathParams)
      : this.path;
    return dispatch =>
      dispatch({
        type: this.types.options.base,
        payload: client
          .options(`${path}/`)
          .catch(error =>
            this.handleError(error, "Error retrieving data", {}, options)
          )
      });
  };

  detailOptions = (id, _options) => {
    const options = _options
      ? lodashMerge(this.defaultOptions, _options)
      : this.defaultOptions;
    // First, populate path with params if needed
    const path = options.pathParams
      ? this.addParamsToPath(options.pathParams)
      : this.path;
    return dispatch =>
      dispatch({
        type: this.types.detailOptions.base,
        payload: client
          .options(`${path}/${id}/`)
          .catch(error =>
            this.handleError(error, "Error retrieving data", {}, options)
          )
      });
  };

  saveOrderingPosition = (items) => {
    return dispatch => ({
      type: this.types.orderingPosition.base,
      payload: client
        .patch(
          `${this.path}/ordering_positions/`,
          items.map(item => ({id: item.id, position: item.position}))
        )
        .then(() => {
          const data = normalize(items, [this.schema]);
          dispatch(mergeItemsEntities(data, this.types.name))
        })
        .catch(error => {
          this.handleError(error);
        })
    });
  }

  clear = () => dispatch => dispatch({ type: "CLEAR_ITEMS", resource: this.types.name });
}

export default Resource;
