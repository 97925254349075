import React from "react";
import PropTypes from "prop-types";
import { fieldInputPropTypes } from "redux-form";

import { TextArea } from "@wfp/ui";

import styled from "styled-components";

import Styles from "./styles.scss";

// Override WFP UI-Kit styles
const StyledWrapper = styled.div`
  textarea::placeholder {
    color: #8c9ba5;
  }
`;

/**
 * Component props
 * @prop {label} str
 * @prop {cssClass} str
 */
const Textarea = ({
  disabled,
  label,
  input,
  readOnly,
  placeholder,
  enableCounter,
  maxLength,
  currentLength,
  rows,
}) => {
  /**
   * If the available characters is less than 10% of the maximum textarea length,
   * the "characters left" message gets an orange color.
   * When the character limit is reached, "characters left" gets a red color.
   */
  let counterLimitStyles = null;

  if (maxLength - currentLength === 0) {
    counterLimitStyles = {
      color: "#c5192d",
      fontWeight: "600"
    };
  } else if (maxLength - currentLength <= maxLength * 0.1) {
    counterLimitStyles = {
      color: "#f7b825",
      fontWeight: "600"
    };
  }

  return (
    <StyledWrapper>
      <TextArea
        {...input}
        id={`text-${label}`}
        labelText={label}
        disabled={disabled}
        rows={rows}
        readOnly={readOnly}
        placeholder={placeholder}
      />
      {enableCounter && !readOnly && (
        <div className={Styles.counter} style={counterLimitStyles}>
          <span>{maxLength - currentLength} characters left</span>
        </div>
      )}
    </StyledWrapper>
  );
};

Textarea.propTypes = {
  cssClass: PropTypes.string,
  currentLength: PropTypes.number,
  disabled: PropTypes.bool,
  enableCounter: PropTypes.bool,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  rows: PropTypes.number,
};

Textarea.defaultProps = {
  cssClass: "",
  currentLength: 0,
  disabled: undefined,
  placeholder: "",
  enableCounter: false,
  maxLength: 0,
  readOnly: false,
  rows: undefined,
};

export default Textarea;
