import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { reset } from "redux-form";
import { withRouter } from "react-router";
import ReactRouterPropTypes from "react-router-prop-types";
import ReactTable from "react-table";
import get from "lodash/get";
import { Button } from "@wfp/ui";
import { iconAddGlyph } from "@wfp/icons";

import DeleteModal from "Modal/DeleteModal";
import Loading from "Loading";
import HelpMessageBox from "HelpMessageBox";
import TableWithModalsMixin from "TableWithModalsMixin";

import destinations from "./destinations";
import ProductAddModal from "./ProductAddModal";
import columnsGenerator from "./tableColumns";
import { toggle } from "../../../../../../actions/togglable";
import { getEvaluationActions } from "../../../../../../actions/evaluations";
import { dePhasesActions } from "../../../../../../actions/phases";
import productsActions from "../../../../../../actions/products";
import productTypesActions from "../../../../../../actions/productTypes";
import {
  getMessages,
  isCreate,
  parseDate
} from "../../../../../../utils";

/** This function is outside ProductsComponent because it is not related to it */
function openLink(url) {
  if (url) {
    window.open(url, "_blank");
  }
}

export class ProductsComponent extends TableWithModalsMixin {
  /** Overriding TableWithModalsMixin' componentDidMount to also fetchOptions */
  componentDidMount = () => {
    // First fetch table data
    this.props.fetchItems(this.props.match.params.evaluationId);
    // Then everything else
    if (this.props.fetchData) {
      this.props.fetchData();
    }

    this.props.fetchDePhases(this.props.match.params.evaluationId);
    this.props.fetchOptions(this.props.match.params.evaluationId);
  };

  customSave = () => this.saveItem(this.props.category);

  customDelete = () => this.deleteItem(this.props.category);

  valuesAdapter = values => ({
    ...values,
    kind: get(values, "kind.value", null),
    release_date:
      values && values.release_date && parseDate(values.release_date)
  });

  /**
   * The calendar widget in products tab should not allow the selection of dates
   * before the start date of the preparation phase, if the date is not empty.
   * When the start date of preparation phase is empty, the calendar should allow the selection of any date
   */
  allowedDatesRange = () => {
    const startDate = get(this.props.preparationPhase, "start_date");

    // this covers the case where we have `preparationPhase` without `start_date`.
    if (startDate) {
      return [startDate];
    }

    // `start_date` is not available return `null` to make all the dates availables.
    return null;
  };

  render() {
    const noRowsStyles =
      this.props.products.length === 0 ? "no_rows_found" : "";
    return (
      <div>
        {this.props.canEdit && (
          <HelpMessageBox
            messageList={this.props.messages}
            title="Please update the following information"
          />
        )}

        {this.props.canEdit && (
          <Button
            kind="secondary"
            type="submit"
            icon={iconAddGlyph}
            iconDescription="Add a new coverage"
            onClick={() => this.getItem(null)}
            disabled={
              !this.props.canEdit ||
              isCreate(this.props.match.params.evaluationId)
            }
          >
            New product
          </Button>
        )}
        <ProductAddModal
          title={
            this.props.selectedItem === null ? "Add Product" : "Edit Product"
          }
          initialValues={this.props.selectedItem}
          toggleModal={this.props.toggleAddModal}
          show={this.props.showAddModal}
          onSubmit={this.customSave}
          types={this.props.types}
          datesRange={this.allowedDatesRange}
        />
        <DeleteModal
          title="Are you sure you want to delete this product?"
          show={this.props.showDeleteModal}
          toggleModal={this.props.toggleDeleteModal}
          onDelete={this.customDelete}
          danger
        />
        {this.props.isFetching ? (
          <Loading />
        ) : (
          <ReactTable
            className={`-highlight wfp-table -border -striped ${noRowsStyles}`}
            data={this.props.products}
            columns={columnsGenerator(
              openLink,
              this.getItem,
              this.setItemToDelete,
              this.props.canEdit,
              this.props.productValueLabels
            )}
            minRows={1}
            showPagination={false}
          />
        )}
      </div>
    );
  }
}

ProductsComponent.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  productValueLabels: PropTypes.instanceOf(Map).isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  formValues: PropTypes.object, // eslint-disable-line
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      is_release_date_mandatory: PropTypes.bool,
      ordering: PropTypes.number
    })
  ).isRequired,
  selectedItem: PropTypes.shape({ id: PropTypes.number }),
  canEdit: PropTypes.bool.isRequired
};

ProductsComponent.defaultProps = {
  products: [],
  selectedItem: null,
  formValues: null
};

export const mapStateToProps = store => {
  const { entities } = store;
  const page = store.pages.products;
  const form = store.form.evProduct;

  // Adapt products data so, to add destinations,
  // we first check if we have the data we need
  const products =
    !isEmpty(entities.products) && !isEmpty(entities.productTypes)
    ? page.items
        .map(productId => {
          const product = entities.products[productId];

          if (!product || product.deleted) {
            return null; // Skip deleted products
          }

          const productType = entities.productTypes[product.kind];
          return {
            ...product,
            productType: productType || null, // Associate product type
            product_destinations: Object.entries(product)
              .filter(dest => dest[1] === true)
              .map(dest => ({ label: destinations[dest[0]] })),
          };
        })
        .filter(Boolean)
        .sort((a, b) => {
          const positionA = a.productType ? a.productType.position : 0;
          const positionB = b.productType ? b.productType.position : 0;

          // Compare based on product type positions
          return positionA - positionB;
        })
    : [];

  // Product types, excluding already used ones (in the filter)
  const productTypes = Object.values(entities.productTypes);
  const types =
    products.length > 0
      ? productTypes
            .filter(pt => !products.map(p => p.kind).includes(pt.id))
            .filter(option => !option.is_deprecated)
            .sort((a, b) => a.position - b.position)
      : productTypes;
  // options shape: [{ value: label }, ...];
  const productValueLabels = new Map(
    Object.entries(get(page.options, "actions.POST", {})).map(i => [
      i[0],
      i[1].label
    ])
  );

  // Manually set release_date to null if it's undefined
  const formValues =
    form && form.values
      ? { ...form.values, release_date: form.values.release_date || null }
      : undefined;

  let selectedItem = entities.products[page.selectedProduct];
  selectedItem = selectedItem && {
    ...selectedItem,
    kind: {
      value: selectedItem.kind,
      label: selectedItem.kind_display
    }
  };

  const phases =
    page && page.phases && page.phases.map(id => entities.dePhases[id]);
  const preparationPhase =
    (phases &&
      phases.length > 0 &&
      phases.find(p => p.phase_display === "PREPARATION")) ||
    null;

  return {
    formValues,
    isFetching: page.isFetching,
    messages: getMessages(store.messages.products),
    productValueLabels,
    preparationPhase,
    products,
    types: types.map(t => ({ label: t.name, value: t.id })),
    selectedItem,
    showDeleteModal: page.deleteModal,
    showAddModal: page.addModal
  };
};

export const mapDispatchToProps = dispatch => ({
  fetchDePhases: evaluationId =>
    dispatch(
      dePhasesActions.list(
        {},
        { pathParams: { evaluationId }, paginated: false }
      )
    ),
  fetchData: () => dispatch(productTypesActions.list({}, { paginated: false })),
  fetchItems: evaluationId =>
    dispatch(
      productsActions.list(
        {},
        { paginated: false, pathParams: { evaluationId } }
      )
    ),
  fetchOptions: evaluationId =>
    dispatch(productsActions.options({ pathParams: { evaluationId } })),
  selectItem: id => dispatch({ type: "SELECT_PRODUCT", id }),
  resetForm: () => dispatch(reset("evProduct")),
  saveItem: (product, evaluationId, category) =>
    dispatch(
      productsActions.save(product, { pathParams: { evaluationId } })
    ).then(() => {
       dispatch(getEvaluationActions(category).validation(evaluationId));
       dispatch(getEvaluationActions(category).detail(evaluationId));
    }),
  deleteItem: (productId, evaluationId, category) =>
    dispatch(
      productsActions.destroy(productId, { pathParams: { evaluationId } })
    ).then(() =>
      dispatch(getEvaluationActions(category).validation(evaluationId))
    ),
  toggleAddModal: () => dispatch(toggle("PRODUCT_ADD_MODAL")),
  toggleDeleteModal: () => dispatch(toggle("PRODUCT_DELETE_MODAL"))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductsComponent)
);
