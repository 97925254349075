import { combineReducers } from "redux";
import { fetching, items, selectable, submitting, togglable } from "./resource";

const toggleMilestones = (state = { milestoneVisible: 0 }, action) => {
  if (action.type === "TOGGLE_MILESTONES") {
    if (state.milestoneVisible === action.milestoneVisible) {
      return {
        milestoneVisible: 0
      };
    }
    return {
      milestoneVisible: action.milestoneVisible
    };
  }
  return state;
};

export default combineReducers({
  selectedPhase: selectable("phase"),
  isFetching:
    fetching("dePhases") ||
    fetching("deMilestones") ||
    fetching("phases") ||
    fetching("validation") ||
    fetching("evaluations") ||
    fetching("milestones"),
  isSubmitting: submitting("dePhases"),
  items: items("dePhases"),
  milestones: items("deMilestones"),
  phaseAddModal: togglable("PHASE_ADD_MODAL"),
  phaseDeleteModal: togglable("PHASE_DELETE_MODAL"),
  milestoneAddModal: togglable("MILESTONE_ADD_MODAL"),
  milestoneDeleteModal: togglable("MILESTONE_DELETE_MODAL"),
  toggleMilestones
});
