import {isInclusivelyAfterDay} from "react-dates";
import moment from "moment";
import {DEFAULT_DATE_FORMAT} from "../../../utils";

const rangeDateHelper = (rangeStartDate, rangeEndDate) => {
    const isOutsideRange = (date, strict = true) => {
        if (!date) {
            return false;
        }

        // only start date is set, so user can select all dates starting from this date
        if (rangeStartDate !== null && rangeEndDate === null) {
            return !isInclusivelyAfterDay(
              date,
              moment(rangeStartDate, DEFAULT_DATE_FORMAT)
            );
        }
        // only end date is set, so user can select all dates before
        if (rangeStartDate === null && rangeEndDate !== null) {
            return isInclusivelyAfterDay(
              date,
              moment(rangeEndDate, DEFAULT_DATE_FORMAT)
            );
        }
        // all dates are available to be chosen by user
        if (rangeStartDate === null && rangeEndDate === null) {
            return false;
        }
        // both end and start date are provided, user can only select dates between this range
        const end = new Date(moment(rangeEndDate, DEFAULT_DATE_FORMAT));
        if (strict) {
            end.setDate(end.getDate()+1);
        }
        return (
          !isInclusivelyAfterDay(date, moment(rangeStartDate, DEFAULT_DATE_FORMAT)) ||
          isInclusivelyAfterDay(date, moment(end, DEFAULT_DATE_FORMAT))
        );
    }

    const hasOverlapping = (startDate, endDate) => (
      isOutsideRange(startDate) || isOutsideRange(endDate)
    );

    const difference = (startDate, date) => (
      moment(date, DEFAULT_DATE_FORMAT).diff(moment(startDate, DEFAULT_DATE_FORMAT), 'days')
    );

    const resetMomentTime = (value) => moment(value, DEFAULT_DATE_FORMAT).startOf('day');

    const getSingleDateDifference = (newValue, oldValue, compareBoundary) => {
        let diff = 0;
        let rangeCorrection = 0;
        let differenceDate = null;

        if (isOutsideRange(newValue, false)) {
            differenceDate = oldValue;
            if (!differenceDate) {
                if (newValue.isBefore(moment(compareBoundary, DEFAULT_DATE_FORMAT))) {
                    differenceDate = rangeStartDate;
                    rangeCorrection = 0;
                } else {
                    differenceDate = rangeEndDate;
                    // End date boundary not included to the range
                    rangeCorrection = 1;
                }
            }
            diff = rangeCorrection + resetMomentTime(newValue).diff(resetMomentTime(differenceDate), 'days');
        }

        return diff;
    }

    const getOverlappingDifferences = (newValues, oldValues) => {
        const startDiff = getSingleDateDifference(newValues.startDate, oldValues.startDate, rangeStartDate);
        const endDiff = getSingleDateDifference(newValues.endDate, oldValues.endDate, rangeEndDate);

        return [startDiff, endDiff];
      }

    return ({
        getOverlappingDifferences,
        isOutsideRange,
        hasOverlapping,
        difference,
    })
}

export default rangeDateHelper
