import { schema } from "normalizr";
import { client } from "../store";
import { wfpUsersTypes } from "../types";
import Resource from "./resource";

const wfpUserSchema = new schema.Entity("wfpUsers");

class WFPUserResource extends Resource {
  fetchOevmisRoleOptions = () => dispatch =>
    dispatch({
      type: "FETCH_OEVMISROLE_OPTIONS",
      payload: client
        .get(`${this.path}/oevmisrole_options/`)
        .then(response => {
          dispatch({
            resource: "oevmisRoleOptions",
            type: "MERGE_ITEMS_NOARRAY",
            data: { result: response.data }
          });
          return response;
        })
        .catch(error => {
          this.handleError(error);
        })
    });
}

export default new WFPUserResource("/wfpusers", wfpUsersTypes, wfpUserSchema);
