import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.scss";

const ContainedRadioButton = ({ isDisabled, label, onChangeHandler }) => (
  <div className={styles.ContainedRadioButton}>
    <input
      type="radio"
      id={`radio-${label}`}
      name="radio-grp"
      disabled={isDisabled}
      value={label}
      onClick={onChangeHandler}
    />
    <label htmlFor={`radio-${label}`} className="wfp-radio-label">
      <span className="wfp-radio-item">{label}</span>
    </label>
  </div>
);

/*
  Props and PropTypes to be
  passed to this component
  */
ContainedRadioButton.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string
};

/*
  Default values for
  component props
  */
ContainedRadioButton.defaultProps = {
  isDisabled: false,
  label: "I am the label"
};

export default ContainedRadioButton;
