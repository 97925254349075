import React from "react";
import PropTypes from "prop-types";

import { Loading as WFPLoading } from "@wfp/ui";

import styles from "./styles.scss";

const Loading = ({ withOverlay }) => (
  <div className={styles.LoadingWrapper} data-testid="loading">
    <WFPLoading withOverlay={withOverlay} />
  </div>
);

Loading.propTypes = {
  withOverlay: PropTypes.bool
};

Loading.defaultProps = {
  withOverlay: false
};

export default Loading;
