import React from "react";
import PropTypes from "prop-types";

import { Icon, Tag } from "@wfp/ui";
import { iconEdit, iconHeaderClose } from "@wfp/icons";
import Tooltip from "Tooltip";

import styles from "../styles.scss";

const legacyTooltipMessage = `This evaluation started before 2016,
  in a status that does not allow for the validation of some mandatory fields`;

const isMigratedTooltipMessage = `This evaluation was migrated
  in a status that does not allow for the validation of some mandatory fields`;

const Edit = props => (
  <Icon
    icon={iconEdit}
    width="19"
    height="19"
    description="Edit item"
    onClick={props.onClick}
  />
);
Edit.propTypes = {
  onClick: PropTypes.func.isRequired
};

const Unedit = props => (
  <Icon
    icon={iconHeaderClose}
    className={styles.unedit}
    width="19"
    height="19"
    description="Close item"
    onClick={props.onClick}
  />
);
Unedit.propTypes = {
  onClick: PropTypes.func.isRequired
};

const StatusHeader = ({
  canEdit,
  editableFields,
  fieldsEditPermission,
  isCancelled,
  isLegacy,
  isMigrated,
  isFinalized,
  toggleEditableFields,
  toggleStatus
}) => {
  const editComponent =
    canEdit &&
    (editableFields ? (
      <Unedit onClick={toggleEditableFields} />
    ) : (
      <Edit onClick={toggleEditableFields} />
    ));
  return (
    <>
      <div className={styles.closeSidebar}>
        <Icon
          icon={iconHeaderClose}
          width="19"
          height="19"
          fill="grey"
          description="Close item"
          onClick={toggleStatus}
        />
      </div>
      <div className={styles.evaluationStatusBarHeader}>
        <div className={styles.leftColumnControls}>
          <h5>Evaluation Status</h5>
          {fieldsEditPermission && editComponent}
        </div>
        {!(isCancelled || isFinalized) && (
          <p>
            Complete the requirements indicated in order to apply the next
            status.
          </p>
        )}
      </div>
      {(isLegacy || isMigrated) && (
        <div className={styles.tagsContainer}>
          {isLegacy && (
            <div>
              <Tag type="wfp">
                <p>Legacy</p>
              </Tag>
              <Tooltip message={legacyTooltipMessage} maxWidth={170} />
            </div>
          )}
          {isMigrated && (
            <div>
              <Tag type="wfp">
                <p>Migrated</p>
              </Tag>
              <Tooltip message={isMigratedTooltipMessage} maxWidth={200} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

StatusHeader.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  editableFields: PropTypes.bool.isRequired,
  fieldsEditPermission: PropTypes.bool.isRequired,
  isCancelled: PropTypes.bool.isRequired,
  isFinalized: PropTypes.bool.isRequired,
  isLegacy: PropTypes.bool.isRequired,
  isMigrated: PropTypes.bool.isRequired,
  toggleEditableFields: PropTypes.func.isRequired,
  toggleStatus: PropTypes.func.isRequired
};

export default StatusHeader;
