import React from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import SecondaryNavigation from "SecondaryNavigation";
import Loading from "Loading";
import ListComponent from "ListComponent";
import TableWithModalsMixin from "../../components/TableWithModalsMixin";

import countriesInfoActions from "../../actions/countriesInfo";
import countriesActions from "../../actions/countries";
import { toggle } from "../../actions/togglable";

import { capitalize } from "../../utils";

import CountryInfoModal from "./CountryInfoModal";
import columnsGenerator from "./tableColumns";

export class CountryInfoListComponent extends TableWithModalsMixin {
  valuesAdapter = formValues => ({
    country: {
      id: formValues.country.value
    },
    development_status: get(formValues, "development_status.value") || null,
    id: formValues.id,
    region: formValues?.region?.value ?? null,
  });

  componentDidMount() {
    this.props.fetchCountries();
    this.props.fetchCountryInfo();
  }

  componentDidUpdate() {
    // TODO: also check for !isFetching (avoid wasted queries)
    if (
      !isEmpty(this.props.data) &&
      isEmpty(this.props.developmentStatusOptions)
    ) {
      // Fetching detailOptions is a real pain.
      // We must use an actual entity id instead of querying the plain endpoint.
      this.props.fetchCountryInfoDetailOptions(this.props.data[0].id);
    }
  }

  render() {
    return this.props.isFetching ? (
      <Loading />
    ) : (
      <div>
        <SecondaryNavigation title="Countries" />

        <CountryInfoModal
          title="Edit Country"
          initialValues={this.props.selectedItem}
          toggleModal={this.props.toggleAddModal}
          show={this.props.showCountryInfoModal}
          countries={this.props.countriesOptions}
          developmentStatusOptions={this.props.developmentStatusOptions}
          regionOptions={this.props.regionOptions}
          onSubmit={this.saveItem}
          canEdit={this.props.canEdit}
        />

        <ListComponent
          fetching={this.props.isFetching}
          tableData={this.props.data}
          columns={columnsGenerator(this.getItem, this.props.canEdit)}
          searchOnChange={input => this.props.searchText(input.query)}
        />
      </div>
    );
  }
}

CountryInfoListComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      country: PropTypes.string,
      country_id: PropTypes.number,
      development_status: PropTypes.string,
      region: PropTypes.string,
      canEdit: false
    })
  ).isRequired
};

export const mapStateToProps = state => {
  const userPermissions = get(state, "user[0].permissions.permission");
  const countriesInfoEntities = get(state, "entities.countriesInfo");
  const countriesEntities = get(state, "entities.countries");

  const page = get(state, "pages.countriesInfo");
  const data =
    page &&
    page.items &&
    page.items
      .map(id => countriesInfoEntities[id])
      .map(c => ({
        country: c.country.name,
        country_id: c.country.id,
        id: c.id,
        development_status: capitalize(c.development_status),
        region: capitalize(c.region),
      }));

  const searchQuery = get(page, "searchText.query", "");
  let results = data;
  if (searchQuery !== "") {
    results = data.filter(v =>
      v.country.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  const detailOption = page?.detailOptions?.actions?.PUT;

  // eslint-disable-next-line camelcase
  const developmentStatusOptions = detailOption?.development_status?.choices?.map(
      o => ({ value: o.value, label: o.display_name })
  ) ?? [];

  const regionOptions = detailOption?.region?.choices?.map(
      o => ({ value: o.value, label: o.display_name })
  ) ?? [];

  const country =
    countriesInfoEntities && countriesInfoEntities[page.selectedCountry];
  const selectedItem = country && {
    id: country.id,
    country: { label: country.country.name, value: country.country.id },
    development_status: country.development_status && {
      label: capitalize(country.development_status.toLowerCase()),
      value: country.development_status
    },
    region: {
      label: country.region,
      value: country.region
    }
  };

  const formValues = get(state, "form.countryInfo.values", {});
  const countriesOptions =
    countriesEntities &&
    Object.keys(countriesEntities).map(k => ({
      label: countriesEntities[k].name,
      value: countriesEntities[k].id
    }));

  const canEdit =
    userPermissions && userPermissions.includes("actors.change_countryinfo");

  return {
    data: results,
    selectedItem,
    countriesOptions,
    developmentStatusOptions,
    regionOptions,
    formValues,
    canEdit,

    isFetching: page.isFetching,
    showCountryInfoModal: page.addModal
  };
};

export const mapDispatchToProps = dispatch => ({
  saveItem: item => dispatch(countriesInfoActions.save(item)),
  selectItem: id => dispatch({ type: "SELECT_COUNTRY", id }),

  searchText: query => dispatch({ type: "COUNTRY_INFO_SEARCH_TEXT", query }),

  fetchCountries: () =>
    dispatch(countriesActions.list({}, { paginated: false })),
  fetchCountryInfo: () =>
    dispatch(countriesInfoActions.list({}, { paginated: false })),
  fetchCountryInfoDetailOptions: id =>
    dispatch(countriesInfoActions.detailOptions(id)),

  toggleAddModal: () => dispatch(toggle("COUNTRY_INFO_ADD_MODAL")),
  resetForm: () => dispatch(reset("countryInfo"))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CountryInfoListComponent)
);
