import React from "react";

import Styles from "./styles.scss";

/* eslint-disable */

export const TickIcon = ({ width, height }) => (
  <svg
    className={Styles.MilestoneTickIcon}
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path
      fill="#00A878"
      d="M9.682 20.329c-.4 0-.7-.2-1-.4l-6.8-6.8c-.3-.3-.4-.6-.4-1s.1-.7.4-1l2.4-2.4c.3-.3.6-.4 1-.4s.7.2 1 .4l3.5 3.5 8.1-8.1c.3-.3.6-.4 1-.4s.7.1 1 .4l2.4 2.4c.3.3.4.6.4 1s-.2.7-.4 1l-11.6 11.4c-.3.3-.6.4-1 .4z"
    />
    <path
      fill="#404040"
      d="M18.782 4.629c-.1 0-.2 0-.3.1l-8.8 8.8-4.1-4.2c-.1-.1-.2-.1-.3-.1-.1 0-.3.1-.3.1l-2.4 2.4c-.1.1-.1.2-.1.3 0 .1.1.3.1.3l6.8 6.9c.1.1.2.1.3.1.1 0 .3-.1.3-.1l11.5-11.5c.1-.1.1-.2.1-.3 0-.1-.1-.3-.1-.3l-2.4-2.4c-.1-.1-.2-.1-.3-.1m0-1.9c.6 0 1.2.2 1.7.7l2.4 2.4c.4.4.7 1 .7 1.7 0 .6-.2 1.2-.7 1.7l-11.6 11.4c-.4.4-1 .7-1.7.7-.6 0-1.2-.3-1.7-.7l-6.8-6.8c-.4-.4-.7-1-.7-1.7 0-.6.2-1.2.7-1.7l2.4-2.4c.4-.4 1-.7 1.7-.7.6 0 1.2.2 1.7.7l2.8 2.8 7.5-7.5c.4-.4 1-.6 1.6-.6z"
    />
  </svg>
);

export const CrossIcon = ({ width, height }) => (
  <svg
    className={Styles.MilestoneCrossIcon}
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <path
      fill="#FF5252"
      d="M17.4 22.3c-.5 0-.9-.2-1.2-.5l-4.3-4.3-4.1 4.1c-.3.3-.8.5-1.2.5-.5 0-.9-.2-1.2-.5l-3.2-3.2c-.3-.3-.5-.8-.5-1.2 0-.5.2-.9.5-1.2l4.1-4.1-4.1-4.1c-.4-.3-.5-.8-.5-1.2 0-.5.2-.9.5-1.2l3.2-3.2c.7-.7 1.8-.7 2.4 0l4.1 4.1L16 2.2c.3-.3.8-.5 1.2-.5.5 0 .9.2 1.2.5l3.2 3.2c.3.3.5.8.5 1.2s-.2.9-.5 1.2l-4.1 4.1 4.3 4.3c.7.7.7 1.8 0 2.4l-3.2 3.2c-.3.4-.7.5-1.2.5z"
    />
    <path
      fill="#404040"
      d="M17.4 21.2c.2 0 .3-.1.4-.2l3.2-3.2c.2-.2.2-.6 0-.8l-5.2-5.2 5-4.8c.2-.2.2-.6 0-.8L17.6 3c-.2-.2-.6-.2-.8 0l-4.9 4.9L7 3c-.1-.1-.3-.2-.4-.2-.2 0-.3.1-.4.2L3 6.2c-.3.2-.3.5 0 .8l4.9 4.9L3 16.8c-.2.2-.2.6 0 .8l3.2 3.2c.2.2.6.2.8 0l4.9-4.9L17 21c.1.1.3.2.4.2m0 2.3c-.8 0-1.5-.3-2-.8l-3.5-3.5-3.3 3.3c-.5.5-1.3.8-2 .8-.8 0-1.5-.3-2-.8l-3.2-3.2c-.5-.5-.8-1.3-.8-2 0-.8.3-1.5.8-2L4.7 12 1.3 8.6c-.5-.5-.8-1.3-.8-2 0-.8.3-1.5.8-2l3.2-3.2C5.6.3 7.4.3 8.6 1.4l3.3 3.3 3.3-3.3c.5-.5 1.3-.8 2-.8.8 0 1.5.3 2 .8l3.2 3.2c.5.5.8 1.3.8 2s-.3 1.5-.8 2l-3.3 3.3 3.5 3.5c1.1 1.1 1.1 2.9 0 4.1l-3.2 3.2c-.5.5-1.2.8-2 .8z"
    />
  </svg>
);

/* eslint-enable */
