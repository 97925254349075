import React from "react";
import PropTypes from "prop-types";
import { Button } from "@wfp/ui";
import { iconArrowLeft, iconChevronLeft } from "@wfp/icons";

import DoubleButtonIcon from "../DoubleButtonIcon";

const NavPrev = ({
  onNavigateYear,
}) => (
  <div
    style={{
      left: '8px',
      top: '9px',
      position: 'absolute',
    }}
  >
    <Button
      data-testid="prev-year-button"
      kind="secondary"
      onClick={(event) => onNavigateYear(event, -1)}
    >
      <DoubleButtonIcon
        icon={iconChevronLeft}
      />
    </Button>
    <Button
      data-testid="prev-month-button"
      kind="secondary"
      icon={iconArrowLeft}
    />
  </div>
);

NavPrev.propTypes = {
  onNavigateYear: PropTypes.func.isRequired,
};

export default NavPrev;
