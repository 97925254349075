import { combineReducers } from "redux";
import {
  fetching,
  items,
  options,
  pagination,
  selectable,
  submitting,
  togglable
} from "./resource";

export default combineReducers({
  selectedItem: selectable("people"),
  isFetching: fetching("people"),
  isSubmitting: submitting("people"),
  options: options("people"),
  items: items("people"),
  countries: items("countries"),
  showAddModal: togglable("addModal"),
  showDeleteModal: togglable("deleteModal"),
  page: pagination("people")
});
