const uselessModificationFields = [
  'last_group_modified_date',
]

export const activityTransformer = (activities) => {
    if (!activities) {
        return [];
    }
    const transformedActivities = [];

    const valueToString = (value) => {
        if (Array.isArray(value)) {
            return value.join(', ');
        }
        if (typeof(value) === 'boolean') {
            return value ? 'Yes' : 'No';
        }

        return value;
    }

    const singleActivityTransformer = (activity, index) => {
        if (activity.changes && activity.changes.length > 0) {
            activity.changes.forEach((change) => {
                if (!uselessModificationFields.includes(change.field)) {
                   transformedActivities.push({
                       ...activity,
                       field: change.field,
                       object: {
                           id: index,
                           label: activity.objectLabel,
                           name: activity.objectName,
                       },
                       previousValue: valueToString(change.previousValue),
                       currentValue: valueToString(change.currentValue),
                   })
                }
            })
        } else {
            transformedActivities.push({
                ...activity,
                object: {
                    id: index,
                    label: activity.objectLabel,
                    name: activity.objectName,
                },
                field: null,
                previousValue: null,
                currentValue: null,
            })
        }
    }

    activities.forEach(singleActivityTransformer)

    return transformedActivities;
}


export const handleTableCellClick = (rowInfo, column, expanded, setExpanded) => {
    if (!rowInfo || column.disableExpander) {
        return {
          onClick: () => null
        };
    }

    const needsExpander = rowInfo?.subRows?.length > 1;
    const expandedRows = Object.keys(expanded)
      .filter(expandedIndex => expanded[expandedIndex] !== false)
      .map(Number);

    const rowIsExpanded = expandedRows.includes(rowInfo.nestingPath[0]) && needsExpander;

    let newExpanded = expanded;
    if (needsExpander) {
         newExpanded = {
             ...expanded,
             [rowInfo.nestingPath[0]]: rowIsExpanded ? false : {},
         }
    }

    return {
      style: {
          cursor: needsExpander ? 'pointer' : 'auto',
      },
      onClick: () => setExpanded(newExpanded)
  }
}
