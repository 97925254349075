import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import { reset, change } from "redux-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactTable from "react-table";
import get from "lodash/get";

import { Button } from "@wfp/ui";
import { iconAddGlyph } from "@wfp/icons";

import { toast } from "react-toastify";

import moment from "moment";

import DeleteModal from "Modal/DeleteModal";
import Loading from "Loading";
import TableWithModalsMixin from "TableWithModalsMixin";
import HelpMessageBox from "HelpMessageBox";

import { COVERAGE_ENTITY_TYPE } from "CoverageLocationSelect";
import { getEvaluationActions } from "../../../../../../actions/evaluations";
import { toggle } from "../../../../../../actions/togglable";
import bureauxActions from "../../../../../../actions/bureaux";
import countriesActions from "../../../../../../actions/countries";
import coverageActions from "../../../../../../actions/coverage";
import { phasesActions } from "../../../../../../actions/phases";
import {
  capitalize,
  getMessages,
  isCreate,
  DEFAULT_DATE_FORMAT
} from "../../../../../../utils";

import columnsGenerator from "./tableColumns";
import AddModal from "./AddModal";

export class CoverageComponent extends TableWithModalsMixin {
  /**
   * Adapts values for `TableWithModalsMixin.saveItem`
   * Receives `form.values` as argument.
   *
   * `country` and `office` come from a single Select and need custom extraction.
   */
  valuesAdapter = values => {
    // If the form hasn't been interacted with
    if (!values) {
      return {};
    }

    // Extract country and/or office from rblohqc
    const country =
      values.rblohqc &&
      values.rblohqc.value &&
      values.rblohqc.entityType === COVERAGE_ENTITY_TYPE.COUNTRIES
        ? values.rblohqc.value
        : null;
    const office =
      values.rblohqc &&
      values.rblohqc.value &&
      values.rblohqc.entityType === COVERAGE_ENTITY_TYPE.OFFICES
        ? values.rblohqc.value
        : null;

    return {
      id: values.id,
      country,
      office,
      type_of_coverage: values.type_of_coverage
        ? values.type_of_coverage.value
        : "",
      phase: values.phase ? values.phase.value : null,
      status: values.status ? values.status.value : "",
      start_date:
        values.dates_range && values.dates_range.startDate
          ? moment(values.dates_range.startDate).format(DEFAULT_DATE_FORMAT)
          : null,
      end_date:
        values.dates_range && values.dates_range.endDate
          ? moment(values.dates_range.endDate).format(DEFAULT_DATE_FORMAT)
          : null
    };
  };

  render() {
    const noRowsStyles = this.props.items.length === 0 ? "no_rows_found" : "";
    return (
      <div>
        {this.props.canEdit && (
          <HelpMessageBox
            messageList={this.props.messages}
            title="Please update the following information"
          />
        )}
        {this.props.canEdit && (
          <Button
            kind="secondary"
            type="submit"
            icon={iconAddGlyph}
            iconDescription="Add a new coverage"
            onClick={() => this.getItem(null)}
            disabled={isCreate(this.props.match.params.evaluationId)}
          >
            New coverage
          </Button>
        )}
        <AddModal
          coverageTypesOptions={this.props.coverageTypesOptions}
          disabled={this.props.disabled}
          initialValues={this.props.selectedItem}
          onSubmit={() => {
            // Prevents error persistence when they have been already fixed.
            toast.dismiss();
            this.saveItem(this.props.category);
          }}
          phaseOptions={this.props.phaseOptions}
          show={this.props.showAddModal}
          statusesOptions={this.props.statusesOptions}
          title={
            this.props.selectedItem === undefined
              ? "Add Coverage"
              : "Edit Coverage"
          }
          toggleModal={this.props.toggleAddModal}
        />
        <DeleteModal
          title="Are you sure you want to delete this coverage?"
          show={this.props.showDeleteModal}
          toggleModal={this.props.toggleDeleteModal}
          onDelete={() => this.deleteItem(this.props.category)}
          danger
        />
        {this.props.isFetching ? (
          <Loading />
        ) : (
          <ReactTable
            className={`-highlight wfp-table -border -striped ${noRowsStyles}`}
            data={this.props.items}
            columns={columnsGenerator(
              this.getItem,
              this.setItemToDelete,
              this.props.canEdit
            )}
            minRows={1}
            defaultPageSize={99999} // http://codeassist.wfp.org/jira/browse/OEV-1108
            showPagination={false}
          />
        )}
      </div>
    );
  }
}

CoverageComponent.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  selectItem: PropTypes.func.isRequired,
  toggleAddModal: PropTypes.func.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
  saveItem: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  deleteItem: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedItem: PropTypes.object, // eslint-disable-line
  selectedBureau: PropTypes.number,
  selectedCountry: PropTypes.number,
  formValues: PropTypes.object, // eslint-disable-line
  canEdit: PropTypes.bool
};

CoverageComponent.defaultProps = {
  selectedCountry: null,
  selectedBureau: null,
  canEdit: false
};

export const mapStateToProps = state => {
  const { entities } = state;
  const page = state.pages.coverage;
  const form = state.form.evCoverage;

  const items = page.items
    .map(id => entities.coverage[id])
    .filter(c => !c.deleted)
    .map(item => ({
      ...item,
      office_display:
        entities.bureaux[item.office] && entities.bureaux[item.office].label
    }))
    /*
     * Allows ordering on RB/LO/HQ/Country composite field
     */
    .map(item => ({
      ...item,
      label: item.office_display || item.country_display
    }));

  /**
   * The Coverage currently being edited.
   *
   * Called `selectedItem` as a TableWithModalsMixin requirement.
   *
   * `undefined` on create.
   */
  let selectedItem = entities.coverage[page.selectedCoverage];

  if (selectedItem) {
    const phase = entities && entities.phases[selectedItem.phase];

    const status =
      (selectedItem.status && {
        value: selectedItem.status,
        label: selectedItem.status_display
      }) ||
      null;

    const typeOfCoverage =
      (selectedItem.type_of_coverage && {
        value: selectedItem.type_of_coverage,
        label: selectedItem.type_of_coverage_display
      }) ||
      null;

    let rblohqc;
    if (selectedItem.country) {
      rblohqc = {
        value: selectedItem.country,
        entityType: COVERAGE_ENTITY_TYPE.COUNTRIES,
        label: selectedItem.country_display
      };
    } else if (selectedItem.office) {
      rblohqc = {
        value: selectedItem.office,
        entityType: COVERAGE_ENTITY_TYPE.OFFICES,
        label: selectedItem.office_display
      };
    }

    selectedItem = {
      ...selectedItem,
      country: {
        value: selectedItem.country,
        label: selectedItem.country_display
      },
      dates_range: {
        startDate: selectedItem.start_date
          ? moment(selectedItem.start_date, DEFAULT_DATE_FORMAT)
          : null,
        endDate: selectedItem.end_date
          ? moment(selectedItem.end_date, DEFAULT_DATE_FORMAT)
          : null
      },
      office: {
        value: selectedItem.office,
        label: selectedItem.office_display
      },
      phase: phase
        ? {
            value: phase.id,
            label: capitalize(phase.name.toLowerCase())
          }
        : null,
      rblohqc,
      status,
      type_of_coverage: typeOfCoverage
    };
  }

  const selectedRblohqc =
    !form || !form.values
      ? get(selectedItem, "rblohqc")
      : get(form, "values.rblohqc");

  const selectedBureau =
    selectedRblohqc && selectedRblohqc.entityType === COVERAGE_ENTITY_TYPE.OFFICES
      ? selectedRblohqc.value
      : null;

  const selectedCountry =
    selectedRblohqc && selectedRblohqc.entityType === COVERAGE_ENTITY_TYPE.COUNTRIES
      ? selectedRblohqc.value
      : null;

  const coverageTypes = get(
    page,
    "options.actions.POST.type_of_coverage.choices",
    []
  );
  // Remove `N.A.` as it is deprecated and should not be offered as an option
  const coverageTypesOptions =
    coverageTypes &&
    coverageTypes
      .filter(c => c.value !== "N.A.")
      .map(c => ({ label: c.display_name, value: c.value }));

  const statuses = get(page, "options.actions.POST.status.choices", []);
  // Remove `N.A.` as it is deprecated and should not be offered as an option
  const statusesOptions =
    statuses &&
    statuses
      .filter(s => s.value !== "N.A.")
      .map(s => ({ label: s.display_name, value: s.value }));

  const messages = getMessages(state.messages.coverage);

  const phaseOptions =
    page &&
    page.phases &&
    page.phases
      .map(id => entities.phases[id])
      .filter(p => p.is_for_coverage && !p.is_deprecated)
      .map(p => ({
        value: p.id,
        label: capitalize(p.name.toLowerCase())
      }));

  return {
    coverageTypesOptions,
    disabled: page.isSubmitting || page.isFetching,
    formValues: form && form.values, // TableWithModalsMixin requirement
    isFetching: page.isFetching,
    items,
    statusesOptions,
    messages,
    phaseOptions,
    selectedBureau,
    selectedCountry,
    selectedItem,
    showAddModal: page.addModal,
    showDeleteModal: page.deleteModal
  };
};

export const mapDispatchToProps = dispatch => ({
  /** TableWithModalsMixin requirement */
  fetchItems: evaluationId =>
    dispatch(
      coverageActions.list(
        {},
        { paginated: false, pathParams: { evaluationId } }
      )
    ),
  /** TableWithModalsMixin requirement */
  fetchData: () =>
    Promise.all([
      dispatch(bureauxActions.list({}, { paginated: false })),
      dispatch(countriesActions.list({}, { paginated: false })),
      dispatch(phasesActions.list({}, { paginated: false })),
      dispatch(coverageActions.options())
    ]),

  /** TableWithModalsMixin requirement */
  selectItem: id => dispatch({ type: "SELECT_COVERAGE", id }),
  /** TableWithModalsMixin requirement */
  resetForm: () => dispatch(reset("evCoverage")),
  saveItem: (item, evaluationId, category) =>
    dispatch(coverageActions.save(item, { pathParams: { evaluationId } })).then(
      () => {
        dispatch(getEvaluationActions(category).validation(evaluationId));
        dispatch(getEvaluationActions(category).detail(evaluationId));
      }
    ),
  deleteItem: (itemId, evaluationId, category) =>
    dispatch(
      coverageActions.destroy(itemId, { pathParams: { evaluationId } })
    ).then(() =>
      dispatch(getEvaluationActions(category).validation(evaluationId))
    ),
  toggleAddModal: () =>
    Promise.all([
      dispatch(reset("evCoverage")),
      dispatch(toggle("COVERAGE_ADD_MODAL"))
    ]),
  toggleDeleteModal: () => dispatch(toggle("COVERAGE_DELETE_MODAL")),
  selectBureau: ({ value, label }) =>
    dispatch(change("evCoverage", "office", { value, label })),
  selectCountry: ({ value, label }) =>
    dispatch(change("evCoverage", "country", { value, label }))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CoverageComponent)
);
