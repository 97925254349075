import React from "react";
import PropTypes from "prop-types";
import { fieldInputPropTypes, fieldMetaPropTypes } from "redux-form";

import { TextInput as WFPTextInput } from "@wfp/ui";

import styled from "styled-components";

// Override WFP UI-Kit styles
const StyledWrapper = styled.div`
  .wfp--text-input {
    min-width: 0;

    &::placeholder {
      color: #8c9ba5;
    }
  }
`;

const TextInput = ({
  disabled,
  input,
  label,
  onChangeExtra,
  placeholder,
  invalid,
  invalidText,
  readOnly,
  tabIndex,
  type
}) => (
  <StyledWrapper>
    <WFPTextInput
      {...input}
      id={input.name}
      type={type}
      labelText={label}
      disabled={disabled}
      readOnly={readOnly}
      placeholder={readOnly ? null : placeholder}
      tabIndex={tabIndex}
      invalid={invalid}
      invalidText={invalidText}
      autoComplete="off"
      autoCorrect="off"
      spellCheck="false"
      onChange={e => {
        input.onChange(e.target.value);
        if (onChangeExtra) {
          onChangeExtra(e.target.value);
        }
      }}
    />
  </StyledWrapper>
);

TextInput.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChangeExtra: PropTypes.func,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  invalidText: PropTypes.string,
  readOnly: PropTypes.bool,
  tabIndex: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired
};

TextInput.defaultProps = {
  label: "",
  placeholder: "",
  tabIndex: undefined,
  type: "text",
  invalid: false,
  invalidText: "A valid value is required",
  disabled: false,
  readOnly: false,
  onChangeExtra: null
};

export default TextInput;
