import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { Wrapper, Link, Icon } from "@wfp/ui";
import { iconWarningSolid } from "@wfp/icons";

import { MIS_EMAIL, MIS_MAIL_TO } from "../../utils";

import Styles from "./styles.scss";

const NotAuthorized = () => (
  <Wrapper
    pageWidth="lg"
    spacing="md"
    className={`wfp--story__center wfp--story__full-height ${Styles.wrapper}`}
  >
    <Icon
      icon={iconWarningSolid}
      fill="#0a6eb4"
      width={100}
      height={100}
      style={{ marginBottom: "3rem" }}
    />
    <h1 className="wfp--story__title">Not authorized</h1>
    <p>
      Sorry, you are not authorized to access this page.
      <br />
      To request access please write to{" "}
      <Link href={MIS_MAIL_TO}>{MIS_EMAIL}</Link>
      .
      <br />
      Go back to <Link href="/">Homepage</Link>
    </p>
  </Wrapper>
);

export default withRouter(connect()(NotAuthorized));
