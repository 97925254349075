import {normalize, schema} from "normalizr";
import {toast} from "react-toastify";
import { companiesTypes } from "../types";
import {setPage} from "./pagination";
import Resource from "./resource";
import {client} from "../store";
import {mergeNoArrayNoItems} from "./entities";

const companiesSchema = new schema.Entity("companies");

export const setCompaniesPage = page => setPage(page, "companies");

class CompanyResource extends Resource {
  updateActive = (id, value) => dispatch => dispatch({
    type: "UPDATE_ACTIVE",
    payload: client
      .patch(
        `${this.path}/${id}/set_deprecation/`,
        {is_deprecated: value}
      )
      .then((response) => {
         if (response.status === 200) {
            toast.success(  `Successfully ${ value ?  'inactivated': 'activated' } the company!`, {
              autoClose: 2000,
            });

            const data = normalize({
              id,
              is_deprecated: value,
            }, this.schema);

            dispatch(mergeNoArrayNoItems(data, this.types.name));
          }
          return response;
      })
      .catch(error => {
        this.handleError(error);
      })
  });
}

export default new CompanyResource("/companies", companiesTypes, companiesSchema);
