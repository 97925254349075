import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@wfp/ui";

const DoubleButtonIcon = ({
  icon,
}) => (
  <>
    <Icon
      icon={icon}
      className="wfp--btn__icon"
      style={{
        marginLeft: '-.6ch',
        marginRight: '-2ch',
      }}
    />
    <Icon
      icon={icon}
      className="wfp--btn__icon"
      style={{
        marginRight: '-.6ch',
      }}
    />
  </>
);

DoubleButtonIcon.propTypes = {
  icon: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};

export default DoubleButtonIcon;
