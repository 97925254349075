import React from "react";

import { Icon } from "@wfp/ui";
import { iconEdit } from "@wfp/icons";

const columnsGenerator = (setItem, canEdit) => [
  {
    Header: "User",
    accessor: "display_name"
  },
  {
    Header: "Username",
    accessor: "username"
  },
  {
    Header: "Email",
    accessor: "email"
  },
  {
    Header: "Role",
    accessor: "role.label"
  },
  {
    Header: "Active",
    accessor: "is_active.label"
  },
  {
    Header: "",
    accessor: "id",
    /* eslint-disable */
    Cell: row =>
      canEdit ? (
        <Icon
          icon={iconEdit}
          width="19"
          height="19"
          description="Edit item"
          onClick={() => setItem(row.value)}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Icon
          icon={iconEdit}
          width="19"
          height="19"
          description="Edit item"
          style={{ cursor: "pointer", opacity: "0.5" }}
        />
      ),
    /* eslint-enable */
    resizable: false,
    sortable: false,
    width: 50,
    style: { justifyContent: "center" }
  }
];

export default columnsGenerator;
