import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import CRUDLayout, {CRUDMapStateToPropsPropTypes, CRUDMapDispatchToProps, CRUDMapDispatchToPropsPropTypes, CRUDMapStateToProps} from "CRUDLayout";
import partnersActions from "../../actions/partners";
import partnersCategoriesActions from '../../actions/partnersCategories';
import TableWithModalsMixin from "../../components/TableWithModalsMixin";
import PartnersModal from "./PartnersModal";
import columnsGenerator from "./tableColumns";


export class PartnersListComponent extends TableWithModalsMixin {

    componentDidMount() {
        super.componentDidMount();
        this.props.fetchPartnersCategories()
    }

    valuesAdapter = values => ({
        ...values,
        category: values.category?.value ?? null,
    });

    render = () => (
      <CRUDLayout
        {...this.props}
        title='Partners'
        entityName='partners'
        columnsGenerator={(setItem, canEdit) => columnsGenerator(setItem, canEdit, this.props.partnersCategories)}
        addModalComponent={PartnersModal}
        getItem={this.getItem}
        onSave={this.saveItem}
        handleDragEnd={this.handleDragEnd}
        extraModalParams={{
            productTypeOptions: Object.values(this.props.partnersCategories).map(item => ({value: item.id, label: item.name})),
        }}
    />
);
}

PartnersListComponent.propTypes = {
  ...CRUDMapStateToPropsPropTypes,
  ...CRUDMapDispatchToPropsPropTypes,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      isDeprecated: PropTypes.bool
    })
  ).isRequired,
  selectedItem: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    isDeprecated: PropTypes.bool
  }),
  fetchPartnersCategories: PropTypes.func.isRequired,
  partnersCategories: PropTypes.shape({}),
};

export const mapStateToProps = state => {
    const crudState = CRUDMapStateToProps(
        state,
        'partners',
        'actors.add_partner',
        'actors.change_partner'
    );

    let {selectedItem} = crudState;

    if (selectedItem) {
        const category = state.entities.partnersCategories[selectedItem.category];
        selectedItem = {
            ...selectedItem,
            category: category ? {value: category.id, label: category.name}: null,
        }
    }

    return ({
        ...crudState,
        partnersCategories: state.entities.partnersCategories,
        selectedItem,
    })
}

export const mapDispatchToProps = dispatch => ({
    ...CRUDMapDispatchToProps(dispatch, partnersActions, 'partners'),
    fetchPartnersCategories: () => dispatch(partnersCategoriesActions.list({}, {paginated: false}))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PartnersListComponent)
);
