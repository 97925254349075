import React from "react";

const ExportXslIcon = () => (
  <svg width={12} height={16} viewBox="0 0 12 16">
    <path
      d="M8 0H1C.4 0 0 .5 0 1v14c0 .6.4 1 1 1h10c.6 0 1-.4 1-1V4L8 0zm0 1.5L10.6 4 8 4.1V1.5zM1 1h6v3c0 .6.5 1 1 1h3v1.2H1V1zm10 6.2V12H1V7.2h10zM1 15v-2h10v2H1z" // eslint-disable-line
    />
    <path
      d="M2.74 11.04l.59-.96.6.96h.96L3.84 9.53l.98-1.56h-.95l-.53.95-.56-.95h-.92l.96 1.5-1.02 1.57zm4.61-.67H6.17v-2.4h-.83v3.07h2.01zm1.4.71c.25 0 .46-.04.64-.12.18-.08.32-.2.42-.34s.14-.32.14-.51c0-.21-.05-.38-.15-.52-.1-.14-.28-.26-.55-.38-.27-.13-.44-.22-.51-.26-.06-.05-.09-.1-.09-.17 0-.06.02-.1.07-.14s.13-.06.24-.06c.21 0 .46.07.74.2l.25-.64a2.44 2.44 0 0 0-.97-.22c-.35 0-.64.08-.84.24s-.31.38-.31.66c0 .15.02.28.07.39.05.11.12.21.22.29.1.09.25.17.45.26.22.1.36.17.41.2s.09.06.11.1.04.07.04.11c0 .07-.03.12-.09.17a.49.49 0 0 1-.27.06c-.14 0-.3-.02-.47-.07-.17-.04-.33-.11-.49-.19v.74a2.01 2.01 0 0 0 .94.2z" // eslint-disable-line
    />
  </svg>
);

export default ExportXslIcon;
