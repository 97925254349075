/**
 * Ideally this module wouldn't be needed:
 * all error labels should come from the backend.
 *
 * However, some frontend fields are proxies to multiple backend fields,
 * and no corresponding requirementsErrorMap entry is received from the backend.
 */

export default {
  rblohqc: {
    slug: "rblohqc",
    label: "Regional Bureau/Liaison Office/HQ/Country"
  },
  epi_framework: {
    slug: "epi_framework",
    label: "Framework"
  },
  standard_framework: {
    slug: "standard_framework",
    label: "Framework"
  },
  standards_assessment_score: {
    slug: "standards_assessment_score",
    label: "Total Score"
  }
};
