import React from "react";
import { Icon } from "@wfp/ui";
import { iconEdit } from "@wfp/icons";

const columnsGenerator = (setItem, canEdit) => [
  {
    Header: "Name",
    accessor: "name",
    sortable: false,
  },
  {
    Header: "Code",
    accessor: "code",
    sortable: false,
  },
  {
    Header: "Is for CE",
    accessor: "isForCe",
    Cell: row => (<input type="checkbox" checked={row.value} disabled />),
    sortable: false,
  },
  {
    Header: "Is for DE",
    accessor: "isForDe",
    Cell: row => (<input type="checkbox" checked={row.value} disabled />),
    sortable: false,
  },
  {
    Header: "CE Deprecated",
    accessor: "isCeDeprecated",
    Cell: row => (<input type="checkbox" checked={row.value} disabled />),
    sortable: false,
  },
  {
    Header: "DE Deprecated",
    accessor: "isDeDeprecated",
    Cell: row => (<input type="checkbox" checked={row.value} disabled />),
    sortable: false,
  },
  {
    Header: "",
    accessor: "id",
    /* eslint-disable */
    Cell: row =>
      canEdit ? (
        <Icon
          icon={iconEdit}
          width="19"
          height="19"
          description="Edit item"
          onClick={() => setItem(row.value)}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Icon
          icon={iconEdit}
          width="19"
          height="19"
          description="Edit item"
          style={{ cursor: "pointer", opacity: "0.5" }}
        />
      ),
    /* eslint-enable */
    resizable: false,
    sortable: false,
    width: 50,
    style: { justifyContent: "center" },
  }
];

export default columnsGenerator;
