import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "../styles.scss";

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
const Delete = ({ onClick }) => (
  <span
    onClick={onClick}
    style={{
      paddingTop: "0px",
      marginRight: "10px",
      color: "red",
      cursor: "pointer"
    }}
  >
    x
  </span>
);
Delete.propTypes = {
  onClick: PropTypes.func
};
Delete.defaultProps = {
  onClick: undefined
};

const Undelete = ({ onClick }) => (
  <span
    onClick={onClick}
    style={{
      paddingTop: "0px",
      marginRight: "10px",
      color: "green",
      cursor: "pointer"
    }}
  >
    v
  </span>
);
Undelete.propTypes = {
  onClick: PropTypes.func
};
Undelete.defaultProps = {
  onClick: undefined
};

const Deviation = ({ disabled, onClick }) =>
  disabled ? <Undelete onClick={onClick} /> : <Delete onClick={onClick} />;
Deviation.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};
Deviation.defaultProps = {
  onClick: undefined
};

const StatusField = props => {
  const selectedStatusStyles = styles.selectedStatus;
  const onClick = () => {
    let input;
    switch (props.fieldName) {
      case "activity_category":
      case "kind":
      case "topics":
        input = document.querySelector(`[id="${props.fieldName}-infoform"]`);
        break;
      case "request_by_donors":
        input = document.querySelector(`[id="request-by-donors-infoform"]`);
        break;
      case "funded_by_donors":
        input = document.querySelector(`[id="funded-by-donors-infoform"]`);
        break;
      case "crosscutting_priorities":
        input = document.querySelector(`[id="crosscutting-priorities-infoform"]`);
        break;
      case "chair_committee_onetoone":
        input = document.querySelector(`[id="chair-committee-infoform"]`);
        break;
      case "commissioning":
        input = document.querySelector(
          '[name="commissioning_office_division"]'
        );
        break;
      case "manager_wfp_onetoone":
        input = document.querySelector(`[id="manager-wfp-infoform"]`);
        break;
      case "research_analyst_wfp_onetoone":
        input = document.querySelector(`[id="research-analyst-wfp-infoform"]`);
        break;
      case "final_approver_onetoone":
        input = document.querySelector(`[id="final-approver-infoform"]`);
        break;
      case "eb_session_migrated":
        input = document.querySelector('[name="eb_session"]');
        break;
      case "sdgs":
        input = document.querySelector(`[id="sdg-infoform"]`);
        break;
      default:
        input = document.querySelector(`[name="${props.fieldName}"]`);
        break;
    }
    if (input) input.focus();
  };
  const component = props.errors ? (
    <li className={styles.singleStatus}>
      {props.editable && (
        <Deviation disabled={props.disabled} onClick={props.onFieldEdit} />
      )}
      <span
        className={`${props.selected ? selectedStatusStyles : ""} ${
          props.disabled ? styles.disabledStatus : ""
        }`}
        onClick={props.editable ? undefined : onClick}
        style={{ cursor: props.editable ? "" : "pointer" }}
      >
        {props.label}
      </span>
    </li>
  ) : (
    <li className={`${styles.active || ""} ${styles.singleStatus}`}>
      {props.editable && (
        <Deviation disabled={props.disabled} onClick={props.onFieldEdit} />
      )}
      <span
        className={`
        ${props.animate ? styles.active : ""}
        ${props.selected ? selectedStatusStyles : ""}
        ${props.disabled ? styles.disabledStatus : ""}`}
        style={{ fontWeight: "600", cursor: props.editable ? "" : "pointer" }}
        onClick={props.editable ? undefined : onClick}
      >
        {props.label}
      </span>
    </li>
  );
  return props.withUrl && !props.editable ? (
    <Link to={props.to}>{component}</Link>
  ) : (
    component
  );
};
/* eslint-enable */

StatusField.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  errors: PropTypes.bool,
  selected: PropTypes.bool,
  animate: PropTypes.bool,
  withUrl: PropTypes.bool,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onFieldEdit: PropTypes.func.isRequired
};

StatusField.defaultProps = {
  errors: false,
  to: "",
  animate: false,
  selected: false,
  withUrl: false
};

export default StatusField;
