import { combineReducers } from "redux";
import {
  fetching,
  submitting,
  items,
  togglable,
  selectable,
} from "./resource";

export default combineReducers({
  selectedItem: selectable("donors"),
  isFetching: fetching("donors"),
  isSubmitting: submitting("donors"),
  items: items("donors"),
  showAddModal: togglable("addModal"),
});
