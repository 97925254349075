import { combineReducers } from "redux";
import {
  fetching,
  submitting,
  options,
  items,
  togglable,
  pagination,
  selectable
} from "./resource";

export default combineReducers({
  selectedSDG: selectable("sdg"),
  isFetching: fetching("sustainableDevelopmentGoals"),
  isSubmitting: submitting("sustainableDevelopmentGoals"),
  options: options("sustainableDevelopmentGoals"),
  items: items("sustainableDevelopmentGoals"),
  addModal: togglable("SDG_ADD_MODAL"),
  deleteModal: togglable("SDG_DELETE_MODAL"),
  page: pagination("sustainableDevelopmentGoals")
});
