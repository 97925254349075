import React from "react";
import {camelCase} from "lodash";
import UserLabel from "../../../../../../components/UserLabel";


/**
 * Wraps `promise` with a Promise that always resolves:
 * both on the wrapped promise's resolution _and_ its rejection.
 *
 * It resolves with `{result: promise.result}` or `{error: promise.error}`
 *
 * We use this workaround because `Promise.allSettled` is not yet widely available.
 */
export const withResolveOnSettle = promise =>
  new Promise(resolve =>
    promise
      .then(result => resolve({result}))
      .catch(error => resolve({error}))
  );

export const emptyStringToNull = (map, obj) => ({
    ...map,
    [obj[0]]: obj[1] === "" ? null : obj[1]
});

export const userFormValueFactory = (evaluation, entities, field) => {
    let WFPUser = null;
    if (evaluation[field]?.email) {
        WFPUser = {
            label: <UserLabel {...evaluation[field]} />,
            value: evaluation[field].email
        }
    }

    return WFPUser;
}

export const getHelpTexts = (object) => {
    if (typeof object !== 'object') {
        return {}
    }

    return Object.keys(object).reduce((texts, key) => {
        if (typeof object[key] !== 'object' || !('help_text' in object[key])) {
            return texts;
        }

        return {
            ...texts,
            [camelCase(key)]: object[key].help_text,
        }
    }, {})
}