import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import Select from "Select";

import ModalForm from "ModalForm";

const CountryInfoModal = props => (
  <ModalForm
    title={props.title}
    isVisible={props.show}
    onClose={props.toggleModal}
    onSubmit={e => {
      e.preventDefault();
      props.onSubmit();
    }}
    onFormSubmit={e => {
      e.preventDefault();
      props.onSubmit();
    }}
    buttonDisabled={props.disabled}
    hiddenButtonDisabled={props.disabled}
  >
    <Field
      name="country"
      label="Country"
      component={Select}
      options={props.countries}
      closeMenuOnSelect
      openMenuOnClick
      readOnly
    />
    <Field
      name="development_status"
      label="Development Status"
      component={Select}
      options={props.developmentStatusOptions}
      closeMenuOnSelect
      openMenuOnClick
    />
    <Field
      name="region"
      label="Region"
      component={Select}
      options={props.regionOptions}
      closeMenuOnSelect
      openMenuOnClick
    />
  </ModalForm>
);

CountryInfoModal.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({ id: PropTypes.any }), // eslint-disable-line
  options: PropTypes.object, // eslint-disable-line
  disabled: PropTypes.bool,

  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ),

  developmentStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  regionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  )
};

CountryInfoModal.defaultProps = {
  show: false,
  countries: [],
  developmentStatusOptions: [],
  regionOptions: [],
  initialValues: {},
  disabled: false
};

export default reduxForm({
  form: "countryInfo",
  enableReinitialize: true
})(CountryInfoModal);
