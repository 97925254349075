import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import {
  SecondaryNavigation as WFPSecondaryNavigation,
  SecondaryNavigationTitle
} from "@wfp/ui";
import styled from "styled-components";

import Breadcrumbs from "Breadcrumb";
import Tabs from "Tabs";

import Styles from "./styles.scss";

/** A "dumb" component used to render arbitrary content to SecondaryNavigation
 *
 * You must ensure that your component renders at least once _after_
 * SecondaryNavigation's first render, otherwise the React portal won't work.
 *
 * Ensure that however you will. Rerendering after a fetch is probably enough.
 */
export const SecondaryNavigationActions = ({ children }) => {
  const target = document.getElementById("secondary-nav-actions");

  if (!target) {
    return null;
  }

  return ReactDOM.createPortal(<>{children}</>, target);
};

/**
 * SecondaryNavigation shows the page info (title and subtitle) and breadcrumbs.
 *
 * Optional features:
 * - Tabs for navigation
 * - You can use the `SecondaryNavigationActions` component to populate its
 *   `secondary-nav-actions` <div> with arbitrary children
 *   from anywhere in the component tree.
 */
const SecondaryNavigation = props => {
  let SecondaryNavigationComponent = SecondaryNavigation;

  // Use styled-component syntax to make SecondaryNavigation full width
  SecondaryNavigationComponent = styled(WFPSecondaryNavigation)`
    & .wfp--secondary-navigation__list {
      width: 100%;
    }
  `;

  return (
    <>
      <SecondaryNavigationComponent
        pageWidth="full"
        additional={props.additional}
      >
        <div className={Styles.mainContent}>
          <div>
            <Breadcrumbs />
            <SecondaryNavigationTitle>
              {props.title}
              <p className={Styles.subtitle}>{props.subtitle}</p>
            </SecondaryNavigationTitle>
          </div>
          <div id="secondary-nav-actions" className={Styles.actions} />
        </div>
        {props.showTabs && <Tabs items={props.navItems} />}
        {props.children}
      </SecondaryNavigationComponent>
    </>
  );
};

SecondaryNavigation.propTypes = {
  /** Additional content placed on the right (e.g.: buttons) */
  additional: PropTypes.node,
  /** Page title */
  title: PropTypes.string.isRequired,
  /** Page subtitle */
  subtitle: PropTypes.node,
  /** Whether show tabs navigation */
  showTabs: PropTypes.bool,
  /** Custom elements included */
  children: PropTypes.node,
  /** Tabs to show */
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      /** Tab label */
      label: PropTypes.string,
      /** Tab url */
      url: PropTypes.string
    })
  )
};

SecondaryNavigation.defaultProps = {
  additional: undefined,
  subtitle: undefined,
  showTabs: false,
  navItems: [],
  children: undefined
};

export default SecondaryNavigation;
