import { schema } from "normalizr";
import { contractTypesTypes } from "../types";
import Resource from "./resource";

const contractTypesSchema = new schema.Entity("contractTypes");

export default new Resource(
  "/contract_types",
  contractTypesTypes,
  contractTypesSchema
);
