import {Field} from "redux-form";
import React from "react";
import { Grid, Row, Col } from "Grid";
import TextInput from "TextInput";
import PropTypes from "prop-types";
import Tooltip from "Tooltip";
import styles from "./styles.scss";

const Label = ({ input, criteriaDescriptions }) => {
  // Split in parts in order to visually group the tooltip icon together with
  // the text
  let firstPart = input.value;
  let secondPart = "";

  if (input.value.includes("&")) {
    const charIndex = input.value.indexOf("&");
    firstPart = input.value.slice(0, charIndex);
    secondPart = input.value.slice(charIndex);
  }
  return (
    <div className={styles.criterion}>
      <p className={styles.criterionFirstPart}>{firstPart}</p>
      {!input.value.includes("&") && criteriaDescriptions && (
        <Tooltip message={criteriaDescriptions} />
      )}
      {input.value.includes("&") && (
        <div className={styles.criterionSecondPart}>
          <span>{secondPart}</span>
          {criteriaDescriptions && <Tooltip message={criteriaDescriptions} />}
        </div>
      )}
    </div>
  );
};

Label.propTypes = {
  input: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  /**
   * This prop is marked as required but comes from the backend and is
   * unavailable on the first few renderings, so it produces warnings. For now
   * we should ignore them.
   * A possible solution is to remove the requirement since a subset of this
   * component's functionality is available regardless.
   */
  criteriaDescriptions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string
    })
  ).isRequired,
  index: PropTypes.number.isRequired
};

export const CriterionRow = ({item, itemName, disableLabels, formName, readOnly}) => (
  <div className="form-row">
    <Grid fluid>
      <Row>
        <Col xs={5}>
          <span className={styles.criterionHeading}>
            {disableLabels ? false : "Criterion Name"}
          </span>
          <div className={styles.criteriaColumn}>
            <Field
              name={`${itemName}.criterion.name`}
              label={disableLabels ? false : "Criterion Name"}
              formName={formName}
              readOnly
              criteriaDescriptions={item.description}
              component={Label}
            />
          </div>
        </Col>
        <Col xs={1} fixedWidth="98px">
          <div>
            <Field
              name={`${itemName}.score`}
              label={disableLabels ? false : "Score"}
              formName={formName}
              component={TextInput}
              readOnly={readOnly}
            />
          </div>
        </Col>
        <Col xs={4}>
          <div>
            <Field
              name={`${itemName}.rating`}
              label={disableLabels ? false : "Rating"}
              formName={formName}
              readOnly
              component={TextInput}
              tabIndex="-1"
            />
          </div>
        </Col>
      </Row>
    </Grid>
  </div>
)