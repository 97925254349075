import React, {useState} from "react";
import {useDispatch} from "react-redux";
import { change } from "redux-form";
import PropTypes from "prop-types";
import DeleteModal from "Modal/DeleteModal";
import {FrameworkSelectForm} from "./FrameworkSelectForm";
import {transformFramework} from "../utils";

export const Framework = ({formName, frameworks, initialFramework, readOnly, isScoresFilled, onChange}) => {
    const fieldName = 'framework';
    const [showDeleteModal, setShowDeleteModal] = useState();
    const [selectedFramework, setSelectedFramework] = useState();
    const dispatch = useDispatch();

    const onSave = (framework) => {
        dispatch(change(formName, fieldName, framework));
        onChange(framework);
    }

    return (
      <div>
        <FrameworkSelectForm
          form={formName}
          fieldName={fieldName}
          options={Object.values(frameworks ?? {}).map(transformFramework)}
          initialValues={{
                    [fieldName]: transformFramework(initialFramework)
                }}
          readOnly={readOnly}
          onUncontrolledChange={(selectedValue) => {
                    setSelectedFramework(selectedValue);
                    if (isScoresFilled) {
                        setShowDeleteModal(true)
                    } else {
                        onSave(selectedValue);
                    }
                }}
            />
        <DeleteModal
          confirmLabel="OK"
          cancelLabel="Cancel"
          message="The scores of the current framework will be deleted. Click OK if you want to continue or Cancel if not."
          show={showDeleteModal}
          toggleModal={() => {
                    setShowDeleteModal(false);
                }}
          onDelete={() => {
                    onSave(selectedFramework);
                    setShowDeleteModal(false);
                }}
            />
      </div>
    )
}


Framework.propTypes = {
  formName: PropTypes.string.isRequired,
  frameworks: PropTypes.shape({}).isRequired,
  initialFramework: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool.isRequired,
  isScoresFilled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  hide: PropTypes.bool,
};

Framework.defaultProps = {
    hide: false,
};