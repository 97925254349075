import Select from "Select";
import {Field} from "redux-form";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Styles from "../../styles.scss";
import {adaptInputValues} from "../../utils";
import {advancedFilterActions} from "../../../../actions/filters";
import {getEvaluationActions} from "../../../../actions/evaluations";


const StartYears = ({label, fieldName, isDisabled}) => {
  const dispatch = useDispatch();
  const {filtersData} = useSelector(state => state.pages.evaluations);

  // eslint-disable-next-line camelcase
  const maxStartYear = filtersData?.start_years?.max ?? null;
  // eslint-disable-next-line camelcase
  const minStartYear = filtersData?.start_years?.min ?? null;

 const startYearsOptions = [];
  if (maxStartYear !== null && minStartYear !== null) {
    for (let i = maxStartYear; i >= minStartYear; i -= 1) {
      startYearsOptions.push({ label: i.toString(), value: i.toString() });
    }
  }

  const onChange = inputs => {
    if (inputs !== null && inputs.length > 0) {
      dispatch(advancedFilterActions.addFilter(fieldName, inputs, {
        start_years: adaptInputValues(inputs)
      }));
    } else {
      dispatch(advancedFilterActions.removeFilter(fieldName));
    }
  };

  useEffect(() => {
     dispatch(getEvaluationActions().filtersData());
  }, [])

  return (
    <Field
      name={fieldName}
      label={label}
      component={Select}
      options={startYearsOptions}
      onChangeExtra={onChange}
      openMenuOnClick
      cssClass={Styles.noPaddingWithSpace}
      disabled={isDisabled}
      allowMultiple
    />
  )
}

export default StartYears;