import {schema} from "normalizr";
import {toast} from "react-toastify";
import {genderTypes} from "../types";
import Resource from "./resource";
import {client} from "../store";
import {getEvaluationActions} from "./evaluations";
import {COLOR_SUCCESS_TOAST} from "../utils/constants";

const genderSchema = new schema.Entity("gender");

class GenderAction extends Resource {
    fetchTotalScore = (evaluationId) => (dispatch) => {
        return dispatch({
            type: "FETCH_GENDER_TOTAL_SCORE",
            payload: client
                .get(`/evaluations/${evaluationId}/epirating/`)
                .then(res => dispatch({type: "SET_TOTAL_SCORE", data: {
                    totalScore: res.data.score, totalRating: res.data.rating,
                }}))
                .catch(error => {
                    this.handleError(error);
                })
        });
    }

    batchGenderSave = (evaluationId, genders, category) => (dispatch) => {
        Promise.all(
            genders
                .filter(g => ![null, undefined, ""].includes(g.score) || g.id)
                .map(g => {
                    if (g.id && [null, undefined, ""].includes(g.score)) {
                        return dispatch(
                            this.destroy(g.id, {
                                notifySuccess: false,
                                pathParams: {evaluationId}
                            })
                        );
                    }
                    return dispatch(
                        this.save(g, {
                            notifySuccess: false,
                            pathParams: {evaluationId}
                        })
                    );
                })
        )
            .then(() =>
                Promise.all([
                    dispatch(getEvaluationActions(category).validation(evaluationId)),
                    dispatch(this.list({}, {pathParams: {evaluationId}, paginated: false})),
                    dispatch(this.fetchTotalScore(evaluationId)),
                    dispatch(getEvaluationActions(category).detail(evaluationId)),
                ])
            )
            .then(() => {
                toast.success("Gender data updated!", {
                    autoClose: 1000,
                    className: {background: COLOR_SUCCESS_TOAST}
                });
                setTimeout(() => toast.dismiss(), 1500);
            })
    }
}

export default new GenderAction(
    "/evaluations/<evaluationId>/epis",
    genderTypes,
    genderSchema
);
