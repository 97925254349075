import { schema } from "normalizr";
import { milestonesTypes, deMilestonesTypes } from "../types";
import Resource from "./resource";
import {client} from "../store";

const milestonesSchema = new schema.Entity("milestones");
const deMilestonesSchema = new schema.Entity("deMilestones");

export const deMilestonesActions = new Resource(
  "/evaluations/<evaluationId>/milestones",
  deMilestonesTypes,
  deMilestonesSchema
);

export const milestonesActions = new Resource(
  "/milestones",
  milestonesTypes,
  milestonesSchema
);

client.enableAutoCamelCase('/milestones');

