import React from "react";
import PropTypes from "prop-types";
import { Toggle as BaseToggle } from "@wfp/ui";
import styled from "styled-components";

// Override WFP UI-Kit styles
const StyledWrapper = styled.div`
  .wfp--toggle__appearance {
      width: 2.5rem;
  }
  .wfp--toggle__appearance:before {
    height: 1.25rem;
  }
  .wfp--toggle__appearance:after {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const Toggle = (props) => {
  return (
    <StyledWrapper>
      <BaseToggle
        {...props}
        defaultToggled={props.value}
      />
    </StyledWrapper>
  );
};

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  onToggle: PropTypes.func,
  labelA: PropTypes.string,
  labelB: PropTypes.string,
};
Toggle.defaultProps = {
  disabled: false,
  value: false,
  labelA: '',
  labelB: '',
  onToggle: function onToggle() {}
};
export default Toggle;