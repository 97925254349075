import React from "react";

import { Icon } from "@wfp/ui";
import { iconEdit, iconDelete } from "@wfp/icons";

/** format numbers to english format */
export const formatNumber = value =>
  value ? Number(value).toLocaleString("en", { minimumFractionDigits: 2 }) : "";

/** return a list of country names starting from a list of codes */
const getCountriesFromCodes = (codes, countries) => {
  const result = [];
  codes.map(code => {
    let country = null;
    country = countries.find(c => c.value === code);
    if (country) {
      result.push(country.label);
    }
    return null;
  });
  return result;
};

const columnsGenerator = (setMember, setMemberToDelete, canEdit, countries, isSimplifiedVersion) => {
  let columns = [
    {
      Header: "Name",
      accessor: "person_display"
    },
    {
      Header: "Sex",
      accessor: "person_sex",
      maxWidth: 100
    },
    {
      Header: "Nationality",
      accessor: "person_nationality",
      Cell: field => (
        <span>
          {(getCountriesFromCodes(field.value, countries) || []).join(", ")}
        </span>
      )
    },
    {
      Header: "Development Status",
      accessor: "person_development_status_display"
    },
    {
      id: 'teamRole',
      Header: "Role",
      accessor: "teamRole",
      Cell: ({value }) => value?.name,
      sortMethod: (a, b) => a?.position > b?.position ? 1 : -1,
    },
  ]
  if (isSimplifiedVersion) {
      columns = [
          ...columns,
          {
            id: 'partner',
            Header: "Organization",
            accessor: "partner",
            Cell: ({value }) => value?.name,
            sortMethod: (a, b) => a?.position > b?.position ? 1 : -1,
            // Last column must not be resizable
            resizable: canEdit
          }
      ];
  } else {
    columns = [
      ...columns,
      {
        Header: "Contract",
        accessor: "contract_type_display"
      },
      {
        Header: "Company",
        accessor: "company_display"
      },
      {
        Header: "Days",
        accessor: "days",
        maxWidth: 100
      },
      {
        Header: "Daily Rate",
        accessor: "daily_rate",
        Cell: field => formatNumber(field.value)
      },
      {
        Header: "Total Fees",
        accessor: "total",
        Cell: field => formatNumber(field.value),
        // Last column must not be resizable
        resizable: canEdit
      }
    ]
  }

  return [
    ...columns,
    ...(!canEdit
        ? []
        : [
          {
            Header: "",
            accessor: "id",
            /* eslint-disable */
            Cell: field => (
                <Icon
                    icon={iconEdit}
                    width="19"
                    height="19"
                    description="Edit item"
                    onClick={canEdit ? () => setMember(field.value) : undefined}
                    style={
                      canEdit
                          ? { cursor: "pointer" }
                          : { opacity: "0.5", cursor: "pointer" }
                    }
                />
            ),
            /* eslint-enable */
            resizable: false,
            sortable: false,
            width: 50,
            style: { justifyContent: "center" }
          },
          {
            Header: "",
            accessor: "id",
            /* eslint-disable */
            Cell: field => (
                <Icon
                    icon={iconDelete}
                    width="19"
                    height="19"
                    description="Delete item"
                    onClick={
                      canEdit ? () => setMemberToDelete(field.value) : undefined
                    }
                    style={
                      canEdit
                          ? { cursor: "pointer" }
                          : { opacity: "0.5", cursor: "pointer" }
                    }
                />
            ),
            /* eslint-enable */
            resizable: false,
            sortable: false,
            width: 50,
            style: { justifyContent: "center" }
          }
        ]
    )
  ]
}

export default columnsGenerator;
