/** Reducer keeping track of whether resource is currently fetching */
const fetching = resource => {
  const name = resource.toUpperCase();
  return (state = false, action) => {
    switch (action.type) {
      case `${name}_LIST_PENDING`:
      case `${name}_SHOW_FETCHING`:
      case `${name}_DETAIL_PENDING`:
        return true;

      case `${name}_LIST_REJECTED`:
      case `${name}_HIDE_FETCHING`:
      case `${name}_LIST_FULFILLED`:
      case `${name}_DETAIL_REJECTED`:
      case `${name}_DETAIL_FULFILLED`:
        return false;

      default:
        return state;
    }
  };
};

/** Reducer keeping track of whether resource is currently deleting */
const deleting = resource => {
  const name = resource.toUpperCase();
  return (state = false, action) => {
    switch (action.type) {
      case `${name}_DELETE_PENDING`:
        return true;

      case `${name}_DELETE_REJECTED`:
      case `${name}_DELETE_FULFILLED`:
        return false;

      default:
        return state;
    }
  };
};

/** Reducer keeping track of whether resource is currently submitting */
const submitting = resource => {
  const name = resource.toUpperCase();
  return (state = false, action) => {
    switch (action.type) {
      case `${name}_CREATE_PENDING`:
      case `${name}_UPDATE_PENDING`:
        return true;

      case `${name}_CREATE_FULFILLED`:
      case `${name}_UPDATE_FULFILLED`:
      case `${name}_CREATE_REJECTED`:
      case `${name}_UPDATE_REJECTED`:
        return false;

      default:
        return state;
    }
  };
};

/** Reducer keeping track of all items returned by (potentially multiple) queries */
const items = (resource, clearPending = true) => (state = [], action) => {
  if (
    ["MERGE_ITEMS", "MERGE_ITEMS_ENTITIES", "MERGE_ENTITIES_NOARRAY", "SET_ITEMS"].includes(
      action.type
    ) &&
    action.resource === resource
  ) {
    if (!Array.isArray(action.data.result)) {
      return [action.data.result];
    }
    return action.data.result;
  }

  if (action.type === "MERGE_ITEMS_NOARRAY" && action.resource === resource) {
    return action.data.result;
  }

  if (action.type === `${resource.toUpperCase()}_LIST_PENDING` && clearPending)
    return [];

  if (action.type === "APPEND_ITEMS" && action.resource === resource) {
    if (!state.includes(action.data.result)) {
      if (!Array.isArray(action.data.result)) {
        return [...new Set([...state, action.data.result])];
      }
      return [...new Set([...state, ...action.data.result])];
    }
    return state || [];
  }

  if (action.type === "CLEAR_ITEMS" && action.resource === resource) {
    return [];
  }

  return state || [];
};

/** Reducer keeping track of options for the given resource */
const options = (resource, detail = false) => {
  const name = resource.toUpperCase();
  return (state = {}, action) => {
    if (detail && action.type === `${name}_DETAIL_OPTIONS_FULFILLED`) {
      return action.payload.data;
    }
    if (!detail && action.type === `${name}_OPTIONS_FULFILLED`) {
      return action.payload.data;
    }
    return state;
  };
};

/** Reducer keeping track of resource's current page */
const pagination = resource => (state = 0, action) => {
  if (action.type === `${resource.toUpperCase()}_SET_PAGE`) {
    return action.page;
  }
  return state;
};

const pageSize = resource => (state = 10, action) => {
  if (action.type === `${resource.toUpperCase()}_SET_PAGE_SIZE`) {
    return action.payload;
  }
  return state;
};

/**
 * Reducer keeping track of shown columns for the given resource
 *
 * Also keeps track of whether the columns filter is being shown
 */
const listColumns = resource => (
  state = { showList: false, visibleColumns: [] },
  action
) => {
  const res = resource.toUpperCase();

  switch (action.type) {
    case `${res}_TOGGLE_COLS_LIST`:
      return {
        ...state,
        showList: !state.showList
      };
    case `${res}_HIDE_COLS_LIST`:
    case "HIDE_ALL_TOGGLABLES":
      return {
        ...state,
        showList: false
      };
    case `${res}_SHOW_COLS_LIST`:
      return {
        ...state,
        showList: true
      };
    case `${res}_SET_VISIBLE`:
      return { ...state, visibleColumns: action.visible };

    default:
      return state;
  }
};

/**
 * Reducer keeping track of whether `name`d togglable is _shown_
 *
 * TODO: did we mistakenly use this for checkboxes? Substitute with `booleanChoose`
 */
const togglable = (name, defaultVal = false) => (
  state = defaultVal,
  action
) => {
  switch (action.type) {
    case `TOGGLE_${name.toUpperCase()}`:
      return !state;
    case `SHOW_${name.toUpperCase()}`:
      return true;
    case `HIDE_${name.toUpperCase()}`:
    case "HIDE_ALL_TOGGLABLES":
      return false;
    default:
      return state;
  }
};

/** Reducer keeping track of whether `name`d thing is shown */
const showHide = (name, defaultVal = false) => (state = defaultVal, action) => {
  switch (action.type) {
    case `SHOW_${name.toUpperCase()}`:
      return true;
    case `HIDE_${name.toUpperCase()}`:
      return false;
    default:
      return state;
  }
};

/**
 * Reducer keeping track of whether `name`d thing is true
 *
 * Its initial value is argument `defaultVal`.
 */
const booleanChoose = (name, defaultVal = false) => (
  state = defaultVal,
  action
) => {
  switch (action.type) {
    case `SET_TRUE_${name.toUpperCase()}`:
      return true;
    case `SET_FALSE_${name.toUpperCase()}`:
      return false;
    default:
      return state;
  }
};

/** Reducer keeping track of which `name`d thing is currently selected */
const selectable = (name, field = "id") => (state = null, action) =>
  action.type === `SELECT_${name.toUpperCase()}` ? action[field] : state;

/**
 * Reducer returning statuses set with SET_STATUSES action
 *
 * TODO: this doesn't belong to resource.js, move it somewhere meaningful
 */
const statuses = (state = [], action) => {
  if (action.type === "SET_STATUSES") {
    return action.data;
  }
  return state;
};

export {
  deleting,
  fetching,
  items,
  listColumns,
  options,
  pagination,
  pageSize,
  selectable,
  statuses,
  submitting,
  togglable,
  showHide,
  booleanChoose
};
