import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import { RadioButtonGroup } from "@wfp/ui";

import { Grid, Row, Col } from "Grid";
import SingleDatePickerField from "DatePicker/SingleDatePicker";
import Loading from "Loading";
import RadioButton from "RadioButton";
import Select from "Select";

import Textarea from "Textarea";
import TextInput from "TextInput";
import TextInputNumber from "TextInputNumber";
import TextInputWithSearch from "TextInputWithSearch";

import {Categories} from "enums";
import styles from "./styles.scss";
import {AGWUserSelect} from "./components";

const formName = "evInfo";

const InfoForm = props => {
  if (props.isLoading) {
    return <Loading />;
  }
  
  return (
    <div className="InfoForm wfp--form-long">
      <div className={styles.tabWrapper}>
        <form className="wfp-form relative">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Field
                  name="commissioning_office_division"
                  readOnly={
                          props.isFieldReadOnly("commissioning_office") ||
                          props.isFieldReadOnly("commissioning_division")
                      }
                  disabled={
                          props.isFieldDisabled("commissioning_office") ||
                          props.isFieldDisabled("commissioning_division")
                      }
                  component={TextInputWithSearch}
                  formName={formName}
                  getDisplay={item => `${item.name} - ${item.code}`}
                  getOtherDisplay={item => `${item.name} - ${item.code}`}
                  label="Commissioning Office or Division"
                  optionsLabel="Offices"
                  optionsField="commissioning_office"
                  otherOptionsLabel="Divisions"
                  otherOptionsField="commissioning_division"
                  fetchItems={props.fetchOffices}
                  fetchOtherItems={props.fetchDivisions}
                  extraFieldsToClear={["commissioning_office_division"]}
                  showCode
                  minChars={2}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Field
                  name="title"
                  readOnly={props.isFieldReadOnly("title")}
                  disabled={props.isFieldDisabled("title")}
                  label="Title"
                  component={TextInput}
                  />
              </Col>
            </Row>
            <Row start="xs">
              <Col md={4}>
                <Field
                  name="kind"
                  label="Type"
                  options={props.stTypes}
                  component={Select}
                  inputId="kind-infoform"
                  readOnly={props.isFieldReadOnly("kind")}
                  disabled={props.isFieldDisabled("kind")}
                  openMenuOnClick
                  />
              </Col>
              <Col md={3} maxWidth="160px">
                <Field
                  name="exercise"
                  label="Exercise"
                  options={props.exerciseOptions}
                  component={Select}
                  inputId="exercise-infoform"
                  readOnly={props.isFieldReadOnly("exercise")}
                  disabled={props.isFieldDisabled("exercise")}
                  openMenuOnClick
                  />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  label="WFP Evaluation Manager (focal point)"
                  name="manager_wfp_onetoone"
                  inputId="manager-wfp-infoform"
                  readOnly={props.isFieldReadOnly("manager_wfp_onetoone")}
                  disabled={props.isFieldDisabled("manager_wfp_onetoone")}
                  component={AGWUserSelect}
                  category={Categories.DECENTRALIZED}
                  />
              </Col>
              <Col md={6}>
                <Field
                  label="Chair Evaluation Committee"
                  name="chair_committee_onetoone"
                  inputId="chair-committee-infoform"
                  readOnly={props.isFieldReadOnly("chair_committee_onetoone")}
                  disabled={props.isFieldDisabled("chair_committee_onetoone")}
                  component={AGWUserSelect}
                  category={Categories.DECENTRALIZED}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  name="manager_external"
                  label="External Evaluation Manager"
                  component={TextInput}
                  readOnly={props.isFieldReadOnly("manager_external")}
                  disabled={props.isFieldDisabled("manager_external")}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={3} maxWidth="225px">
                <Field
                  name="start_date"
                  index="start_date"
                  label="Start"
                  readOnly
                  disabled={props.isFieldDisabled("start_date")}
                  component={SingleDatePickerField}
                  isTestMode={props.isTestMode}
                  helpText={"Start of preparation phase"}
                />
              </Col>
              <Col md={3} maxWidth="225px">
                <Field
                  name="planned_completed_date"
                  index="planned_completed_date"
                  label="Planned Completion Date"
                  readOnly={props.isFieldReadOnly("planned_completed_date")}
                  disabled={props.isFieldDisabled("planned_completed_date")}
                  component={SingleDatePickerField}
                  setDatesRange={props.setDatesRange}
                  initialVisibleMonth={props.setInitialVisibleMonth}
                  isTestMode={props.isTestMode}
                  helpText={props.helpTexts.plannedCompletedDate}
                  />
              </Col>
              <Col md={3} maxWidth="225px">
                <Field
                  name="approval_date"
                  index="approval_date"
                  label="Evaluation Report Approval"
                  component={SingleDatePickerField}
                  disabled={props.isFieldDisabled("approval_date")}
                  setDatesRange={props.setDatesRange}
                  initialVisibleMonth={props.setInitialVisibleMonth}
                  isTestMode={props.isTestMode}
                  readOnly
                  helpText={props.helpTexts.approvalDate}
                  withPortal={false}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <RadioButtonGroup
                  name="joint"
                  labelText="Joint"
                  controlled={false}
                  >
                  <Field
                    component={RadioButton}
                    readOnly={props.isFieldReadOnly("is_joint")}
                    disabled={props.isFieldDisabled("is_joint")}
                    name="is_joint"
                    id="is_joint_1"
                    inputId="is_joint"
                    value="is_joint_1"
                    label="Yes"
                    />
                  <Field
                    component={RadioButton}
                    readOnly={props.isFieldReadOnly("is_joint")}
                    disabled={props.isFieldDisabled("is_joint")}
                    name="is_joint"
                    id="is_joint_0"
                    inputId="is_joint"
                    value="is_joint_0"
                    label="No"
                    />
                </RadioButtonGroup>
              </Col>
              <Col md={6}>
                <Field
                  name="joint_partners"
                  inputId="joint_partners"
                  testId="joint_partners"
                  label="Joint Partners"
                  options={props.jointPartnersOptions}
                  component={Select}
                  readOnly={props.isFieldReadOnly("joint_partners")}
                  disabled={props.isFieldDisabled("joint_partners")}
                  closeMenuOnSelect={false}
                  openMenuOnClick
                  allowMultiple
                  />
              </Col>
              <Col md={4}>
                <Field
                  component={Select}
                  readOnly={props.isFieldReadOnly("external_commissioner")}
                  disabled={props.isFieldDisabled("external_commissioner")}
                  name="external_commissioner"
                  testId="external_commissioner"
                  inputId="external_commissioner"
                  label="External Commissioner"
                  options={props.externalCommissionerOptions}
                  openMenuOnClick
                  />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Field
                  name="topics"
                  label="Topics"
                  options={props.topics}
                  component={Select}
                  inputId="topics-infoform"
                  readOnly={props.isFieldReadOnly("topics")}
                  disabled={props.isFieldDisabled("topics")}
                  allowMultiple
                  openMenuOnClick
                  />
              </Col>
              <Col md={4}>
                <Field
                  name="activity_categories"
                  label="Activity Categories"
                  inputId="activity_category-infoform"
                  options={props.activityCategoriesOptions}
                  component={Select}
                  readOnly={props.isFieldReadOnly("activityCategory")}
                  disabled={props.isFieldDisabled("activityCategory")}
                  allowMultiple
                  openMenuOnClick
                  />
              </Col>
              <Col md={4}>
                <Field
                  name="sustainable_development_goals"
                  label="SDGs"
                  options={props.sustainableDevelopmentGoals}
                  component={Select}
                  readOnly={props.isFieldReadOnly("sustainableDevelopmentGoals")}
                  disabled={props.isFieldDisabled("sustainableDevelopmentGoals")}
                  inputId="sdg-infoform"
                  helpText={props.helpTexts.sustainableDevelopmentGoals}
                  allowMultiple
                  openMenuOnClick
                  />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Field
                  name="planned_budget"
                  label="Planned Budget"
                  prependLabel="US $"
                  component={TextInputNumber}
                  readOnly={props.isFieldReadOnly("planned_budget")}
                  disabled={props.isFieldDisabled("planned_budget")}
                  helpText={props.helpTexts.plannedBudget}
                  isTestMode={props.isTestMode}
                  />
              </Col>
              <Col md={3}>
                <Field
                  name="cef_allocation_requested"
                  label="CEF Allocation Requested"
                  prependLabel="US $"
                  component={TextInputNumber}
                  readOnly={props.isFieldReadOnly("cef_allocation_requested")}
                  disabled={props.isFieldDisabled("cef_allocation_requested")}
                  />
              </Col>
              <Col md={3}>
                <Field
                  name="subject_funded_value"
                  label="Funding of the evaluation subject"
                  prependLabel="US $"
                  component={TextInputNumber}
                  readOnly={props.isFieldReadOnly("subject_funded_value")}
                  disabled={props.isFieldDisabled("subject_funded_value")}
                  onChangeExtra={props.onSubjectFundedChanged}
                  />
              </Col>
              <Col md={3}>
                <Field
                  name="subject_funded_percentage"
                  label="% of Funded Portfolio"
                  component={TextInput}
                  readOnly
                  />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Field
                  name="actual_expenditure"
                  label="Actual Expenditure"
                  prependLabel="US $"
                  component={TextInputNumber}
                  readOnly={props.isFieldReadOnly("actual_expenditure")}
                  disabled={props.isFieldDisabled("actual_expenditure")}
                  helpText={props.helpTexts.actualExpenditure}
                  />
              </Col>
              <Col md={3}>
                <Field
                  name="cef_granted"
                  label="CEF Allocation Granted"
                  prependLabel="US $"
                  component={TextInputNumber}
                  readOnly={props.isFieldReadOnly("cef_granted")}
                  disabled={props.isFieldDisabled("cef_granted")}
                  />
              </Col>
              <Col md={3}>
                <Field
                  name="entire_project_funding"
                  label="Funding of the entire portfolio"
                  prependLabel="US $"
                  component={TextInputNumber}
                  readOnly={props.isFieldReadOnly("entire_project_funding")}
                  disabled={props.isFieldDisabled("entire_project_funding")}
                  onChangeExtra={props.onEntireFundedChanged}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Field
                  name="publication_reference"
                  label="Publication Reference"
                  component={TextInput}
                  readOnly={props.isFieldReadOnly("publication_reference")}
                  disabled={props.isFieldDisabled("publication_reference")}
                  />
              </Col>
              <Col md={4}>
                <Field
                  name="crosscutting_priorities"
                  label="WFP Cross-Cutting Priorities"
                  options={props.crosscuttingPriorities}
                  component={Select}
                  readOnly={props.isFieldReadOnly("crosscuttingPriorities")}
                  disabled={props.isFieldDisabled("crosscuttingPriorities")}
                  inputId="crosscutting-priorities-infoform"
                  allowMultiple
                  openMenuOnClick
                  smallMenuList
                  />
              </Col>
              <Col md={4}>
                <Field
                  name="request_by_donors"
                  label="Request By Donors"
                  options={props.requestByDonors}
                  component={Select}
                  readOnly={props.isFieldReadOnly("requestByDonors")}
                  disabled={props.isFieldDisabled("requestByDonors")}
                  inputId="request-by-donors-infoform"
                  allowMultiple
                  openMenuOnClick
                  smallMenuList
                  />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Field
                  name="notes"
                  label="Notes"
                  component={Textarea}
                  readOnly={props.isFieldReadOnly("notes")}
                  disabled={props.isFieldDisabled("notes")}
                  enableCounter
                  maxLength={props.notesMaxLength}
                  currentLength={props.notesCurrentLength}
                  />
              </Col>
            </Row>
            <Row>
              <Col md>
                <Field
                  name="description"
                  label="Description"
                  component={Textarea}
                  readOnly={props.isFieldReadOnly("description")}
                  disabled={props.isFieldDisabled("description")}
                  enableCounter
                  maxLength={props.descriptionMaxLength}
                  currentLength={props.descriptionCurrentLength}
                    />
              </Col>
            </Row>
          </Grid>
        </form>
      </div>
    </div>
  );
}

InfoForm.propTypes = {
  stTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  topics: PropTypes.arrayOf(PropTypes.object).isRequired,
  sustainableDevelopmentGoals: PropTypes.arrayOf(PropTypes.object),
  requestByDonors: PropTypes.arrayOf(PropTypes.object),
  crosscuttingPriorities: PropTypes.arrayOf(PropTypes.object),
  jointPartnersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.array.isRequired
    })
  ),

  externalCommissionerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.array
    })
  ),

  exerciseOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),

  activityCategoriesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ),

  fetchOffices: PropTypes.func.isRequired,
  fetchDivisions: PropTypes.func.isRequired,

  isFieldReadOnly: PropTypes.func.isRequired,
  isFieldDisabled: PropTypes.func.isRequired,

  /* eslint-disable */
  initialValues: PropTypes.object,
  /* eslint-enable */

  initialized: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  // date-pickers props
  setDatesRange: PropTypes.func.isRequired,
  setInitialVisibleMonth: PropTypes.func.isRequired,

  isTestMode: PropTypes.bool, // only used for testing proposes
  notesMaxLength: PropTypes.number.isRequired,
  notesCurrentLength: PropTypes.number.isRequired,
  descriptionMaxLength: PropTypes.number.isRequired,
  descriptionCurrentLength: PropTypes.number.isRequired,
  parserToNumberOrNull: PropTypes.func,
  helpTexts: PropTypes.shape({
      actualExpenditure: PropTypes.string,
      approvalDate: PropTypes.string,
      plannedCompletedDate: PropTypes.string,
      plannedBudget: PropTypes.string,
      startYear: PropTypes.string,
      managerWfpOnetoone: PropTypes.string,
      sustainableDevelopmentGoals: PropTypes.string,
  }),
  onSubjectFundedChanged: PropTypes.func.isRequired,
  onEntireFundedChanged: PropTypes.func.isRequired,
};

InfoForm.defaultProps = {
  initialValues: undefined,
  jointPartnersOptions: [],
  externalCommissionerOptions: [],
  exerciseOptions: [],
  activityCategoriesOptions: [],
  sustainableDevelopmentGoals: [],
  requestByDonors: [],
  crosscuttingPriorities: [],
  parserToNumberOrNull: () => null,
  isTestMode: false, // only used for testing proposes
  helpTexts: {}
};

export default reduxForm({
  form: formName,
  /**
   * `enableReinitialize` reinitializes the form whenever `initialValues` changes.
   * Disabled because `initialValues` changes multiple times as we save,
   * and we only want to reinitialize at the end of the saving procedure.
   */
  enableReinitialize: false,
  destroyOnUnmount: true,
  keepDirtyOnReinitialize: false
})(InfoForm);
