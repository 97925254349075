import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Loading} from "@wfp/ui";
import {change} from "redux-form";
import {useGetCategory, useGetDetailedEvaluation} from "../../../../../hooks";
import undisActions, {undisFramework} from "../../../../../../../actions/undises";
import {getEvaluationActions} from "../../../../../../../actions/evaluations";
import {generateFormCriteria, transformUndisScore} from "../utils";
import {CriterionForm} from "../components/CriterionForm";
import {CriterionRow} from "./CriterionRow";
import {getMessages, isCreate} from "../../../../../../../utils";


const PHQAUndis = ({canEdit}) => {
    const evaluation = useGetDetailedEvaluation();
    const category = useGetCategory();
    const dispatch = useDispatch();

    const page = useSelector(store => store.pages?.undises)

    const frameworks = useSelector((store) => store.entities.undisFrameworks);
    const undises = useSelector((store) => store.entities.undises);
    const messages = useSelector(store => store.messages.undises);
    const memorizedMessages = useMemo(() => getMessages(messages), [messages]);

    const filteredUndises = Object.values(undises).filter(item => page.items.includes(item.id));

    // eslint-disable-next-line camelcase
    const criteria = generateFormCriteria(filteredUndises, frameworks?.[evaluation?.undis_framework]).map(item => ({
        ...item,
    }))

    useEffect(() => {
        if (evaluation) {
            dispatch(undisFramework.list({}, {paginated: false}));
            dispatch(undisActions.list({}, {pathParams: {evaluationId: evaluation.id}, paginated: false}));
            dispatch(getEvaluationActions(category).fetchScoreAndRating(evaluation.id));
        }
    }, [evaluation?.id]);

    const onCriterionSave = async ({items}) => {
        await dispatch(undisActions.saveUndises(evaluation.id, items));

        await dispatch(getEvaluationActions(category).fetchScoreAndRating(evaluation.id));
        await dispatch(getEvaluationActions(category).validation(evaluation.id));
        await dispatch(getEvaluationActions(category).detail(evaluation.id));
    }

    useEffect(() => {
        dispatch(change('evUndis', 'totalScore', page.totalScore.totalScore));
        dispatch(change('evUndis', 'totalRating', page.totalScore.totalRating));
    }, [page.totalScore]);

    const readOnly = !canEdit || isCreate(evaluation?.id);

    if (Object.values(frameworks).length === 0 || !evaluation) {
        return <Loading />;
    }

    return (
      <div>
        <CriterionForm
          form='evUndis'
          messages={memorizedMessages}
          initialValues={{
            ...page.totalScore,
            items: criteria.map(criterion => ({...criterion, score: transformUndisScore(criterion.score)})),
          }}
          save={onCriterionSave}
          readOnly={readOnly}
          rowComponent={CriterionRow}
          defaultMessage='Please add score (Yes/No/NA) on all criteria'
        />
      </div>
    )
}


export default PHQAUndis;


