import React from "react";
import PropTypes from "prop-types";
import includes from "lodash/includes";

import { Button } from "@wfp/ui";
import Checkbox from "Checkbox";

import Styles from "./styles.scss";

export const Control = props => (
  <Checkbox
    id={props.value}
    label={props.label}
    input={{
      name: props.name,
      value: includes(props.dataVisible, props.value),
      onChange: () =>
        props.onChange({
          value: !includes(props.dataVisible, props.value),
          name: props.value
        }),
    }}
  />
);

Control.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dataVisible: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string
};

Control.defaultProps = {
  name: ""
};

/**
 * Allow the user to edit the columns of a table
 */
const ToggleTbColumns = props => {
  const columns = props.data.map(option => (
    <li key={`control-${option.value}-${option.label}`}>
      <Control
        label={option.label}
        value={option.value}
        dataVisible={props.dataVisible}
        onChange={props.onToggleHandler}
      />
    </li>
  ));

  return (
    <ul className={["wfp-form", Styles.toggleColumns].join(" ")}>
      <li className={Styles.toggleColumnsHeader}>
        <Button kind="ghost" small onClick={props.onSelectAll}>
          Select all
        </Button>
        <Button kind="ghost" small onClick={props.onSelectNone}>
          Select none
        </Button>
        <Button kind="ghost" small onClick={props.onInvertSelection}>
          Invert
        </Button>
        <Button kind="ghost" small onClick={props.onSelectDefault}>
          Default
        </Button>
      </li>
      {columns}
    </ul>
  );
};

ToggleTbColumns.propTypes = {
  /** Columns data */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  /** Whether to show or not the list */
  dataVisible: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Handler when clicking the toggle button */
  onToggleHandler: PropTypes.func.isRequired,
  /** Handler for selectAll */
  onSelectAll: PropTypes.func.isRequired,
  /** Handler to select no column */
  onSelectNone: PropTypes.func.isRequired,
  /** Handler to invert selection */
  onInvertSelection: PropTypes.func.isRequired,
  /** Handler to select default columns */
  onSelectDefault: PropTypes.func.isRequired
};

export default ToggleTbColumns;
