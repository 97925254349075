import { combineReducers } from "redux";
import {
  fetching,
  submitting,
  items,
  selectable,
  togglable,
  options
} from "./resource";

export default combineReducers({
  selectedCoverage: selectable("coverage"),
  // Pending states
  isFetching:
    fetching("coverage") || fetching("validation") || fetching("evaluations"),
  isSubmitting: submitting("coverage"),
  // Items
  options: options("coverage"),
  items: items("coverage"),
  phases: items("phases"),
  bureaux: items("bureaux"),
  countries: items("countries"),
  // Modals
  addModal: togglable("COVERAGE_ADD_MODAL"),
  deleteModal: togglable("COVERAGE_DELETE_MODAL")
});
