exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__userLabel___1tR8L{display:flex}.styles__userLabelAvatar___YSk4e{border-radius:50%;flex-shrink:0;margin-right:8px;height:25px;overflow:hidden;width:25px}.styles__userLabelAvatar___YSk4e>img{height:auto;width:100%}.styles__userLabelText___3beVB{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.styles__userLabelTextFullName___2uN--{display:inline-block;position:relative;margin-bottom:0.5rem}.styles__userLabelTextJob___1VgMu{font-style:italic}\n", ""]);

// Exports
exports.locals = {
	"userLabel": "styles__userLabel___1tR8L",
	"userLabelAvatar": "styles__userLabelAvatar___YSk4e",
	"userLabelText": "styles__userLabelText___3beVB",
	"userLabelTextFullName": "styles__userLabelTextFullName___2uN--",
	"userLabelTextJob": "styles__userLabelTextJob___1VgMu"
};