import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import { connect } from "react-redux";
import { getFormMeta } from "redux-form";
import get from "lodash/get";

import Loading from "Loading";
import StatusGroup from "./components/StatusGroup";
import StatusHeader from "./components/StatusHeader";
import Title from "./components/Title";

import { getEvaluationActions } from "../../../../actions/evaluations";
import {
  setExpandedStatuses,
  toggleStatusExpansion
} from "../../../../actions/leftColumn";
import deviationActions from "../../../../actions/deviations";
import fetchUser from "../../../../actions/user";
import { toggle } from "../../../../actions/togglable";
import getStatuses from "../../../../actions/decentralizedStatuses";
import { getIsUserSupport } from "../../../../selectors/user";
import { customToastSuccess, isCreate } from "../../../../utils";

import styles from "./styles.scss";
import { difference } from "./utils";
import fieldManipulator from "./fieldManipulator";

const urlMap = {
  team: "members",
  phqastandards: "phqa-standards",
  phqagenderepi: "phqa-gender",
  phqaundis: "phqa-undis",
  milestones: "phases"
};

const getUrl = field => {
  // Some field names correctly maps to the url
  if (["products", "phases"].includes(field)) return field;
  // Excluded the precediing ones, every other field without
  // a '__' is considered to be placed in the Info tab
  if (field.indexOf("__") === -1) return "info";
  // Otherwise return the result of a manual mapping between name and url
  const [model] = field.split("__");
  return urlMap[model] || model;
};

export class LeftColumn extends Component {
  componentDidMount() {
    if (this.props.category) {
      // Clean validation state first
      this.props.fetchUser();
      this.props.fetchStatuses(this.props.category);
      this.props.resetValidation(this.props.category);
      const { evaluationId } = this.props.match.params;
      if (!isCreate(evaluationId)) {
        this.props.fetchRequirements(this.props.category, evaluationId);
        this.props.fetchEvaluation(evaluationId, this.props.category);
        this.props.fetchValidation(evaluationId, this.props.category);
        if (this.props.fieldsEditPermission) {
          this.props.fetchDeviations();
        }
      } else {
        this.props.fetchRequirements(this.props.category);
      }
    }
  }

  onFieldEdit = (field, enable) => {
    if (!enable) {
      const fieldName = get(this.props.requirements, `[${field}].label`, field);
      return this.props.enableField(field, fieldName, this.props.category);
    }
    const fieldId = (this.props.deviations.find(d => d.name === field) || {})
      .id;
    if (fieldId) {
      return this.props.disableField(fieldId, this.props.category);
    }
    return undefined;
  };

  /** Returns whether the given status' requirements list is expanded */
  isStatusExpanded = statusCode => {
    // Current working status should always be expanded
    if (this.isCurrentWorkingStatus(statusCode)) {
      return true;
    }
    return this.props.expandedStatuses[statusCode];
  };

  /** Toggle requirements expansion for the given status */
  toggleStatusExpanded = statusCode => {
    const updatedToggles = {
      ...this.props.expandedStatuses,
      [statusCode]: !this.props.expandedStatuses[statusCode]
    };
    this.props.setExpandedStatuses(updatedToggles);
    return updatedToggles;
  };

  /** Returns whether the given status is the next one to be achieved */
  isCurrentWorkingStatus = statusCode => {
    const i = this.props.statuses.findIndex(
      status => status.code === statusCode
    );

    if (i === 0) {
      return false;
    }

    const prevStatus = this.props.statuses[i - 1];

    // If the status before this one was reached and this one is not,
    // it must mean that it is the current working status
    return (
      this.isStatusReached(prevStatus.code) && !this.isStatusReached(statusCode)
    );
  };

  isStatusReached = statusCode => {
    if (!statusCode) return true;
    if (isCreate(this.props.match.params.evaluationId)) return false;
    if (this.props.statusCode && this.props.statuses) {
      const codes = this.props.statuses.map(s => s.code);
      return codes.indexOf(statusCode) <= codes.indexOf(this.props.statusCode);
    }
    return false;
  };

  hasError = (statusCode, field) => {
    // If we are fetching validation, show everything greyed out
    if (this.props.fetchingValidation || !statusCode) return true;
    // Same if we are in creation phase
    if (isCreate(this.props.match.params.evaluationId)) return true;
    if (!statusCode) {
      return true;
    }
    if (get(this.props.validation, `[${statusCode}][${field}]`)) {
      return true;
    }
    return false;
  };

  stateHasError = code => {
    if (!this.props.canEdit) return true;
    // If we are fetching validation, show as if it had errors
    if (this.props.fetchingValidation) return true;
    // Same if we are in creation phase
    if (isCreate(this.props.match.params.evaluationId)) return true;
    if (this.props.validation[code]) return true;
    return false;
  };

  getLabel = field => get(this.props.requirements, `[${field}].label`, field);

  onApply = code => {
    if (this.isStatusReached(code)) {
      return this.props.statusRollback(code, this.props.category);
    }

    return this.props.statusAdvance(code, this.props.category);
  };

  onRestore = () => {
    return this.props.statusRestore(this.props.category);
  };

  render() {
    const {
      canEdit,
      editableFields,
      fieldsEditPermission,
      isCancelled,
      isFinalized,
      isLegacy,
      isMigrated,
      statusCodePrev,
      statuses: _statuses,
      toggleEditableFields,
      toggleStatus
    } = this.props;

    // Copied for safe in-place manipulation
    const statuses = [..._statuses];

    const statusPrevIndex = statuses.findIndex(
      status => status.code === statusCodePrev
    );

    // Store CANCELLED in statusCancelled
    const statusCancelledIndex = statuses.findIndex(
      status => status.code === "CANCELLED"
    );
    const statusCancelled = statuses[statusCancelledIndex];

    // Remove CANCELLED from statuses
    if (statusCancelledIndex !== -1) {
      statuses.splice(statusCancelledIndex, 1);
    }

    // Split statuses in two lists, according to cancellation status
    let preCancellationStatuses;
    let postCancellationStatuses;
    if (isCancelled) {
      preCancellationStatuses = statuses.slice(0, statusPrevIndex + 1);
      postCancellationStatuses = statuses.slice(statusPrevIndex + 1);
    } else {
      preCancellationStatuses = statuses;
      postCancellationStatuses = [];
    }

    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    return (
      <div
        className={[
          styles.evaluationStatusBar,
          this.props.isStatusOpen ? styles.isOpen : ""
        ].join(" ")}
      >
        <StatusHeader
          canEdit={canEdit}
          editableFields={editableFields}
          fieldsEditPermission={fieldsEditPermission}
          isCancelled={isCancelled}
          isFinalized={isFinalized}
          isLegacy={isLegacy}
          isMigrated={isMigrated}
          toggleEditableFields={toggleEditableFields}
          toggleStatus={toggleStatus}
        />
        {this.props.fetchingValidation ? (
          <Loading />
        ) : (
          <>
            {[
              // Statuses before CANCELLED (could be all statuses)
              preCancellationStatuses.map(
                (
                  {
                    code,
                    name,
                    requirements_delta: requirementsDelta,
                    deviations_delta: deviationsDelta
                  },
                  index
                ) => (
                  <ul className={styles.statusGroup} key={name}>
                    <StatusGroup
                      activeFields={this.props.active}
                      baseUrl={`/evaluations/${this.props.match.params.evaluationId}/`}
                      changedFields={this.props.changed}
                      code={code}
                      currentUrl={this.props.location.pathname}
                      deviationsDelta={deviationsDelta}
                      editableFields={this.props.editableFields}
                      fields={this.props.fields}
                      getFieldUrl={getUrl}
                      getLabel={this.getLabel}
                      hasError={this.hasError}
                      hasErrors={this.stateHasError(code)}
                      isCurrentStatus={this.props.statusCode === code}
                      isCurrentWorkingStatus={this.isCurrentWorkingStatus(code)}
                      isStatusReached={this.isStatusReached(code)}
                      isStatusExpanded={this.isStatusExpanded(code)}
                      name={name}
                      number={index + 1}
                      onApply={isCancelled ? undefined : this.onApply}
                      onFieldEdit={this.onFieldEdit}
                      onToggleRequirements={this.props.toggleStatusExpansion}
                      requirementsDelta={requirementsDelta}
                    />
                    {/* Don't add endingLine to last status
                     * (preCancellationStatuses may be _all_ statuses) */}
                    {index < statuses.length - 1 && (
                      <div className={styles.endingLine} />
                    )}
                  </ul>
                )
              ),

              isCancelled && [
                <ul
                  className={`${styles.statusGroup} ${styles.statusCancelled}`}
                  key={statusCancelled && statusCancelled.name}
                >
                  <Title
                    actualStatus
                    name={statusCancelled && statusCancelled.name}
                    onRestore={this.onRestore}
                    statusReached
                  />
                  {/* No need to check whether this is the last status:
                   * you can't go to CANCELLED from the last status */}
                  <div className={styles.endingLine} />
                </ul>
              ],

              // Statuses after CANCELLED (could be 0)
              postCancellationStatuses.map(
                (
                  {
                    code,
                    name,
                    requirements_delta: requirementsDelta,
                    deviations_delta: deviationsDelta
                  },
                  index
                ) => (
                  <ul
                    className={`${styles.statusGroup} ${styles.disabledStatus}`}
                    key={name}
                  >
                    <StatusGroup
                      activeFields={this.props.active}
                      baseUrl={`/evaluations/${this.props.match.params.evaluationId}/`}
                      changedFields={this.props.changed}
                      code={code}
                      currentUrl={this.props.location.pathname}
                      deviationsDelta={deviationsDelta}
                      editableFields={this.props.editableFields}
                      fields={this.props.fields}
                      getFieldUrl={getUrl}
                      getLabel={this.getLabel}
                      hasError={this.hasError}
                      hasErrors={this.stateHasError(code)}
                      isCurrentStatus={false}
                      isCurrentWorkingStatus={false}
                      isStatusExpanded={this.isStatusExpanded(code)}
                      isStatusReached={this.isStatusReached(code)}
                      name={name}
                      number={index + 1 + statusPrevIndex + 1}
                      onFieldEdit={this.onFieldEdit}
                      onToggleRequirements={this.props.toggleStatusExpansion}
                      requirementsDelta={requirementsDelta}
                    />
                    {/* Don't add endingLine to last status */}
                    {index < postCancellationStatuses.length - 1 && (
                      <div className={styles.endingLine} />
                    )}
                  </ul>
                )
              )
            ]}
          </>
        )}
      </div>
    );
    /* eslint-enable */
  }
}

LeftColumn.propTypes = {
  canEdit: PropTypes.bool,
  category: PropTypes.string,
  expandedStatuses: PropTypes.objectOf(PropTypes.bool),
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  fetchValidation: PropTypes.func.isRequired,
  fetchingValidation: PropTypes.bool.isRequired,
  resetValidation: PropTypes.func.isRequired,
  fetchStatuses: PropTypes.func.isRequired,
  fetchRequirements: PropTypes.func.isRequired,
  fetchEvaluation: PropTypes.func.isRequired,
  setExpandedStatuses: PropTypes.func.isRequired,
  statusAdvance: PropTypes.func.isRequired,
  statusRollback: PropTypes.func.isRequired,
  statusRestore: PropTypes.func.isRequired,
  toggleStatusExpansion: PropTypes.func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  validation: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  requirements: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  statusCode: PropTypes.string,
  statusCodePrev: PropTypes.string,
  statuses: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */
  active: PropTypes.arrayOf(PropTypes.string),
  changed: PropTypes.arrayOf(PropTypes.string),
  isStatusOpen: PropTypes.bool,
  toggleStatus: PropTypes.func.isRequired,

  isCancelled: PropTypes.bool,
  isFinalized: PropTypes.bool,
  isLegacy: PropTypes.bool,
  isMigrated: PropTypes.bool,

  editableFields: PropTypes.bool.isRequired, // Are the column fields editable?
  fieldsEditPermission: PropTypes.bool.isRequired,
  fetchUser: PropTypes.func.isRequired,
  fetchDeviations: PropTypes.func.isRequired,
  toggleEditableFields: PropTypes.func.isRequired,
  enableField: PropTypes.func.isRequired,
  disableField: PropTypes.func.isRequired,
  deviations: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

LeftColumn.defaultProps = {
  canEdit: false,
  validation: undefined,
  requirements: undefined,
  category: undefined,
  active: [],
  changed: [],
  expandedStatuses: {},
  isStatusOpen: false,
  isCancelled: false,
  isFinalized: false,
  isMigrated: false,
  isLegacy: false,
  statusCode: undefined,
  statusCodePrev: undefined
};

const fieldMap = {
  commissioning_office_division: "commissioning",
  manager_wfp_display: "manager",
  research_analyst_wfp_display: "research_analyst",
  eb_session: ["eb_session_migrated", "eb_session"]
};

const locationMap = {
  coverage: ["coverage__any"],
  phases: [
    "phases__all",
    "milestones__tor_approved",
    "milestones__er_approved",
    "milestones__tor_submitted"
  ],
  members: ["team__leader", "team__contract", "team__members"],
  products: [
    "products",
    "products__full_report",
    "products__management_response"
  ],
  "phqa-standards": ["phqastandards__all"],
  "phqa-gender": ["phqagenderepi__all"],
  "phqa-undis": ["phqaundis__all"],
};

// Exported for tests
export const mapStateToProps = (state, ownProps) => {
  const { entities } = state;
  const { evaluationId } = ownProps.match.params;
  const form = state.form.evInfo;

  // Guess which field should be highlighted in blue
  const meta = getFormMeta("evInfo")(state);
  const activeFormField =
    (meta && Object.keys(meta).find(field => meta[field].active)) || null;
  let active = [];
  const location = ownProps.location.pathname.split("/").splice(-1);
  if (activeFormField) {
    if (Array.isArray(fieldMap[activeFormField])) {
      active = fieldMap[activeFormField];
    } else {
      active = [fieldMap[activeFormField] || activeFormField];
    }
  } else if (locationMap[location]) {
    active = locationMap[location];
  }
  // Guess if a field should be animated (if its value has changed)
  const diff = form?.values && form?.initial
    ? difference(form.values, form.initial)
    : {};
  const changed = Object.keys(diff).map(f => fieldMap[f] || f);

  const page = state.pages.leftColumn;

  const evaluation = entities.evaluations[evaluationId];
  const status =
    evaluation && entities.decentralizedStatuses[evaluation.status_code];

  const fields =
    form && form.values
      ? {
          ...form.values,
          commissioning:
            form.values.commissioning_office ||
            form.values.commissioning_division,
          manager:
            form.values.manager_wfp ||
            form.values.manager_external ||
            form.values.manager_wfp_display,
          topics:
            form.values.topics && form.values.topics.length === 0
              ? false
              : form.values.topics,
          request_by_donors:
            form.values.request_by_donors && form.values.request_by_donors.length === 0
              ? false
              : form.values.request_by_donors,
          funded_by_donors:
            form.values.funded_by_donors && form.values.funded_by_donors.length === 0
              ? false
              : form.values.funded_by_donors,
          crosscutting_priorities:
          form.values.crosscutting_priorities && form.values.crosscutting_priorities.length === 0
            ? false
            : form.values.crosscutting_priorities,
          joint_partners:
            form.values.joint_partners &&
            (form.values.joint_partners.length === 0
              ? false
              : form.values.joint_partners),
          kind: form.values.kind && form.values.kind.value,
          activity_categories:
            form.values.activity_categories &&
            (form.values.activity_categories.length === 0
              ? false
              : form.values.activity_categories)
        }
      : {};

  const isUserSupport = getIsUserSupport(state);

  const [statuses, requirements, validation] = fieldManipulator.manipulate(page.statuses, entities.requirements, state.validation)

  return {
    active,
    changed,
    deviations:
      page.deviations
        .map(id => entities.deviations[id])
        .filter(d => !d.deleted) || [],
    editableFields: isUserSupport && page.editableFields,
    expandedStatuses: state.pages.leftColumn.expandedStatuses,
    fetchingValidation: page.fetchingValidation,
    fields,
    fieldsEditPermission: isUserSupport,
    isCancelled: evaluation && evaluation.status_code === "CANCELLED",
    isFinalized: evaluation && evaluation.status_code === "FINALIZED",
    isLegacy: evaluation && evaluation.is_legacy,
    isMigrated: evaluation && evaluation.is_migrated,
    isStatusOpen: page.status,
    migrationCode: evaluation && evaluation.migration_code,
    requirements,
    status,
    statusCode: evaluation ? evaluation.status_code : null,
    statusCodePrev: evaluation ? evaluation.status_code_prev : null,
    statuses,
    validation,
  };
};

// Exported for tests
export const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { evaluationId }
    }
  }
) => ({
  toggleStatus: () => dispatch(toggle("LEFTCOLUMN_STATUS")),
  toggleEditableFields: () => dispatch(toggle("EDITABLE_FIELDS")),
  fetchStatuses: category => dispatch(getStatuses({}, category, evaluationId)),
  fetchEvaluation: (id, category) =>
    dispatch(getEvaluationActions(category).detail(id)),
  fetchValidation: (id, category) =>
    dispatch(getEvaluationActions(category).validation(id, true)),
  resetValidation: category =>
    dispatch(getEvaluationActions(category).resetValidation()),
  fetchRequirements: (category, id) => {
    if (id) {
      return dispatch(getEvaluationActions(category).evaluationRequirements(id))
    }

    return dispatch(getEvaluationActions(category).requirements())
  },
  fetchDeviations: () =>
    dispatch(
      deviationActions.list(
        {},
        { pathParams: { evaluationId }, paginated: false }
      )
    ),
  fetchUser: () => dispatch(fetchUser()),
  enableField: (name, description, category) =>
    dispatch(
      deviationActions.create(
        { description, name },
        { pathParams: { evaluationId } }
      )
    ).then(() =>
      Promise.all([
        dispatch(getEvaluationActions(category).validation(evaluationId)),
        dispatch(getStatuses({}, category, evaluationId))
      ])
    ),
  disableField: (fieldId, category) =>
    dispatch(
      deviationActions.destroy(fieldId, { pathParams: { evaluationId } })
    ).then(() =>
      Promise.all([
        dispatch(getEvaluationActions(category).validation(evaluationId)),
        dispatch(getStatuses({}, category, evaluationId))
      ])
    ),
  setExpandedStatuses: expandedStatuses =>
    dispatch(setExpandedStatuses(expandedStatuses)),
  toggleStatusExpansion: statusCode =>
    dispatch(toggleStatusExpansion(statusCode)),
  statusAdvance: (statusCode, category) =>
    dispatch(
      getEvaluationActions(category).statusAdvance(evaluationId, {
        status_code: statusCode
      })
    )
      .then(() =>
        dispatch(getEvaluationActions(category).validation(evaluationId))
      )
      .then(() => {
        customToastSuccess();
      }),
  statusRollback: (statusCode, category) =>
    dispatch(
      getEvaluationActions(category).statusRollback(evaluationId, {
        status_code: statusCode
      })
    )
      .then(() =>
        dispatch(getEvaluationActions(category).validation(evaluationId))
      )
      .then(() => {
        customToastSuccess();
      }),
  statusRestore: category =>
    dispatch(getEvaluationActions(category).statusRestore(evaluationId))
      .then(() =>
        dispatch(getEvaluationActions(category).validation(evaluationId))
      )
      .then(() => {
        customToastSuccess();
      })
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LeftColumn)
);
