const defaultAuthState = {
  isPosting: false,
  isPosted: false,
  /** isSessionExpired = has client received a 401 UNAUTHORIZED */
  isSessionExpired: false,
  error: null
};

export default (state = defaultAuthState, action) => {
  switch (action.type) {
    case "AUTH_USER_PENDING": {
      return {
        ...state,
        isPosting: true,
        isPosted: false
      };
    }
    case "AUTH_USER_FULFILLED": {
      return {
        ...state,
        isPosting: false,
        isPosted: true,
        isSessionExpired: false
      };
    }
    case "AUTH_USER_SESSION_EXPIRED": {
      return {
        ...state,
        isPosting: false,
        isPosted: false,
        isSessionExpired: true
      };
    }
    default:
      return state;
  }
};
