import React from "react";
import PropTypes from "prop-types";

import Styles from "./styles.scss";
import ColumnSeparator from "../ColumnSeparator";

const Category = props => (
  <div className={Styles.container}>
    <div className={Styles.titleContainer} style={{backgroundColor: props.categoryCode?.toLowerCase() === 'centralized' ? '#c0e1fe' : '#f9d4d1' }}>
      <p className={Styles.title}>{props.categoryCode}</p>
    </div>
    <ColumnSeparator />
    <div className={Styles.childContainer}>
      {props.children}
    </div>
  </div>
)


Category.propTypes = {
  categoryCode: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Category.defaultProps = {
};

export default Category;
