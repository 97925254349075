import React from "react";
import PropTypes from "prop-types";

import { RadioButton as WFPRadioButton } from "@wfp/ui";

import styled from "styled-components";

/**
 * Override disabled styles when readOnly
 */
const StyledWrapper = styled.div.attrs(props => ({
  labelCursor: props.readOnly && "default !important",
  labelOpacity: props.readOnly && "1 !important",
  buttonColor: props.readOnly && "#eff2f5"
}))`
  .wfp--radio-button__label {
    cursor: ${props => props.labelCursor};
    opacity: ${props => props.labelOpacity};
  }
  .wfp--radio-button__appearance {
    border-color: ${props => props.buttonColor};
  }
`;

const RadioButton = ({
  disabled,
  id,
  label,
  input: { name, value, onChange },
  readOnly
}) => {
  return (
    <div className="wfp--form-item">
      <StyledWrapper readOnly={readOnly}>
        <WFPRadioButton
          name={name}
          checked={value === id}
          onChange={onChange}
          labelText={label}
          id={id}
          value={id}
          disabled={disabled || readOnly} // Underlying component has only disabled state
          style={{
            cursor: readOnly && "default !important"
          }}
        />
      </StyledWrapper>
    </div>
  );
};

RadioButton.propTypes = {
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired
};

RadioButton.defaultProps = {
  disabled: false,
  readOnly: false
};

export default RadioButton;
