import {normalize, schema} from "normalizr";
import {toast} from "react-toastify";
import {undisesTypes, undisFrameworkTypes} from "../types";
import Resource from "./resource";
import {client} from "../store";
import {mergeItemsEntities} from "./entities";

const undisesSchema = new schema.Entity("undises");
const undisFrameworkSchema = new schema.Entity("undisFrameworks");

class UndisAction extends Resource {
    saveUndises = (evaluationId, undis) => (dispatch) => {
        const cleanedUndis = undis
            .filter(item => item.score) // Remove deleted scores
            .map((item) => ({
                criterion: item?.criterion?.id,
                score: item.score.value,
            }))
        return dispatch({
            type: "SAVE_TOTAL_SCORE",
            payload: client.put(`${this.addParamsToPath({evaluationId})}/bulk_update/`, {
                undises: cleanedUndis,
            })
                .then((response) => {
                    const data = normalize(response.data, [this.schema]);
                    dispatch(mergeItemsEntities(data, this.types.name));
                    toast.success("Undises updated!", {autoClose: 1500});
                }).catch(error => {
                    this.handleError(error);
                })
        });
    }
}

export default new UndisAction(
    "/evaluations/<evaluationId>/undises",
    undisesTypes,
    undisesSchema
);

client.enableAutoCamelCase(/\/evaluations\/\d+\/undises\/$/);

export const undisFramework = new Resource(
    "/undis-frameworks",
    undisFrameworkTypes,
    undisFrameworkSchema
);

client.enableAutoCamelCase('/undis-frameworks');
