import React from 'react';
import HelpMessageBox from "components/HelpMessageBox";
import PropTypes from "prop-types";
import {dateFormatter} from "utils";
import PredictedMilestoneMessageBox from "./PredictedMilestoneMessageBox";

const PredictedPhaseMessageBox = (props) => {
    const differences = [];
    if (!props?.shiftedPhase) {
        return null;
    }

    const { phase: { dates_range: dateRange, milestones: phaseMilestones }} = props;
    const { shiftedPhase: { dates_range: shiftedDateRange, milestones: shiftedMilestones }} = props;

    if (dateRange.startDate && !dateRange.startDate?.isSame(shiftedDateRange.startDate)) {
        differences.push(
          <p>Start date will be changed from
            <strong> {dateFormatter(dateRange.startDate).toString()}</strong> to
            <strong> {dateFormatter(shiftedDateRange.startDate).toString()}</strong>
          </p>
        )
    }

    if (dateRange.endDate && !dateRange.endDate?.isSame(shiftedDateRange.endDate)) {
        differences.push(
          <p>End date will be changed from
            <strong> {dateFormatter(dateRange.endDate).toString()}</strong> to
            <strong> {dateFormatter(shiftedDateRange.endDate).toString()}</strong>
          </p>
        )
    }

    const milestones = props.milestones.reduce((data, milestone) => {
        return {...data, [milestone.id]: milestone};
    }, {});

    // eslint-disable-next-line react/prop-types
    phaseMilestones.forEach((milestone, index) => {
        const shiftedMilestone = shiftedMilestones[index];
        if(shiftedMilestone) {
            const predictedMilestoneMessage = PredictedMilestoneMessageBox(
                milestone,
                milestones[index],
                shiftedMilestone
            )
            if (predictedMilestoneMessage) {
                differences.push(predictedMilestoneMessage);
            }
        }
    })

    if (!differences.length) {
        return null;
    }

    return (<HelpMessageBox
      messageList={differences}
      title={props.phase.phase_display}
      messageType="error"
    />)
}

PredictedPhaseMessageBox.propTypes = {
    phase: PropTypes.shape({
        dates_range: PropTypes.shape({}),
        phase_display: PropTypes.string,
        milestones: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    shiftedPhase: PropTypes.shape({
        dates_range: PropTypes.shape({}),
        phase_display: PropTypes.string,
        milestones: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    milestones: PropTypes.arrayOf(PropTypes.shape({})),
};

PredictedPhaseMessageBox.defaultProps = {
    shiftedPhase: null,
    milestones: {},
};

export default PredictedPhaseMessageBox
