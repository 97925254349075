import {Icon} from "@wfp/ui";
import {iconArrowRight} from "@wfp/icons";
import React from "react";
import PropTypes from "prop-types";

import Styles from "./styles.scss";

const ColumnSeparator = props => (
  <div data-testid='column-separator' className={Styles.container}>
    {props.icon && <Icon
      data-testid='column-separator-icon'
      icon={props.icon}
      width={props.size}
      height={props.size}
      className={Styles.icon}
    />
    }
  </div>
)

ColumnSeparator.propTypes = {
  size: PropTypes.string,
  icon: PropTypes.shape({}),
};

ColumnSeparator.defaultProps = {
  size: '19',
  icon: iconArrowRight,
};

export default ColumnSeparator;
