import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  focus,
  fieldInputPropTypes,
  fieldMetaPropTypes,
  change
} from "redux-form";
import { connect } from "react-redux";
import debounce from "lodash/debounce";

import Styles from "./styles.scss";

const validationStyles = {
  display: "block",
  maxHeight: "12.5rem"
};

// Exported here for tests
export class TextInputWithSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // dropdownCssClass: "dropdown",
      dropdownCssClass: Styles.dropdown,
      status: "done",
      options: [],
      otherOptions: [], // In case there is a 2nd list show in the dropdown
      selectedOption: null
    };
    this.handleBlur = debounce(this.handleBlur.bind(this), 150);
    this.lookupVal = debounce(this.lookupVal.bind(this), 300);
  }

  componentWillUnmount() {
    this.handleBlur.cancel();
    this.lookupVal.cancel();
  }

  handleFocus = () => {
    this.props.focusField(this.props.formName, this.props.input.name);
    if (this.props.input.value.length >= this.props.minChars) {
      this.filterOptions(this.props.input.value);
    }
  };

  /** Stop showing dropdown */
  handleBlur = () =>
    this.setState({
      // dropdownCssClass: "dropdown"
      dropdownCssClass: Styles.dropdown
    });

  lookupVal = () => {
    if (this.props.input.value.length >= this.props.minChars) {
      this.setState({ status: "pending" });
      return this.handleFocus();
    }
    return this.handleBlur();
  };

  handleKeyDown = event => {
    if (event.key === "ArrowDown") {
      this.setState(prevState => ({
        selectedOption:
          prevState.selectedOption !== null ? prevState.selectedOption + 1 : 0
      }));
    } else if (event.key === "ArrowUp") {
      this.setState(prevState => ({
        selectedOption:
          prevState.selectedOption && prevState.selectedOption > 0
            ? prevState.selectedOption - 1
            : null
      }));
    }
  };

  handleKeyUp = e => {
    // Keyboard navigation handling first
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      if (this.selectedOption)
        this.selectedOption.scrollIntoView({
          block: "center",
          behaviour: "smooth"
        });
      return;
    }
    if (e.key === "Enter" && this.selectedOption) {
      this.handleSelect();
      return;
    }
    if (
      this.props.input.value.length < this.props.minChars ||
      this.props.input.value.length === 0
    ) {
      this.handleBlur();
      this.props.updateField(this.props.formName, this.props.optionsField, "");
      if (this.props.otherOptionsField) {
        this.props.updateField(
          this.props.formName,
          this.props.otherOptionsField,
          ""
        );
      }
      this.setState({ status: "done" });
    } else if (e.key === "Backspace") {
      this.setState({
        options: [],
        otherOptions: [],
        status: "pending",
        selectedOption: null
      });
    }
    this.lookupVal();
  };

  /** Clear optionsField, otherOptionsField, extraFieldsToClear */
  clear = () => {
    const {
      extraFieldsToClear,
      formName,
      optionsField,
      otherOptionsField,
      updateField
    } = this.props;

    this.handleBlur();

    updateField(formName, optionsField, "");
    if (otherOptionsField) {
      updateField(formName, otherOptionsField, "");
    }

    extraFieldsToClear.forEach(fieldName => {
      updateField(formName, fieldName, "");
    });

    this.setState({
      options: [],
      otherOptions: [],
      selectedOption: null
    });
  };

  handleClick(item, other = false) {
    const {
      updateField,
      input,
      getDisplay,
      optionsField,
      otherOptionsField,
      indexField,
      formName,
      showCode
    } = this.props;

    const id = item[indexField];
    const label = getDisplay(item, showCode);

    input.onChange(label);
    if (!other) {
      updateField(formName, optionsField, id);
      if (otherOptionsField) {
        updateField(formName, otherOptionsField, null);
      }
    } else {
      updateField(formName, optionsField, null);
      if (otherOptionsField) {
        updateField(formName, otherOptionsField, id);
      }
    }
    this.handleBlur();
  }

  filterOptions(val) {
    this.props.fetchItems(val).then(res => {
      this.setState({
        options: res.value.data,
        // dropdownCssClass: `dropdown ${Styles.dropdown}`,
        dropdownCssClass: `${Styles.dropdown} ${Styles.dropdownOpen}`,
        status: "done"
      });
    });
    if (this.props.fetchOtherItems) {
      this.props.fetchOtherItems(val).then(res => {
        this.setState({
          status: "done",
          otherOptions: res.value.data
        });
      });
    }
  }

  renderList(other = false) {
    const options = other ? this.state.otherOptions : this.state.options;
    const indexField = other
      ? this.props.indexField
      : this.props.otherIndexField;

    // No results
    if (options.length === 0) {
      return (
        <li>
          <i>No matches found :(</i>
        </li>
      );
    }

    const { pictureField } = this.props;
    const showPicture = typeof pictureField !== "undefined";

    // Results fetched
    if (this.state.status === "done") {
      /* eslint-disable jsx-a11y/click-events-have-key-events */
      /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
      const elements = options.map((item, index) => {
        const selected = other
          ? this.state.selectedOption === index + this.state.options.length
          : this.state.selectedOption === index;

        const pictureURL = pictureField ? item[pictureField] : undefined;

        return (
          <li
            key={item[indexField]}
            onClick={() => this.handleClick(item, other)}
            className={selected ? Styles.selected : ""}
            ref={element => {
              if (selected) {
                this.selectedOption = element;
                this.handleSelect = () => this.handleClick(item, other);
              }
            }}
          >
            {showPicture && (
              <div className={Styles.avatarContainer}>
                {pictureURL ? (
                  <div
                    className={Styles.avatar}
                    style={{
                      backgroundImage: `url(${pictureURL})`
                    }}
                  />
                ) : (
                  <svg
                    className="wfp--user__icon wfp--user__icon--empty"
                    fill="#ffffff"
                    fillRule="evenodd"
                    height="14"
                    role="img"
                    viewBox="0 0 16 16"
                    width="14"
                    aria-label="username"
                    alt="username"
                  >
                    <title>Placeholder Avatar</title>
                    <path d="M6.123 9.455a3.5 3.5 0 1 1 3.754 0c1.958.97 3.571 3.424 4.436 6.64-5.852-.027-6.488-.027-12.62-.027.867-3.203 2.477-5.645 4.43-6.613z" />
                  </svg>
                )}
              </div>
            )}
            {other
              ? this.props.getOtherDisplay(item, this.props.showCode)
              : this.props.getDisplay(item, this.props.showCode)}
          </li>
        );
      });
      /* eslint-enable */
      if (options.length === 1 && this.props.autoSelect) {
        this.props.updateField(
          this.props.formName,
          other ? this.props.otherOptionsField : this.props.optionsField,
          options[0][indexField]
        );
        this.props.updateField(
          this.props.formName,
          this.props.input.name,
          other
            ? this.props.getOtherDisplay(options[0], this.props.showCode)
            : this.props.getDisplay(options[0], this.props.showCode)
        );
        this.props.updateField(
          this.props.formName,
          other ? this.props.optionsField : this.props.otherOptionsField,
          undefined
        );
      }
      return elements;
    }

    // Searching results
    return (
      <li>
        <i>Filtering results...</i>
      </li>
    );
  }

  render() {
    return (
      <div
        className={`${this.props.cssClass} wfp--form-item relative`}
        style={{ display: "block" }}
      >
        <label
          className={
            this.props.disabled
              ? "wfp--label wfp--label--disabled"
              : "wfp--label"
          }
          htmlFor={`${this.props.label}-label`}
        >
          {this.props.label}
        </label>
        <input
          className={`wfp--text-input ${Styles.input}`}
          style={{ borderColor: this.props.invalid && "#c5192d" }}
          name={this.props.input.name}
          value={this.props.input.value}
          onChange={this.props.input.onChange}
          disabled={this.props.disabled}
          readOnly={this.props.readOnly}
          id={`${this.props.label}-label`}
          type="text"
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          onKeyDown={this.handleKeyDown}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          placeholder={
            this.props.readOnly
              ? ""
              : `Please type at least ${this.props.minChars} characters to search`
          }
          aria-autocomplete="list"
        />
        {this.props.input.value &&
          !this.props.disabled &&
          !this.props.readOnly && (
            <div
              className={Styles.clearButton}
              // Relocate the close icon when a validation message is shown
              style={{ top: this.props.invalid && "calc(50% + 2px)" }}
              onClick={this.clear}
            >
              <svg
                height="20"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden
                focusable={false}
              >
                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
              </svg>
            </div>
          )}
        {this.state.status === "pending" && <span className="spinner" />}
        {!this.props.disabled && !this.props.readOnly && (
          <div className="relative">
            <ul className={this.state.dropdownCssClass}>
              {/* If there is an options label, show it */}
              {this.props.optionsLabel && (
                <li className={Styles.optionsLabel}>
                  {this.props.optionsLabel}
                </li>
              )}
              {/* Render the list of results for the first option type */}
              {this.renderList()}
              {/* If there is an 'otherOptions' label, show it too */}
              {this.props.otherOptionsLabel && (
                <li className={Styles.optionsLabel}>
                  {this.props.otherOptionsLabel}
                </li>
              )}
              {/* Render the list of results for the second option type */}
              {this.props.fetchOtherItems && this.renderList(true)}
            </ul>
          </div>
        )}
        {this.props.invalid && (
          <div className="wfp--form-requirement" style={validationStyles}>
            {this.props.invalidText}
          </div>
        )}
      </div>
    );
  }
}

TextInputWithSearch.propTypes = {
  // Redux form props
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  // The name of the redux form to make changes to it
  formName: PropTypes.string.isRequired,
  // Label
  label: PropTypes.string.isRequired,
  // Functions used to retrieve data for the two resources
  fetchItems: PropTypes.func.isRequired,
  fetchOtherItems: PropTypes.func,
  // Manually handle redux form state
  updateField: PropTypes.func.isRequired,
  focusField: PropTypes.func.isRequired,
  // Field names
  optionsField: PropTypes.string.isRequired,
  otherOptionsField: PropTypes.string,
  // Field labels
  optionsLabel: PropTypes.string,
  otherOptionsLabel: PropTypes.string,
  // Whether to show or note item.code
  showCode: PropTypes.bool,
  // Disable input
  disabled: PropTypes.bool,
  // css class applied to the fieldset
  cssClass: PropTypes.string,
  // Which field should be used as index, default to id
  indexField: PropTypes.string,
  // Function used to retrieve field representation (item, showCode) => String
  getDisplay: PropTypes.func,
  // Field used to retrieve picture
  pictureField: PropTypes.string,
  // Which field should be used as index for the other field, default to id
  otherIndexField: PropTypes.string,
  // Function used to retrieve other field representation (item, showCode) => String
  getOtherDisplay: PropTypes.func,
  // Minimum number of chars before starting searching
  minChars: PropTypes.number,
  // Autoselect if only one result
  autoSelect: PropTypes.bool,
  readOnly: PropTypes.bool,
  // Fields to clear on clear
  extraFieldsToClear: PropTypes.arrayOf(PropTypes.string),
  invalid: PropTypes.bool,
  invalidText: PropTypes.string
};

TextInputWithSearch.defaultProps = {
  showCode: false,
  cssClass: "",
  fetchOtherItems: undefined,
  otherOptionsField: undefined,
  optionsLabel: undefined,
  otherOptionsLabel: undefined,
  disabled: undefined,
  autoSelect: false,
  // These defaults are for backwards compatibility with the old TextInputWithSearch
  getDisplay: (item, showCode) =>
    showCode
      ? `${item.code} - ${item.name}`
      : item.display_name || `${item.first_name} ${item.last_name}`,
  indexField: "id",
  pictureField: undefined,
  minChars: 4,
  getOtherDisplay: (item, showCode) =>
    showCode
      ? `${item.code} - ${item.name}`
      : item.name || `${item.first_name} ${item.last_name}`,
  otherIndexField: "id",
  readOnly: false,
  extraFieldsToClear: [],
  invalid: false,
  invalidText: "A valid value is required"
};

const mapDispatchToProps = dispatch => ({
  updateField: (formName, fieldName, val) =>
    dispatch(change(formName, fieldName, val)),
  focusField: (formName, fieldName) => dispatch(focus(formName, fieldName))
});

export default connect(
  null,
  mapDispatchToProps
)(TextInputWithSearch);
