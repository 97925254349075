import React from "react";
import PropTypes from "prop-types";

import ModalForm from "ModalForm";
import { reduxForm } from "redux-form";
import PersonFormComponent from "./PersonForm";

const AddModal = props => (
  <ModalForm
    title={props.initialValues?.id ? "Edit Team member" : "Add Team member"}
    isVisible={props.show}
    onClose={props.toggleModal}
    onSubmit={e => {
      e.preventDefault();
      props.onSubmit();
    }}
    onFormSubmit={e => {
      e.preventDefault();
      props.onSubmit();
    }}
    buttonDisabled={props.disabled}
    hiddenButtonDisabled={props.disabled}
    formClassName={props.formClassName}
  >
    <fieldset disabled={props.disabled}>
      <PersonFormComponent
        countries={props.countries}
        sex={props.sex}
        initialValues={props.initialValues}
        inModal
      />
    </fieldset>
  </ModalForm>
);

AddModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired,
  sex: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  formClassName: PropTypes.string,
  disabled: PropTypes.bool
};

AddModal.defaultProps = {
  show: false,
  disabled: false,
  formClassName: "",
  initialValues: null
};

export default reduxForm({
  form: "people",
  enableReinitialize: true
})(AddModal);
