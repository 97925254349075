import { combineReducers } from "redux";
import {
  fetching,
  submitting,
  items,
  togglable,
  selectable,
} from "./resource";

export default combineReducers({
  selectedItem: selectable("activityCategories"),
  isFetching: fetching("activityCategories"),
  isSubmitting: submitting("activityCategories"),
  items: items("activityCategories"),
  showAddModal: togglable("addModal"),
});
