import { schema } from "normalizr";

import Resource from "./resource";
import { deviationTypes } from "../types";

const deviationSchema = new schema.Entity("deviations");
export default new Resource(
  "/evaluations/<evaluationId>/deviations",
  deviationTypes,
  deviationSchema,
  false
);
