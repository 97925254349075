import { schema } from "normalizr";
import { countriesInfoTypes } from "../types";
import Resource from "./resource";

const countryInfoSchema = new schema.Entity("countriesInfo");

export default new Resource(
  "/countries-info",
  countriesInfoTypes,
  countryInfoSchema
);
