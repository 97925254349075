import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import ModalForm from "ModalForm";

import { Grid, Row, Col } from "Grid";
import Select from "Select";
import TextInput from "TextInput";

const UserEditModal = props => (
  <ModalForm
    title={props.title}
    isVisible={props.show}
    onClose={props.toggleModal}
    onSubmit={e => {
      e.preventDefault();
      props.onSubmit();
    }}
    onFormSubmit={e => {
      e.preventDefault();
      props.onSubmit();
    }}
    buttonDisabled={false}
    hiddenButtonDisabled={props.disabled}
  >
    <fieldset disabled={props.disabled}>
      <Grid fluid>
        <Row>
          <Col xs>
            <Field
              name="display_name"
              component={TextInput}
              label="Display Name"
              readOnly
            />
          </Col>
        </Row>
        <Row>
          <Col xs>
            <Field
              name="username"
              component={TextInput}
              label="Username"
              readOnly
            />
          </Col>
        </Row>
        <Row>
          <Col xs>
            <Field name="email" component={TextInput} label="Email" readOnly />
          </Col>
        </Row>
        <Row>
          <Col xs>
            <Field
              name="first_name"
              component={TextInput}
              label="First Name"
              readOnly
            />
          </Col>
        </Row>
        <Row>
          <Col xs>
            <Field
              name="last_name"
              component={TextInput}
              label="Last Name"
              readOnly
            />
          </Col>
        </Row>
        <Row middle="xs">
          <Col xs={4}>
            <Field
              name="is_active"
              component={Select}
              label="Active"
              openMenuOnClick
              options={[
                { label: "True", value: true },
                { label: "False", value: false }
              ]}
            />
          </Col>
          <Col xs={8}>
            <Field
              name="role"
              component={Select}
              label="Role"
              openMenuOnClick
              options={props.oevmisRoleOptions}
            />
          </Col>
        </Row>
      </Grid>
    </fieldset>
  </ModalForm>
);

UserEditModal.propTypes = {
  disabled: PropTypes.bool,
  initialValues: PropTypes.shape({ id: PropTypes.any }), // eslint-disable-line
  isSaveButtonDisabled: PropTypes.bool,
  loadAGWUserOptions: PropTypes.func.isRequired,
  oevmisRoleOptions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  options: PropTypes.object, // eslint-disable-line
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired
};

UserEditModal.defaultProps = {
  disabled: false,
  initialValues: {},
  isSaveButtonDisabled: true,
  show: false
};

export default reduxForm({
  form: "users",
  enableReinitialize: true
})(UserEditModal);
