import { schema } from "normalizr";
import { donorsTypes } from "../types";
import Resource from "./resource";
import {client} from "../store";

export const donorsSchema = new schema.Entity(
    "donors"
);

export default new Resource(
    "/donors",
    donorsTypes,
    donorsSchema,
);

client.enableAutoCamelCase('/donors');
