import { combineReducers } from "redux";
import {
  fetching,
  submitting,
  items,
  togglable,
  selectable,
} from "./resource";

export default combineReducers({
  selectedItem: selectable("partners"),
  isFetching: fetching("partners"),
  isSubmitting: submitting("partners"),
  items: items("partners"),
  showAddModal: togglable("addModal"),
});
