exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__Tooltip___27o7q{display:inline-flex}*+.styles__Tooltip___27o7q>.styles__TooltipIcon___1sMd8,.styles__Tooltip___27o7q>*+.styles__TooltipIcon___1sMd8{margin-left:0.375rem}.styles__TooltipIcon___1sMd8 svg.styles__iconHelp___wMDVv path{fill:#0a6eb4}.styles__TooltipIcon___1sMd8 svg.styles__iconInfo___2YoET path{fill:#53768f}.styles__TooltipMessage___3JRAI{font-size:.875rem;text-align:left}\n", ""]);

// Exports
exports.locals = {
	"Tooltip": "styles__Tooltip___27o7q",
	"TooltipIcon": "styles__TooltipIcon___1sMd8",
	"iconHelp": "styles__iconHelp___wMDVv",
	"iconInfo": "styles__iconInfo___2YoET",
	"TooltipMessage": "styles__TooltipMessage___3JRAI"
};