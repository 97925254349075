import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactRouterPropTypes from "react-router-prop-types";
import { withRouter, NavLink, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, BreadcrumbHome } from "@wfp/ui";
import get from "lodash/get";

/** App routes. Add to this list using names matching the necessary URLs */
const routes = (id, evaluation) => ({
  [`/evaluations/${id}`]: evaluation
    ? evaluation.publication_reference || evaluation.internal_code
    : id,
  [`/evaluations/${id}/info`]: "Info",
  [`/evaluations/${id}/coverage`]: "Country coverage",
  [`/evaluations/${id}/phases`]: "Phases and Milestones",
  [`/evaluations/${id}/members`]: "Team members",
  [`/evaluations/${id}/products`]: "Products",
  [`/evaluations/${id}/standards`]: "PHQA Scores",
  [`/evaluations/${id}/gender`]: "PHQA UN-SWAP EPI",
  [`/evaluations/${id}/enhanced-activity`]:  "Activity",
  "/evaluations": "Evaluations",
  "/companies": "Companies",
  "/countries-info": "Countries",
  "/people": "Consultants",
  "/enhanced-activity": "Activity",
  "/sustainable-development-goals": "SDGs",
  "/reasons-for-cancellation": "RFCs",
  "/milestones": "Milestones",
  "/donors": "Donors",
  "/crosscutting-priority": "CrosscuttingPriority",
  "/types": "Types",
  "/status-validation-schema": "Status validation schema",
  "/topics": "Topics",
  "/activity-category": "Activity Category",
  "/product-types": "Product Types",
  "/partners": "Partners",
});

// Exported for tests
export const getPaths = pathname => {
  const paths = ["/"];
  if (pathname === "/") return paths;
  pathname.split("/").reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });
  return paths.map(p => p.replace("?", ""));
};

const capitalize = s => s && s[0].toUpperCase() + s.slice(1);

/**
 * Breadcrumbs enable users to quickly see their location within a path of
 * navigation and move up to a parent level if desired.
 */
const Breadcrumbs = React.memo(props => {
  const { evaluationId, tab } = props.match.params;
  const evIdString = evaluationId
    ? `${
        routes(evaluationId, props.evaluation)[`/evaluations/${evaluationId}`]
      } |`
    : "";

  useEffect(() => {
    const pageString = tab === "people" ? "" : `${capitalize(tab)} |`;
    let documentTitle = "Office of Evaluation";
    if (pageString && tab && evIdString) {
      documentTitle = `${pageString} ${evIdString} ${tab} OEVMIS`;
    }
    document.title = documentTitle;
  });
  const paths = getPaths(props.match.url);

  const getRoutes = routes(evaluationId, props.evaluation);

  const mapBreadcrumbs = (route, i) => {
    if (i === paths.length - 1) {
      return (
        <BreadcrumbItem key={route} disableLink>
          {getRoutes[route]}
        </BreadcrumbItem>
      );
    }
    return (
      <BreadcrumbItem key={route}>
        <NavLink to={route}>{getRoutes[route]}</NavLink>
      </BreadcrumbItem>
    );
  };

  return (
    <Breadcrumb>
      <Link to="/">
        <BreadcrumbHome hometext="Evaluations" />
      </Link>
      {paths.map(mapBreadcrumbs)}
    </Breadcrumb>
  );
});

const mapStateToProps = (
  state,
  {
    match: {
      params: { evaluationId }
    }
  }
) => ({
  evaluation: get(state, `entities.evaluations[${evaluationId}]`)
});

export default withRouter(connect(mapStateToProps)(Breadcrumbs));

Breadcrumbs.propTypes = {
  /** Evaluation ID */
  match: ReactRouterPropTypes.match.isRequired,
  /** Evaluation object */
  evaluation: PropTypes.shape({})
};
Breadcrumbs.defaultProps = {
  evaluation: undefined
};
