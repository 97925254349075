import React from "react";
import PropTypes from "prop-types";
import { fieldInputPropTypes, fieldMetaPropTypes } from "redux-form";

/**
 * Used for hidden redux-form fields
 */
const HiddenField = ({ input, cssClass, meta: { touched, error } }) => (
  <div className={[cssClass, "hidden"].join(" ")}>
    <input {...input} type="hidden" />
    {touched && error && <span className="error">{error}</span>}
  </div>
);

HiddenField.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  cssClass: PropTypes.string.isRequired
};

export default HiddenField;
