import React from "react";
import PropTypes from "prop-types";

import {
  OPEN_DOWN,
  OPEN_UP,
  ANCHOR_LEFT,
  ANCHOR_RIGHT,
  HORIZONTAL_ORIENTATION,
  VERTICAL_ORIENTATION
} from "react-dates/lib/constants";

import moment from "moment";

// Must import "react-dates/initialize" before any react-dates component
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";

import styled from "styled-components";

import {DEFAULT_DATE_FORMAT} from "../../../utils";
import rangeDateHelper from "./rangeDateHelper";
import {DatePicker} from "../DatePicker";
import {NavNext, NavPrev, CalendarTitle, Legend} from "../components";

const validationStyles = {
  display: "block",
  maxHeight: "12.5rem"
};

// DateRangePicker custom styles
const StyledWrapper = styled.div.attrs(props => ({
  invalidStyles: props.invalid && "border-color: #c5192d"
}))`
  div {
    // Make the clear button wrapper round
    .DateRangePickerInput_clearDates {
      line-height: 15px;
    }
    .DateInput_input {
      padding-left: 8px !important;
    }
    .DateInput_input::placeholder {
      color: #8c9ba5;
    }
    .DateRangePickerInput_calendarIcon_svg {
      fill: #0b77c2;
    }
    // Validation styles
    .DateInput_input {
      ${props => props.invalidStyles};
    }
  }
`;


//
// ALERT! If changes are made in this component, please, check if styles are working as expected
//

class DateRangePickerField extends DatePicker {
  constructor(props) {
    super(props);

    this.isDayHighlighted = this.isDayHighlightedFactory();

    this.state = {
      focusedInput: null,
    };
  }

  /** used to handle the focus state */
  onFocusChange = focusedInput =>
    this.props.disabled
      ? this.setState({ focusedInput: { startDate: false, endDate: false } })
      : this.setState({ focusedInput });

  /** used to disable days outside a range in the date picker */
  isOutsideRange = day => this.props.enableStrictRange && rangeDateHelper(this.startDate, this.endDate).isOutsideRange(day);

  /**
   * Used to highlight days outside a range in the date picker
   *
   * Factory is required to able to trigger rerender after days were shifting. It allows to better control when you
   * want to trigger a recalculation of the method because you basically generate a new method every time you
   * want to guarantee a recalculation.
   * */
  isDayHighlightedFactory = () => (day) => {
    return this.props.enableHighlight && rangeDateHelper(this.startDate, this.endDate).isOutsideRange(day, false);
  }

  /** func used by DateRangePicker to set initial visible month of calendar */
  initialVisibleMonth = () => {
    const initialVisibleMonth = this.props.initialVisibleMonth();
    // TODO is props.input.startDate EVER defined???
    if (initialVisibleMonth && !this.props.input.startDate) {
      return () => moment(initialVisibleMonth, DEFAULT_DATE_FORMAT);
    }
    return undefined;
  };

  updateRangeDates = () => {
    if (this.props.setDatesRange) {
      ([this.startDate, this.endDate ] = this.props.setDatesRange() ?? [null, null]);
    }
  }

  onDatesChange = (values, callback) => {
      if(this.props.onOverLapping) {
        const [startDiff, endDiff] = rangeDateHelper(this.startDate, this.endDate)
          .getOverlappingDifferences(values, this.props.input.value);

        if (startDiff || endDiff) {
            this.props.onOverLapping(startDiff, endDiff, () => {
              callback(values);
              this.isDayHighlighted = this.isDayHighlightedFactory();
            });
        } else {
              callback(values);
        }
      } else {
        callback(values);
      }
  }

  render() {
    const {
      input: {
        value: { startDate = null, endDate = null },
        onChange
      },
      index,
      renderCalendarInfo,
      disabled,
      invalid,
      invalidText,
      openDirection,
      anchorDirection,
      orientation,
      withPortal,
    } = this.props;

    this.updateRangeDates();

    return (
      <StyledWrapper className="wfp--form-item" invalid={invalid}>
        <DateRangePicker
          testId={this.props.testId}
          endDateId={`endDate-${index}`}
          endDate={endDate}
          minimumNights={this.props.minimumNights}
          endDatePlaceholderText="End Date"
          focusedInput={this.state.focusedInput}
          onDatesChange={(value) => this.onDatesChange(value, onChange)}
          onFocusChange={this.onFocusChange}
          startDateId={`startDate-${index}`}
          startDate={startDate}
          startDatePlaceholderText="Start Date"
          navPrev={
            <NavPrev onNavigateYear={this.onNavigateYear} />
          }
          navNext={
            <NavNext onNavigateYear={this.onNavigateYear} />
          }
          renderMonthElement={this.renderMonthElement}
          displayFormat={DEFAULT_DATE_FORMAT}
          firstDayOfWeek={1}
          isDayHighlighted={this.isDayHighlighted}
          isOutsideRange={this.isOutsideRange}
          initialVisibleMonth={this.initialVisibleMonth()}
          horizontalMonthPadding={50}
          readOnly
          noBorder
          showDefaultInputIcon
          inputIconPosition="after"
          withPortal={withPortal}
          hideKeyboardShortcutsPanel
          showClearDates={!disabled}
          openDirection={openDirection}
          anchorDirection={anchorDirection}
          orientation={orientation}
          renderCalendarInfo={() => (
            <div>
              <CalendarTitle>{renderCalendarInfo}</CalendarTitle>
              {this.props.enableLegends &&
                <div style={{ paddingRight: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <Legend label="Selected range" color="#85b7da" />
                  {this.props.onOverLapping && <Legend label="Overlapping" color="#ffe8bc" />}
                </div>
              }
            </div>
          )}
          calendarInfoPosition="top"
        />
        {invalid && (
          <div className="wfp--form-requirement" style={validationStyles}>
            {invalidText}
          </div>
        )}
      </StyledWrapper>
    );
  }
}

DateRangePickerField.propTypes = {
  initialVisibleMonth: PropTypes.func,
  setDatesRange: PropTypes.func,
  index: PropTypes.number.isRequired,
  input: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  renderCalendarInfo: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node
  ]),
  invalid: PropTypes.bool,
  invalidText: PropTypes.string,
  disabled: PropTypes.bool,
  withPortal: PropTypes.bool,
  anchorDirection: PropTypes.oneOf([ANCHOR_LEFT, ANCHOR_RIGHT]),
  openDirection: PropTypes.oneOf([OPEN_DOWN, OPEN_UP]),
  orientation: PropTypes.oneOf([HORIZONTAL_ORIENTATION, VERTICAL_ORIENTATION]),
  testId: PropTypes.string,
  onOverLapping: PropTypes.func,
  enableStrictRange: PropTypes.bool,
  enableHighlight: PropTypes.bool,
  enableLegends: PropTypes.bool,
  minimumNights: PropTypes.number,
};

DateRangePickerField.defaultProps = {
  initialVisibleMonth: undefined,
  setDatesRange: undefined,
  renderCalendarInfo: null,
  invalid: false,
  invalidText: "A valid date is required",
  disabled: false,
  withPortal: true,
  anchorDirection: ANCHOR_LEFT,
  openDirection: OPEN_DOWN,
  orientation: HORIZONTAL_ORIENTATION,
  testId: undefined,
  onOverLapping: undefined,
  enableStrictRange: false,
  enableHighlight: false,
  enableLegends: false,
  minimumNights: 1,
};

export default DateRangePickerField;
