import React,{useState} from "react";

export const SeeMore = ({
  children,
  maxLength = 100,
}) => {
  const [expanded, setExpanded] = useState(false);

  const fullText = (children ?? '').toString();
  const shortText = fullText.slice(0, maxLength)

  if (shortText === fullText) {
    return fullText;
  }

  return (
    <div>
      <span>
        {expanded ? fullText : `${shortText}…`}
        {' '}
      </span>
      <span
        role="button"
        style={{
          fontWeight: 'bold',
          cursor: 'pointer',
          userSelect: 'none',
          whiteSpace: 'nowrap',
        }}
        tabIndex={0}
        onClick={() => setExpanded(x => !x)}
      >
        {expanded ? 'See less «' : 'See more »'}
      </span>
    </div>
  );
};
