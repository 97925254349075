import {Button} from "@wfp/ui";
import React from "react";
import {destroy} from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";
import Styles from "../ListComponent/styles.scss";


export const ClearAllFilters = () => {
  const dispatch = useDispatch()
  const {
    filters,
    activeAdvancedFilters: { evaluationSearch, ...activeAdvancedFilters}
  } = useSelector(state => state.pages.evaluations);
  const searchQuery = useSelector(state => state.form.search?.values?.query);

  const isClearEnabled = () => {
      const isSearchActive = !isEmpty(searchQuery);
      const isAnyFilterActive = Object.values(filters).some(Boolean);
      const activeAdvancedFilterWithQuery = Object.values(activeAdvancedFilters)
        .filter(filter => Object.keys(filter.queryParams ?? {}).length > 0)

      return isSearchActive || isAnyFilterActive || (activeAdvancedFilterWithQuery.length > 0);
  }

  const resetAllFilters = () => {
    dispatch({type: "RESET_ALL_FILTERS"});
    dispatch(destroy("search"));
    dispatch(destroy("newEvSearch"));
  }

  return (
    <Button
      className={Styles.clearFilters}
      kind="ghost"
      onClick={resetAllFilters}
      disabled={!isClearEnabled()}
    >
      Clear all filters
    </Button>
  )
}