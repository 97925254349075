import {normalize, schema} from "normalizr";
import {toast} from "react-toastify";
import {standardsTypes, standardsFrameworkTypes} from "../types";
import Resource from "./resource";
import {client} from "../store";
import {mergeItemsEntities} from "./entities";

const standardsSchema = new schema.Entity("standards");
const standardsFrameworkSchema = new schema.Entity("standardsFrameworks");

class StandardsAction extends Resource {
    savePHQAStandards = (evaluationId, standards) => (dispatch) => {
        const cleanedStandards = standards
            .filter(standard => standard.score) // Remove deleted scores
            .map((standard) => ({
                criterion: standard?.criterion?.id,
                score: standard.score || null,
            }))
        return dispatch({
            type: "SAVE_TOTAL_SCORE",
            payload: client.put(`${this.addParamsToPath({evaluationId})}/bulk_update/`, {
                standards: cleanedStandards,
            })
                .then((response) => {
                    const data = normalize(response.data, [this.schema]);
                    dispatch(mergeItemsEntities(data, this.types.name));
                    toast.success("Standards updated!", {autoClose: 1500});
                }).catch(error => {
                    this.handleError(error);
                })
        });
    }
}

export default new StandardsAction(
    "/evaluations/<evaluationId>/standards",
    standardsTypes,
    standardsSchema
);

client.enableAutoCamelCase(/\/evaluations\/\d+\/standards\/$/);

export const standardsFramework = new Resource(
    "/standard-frameworks",
    standardsFrameworkTypes,
    standardsFrameworkSchema
);

client.enableAutoCamelCase('/standard-frameworks');
