import Select from "Select";
import {Field} from "redux-form";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Styles from "../../styles.scss";
import {adaptInputValues} from "../../utils";
import {advancedFilterActions} from "../../../../actions/filters";
import {getEvaluationActions} from "../../../../actions/evaluations";


const PlannedCompletionYears = ({label, fieldName, isDisabled}) => {
  const dispatch = useDispatch();
  const {filtersData} = useSelector(state => state.pages.evaluations);

  // eslint-disable-next-line camelcase
  const maxPlannedCompletedYear = filtersData?.planned_completed_years?.max ?? null;
  // eslint-disable-next-line camelcase
  const minPlannedCompletedYear = filtersData?.planned_completed_years?.min ?? null;

  const plannedCompletedYearsOptions = [];
  if (maxPlannedCompletedYear !== null && minPlannedCompletedYear !== null) {
    for (let i = maxPlannedCompletedYear; i >= minPlannedCompletedYear; i -= 1) {
      plannedCompletedYearsOptions.push({label: i.toString(), value: i.toString()});
    }
  }

  const onChange = inputs => {
    if (inputs !== null && inputs.length > 0) {
      dispatch(advancedFilterActions.addFilter(fieldName, inputs, {
        planned_completed_years: adaptInputValues(inputs)
      }));
    } else {
      dispatch(advancedFilterActions.removeFilter(fieldName));
    }
  };

  useEffect(() => {
     dispatch(getEvaluationActions().filtersData());
  }, [])

  return (
    <Field
      name={fieldName}
      label={label}
      component={Select}
      options={plannedCompletedYearsOptions}
      onChangeExtra={onChange}
      openMenuOnClick
      cssClass={Styles.noPaddingWithSpace}
      disabled={isDisabled}
      allowMultiple
    />
  )
}

export default PlannedCompletionYears;