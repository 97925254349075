import React from "react";

import { Icon } from "@wfp/ui";
import { iconEdit } from "@wfp/icons";

import { capitalize } from "../../utils";

const columnsGenerator = (setItem, canEdit) => [
  {
    Header: "Country",
    accessor: "country"
  },
  {
    Header: "Development Status",
    accessor: "development_status",
    /** UGLY: make development_status label by manipulating value */
    Cell: row =>
      row.original.development_status &&
      capitalize(row.original.development_status.toLowerCase())
  },
  {
    Header: "Region",
    accessor: "region",
  },
  {
    Header: "",
    accessor: "id",
    /* eslint-disable */
    Cell: row =>
      canEdit ? (
        <Icon
          icon={iconEdit}
          width="19"
          height="19"
          description="Edit item"
          onClick={() => setItem(row.value)}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Icon
          icon={iconEdit}
          width="19"
          height="19"
          description="Edit item"
          style={{ cursor: "pointer", opacity: "0.5" }}
        />
      ),
    /* eslint-enable */
    resizable: false,
    sortable: false,
    width: 50,
    style: { justifyContent: "center" }
  }
];

export default columnsGenerator;
