import React from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { Tabs as WFPTabs, Tab } from "@wfp/ui";

import Styles from "./styles.scss";

/**
 * isActive is used to determine if a Tab is selected or not whenever the `url` match.
 * e.g:
 * currentUrl is `http://.../phases/` and the tab url is `phases` isActive will return true
 * and you will be able to see the tab underlined.
 *
 * exported for test
 */
export const isActive = (currentUrl, item) => {
  if (item.subUrlRegexPatter) {
    return new RegExp(item.subUrlRegexPatter).test(currentUrl)
  }
  return currentUrl.endsWith(item.url);
}

/**
 * Returns the index of the selected tab
 * @param currentUrl the current URL
 * @param items the list of tabs
 */
export const getSelectedTab = (items, currentUrl) => {
  let selected = 0;
  items.map((item, index) => {
    if (isActive(currentUrl, item)) {
      selected = index;
    }
    return null;
  });
  return selected;
};

/*
 * Tabs are used to quickly navigate between views within the same context
 */
const Tabs = props => (
  <WFPTabs
    className={Styles.Tabs}
    selected={getSelectedTab(props.items, props.location.pathname)}
    onKeyDown={() => undefined}
  >
    {props.items.map(n => (
      <Tab
        key={n.url}
        label={n.label}
        onClick={() => !n.disabled && props.history.push(n.url)}
        disabled={n.disabled}
      />
    ))}
  </WFPTabs>
);

Tabs.propTypes = {
  /** Tabs navigations items */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** Tab label */
      label: PropTypes.string,
      /** Tab url */
      url: PropTypes.string,
      /** SubTab url Regex Patter */
      subUrlRegexPatter: PropTypes.string,
    })
  ),
  /* React router location */
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  /* History URL */
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

Tabs.defaultProps = {
  items: []
};

export default withRouter(Tabs);
