import React, {useState} from "react";
import PropTypes from "prop-types";
import BaseSelect from "Select";
import Styles from "./styles.scss";

const Select = props => {
  let values = props.value;
  const [options, setOptions ] = useState(props.options);

  // Avoid slow loading replace readonly string and empty components with div
  if (props.readOnly && (typeof props.value === 'string' ||  props.value === null || props.value.length === 0)) {
    return (
      <div className={Styles.container}>
        <div className={Styles.readonlyTextPlaceholder}>
          { props.value}
        </div>
      </div>
    );
  }

  if (typeof props.value === 'string') {
    values = [props.value];
  }

  return (
    <div className={Styles.container}>
      <BaseSelect
        label={null}
        className="wfp--react-select-container"
        classNamePrefix="wfp--react-select"
        id={props.id}
        options={options}
        readOnly={props.readOnly}
        allowMultiple={props.allowMultiple}
        input={{
            value: values?.map(value => ({
              value,
              label: value,
            })),
            onChange: props.onChange,
            onFocus: () => {
              if (props.dynamicOptions) {
                 setOptions(props.dynamicOptions());
              }
            },
          }}
       />
    </div>
  )
}


Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  allowMultiple: PropTypes.bool,
  onChange: PropTypes.func,
  dynamicOptions: PropTypes.func,
};

Select.defaultProps = {
  value: [],
  readOnly: false,
  allowMultiple: false,
  options: [],
  onChange: undefined,
  dynamicOptions: null,
};

export default Select;
