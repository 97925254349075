/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */

import React from "react";
import PropTypes from "prop-types";

import StatusField from "./StatusField";
import Title from "./Title";

import styles from "../styles.scss";

const StatusGroup = ({
  activeFields,
  baseUrl,
  changedFields,
  code,
  currentUrl,
  deviationsDelta,
  editableFields,
  fields,
  getFieldUrl,
  getLabel,
  hasError,
  hasErrors,
  isCurrentStatus,
  isCurrentWorkingStatus,
  isStatusReached,
  isStatusExpanded,
  name,
  number,
  onApply,
  onToggleRequirements,
  onFieldEdit,
  requirementsDelta
}) => {
  const expandedStatusStyles = isStatusExpanded ? styles.isExpanded : "";

  return (
    <>
      <Title
        code={code}
        name={name}
        number={number}
        hasErrors={hasErrors}
        isStatusReached={isStatusReached}
        onApply={onApply}
        isCurrentStatus={isCurrentStatus}
        isCurrentWorkingStatus={isCurrentWorkingStatus}
        onToggleRequirements={onToggleRequirements}
        isStatusExpanded={isStatusExpanded}
      />
      <ul className={`${styles.requirementsList} ${expandedStatusStyles}`}>
        {requirementsDelta &&
          requirementsDelta.map(field => {
            const url = `${baseUrl}${getFieldUrl(field)}`;
            return (
              <StatusField
                withUrl={url !== currentUrl}
                key={field}
                errors={hasError(code, field) && !fields[field]}
                label={getLabel(field)}
                fieldName={field}
                to={url}
                selected={activeFields.includes(field)}
                animate={changedFields.includes(field)}
                editable={editableFields}
                disabled={false}
                onFieldEdit={() => onFieldEdit(field, false)}
              />
            );
          })}
      </ul>
      <ul className={`${styles.requirementsList} ${expandedStatusStyles}`}>
        {deviationsDelta &&
          deviationsDelta.map(field => {
            const url = `${baseUrl}${getFieldUrl(field)}`;
            return (
              <StatusField
                withUrl={url !== currentUrl}
                key={field}
                errors={hasError(code, field) && !fields[field]}
                label={getLabel(field)}
                fieldName={field}
                to={url}
                selected={activeFields.includes(field)}
                animate={changedFields.includes(field)}
                editable={editableFields}
                disabled
                onFieldEdit={() => onFieldEdit(field, true)}
              />
            );
          })}
      </ul>
    </>
  );
};

StatusGroup.propTypes = {
  activeFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  baseUrl: PropTypes.string.isRequired,
  changedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  code: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
  deviationsDelta: PropTypes.array,
  editableFields: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  getFieldUrl: PropTypes.func.isRequired,
  getLabel: PropTypes.func.isRequired,
  hasError: PropTypes.func.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  isCurrentStatus: PropTypes.bool.isRequired,
  isCurrentWorkingStatus: PropTypes.bool.isRequired,
  isStatusReached: PropTypes.bool.isRequired,
  isStatusExpanded: PropTypes.bool,
  name: PropTypes.string.isRequired,
  number: PropTypes.number,
  onApply: PropTypes.func.isRequired,
  onToggleRequirements: PropTypes.func.isRequired,
  onFieldEdit: PropTypes.func.isRequired,
  requirementsDelta: PropTypes.array
};

StatusGroup.defaultProps = {
  isStatusExpanded: false,
  number: undefined,
  deviationsDelta: [],
  requirementsDelta: []
};

export default StatusGroup;
