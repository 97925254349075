import React from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Button } from "@wfp/ui";
import { iconAddGlyph } from "@wfp/icons";
import SecondaryNavigation from "SecondaryNavigation";
import Loading from "Loading";
import ListComponent from "ListComponent";
import { milestonesActions } from "../../actions/milestones";
import { phasesActions } from "../../actions/phases";
import TableWithModalsMixin from "../../components/TableWithModalsMixin";
import { toggle } from "../../actions/togglable";
import MilestonesModal from "./MilestoneModal";
import columnsGenerator from "./tableColumns";
import productTypesActions from "../../actions/productTypes";


export class MilestoneListComponent extends TableWithModalsMixin {
  valuesAdapter = formValues => ({
    ...formValues,
    phase: formValues.phase?.value,
    productType: formValues.productType?.value ?? null,
  });

  componentDidMount() {
    this.props.fetchData();
    this.props.fetchPhases();
    this.props.fetchProductTypes();
  }

  render() {
    return this.props.isFetching ? (
      <Loading />
    ) : (
      <div>
        <SecondaryNavigation
          additional={
            <Button
              type="submit"
              icon={iconAddGlyph}
              iconDescription="Add a new Milestones"
              disabled={!this.props.canAdd}
              onClick={() => this.getItem(null)}
            >
              New Milestone
            </Button>
          }
          title="Milestones"
        />

        <MilestonesModal
          title={`${this.props.selectedItem?.id ? 'Edit' : 'New' } Milestone`}
          initialValues={this.props.selectedItem}
          toggleModal={this.props.toggleAddModal}
          show={this.props.showMilestoneModal}
          onSubmit={this.saveItem}
          canEdit={this.props.canEdit}
          phaseOptions={this.props.phaseOptions}
          productTypeOptions={this.props.productTypeOptions}
        />

        <ListComponent
          fetching={this.props.isFetching}
          tableData={this.props.data}
          columns={columnsGenerator(this.getItem, this.props.canEdit)}
          noSearch
          enableDragAndDropSorting
          handleDragEnd={(results) => this.handleDragEnd(results)}
          pageSize={50}
        />
      </div>
    );
  }
}

MilestoneListComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      isDeprecated: PropTypes.bool
    })
  ).isRequired,
  canEdit: PropTypes.bool,
  canAdd: PropTypes.bool,
  selectedItem: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    isDeprecated: PropTypes.bool
  }),
  formValues: PropTypes.shape({}),
  showMilestoneModal: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  productTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
};

export const mapStateToProps = state => {
  const userPermissions = get(state, "user[0].permissions.permission", []);
  const milestoneEntities = get(state, "entities.milestones", {});
  const phaseEntities = get(state, "entities.phases", {});
  const productTypeEntities = get(state, "entities.productTypes", {});

  const page = get(state, "pages.milestones");
  const data =
    page &&
    page.items &&
    page.items
      .map(id => milestoneEntities[id])
      .sort((a, b) => a.position - b.position);

  const formValues = state.form.milestones
    ? state.form.milestones.values
    : {};

  const canEdit = userPermissions.includes(
    "exercise.change_milestone"
  );

  const canAdd = userPermissions.includes(
    "exercise.add_milestone"
  );

  const selectedMilestones = milestoneEntities[page.selectedMilestone];

  return {
    data,
    canAdd,
    canEdit,
    selectedItem: selectedMilestones && {
      ...selectedMilestones,
      phase: {
        value: phaseEntities[selectedMilestones?.phase]?.id,
        label: phaseEntities[selectedMilestones?.phase]?.name
      },
      productType: {
        value: productTypeEntities[selectedMilestones?.productType]?.id,
        label: productTypeEntities[selectedMilestones?.productType]?.name
      },
    },
    phaseOptions: Object.values(phaseEntities).map((phase) => ({ value: phase.id, label: phase.name })),
    productTypeOptions: Object.values(productTypeEntities).map((type) => ({ value: type.id, label: type.name })),
    formValues,
    isFetching: page.isFetching,
    showMilestoneModal: page.addModal
  };
};

export const mapDispatchToProps = dispatch => ({
  saveItem: item => dispatch(milestonesActions.save(item)),
  fetchData: () => dispatch(milestonesActions.list({}, { paginated: false })),
  fetchPhases: () => dispatch(phasesActions.list({milestoneOnly: true}, { paginated: false })),
  fetchProductTypes: () => dispatch(productTypesActions.list({}, { paginated: false })),
  selectItem: id => dispatch({ type: "SELECT_MILESTONES", id }),
  saveOrderingPosition: (items) => dispatch(milestonesActions.saveOrderingPosition(items)),
  toggleAddModal: () => dispatch(toggle("MILESTONE_ADD_MODAL")),
  resetForm: () => dispatch(reset("milestones"))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MilestoneListComponent)
);
