import { schema } from "normalizr";
import { coverageTypes } from "../types";
import Resource from "./resource";

const coverageSchema = new schema.Entity("coverage");

export default new Resource(
  "/evaluations/<evaluationId>/coverage",
  coverageTypes,
  coverageSchema
);
