export const generateFormCriteria = (filledCriteria, framework) => {
    const mappedCriteria = Object.values(filledCriteria).reduce((accumulator, criterion) => {
        return {...accumulator, [criterion.criterion.id]: criterion};
    }, {});

    return framework?.criteria?.map(criterion => ({
        criterion,
        ...mappedCriteria?.[criterion.id],
    })) ?? []
}

export const transformFramework = (framework) => ({
    id: framework?.id,
    label: framework?.name,
    value: framework?.code,
})


export const transformUndisScore = (score) => {
    if (!score) {
        return null;
    }
    return {
        label: score,
        value: score,
    }
}