exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "button+button{margin-left:0.5rem}.styles__legend___3gsna{align-items:center;display:flex;float:right;font-size:.875rem}.styles__legend___3gsna li:first-child{color:#5a6872}.styles__legend___3gsna li+li{margin-left:0.8rem}.styles__legend___3gsna p{height:30px;text-indent:-999999px;width:30px}.styles__calendar___1bdEo{display:flex;justify-content:stretch;margin-top:2rem;width:100%}.styles__calendar___1bdEo>li{padding:10px;width:100%}.styles__calendar___1bdEo>li:first-child{text-align:right;width:60px}.styles__calendar___1bdEo ul li{color:#5a6872;flex:1;font-weight:600;height:4.4rem;margin:0}.styles__calendar___1bdEo li:not(:first-child) ul li{border-top:1px solid #dfe3e6;color:#031c2d}.styles__phaseLine___GAD7g{display:flex;margin:0 0 20px 0}.styles__colorBox___1GEaB{height:44px;margin:0;padding:0;vertical-align:bottom;width:44px}.styles__colorBox___1GEaB div{align-items:center;cursor:pointer;display:flex;font-size:1.4em;height:44px;justify-content:center;width:44px}.styles__milestoneLine___1xz94{border-left:dotted 1px #aaaaaa;margin:0 0 40px 20px;padding:10px 0 0 34px}.styles__milestoneFields___2KBm6{align-items:center;display:flex}.styles__milestoneDateRangeField___3eO5G{position:relative;top:3px}.styles__nameLabel___3tD7t{font-size:0.8em;margin:0px;padding:10px 0px}.styles__infoBar___3wJ28{margin-bottom:2.5rem}.styles__phase___COlMP{margin:0;padding:0;width:calc(100% - 30px)}.styles__colColor___3RUlI{display:inline-block;width:70px}.styles__colPhase___2m9OX{display:inline-block;margin-right:1rem;width:calc(100% - 20px - 70px - 320px)}\n", ""]);

// Exports
exports.locals = {
	"legend": "styles__legend___3gsna",
	"calendar": "styles__calendar___1bdEo",
	"phaseLine": "styles__phaseLine___GAD7g",
	"colorBox": "styles__colorBox___1GEaB",
	"milestoneLine": "styles__milestoneLine___1xz94",
	"milestoneFields": "styles__milestoneFields___2KBm6",
	"milestoneDateRangeField": "styles__milestoneDateRangeField___3eO5G",
	"nameLabel": "styles__nameLabel___3tD7t",
	"infoBar": "styles__infoBar___3wJ28",
	"phase": "styles__phase___COlMP",
	"colColor": "styles__colColor___3RUlI",
	"colPhase": "styles__colPhase___2m9OX"
};