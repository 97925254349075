import React, {useState} from "react";
import PropTypes from "prop-types";

import {iconUser} from "@wfp/icons";
import {Icon} from "@wfp/ui";
import Styles from "./styles.scss";

const UserLabel = ({
  email,
  org_unit: orgUnit,
  position_title: positionTitle,
  display_name: displayName
}) => {
  const [imageError, setImageError ] = useState(false);
  const profilePicture = !imageError && email ? (
    <img
      alt="User avatar"
      // Pull user avatar from the WFP Global Telephone Directory
      src={`https://old.gtd.wfp.org/media/picture/${email}.jpg`}
      onError={() => setImageError(true)}
    />
  ) : (<Icon
    icon={iconUser}
  />);

  return (
    <div className={Styles.userLabel}>
      <div className={Styles.userLabelAvatar}>{profilePicture}</div>
      <div className={Styles.userLabelText}>
        <span
          className={Styles.userLabelTextFullName}
          style={{ top: positionTitle || orgUnit ? "0" : "6px" }}
        >
          {displayName}
        </span>
        <br />{" "}
        <span className={Styles.userLabelTextJob}>
          {positionTitle}
          {positionTitle && orgUnit && ", "}
          {orgUnit}
        </span>
      </div>
    </div>
  );
};

UserLabel.propTypes = {
  email: PropTypes.string,
  display_name: PropTypes.string,
  org_unit: PropTypes.string,
  position_title: PropTypes.string
};

UserLabel.defaultProps = {
  email: "",
  display_name: "",
  org_unit: "",
  position_title: ""
};

export default UserLabel;
