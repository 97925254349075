/* eslint-disable camelcase */
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import get from "lodash/get";
import TableWithModalsMixin from "TableWithModalsMixin";
import CRUDLayout, {CRUDMapStateToPropsPropTypes, CRUDMapDispatchToProps, CRUDMapDispatchToPropsPropTypes, CRUDMapStateToProps} from "CRUDLayout";
import peopleActions from "../../actions/people";
import countriesActions from "../../actions/countries";
import fetchUser from "../../actions/user";
import columnsGenerator from "./tableColumns";
import AddModal from "./AddModal";
import {capitalize} from "../../utils";


class People extends TableWithModalsMixin {
    componentDidMount = () => {
        super.componentDidMount()
        this.props.fetchOptions(this.props.match.params.evaluationId);
    };

    // Manipulate saving data in TableWithModalMixin
    valuesAdapter = values => ({
        ...values,
        nationality: values?.nationality?.map(({ value }) => ({ id: value })) ?? [],
        sex: values?.sex?.value ?? null,
    });

    render = () => (
      <CRUDLayout
        {...this.props}
        title='Team members'
        entityName='Team member'
        columnsGenerator={columnsGenerator}
        addModalComponent={AddModal}
        getItem={this.getItem}
        onSave={this.saveItem}
        setItemToDelete={this.setItemToDelete}
        onDelete={this.deleteItem}
        extraModalParams={{
            countries: this.props.countries,
            sex: this.props.sex
        }}
        noSearch={false}
      />
    )
}

People.propTypes = {
    ...CRUDMapStateToPropsPropTypes,
    ...CRUDMapDispatchToPropsPropTypes,
    countries: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
    })).isRequired,
    sex: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })).isRequired,
};

// Exported for tests
export const mapStateToProps = state => {
    const crudProps = CRUDMapStateToProps(
      state,
      'people',
      'actors.add_person',
      'actors.change_person',
      'actors.delete_person'
    )

    const page = state.pages.people;
    const {entities} = state;


    // Map sex to correct keys
    const sex = get(page.options, "actions.POST.sex.choices", []).map(g => ({
        label: g.display_name,
        value: g.value
    }));

    // Map countries to correct keys
    const countries =
      page.countries !== undefined &&
      page.countries.map(id => ({
          label: entities.countries[id].name,
          value: id
      }));

    let {selectedItem} = crudProps;
    if (selectedItem) {
        selectedItem = {
            ...selectedItem,
            nationality: selectedItem.nationality.map(n => ({label: n.name, value: n.id})),
            sex: { label: selectedItem.sex_display, value: selectedItem.sex },
            development_status: capitalize(selectedItem?.development_status?.toLowerCase()),
        }
    }

    return {
        ...crudProps,
        selectedItem,
        sex,
        countries,
    };
};

// Exported for tests
export const mapDispatchToProps = dispatch => ({
    ...CRUDMapDispatchToProps(dispatch, peopleActions, 'people'),
    saveItem: item => dispatch(peopleActions.save(item, {mergeNoArrayNoItems: true})),
    fetchData: () =>
      Promise.all([
          dispatch(fetchUser()),
          dispatch(peopleActions.options({}, {})),
          dispatch(countriesActions.list({}, {paginated: false}))
      ]),
    fetchOptions: () => dispatch(peopleActions.options()),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(People)
);
