import { dateFormatter} from "utils";
import {Categories} from "enums";
import { emptyStringToNull } from "./utils";

export default class PhaseFormTransformer {

    /** Retrieves data from phases form, also milestone are stored in the form */
    static transformToValue = (phaseForm) => ({
        phases: phaseForm.phases
            .map(ph => Object.entries(ph).reduce(emptyStringToNull, {}))
            .map(ph => ({
                ...ph,
                start_date: dateFormatter(ph.dates_range?.startDate).toString(),
                end_date: dateFormatter(ph.dates_range?.endDate).toString(),
            }))
    });

    static transformToFormValue = (phases, dePhases, category) => {
        // retrieve data related to phases and milestones that will be used in the phases modal
        return {
            // Here we build the initialValues for the fieldarray. We need to send an object
            // with a 'phases' key that will hold the list of the phases to be displayed
            phases: Object.values(phases)
                .filter(phase => !phase.is_deprecated)
                .filter(p => {
                  if (category === Categories.DECENTRALIZED) {
                    return p.is_for_de;
                  }
                  if (category === Categories.CENTRALIZED) {
                    return p.is_for_ce;
                  }
                  if (category === Categories.IMPACT) {
                    return p.is_for_ie;
                  }
                  return true;
                })
                .sort((p1, p2) => p1.ordering - p2.ordering)
                .map(
                    phase =>
                        dePhases.find(ph => ph.phase === phase.id) || {
                            phase: phase.id,
                            phase_display: phase.__unicode__, // eslint-disable-line no-underscore-dangle
                            phase_color: phase.color
                        }
                )
                .map(p => ({
                    ...p,
                    dates_range: {
                        startDate: dateFormatter(p.start_date).toMoment(),
                        endDate: dateFormatter(p.end_date).toMoment(),
                    }
                }))
        };
    }

    static transformToFormValueWithMilestone = (phases, dePhases, milestones, deMilestones, category) => {
        const getInitialMilestones = phase => {
            const result = [];
            // milestones are retrieved from the store and adapted to be used in phases modal
            Object.values(deMilestones)
                // filters milestones by phase evaluation id
                .filter(m => m.evaluationId === phase.evaluationId)
                // then search in the milestone entity to find the right milestone
                .filter(m => {
                    const data = Object.values(milestones).filter(
                        mm => mm.phase === phase.phase && mm.id === m.milestone
                    );
                    return data.length === 1;
                })
                .map(milestone => {
                    result[milestone.milestone] = {};
                    // set id of the milestone to allow user to modify it
                    result[milestone.milestone].id = milestone.id;
                    // is done can be true, false or null, the right label must be shown
                    switch (milestone.isDone) {
                        case false:
                            result[milestone.milestone].isDone = {
                                value: false,
                                label: "No"
                            };
                            break;
                        case true:
                            result[milestone.milestone].isDone = {
                                value: true,
                                label: "Yes"
                            };
                            break;
                        default:
                            break;
                    }
                    // retrieving dates from milestone and adapting them to be used by the phase modal
                    result[milestone.milestone].eventDate = milestone.eventDate;
                    result[milestone.milestone].datesRange = {
                        startDate: dateFormatter(milestone.startDate).toMoment(),
                        endDate: dateFormatter(milestone.endDate).toMoment(),
                    };
                    return null;
                });
            return result;
        };
        
        return {
            phases: PhaseFormTransformer.transformToFormValue(phases, dePhases, category).phases
                .map(p => ({
                    ...p,
                    milestones: getInitialMilestones(p)
                }))
        }
    }
}
