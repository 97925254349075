import { combineReducers } from "redux";
import { fetching, items, selectable, options, togglable } from "./resource";

const searchFilterReducer = (state = { query: "" }, action) => {
  if (action.type === "COUNTRY_INFO_SEARCH_TEXT") {
    return { ...state, query: action.query };
  }

  return state;
};

export default combineReducers({
  selectedCountry: selectable("country"),
  isFetching: fetching("countriesInfo") || fetching("countries"),
  items: items("countriesInfo"),
  detailOptions: options("countriesInfo", true),

  countries: items("countries"),
  addModal: togglable("COUNTRY_INFO_ADD_MODAL"),

  searchText: searchFilterReducer
});
