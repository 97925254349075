import { combineReducers } from "redux";
import {fetching, items } from "./resource";

const scoreReducer = (state = {}, action) => {
  if (action.type === "SET_PHQA_STANDARD_TOTAL_SCORE") {
    return action.data;
  }
  return state;
};

export default combineReducers({
  totalScore: scoreReducer,
  isFetching: fetching("standards") || fetching("evaluations") || fetching("standardsFrameworks"),
  standardsFramework: items("standardsFrameworks"),
  items: items("standards"),
});
