/* eslint-disable import/prefer-default-export */

/**
 * checkVersion() intercepts responses which contain a custom `x-oev-version` header
 * which identifies the current application version.
 *
 * The version is stored in localstorage.
 *
 * If there is a new version that doesn't match the one in localstorage,
 * we ask the user to refresh.
 */
export const checkVersion = (dispatch, headers) => {
  // check if `version` is already stored
  const version = localStorage.getItem("oev-version");

  if (headers && version !== "") {
    localStorage.setItem("oev-version", headers["x-oev-version"]);
  }

  // if `version` is already stored we compare it with the new arrived one
  if (headers && version !== "" && version !== headers["x-oev-version"]) {
    dispatch({ type: "SHOULD_REFRESH" });
  }
};

/* eslint-enable */
