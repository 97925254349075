import PropTypes from "prop-types";
import {reset} from "redux-form";
import {toggle} from "../../actions/togglable";
import search from "../../utils/search";
import peopleActions from "../../actions/people";

export const CRUDMapStateToPropsPropTypes = {
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool,
    canDelete: PropTypes.bool,
    isFetching: PropTypes.bool,
    showAddModal: PropTypes.bool,
    showDeleteModal: PropTypes.bool,

    // Depends on the actual entity
    data: PropTypes.arrayOf(PropTypes.shape({})),
    selectedItem: PropTypes.shape({}),
    formValues: PropTypes.shape({}),
}

export const getFieldKeyOptions = (item) => Object.keys(item ?? {}).map((key) => ({value: key}));

export const CRUDMapStateToProps = (state, stateField, addPermission, changePermission, deletePermission) => {
    const userPermissions = state?.user?.[0]?.permissions?.permission ?? [];
    const entities = state?.entities?.[stateField] ?? {};
    const page = state?.pages?.[stateField] ?? {};

    let data = page?.items?.map(id => entities[id])?.sort((a, b) => a.position - b.position) ?? [];
    data = data?.filter(item => item && !item.deleted);
    const formValues = state.form?.[stateField]?.values ?? {};

    // First get the query from the search form
    const query = state?.form?.search?.values?.query;
    if (query && data.length > 0) {
        data = search(query, data, getFieldKeyOptions(data?.[0]));
    }

    return {
        canAdd: userPermissions.includes(addPermission),
        canEdit: userPermissions.includes(changePermission),
        canDelete: userPermissions.includes(deletePermission),
        isFetching: page?.isFetching ?? false,
        showAddModal: page?.showAddModal ?? false,
        showDeleteModal: page?.showDeleteModal ?? false,

        data: data ?? [],
        selectedItem: entities[page?.selectedItem] ?? null,
        formValues: formValues ?? {},
    }
}


export const CRUDMapDispatchToPropsPropTypes = {
    selectItem: PropTypes.func,
    deleteItem: PropTypes.func,
    fetchData:  PropTypes.func,
    fetchItems: PropTypes.func,
    toggleAddModal: PropTypes.func,
    toggleDeleteModal: PropTypes.func,
    saveItem: PropTypes.func,
    saveOrderingPosition: PropTypes.func,
    resetForm: PropTypes.func,
}

export const CRUDMapDispatchToProps = (dispatch, actions, stateField) => ({
    selectItem: id => dispatch({type: `SELECT_${stateField.toUpperCase()}`, id}), // Used by TableWithModalsMixin
    deleteItem: itemId => dispatch(peopleActions.destroy(itemId)),
    fetchData: () => dispatch(actions.list({}, {paginated: false})), // Used by TableWithModalsMixin
    fetchItems: () => dispatch(peopleActions.list({}, {paginated: false})),
    toggleAddModal: () => dispatch(toggle('addModal')), // Used by TableWithModalsMixin
    toggleDeleteModal: () => dispatch(toggle("deleteModal")),
    saveItem: item => dispatch(actions.save(item)),
    saveOrderingPosition: (items) => dispatch(actions.saveOrderingPosition(items)),
    resetForm: () => dispatch(reset(stateField))
})

