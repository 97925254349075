import Select from "Select";
import {Field} from "redux-form";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Styles from "../../styles.scss";
import {adaptInputValues} from "../../utils";
import {adaptPartnersOptions} from "../../../../utils";
import partnersActions from "../../../../actions/partners";
import partnersCategoriesActions from "../../../../actions/partnersCategories";
import {advancedFilterActions} from "../../../../actions/filters";


const JointPartners = ({label, fieldName, isDisabled}) => {
  const dispatch = useDispatch();
  const {partners, partnersCategories} = useSelector(state => state.entities);

  useEffect(() => {
    dispatch(partnersActions.list({}, {paginated: false}));
    dispatch(partnersCategoriesActions.list({}, {paginated: false}));
  }, [])

  const onChange = (inputs) => {
    if (inputs !== null && inputs.length > 0) {
      dispatch(advancedFilterActions.addFilter(fieldName, inputs, {
        partners: adaptInputValues(inputs)
      }));
    } else {
      dispatch(advancedFilterActions.removeFilter(fieldName));
    }
  }

  return (
    <Field
      name={fieldName}
      label={label}
      component={Select}
      options={adaptPartnersOptions(Object.values(partners), Object.values(partnersCategories))}
      onChangeExtra={onChange}
      openMenuOnClick
      cssClass={Styles.noPaddingWithSpace}
      disabled={isDisabled}
      allowMultiple
    />
  )
}

export default JointPartners;