import React from "react";
import {
    Redirect,
    useRouteMatch
, Route, Switch } from "react-router-dom";


import Tabs from "Tabs";
import {usePermission} from "hooks";
import PHQAStandards from "./PHQAStandards";
import PHQAGender from "./PHQAGender";
import PHQAUndis from "./PHQAUndis";

const subNavigationItems = {
    standards: { url: `phqa-standards`, label: "PHQA Scores" },
    gender: { url: `phqa-gender`, label: "PHQA UN-SWAP EPI"},
    undis: { url: `phqa-undis`, label: "PHQA UNDIS"},
}

export const PHQA = ({canEdit}) => {
    const match = useRouteMatch();

    const standardCanEdit = canEdit && usePermission(['exercise.change_standardframework', 'exercise.change_standardcriterion']);
    const genderCanEdit = canEdit && usePermission(['exercise.change_epiframework', 'exercise.change_epicriterion']);
    const undisCanEdit = canEdit && usePermission(['exercise.change_undisframework', 'exercise.change_undiscriterion']);

    return (
      <div>
        <div style={{ marginTop: -20, marginBottom:20 }}>
          <Tabs items={Object.values(subNavigationItems)} />
        </div>
        <Switch>
          <Route
            path={`${match.path}standards`}
            render={() => <PHQAStandards canEdit={standardCanEdit} />}
          />
          <Route
            path={`${match.path}gender`}
            render={() => <PHQAGender canEdit={genderCanEdit} />}
          />
          <Route
            path={`${match.path}undis`}
            render={() => <PHQAUndis canEdit={undisCanEdit} />}
          />
          <Route
            exact
            path="/evaluations/:evaluationId/phqa"
            render={() => <Redirect to={subNavigationItems.standards.url} />}
          />
        </Switch>
      </div>
    )
}
