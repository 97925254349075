import get from "lodash/get";

/** Defines the column order within EvaluationList's table, as requested by business */
const columnsOrder = [
  "category_code",
  "status",
  "publication_reference",
  "title",
  "kind",
  "exercise",
  "windows_names",
  "commissioning",
  "commissioning_parent",
  "last_group_modified_date",
  "last_group_modified_user",
  "start_date",
  "publication_year",
  "planned_completed_date",
  "approval_date",
  "standards_assessment_score",
  "report_year",
  "is_joint",
  "joint_partners_names",
  "request_by_donors_names",
  "funded_by_donors_names",
  "eb_session",
  "chair_committee_onetoone",
  "manager_wfp_onetoone",
  "manager_external",
  "research_analyst_wfp_onetoone",
  "qa2_onetoone",
  "final_approver_onetoone",
  "planned_budget",
  "cef_allocation_requested",
  "cef_granted",
  "subject_funded_value",
  "entire_project_funding",
  "subject_funded_percentage",
  "actual_expenditure",
  "topics_names",
  "crosscutting_priorities_names",
  "activity_categories_names",
  "sustainable_development_goals_names",
  "notes",
  "description",
  "status_prev",
  "reason_for_cancellation",
  "migration_code",
  "is_legacy",
  "is_migrated",
  "errors_number",

];

/** Sort columns in the order defined by columnsOrder */
export const defaultColumnsSort = (x, y) =>
  columnsOrder.indexOf(x) > columnsOrder.indexOf(y) ? 1 : -1;

/** Return an array of strings based on the passed key (default is "value") */
export const adaptInputValues = (input, key = "value") =>
  input.map(input_ => input_[key]).join(",");

/** Do not start with zero or change the logic in the sort method */
export const statuses = {
  "planned tentative": 2,
  "planned confirmed": 3,
  preparation: 4,
  "on going": 5,
  completed: 6,
  finalized: 7,
  empty: 8 // assigned to those evaluation with `previous_status` null.
};

/** Do not start with zero or change the logic in the sort method */
export const exercises = {
  baseline: 1,
  midline: 2,
  endline: 3
};

/** Sort exercises according to `exercise` constant */
export const sortExercises = (_a, _b) => {
  const a = _a && (exercises[_a.toLowerCase()] || -Infinity);
  const b = _b && (exercises[_b.toLowerCase()] || -Infinity);
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

/** Sort statuses according to `statuses` constant */
export const sortStatuses = (_a, _b) => {
  // force null and undefined to the bottom
  let a = _a === null || _a === undefined ? "empty" : _a;
  let b = _b === null || _b === undefined ? "empty" : _b;

  a = statuses[a.toLowerCase()] || -Infinity;
  b = statuses[b.toLowerCase()] || -Infinity;

  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};


export const sortByPosition = (_a, _b) => {
  // force null and undefined to the bottom
  const a = _a?.position === null || _a?.position === undefined ? Infinity : _a.position
  const b = _b?.position === null || _b?.position === undefined ? Infinity : _b.position

  // Return either 1 or -1 to indicate a sort priority
  if (a > b) {
    return 1
  }
  if (a < b) {
    return -1
  }
  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0
}

/** Build a column object to be used in columns generator */
export const buildColumn = ({ label, value }, params, isVisible, code) => ({
  Header: get(params, "header", label),
  accessor: value,
  show: get(params, "show", isVisible(value)),
  width: get(params, "width"),
  maxWidth: get(params, "maxWidth"),
  Cell: get(params, "cell"),
  sortable: get(params, "sortable", true),
  sortMethod: get(params, "sortMethod", undefined),
  code
});

/**
 * Format a number-containing string by adding a comma every three digits.
 *
 * Only handles strings. Providing an argument of a different type returns undefined.
 */
export const setThousandsCommas = number => {
  if (typeof number !== "string") {
    return undefined;
  }
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Compare two strings containing numbers formatted with `setThousandsCommas`.
 *
 * Casts x and y to Number, then returns x - y.
 *
 * If x is null, undefined, or empty string, returns that y is bigger; and viceversa.
 */
export const compareThousandsCommas = (x, y) => {
  if (x === null || x === undefined || x === "") return -1;
  if (y === null || y === undefined || y === "") return 1;
  return Number(x.replace(",", "")) - Number(y.replace(",", ""));
};
