import Select from "Select";
import {Field,change} from "redux-form";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Checkbox from "Checkbox";
import Styles from "../../styles.scss";
import {adaptInputValues} from "../../utils";

import {advancedFilterActions, filterActions} from "../../../../actions/filters";
import getStatuses from "../../../../actions/decentralizedStatuses";

const CANCELLED_STATUS_CODE = 'CANCELLED';

const Statuses = ({label, fieldName, isDisabled}) => {
  const dispatch = useDispatch();
  const {statuses, filters: {includeCancelled}} = useSelector(state => state.pages.evaluations);
  const selectedStatuses = useSelector(state => state.form.newEvSearch?.values?.statuses);
  const cancelledStatus = statuses.find(status => status.code === CANCELLED_STATUS_CODE);

  const onChange = inputs => {
    if (inputs !== null && inputs.length > 0) {
      dispatch(advancedFilterActions.addFilter(fieldName, inputs, {
        statuses: adaptInputValues(inputs)
      }));
    } else {
      dispatch(advancedFilterActions.removeFilter(fieldName));
    }
  };

  const statusToOption = (status) => ({label: status.name, value: status.code})

  useEffect(() => {
     dispatch(getStatuses({}, "DECENTRALIZED"));
  }, [])

  return (
    <fieldset
      className={`wfp--fieldset ${Styles.advancedFilterFieldset}`}
    >
      <legend className="wfp--label">{label}</legend>
      <div>
        <Checkbox
          label="Include Cancelled"
          input={{
            name: "Include Cancelled",
            value: includeCancelled,
            onBlur: () => null,
            onDragStart: () => null,
            onDrop: () => null,
            onFocus: () => null,
            onChange: () => {
              // this function happens before includeCancelled is toggled
              dispatch(filterActions.toggleFilter("includeCancelled"));
              const someFiltersAreActive = Array.isArray(selectedStatuses) && selectedStatuses.length > 0;
              if (someFiltersAreActive) {
                let newStatuses = [];
                if (includeCancelled) {
                  newStatuses = selectedStatuses.filter(status => status.value !== CANCELLED_STATUS_CODE)
                } else {
                  newStatuses = [
                    ...selectedStatuses,
                    statusToOption(cancelledStatus),
                  ]
                }
                onChange(newStatuses);
                dispatch(change('newEvSearch', 'statuses', newStatuses))
              }
            }
          }}
        />
      </div>
      <div className={Styles.filterField}>
        <Field
          name={fieldName}
          // isOptionDisabled: disable "Cancelled" dropdown option according to prop
          isOptionDisabled={option =>
            option.value === CANCELLED_STATUS_CODE ? !includeCancelled : false
          }
          label=""
          component={Select}
          options={statuses.map(statusToOption)}
          onChangeExtra={onChange}
          openMenuOnClick
          cssClass={Styles.noPaddingWithSpace}
          disabled={isDisabled}
          allowMultiple
        />
      </div>
    </fieldset>
  )
}

export default Statuses;