import React, { useEffect, useMemo} from "react";
import { useDispatch, useSelector} from "react-redux";

import {change} from "redux-form";
import Loading from "Loading";
import {useGetCategory, useGetDetailedEvaluation} from "../../../../../hooks";
import genderActions from "../../../../../../../actions/gender";
import epiframeworksActions from "../../../../../../../actions/epiframeworks";
import { getEvaluationActions } from "../../../../../../../actions/evaluations";
import { getMessages, isCreate } from "../../../../../../../utils";
import {Framework} from "../components/Framework";
import {CriterionForm} from "../components/CriterionForm";
import {CriterionRow} from "./CriterionRow";
import {generateFormCriteria} from "../utils";


const PHQAGender = ({canEdit}) => {
    const evaluation = useGetDetailedEvaluation();
    const category = useGetCategory();
    const dispatch = useDispatch();
    const page = useSelector(store => store.pages?.gender);
    const frameworks = useSelector((store) => store.entities.epiframeworks);
    const selectedFramework = useSelector((store) => store.form.evPHQAEPIFramework?.values?.framework);
    const genders = useSelector((store) => store.entities.gender);
    const messages = useSelector(store => store.messages.phqagenderepi);
    const memorizedMessages = useMemo(() => getMessages(messages), [messages]);

    const filteredGenders = Object.values(genders).filter(standard => page.items.includes(standard.id))
    const criteria = generateFormCriteria(filteredGenders, frameworks?.[selectedFramework?.id]);


    useEffect(() => {
        if (evaluation) {
            dispatch(epiframeworksActions.list({}, {paginated: false}));
            dispatch(genderActions.list({}, {pathParams: {evaluationId: evaluation.id}, paginated: false}));
            dispatch(genderActions.fetchTotalScore(evaluation.id));
        }
    }, [evaluation?.id]);

     const onFrameworkSave = async (framework) => {
        await dispatch(getEvaluationActions(category).setEpiFramework(evaluation.id, { epiFramework: framework.value }));
        dispatch(genderActions.clear());

        dispatch(genderActions.fetchTotalScore(evaluation.id));
     }

    const onCriterionSave = async ({items}) => {
        dispatch(genderActions.batchGenderSave(evaluation.id, items, category));
      }

    useEffect(() => {
        dispatch(change('evPHQAGender', 'totalScore', page.totalScore.score));
        dispatch(change('evPHQAGender', 'totalRating', page.totalScore.rating));
    }, [page.totalScore]);

    const readOnly = !canEdit || isCreate(evaluation?.id);

    if (!Object.values(frameworks).length > 0 || !evaluation) {
        return <Loading />;
    }

    return (
      <div>
        <Framework
          // eslint-disable-next-line camelcase
          initialFramework={frameworks?.[evaluation?.epi_framework]}
          frameworks={frameworks}
          readOnly={readOnly}
          formName='evPHQAEPIFramework'
          onChange={onFrameworkSave}
          isScoresFilled={filteredGenders.length > 0}
      />
        <CriterionForm
          form='evPHQAGender'
          messages={memorizedMessages}
          defaultMessage='Please add score (0-3) on all criteria'
          initialValues={{
            ...page.totalScore,
            items: criteria,
          }}
          save={onCriterionSave}
          readOnly={readOnly}
          rowComponent={CriterionRow}
      />
      </div>
    )
}


export default PHQAGender;

