import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import TextInput from "TextInput";
import Select from "Select";

// Exported for test
export const PersonForm = props => (
  <div>
    <Field name="name" component={TextInput} label="Name" />
    <Field
      name="sex"
      label="Sex"
      options={props.sex}
      component={Select}
      openMenuOnClick
    />
    <Field
      name="nationality"
      label="Nationality"
      options={props.countries}
      component={Select}
      closeMenuOnSelect={false}
      allowMultiple
      openMenuOnClick
    />
    <Field
      id="development_status"
      name="development_status"
      component={TextInput}
      label="Development Status"
      readOnly
    />
  </div>
);

PersonForm.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired,
  sex: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired
};

export default reduxForm({
  form: "people",
  enableReinitialize: true
})(PersonForm);
