import React, {useEffect} from "react";
import {Grid, Row, Col} from "Grid";
import {initialize, reduxForm} from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import Styles from "../styles.scss";
import {useAdvancedFilter} from "./useAdvancedFilter";

const NewAdvanceFilters = () => {
  const dispatch = useDispatch();
  const activeAdvancedFilters = useSelector(state => state.pages.evaluations.activeAdvancedFilters);
  const {advancedFilters} = useAdvancedFilter();

  const renderAdvancedFilter = (filter) => {
    if (!filter.isVisible) {
      return null;
    }
    const {component, ...restProps} = filter;
    return (
      <Col key={filter.fieldName} lg={3} md={4} sm={6}>
        <div className={Styles.filterField}>
          {component(restProps)}
          <span
            className={Styles.removeFilter}
            onClick={filter.onHide}
          />
        </div>
      </Col>
    )
  }

  useEffect(() => {
    const initialValues = Object.entries(activeAdvancedFilters).reduce((accumulator, [fieldName, filter]) => {
      if (!filter.show || !filter?.inputs) {
        return accumulator;
      }
      return {...accumulator, [fieldName]: filter.inputs}
    }, {});

    if (Object.keys(initialValues).length > 0) {
      dispatch(initialize("newEvSearch", initialValues))
    }
  }, []);

  return (
    <div className={`wfp-form wfp--form-long ${Styles.advancedFiltersForm}`}>
      <Grid fluid>
        <Row bottom="xs">
          {advancedFilters.map(renderAdvancedFilter)}
        </Row>
      </Grid>
    </div>
  )
}


export default reduxForm({
  form: "newEvSearch",
  destroyOnUnmount: true,
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
  forceUnregisterOnUnmount: false,
  pure: false
})(NewAdvanceFilters);