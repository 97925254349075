import React from "react";
import ReactMarkdown from 'react-markdown'
import 'github-markdown-css'
import {
  Module,
  ModuleHeader,
  ModuleBody,
  Wrapper
} from "@wfp/ui";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import fetchProject from "../../actions/project";

class Changelog extends React.Component {
  componentDidMount() {
    this.props.fetchProject();
  }

  render() {
    return (
      <Wrapper spacing="md" pageWidth="full">
        <div className="row">
          <div className="col-xs-12 col-md-8 col-lg-8">
            <Module noMargin light>
              <ModuleHeader>
                <h2>Changelog</h2>
              </ModuleHeader>
              <ModuleBody>
                <div className='markdown-body'>
                  <ReactMarkdown
                    components={{
                        ul: ({node, ...props}) => <ul style={{ listStyle: 'inherit' }} {...props} />
                      }}
                  >
                    { this.props.changelog }
                  </ReactMarkdown>
                </div>
              </ModuleBody>
            </Module>
          </div>
        </div>
      </Wrapper>
    );
  }
}

Changelog.propTypes = {
  changelog: PropTypes.arrayOf(PropTypes.string),
  fetchProject: PropTypes.func.isRequired
};

Changelog.defaultProps = {
  changelog: []
};

export const mapStateToProps = state => {
  const page = state.pages.header;

  return {
    changelog: page.projectInfo.changelog
  };
};

// Exported for tests
export const mapDispatchToProps = dispatch => ({
  fetchProject: () => dispatch(fetchProject())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Changelog);
