import React from "react";
import { Icon } from "@wfp/ui";
import { iconEdit } from "@wfp/icons";

const columnsGenerator = (setItem, canEdit) => [
  {
    Header: "Name",
    accessor: "name",
    sortable: false,
  },
  {
    Header: "Code",
    accessor: "code",
    sortable: false,
  },
  {
    Header: "Deprecated",
    accessor: "isDeprecated",
    Cell: row => (<input type="checkbox" checked={row.value} disabled />),
    sortable: false,
  },
  {
    Header: "",
    accessor: "id",
    /* eslint-disable */
    Cell: row =>
      canEdit ? (
        <Icon
          role="button"
          icon={iconEdit}
          width="19"
          height="19"
          description="Edit item"
          onClick={() => setItem(row.value)}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Icon
          role="button"
          icon={iconEdit}
          width="19"
          height="19"
          description="Edit item"
          style={{ cursor: "pointer", opacity: "0.5" }}
        />
      ),
    /* eslint-enable */
    resizable: false,
    sortable: false,
    width: 50,
    style: { justifyContent: "center" },
  }
];

export default columnsGenerator;
