import transform from "lodash/transform";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const difference = (
  object,
  base // eslint-disable-line import/prefer-default-export
) =>
  transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      /* eslint-disable no-param-reassign */
      result[key] =
        isObject(value) && isObject(base[key])
          ? difference(value, base[key])
          : value;
      /* eslint-enable */
    }
  });
