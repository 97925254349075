import React from "react";
import PropTypes from "prop-types";
import {RequirementCodes} from "enums";
import Category from "./components/Category";
import Status from "./components/Status";
import StatusRequirements from "./components/StatusRequirement";
import MilestoneStatusRequirements from "./components/StatusRequirement/MilestoneStatusRequirement";


const StatusValidationSchemaEditor = props => {
  const renderRequirement = (category, status, requirement, index) => {
    const commonProps = {
        key: `status-requirements-${category?.categoryCode?.toLowerCase()}-${status?.statusCode?.toLowerCase()}-${index}`,
        requirement,
        index,
        categoryCode: category.categoryCode,
        statusCode: status.statusCode,
        schema: props.schema,
        setSchema: props.setSchema,
        conditionOptions: props.conditionOptions,
    }

    switch(requirement.requirementClass) {
      case RequirementCodes.MILESTONE:
         return (
           <MilestoneStatusRequirements
             {...commonProps}
             milestoneOptions={props.milestoneOptions}
          />
        );
      default:
        return (
          <StatusRequirements
            {...commonProps}
          />
        );
    }
  }
  
  return(
    <div className="wfp-wrapper wfp-content">
      {props.schema?.map((category) => (
        <Category categoryCode={category.categoryCode} key={category.categoryCode}>
          {category?.statuses?.map((status) => {
            if (status.statusCode?.toLowerCase() === 'cancelled') {
              return null;
            }

            return (
              <Status
                categoryCode={category.categoryCode}
                statusCode={status.statusCode}
                schema={props.schema}
                setSchema={props.setSchema}
                key={category.categoryCode+status.statusCode}
                requirements={props.requirements}
              >
                {status?.requirements?.map((requirement, index) => renderRequirement(category, status, requirement, index))}
              </Status>
            )
          })}
        </Category>
      ))}
    </div>
  )
}


StatusValidationSchemaEditor.propTypes = {
  schema: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSchema: PropTypes.func.isRequired,
  milestoneOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  conditionOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  requirements: PropTypes.shape({}).isRequired,
};

StatusValidationSchemaEditor.defaultProps = {};

export default StatusValidationSchemaEditor;
