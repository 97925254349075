import Checkbox from "Checkbox";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {filterActions} from "../../../../actions/filters";
import Styles from "../../styles.scss";

const JointEvaluations = ({label, fieldName, isDisabled}) => {
  const dispatch = useDispatch();
  const {isJoint} = useSelector(state => state.pages.evaluations.filters);

  return (
    <div className={Styles.checkboxAdvancedFilter}>
      <Checkbox
        name={fieldName}
        label={label}
        input={{
          name: fieldName,
          value: isJoint,
          onBlur: () => null,
          onDragStart: () => null,
          onDrop: () => null,
          onFocus: () => null,
          onChange: () => dispatch(filterActions.toggleFilter("isJoint")),
        }}
        disabled={isDisabled}
      />
    </div>
  )
}

export default JointEvaluations;