import React from "react";
import PropTypes from "prop-types";

import Modal from "Modal";
import ContainedRadioButton from "ContainedRadioButton";

import {usePermission} from "hooks";

import Styles from "../styles.scss";

/**
 * Modal used to make the user choose
 * between centralized and decentralized evaluations
 */
const EvaluationChoiceModal = ({onSelect, onClose, modalIsVisible}) => {
    const hasCentralizedPermission = usePermission(['exercise.add_centralizedevaluation']);
    const hasDecentralizedPermission = usePermission(['exercise.add_decentralizedevaluation']);
    const hasImpactPermission = usePermission(['exercise.add_centralizedevaluation']);

    return (
      <Modal
        isVisible={modalIsVisible}
        onClose={onClose}
        title="Which kind of evaluation you want to create?"
        contentCentered
        confirmLabel={'Cancel'}
        cancelLabel={null}
        onSubmit={onClose}
        >
        <ul className={Styles.wfpModalOptions}>
          <li>
            <ContainedRadioButton
              label="Centralized"
              isDisabled={!hasCentralizedPermission}
              onChangeHandler={() => onSelect("newCE")}
            />
          </li>
          <li>
            <ContainedRadioButton
              label="Decentralized"
              isDisabled={!hasDecentralizedPermission}
              onChangeHandler={() => onSelect("newDE")}
            />
          </li>
          <li>
            <ContainedRadioButton
              label="Impact"
              isDisabled={!hasImpactPermission}
              onChangeHandler={() => onSelect("newIE")}
              />
          </li>
        </ul>
      </Modal>
    );
}

EvaluationChoiceModal.propTypes = {
    modalIsVisible: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default EvaluationChoiceModal;
