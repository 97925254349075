import React, {useState,useEffect } from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import {Button, TablePagination} from "@wfp/ui";
import {activityTransformer, handleTableCellClick} from "./helper";
import Loading from "../Loading";
import Tooltip from "../Tooltip";
import { SeeMore } from "../SeeMore";

const ActivityTable = ({
    items,
    isFetching,
    showGroup,
    ...props
}) => {
    if (isFetching) {
        return <Loading />
    }

    const [expanded, setExpanded] = useState({})
    const transformedData = activityTransformer(items);

    useEffect(() => {
        setExpanded({});
    }, [items]);

    const pivotColumn = {
        aggregate: row => row[0],
        Cell: () => null,
        Aggregated: ({ value }) => value,
    };

    const subColumn = {
        aggregate: (values) => values.length === 1 ? values[0] : null,
    };

    return (
      <div>
        <ReactTable
          className="-highlight wfp-table -border -striped"
          data={transformedData}
          PaginationComponent={TablePagination}
          defaultPageSize={10}
          pageSizeOptions={[10, 50, 100]}
          // Displayed number of rows can be smaller than page size due to grouping.
          minRows={1}
          pivotBy={['pivot']}
          getTdProps={(state, rowInfo, column) => handleTableCellClick(rowInfo, column, expanded, setExpanded)}
          showPaginationTop
          getPaginationProps={(p) => ({
            totalItems: p.resolvedData.length,
            ...p,
          })}

          // These event handlers are required?
          onExpandedChange={setExpanded}
          {...props}

          expanded={expanded}
          PivotValueComponent={() => null}
          ExpanderComponent={({subRows, isExpanded}) => {
              if (subRows?.length === 1) {
                  return null;
              }

              return (
                <div className={`rt-expander ${isExpanded ? '-open' : ''}`} style={{display: 'block',zoom:1.5}}>
                  <Button small>{isExpanded ? 'Hide Details' : 'Show Details'}</Button>
                </div>
              )
          }}
          columns={[
              {
                  Header: "",
                  id: 'pivot',
                  accessor: (row) => JSON.stringify([row.when, row.who, row.action, row.object]),
                  width: 40,
              },
              {
                  ...pivotColumn,
                  Header: "When",
                  accessor: "when",
                  disableExpander: true,
                  width: 150,
              },
              {
                  ...pivotColumn,
                  Header: "Who",
                  accessor: "user",
                  width: 200,
              },
              {
                  ...pivotColumn,
                  Header: "Action",
                  accessor: "action",
                  width: 90,
              },
              {
                  ...pivotColumn,
                  Header: "Group",
                  accessor: "groupObject",
                  width: 400,
                  show: showGroup,
                  Cell: ({ value }) => <SeeMore>{value}</SeeMore>,
              },
              {
                  ...pivotColumn,
                  accessor: "object",
                  Header: "Object",
                  Aggregated: ({ value }) => (
                    <div>
                      {value.label}
                      {' '}
                      <Tooltip message={value.name} />
                    </div>
                  ),
                  width: 190,
              },
              {
                  ...subColumn,
                  Header: "Field",
                  accessor: "field",
                  width: 200,
              },
              {
                  ...subColumn,
                  Header: "Previous value",
                  accessor: "previousValue",
                  Cell: ({ value }) => <SeeMore>{value}</SeeMore>,
              },
              {
                  ...subColumn,
                  Header: "Current value",
                  accessor: "currentValue",
                  Cell: ({ value }) => <SeeMore>{value}</SeeMore>,
              },
          ]}
        />
      </div>
  )
}

ActivityTable.propTypes = {
    isFetching: PropTypes.bool,
    showGroup: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ActivityTable.defaultProps = {
    isFetching: false,
    showGroup: true,
};

export default ActivityTable;
