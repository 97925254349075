import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@wfp/ui";
import PredictedPhaseMessageBox from "./PredictedPhaseMessageBox";

import styles from "./styles.scss";

const ShiftingConfirmationModal = props => (
  <Modal
    data-testid='shifting-confirmation-modal'
    open={props.show}
    className={styles.shiftingConfirmationModal}
    modalHeading="Phase shifting"
    onRequestSubmit={props.onRequestSubmit}
    onRequestClose={props.onRequestClose}
    primaryButtonText="Shift phases"
    secondaryButtonText="Cancel"
    danger
    selectorPrimaryFocus={false}
    >
    <p>The overlapping intervals can be automatically resolved by shifting the following Phases:</p>
    {props.phasesFormValues?.map((phase, index) => (
      <PredictedPhaseMessageBox
        milestones={props.milestones}
        key={`predicted-message-box-${phase.phase}`} 
        phase={phase} 
        shiftedPhase={props.predictedShiftedPhases?.[index]} 
      />
      ))}
    <p>
        Please note that shifting the Phases automatically will NOT save the changes.
    </p>
    <p>
        Please double check and confirm the changes by clicking Save.
    </p>
  </Modal>
);


ShiftingConfirmationModal.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    onRequestSubmit: PropTypes.func.isRequired,
    predictedShiftedPhases: PropTypes.arrayOf(PropTypes.shape({})),
    phasesFormValues: PropTypes.arrayOf(PropTypes.shape({})),
    show: PropTypes.bool.isRequired,
    milestones:  PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ShiftingConfirmationModal.defaultProps = {
    predictedShiftedPhases: [],
    phasesFormValues: [],
};

export default ShiftingConfirmationModal;
