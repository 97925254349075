import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";

import { items } from "./pages/resource";
import appReducer from "./app";
import authReducer from "./auth";
import featureFlagsReducer from "./featureFlags";
import fieldErrorsReducer from "./fieldErrors";
import pagesReducers from "./pages";

const defaultEntitiesState = {
  activityCategories: {},
  enhancedActivity: {},
  bureaux: {},
  centralizedStatuses: {},
  companies: {},
  contractTypes: {},
  countries: {},
  countriesInfo: {},
  coverage: {},
  decentralizedStatuses: {},
  deMilestones: {},
  dePhases: {},
  donors: {},
  crosscuttingPriorities: {},
  evaluations: {},
  gender: {},
  members: {},
  milestones: {},
  partners: {},
  partnersCategories: {},
  epiframeworks: {},
  expertises: {},
  people: {},
  phases: {},
  products: {},
  productTypes: {},
  reasonsForCancellation: {},
  requirementConditions: {},
  requirements: {},
  teamRoles: {},
  Windows: {},
  services: {},
  standards: {},
  undises: {},
  undisFrameworks: {},
  statusValidationSchema: {},
  sustainableDevelopmentGoals: {},
  topics: {},
  titles: {},
  types: {},
  user: {},
  users: {},
  wfpOffices: {},
  wfpDivisions: {},
  wfpUsers: {}
};

/**
 * Method used to overwrite arrays in answer's objects, so to
 * delete array elements if they are not in the answer (instead of merging new and old ones)
 */
export const adapter = (objValue, srcValue) =>
  Array.isArray(objValue) ? srcValue : undefined;

/** Update an entity cache in response to any action with response.entities */
export const entitiesReducer = (state = defaultEntitiesState, action) => {
  if (
    action.type === "MERGE_ENTITIES" ||
    action.type === "MARK_DELETED" ||
    action.type === "MERGE_ITEMS_ENTITIES"
  ) {
    return merge({}, state, action.data.entities);
  }
  if (
    action.type === "MERGE_ENTITIES_NOARRAY" ||
    action.type === "MERGE_ENTITIES_NOARRAY_NOITEMS"
  ) {
    return mergeWith({}, state, action.data.entities, adapter);
  }
  if (action.type === "SET_ITEMS") {
    return {...state, ...action.data.entities};
  }
  if (action.type === "AUTH_USER_LOGOUT") {
    return { ...state, user: {} };
  }
  return state;
};

// Exported for tests
export const validationReducer = (state = {}, action) => {
  if (action.type === "SET_VALIDATION") {
    return action.data;
  }
  return state;
};

// Exported for tests
export const messagesReducer = (state = {}, action) => {
  if (
    action.type === "SET_VALIDATION" &&
    action.data &&
    action.data.FINALIZED
  ) {
    return Object.entries(action.data.FINALIZED).reduce((acc, elem) => {
      const [model, field] = elem[0].indexOf("__")
        ? elem[0].split("__")
        : [null, null];
      const modelData = acc[model] || {};
      const next =
        model && field
          ? {
              [model]: {
                ...modelData,
                [field]: elem[1]
              }
            }
          : { [elem[0]]: elem[1] };
      return {
        ...acc,
        ...next
      };
    }, {});
  }
  return state;
};

// Exported for tests
export const windowReducer = (state = { isMobile: false }, action) => {
  if (action.type === "UPDATE_WINDOW_STATE") {
    const width = document.documentElement.clientWidth || window.innerWidth;
    return {
      isMobile: width < 1024
    };
  }
  return state;
};

// Exported for tests
export const nextReducer = (state = "", action) => {
  if (action.type === "SET_NEXT") {
    return action.next;
  }
  return state;
};

/*
 * http://codeassist.wfp.org/jira/browse/OEV-1276
 * In order to avoid incosistencies call this action in component `willUnMount`
 */
const customPagesReducers = (_state, action) => {
  let state = _state;

  if (action.type === "RESET_EVALUATION") {
    state = { ...state, evaluation: {} };
  }

  return pagesReducers(state, action);
};

export default history =>
  combineReducers({
    app: appReducer,
    auth: authReducer,
    entities: entitiesReducer,
    featureFlags: featureFlagsReducer,
    fieldErrors: fieldErrorsReducer,
    form: formReducer,
    messages: messagesReducer,
    next: nextReducer,
    pages: customPagesReducers,
    router: connectRouter(history),
    user: items("user"),
    validation: validationReducer,
    window: windowReducer
  });
