import React from "react";
import PropTypes from "prop-types";

import Milestone from "./Milestone";

import Styles from "./styles.scss";

/**
 * Calendar week row
 */
const WeekRow = ({ phaseBgColor, phaseInfo, milestones, onMilestoneClick }) => (
  <div
    className={Styles.weekRow}
    style={{
      // Append opacity 25%
      backgroundColor: `${phaseBgColor}40`
    }}
  >
    <div className={Styles.phaseInfo} style={{ backgroundColor: phaseBgColor }}>
      {phaseInfo}
    </div>
    <div className={Styles.weekMilestones}>
      {milestones.length > 0 &&
        milestones.map(ms => (
          <Milestone
            milestone={ms}
            key={ms.id}
            onClick={() => onMilestoneClick(ms)}
          />
        ))}
    </div>
  </div>
);

WeekRow.propTypes = {
  // Phase background color
  phaseBgColor: PropTypes.string,
  // Contains info pertaining to the phase
  phaseInfo: PropTypes.node,
  // Milestones in this week
  milestones: PropTypes.arrayOf(PropTypes.object),
  // Callback on milestone click
  onMilestoneClick: PropTypes.func.isRequired
};

WeekRow.defaultProps = {
  phaseBgColor: "#fbfcfc",
  phaseInfo: null,
  milestones: []
};

export default WeekRow;
