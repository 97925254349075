/* eslint-disable camelcase */
import {omit} from "lodash";
import moment from "moment";
import {COVERAGE_ENTITY_TYPE } from "CoverageLocationSelect";
import {DEFAULT_DATE_FORMAT} from "../../../../../../utils";
import {emptyStringToNull} from "./utils";

export class InfoFormTransformer {

    static transformEBSession = (formValues) => {
        // Before saving, EB session data should be merged into a single field
        let ebSessionType = "";
        let ebSessionYear = "";

        if (formValues.eb_session_type) {
            ebSessionType = formValues.eb_session_type.value;
        }
        if (formValues.eb_session_year) {
            ebSessionYear = formValues.eb_session_year;
        }
        const values = omit(formValues, ["eb_session_type", "eb_session_year"]);
        if (ebSessionType && ebSessionYear) {
            values.eb_session = `${ebSessionType}/${ebSessionYear}`;
        } else {
            values.eb_session = "";
        }

        return values
    }

    static transformDates = (initialFormValues, formValues) => {
        const values = {};

        // Dates must be converted to the right format if they are changed
        if (
          formValues.planned_completed_date &&
          formValues.planned_completed_date !==
          initialFormValues.planned_completed_date
        ) {
            values.planned_completed_date = moment(
              formValues.planned_completed_date
            ).format(DEFAULT_DATE_FORMAT);
        }
        if (
          formValues.approval_date &&
          formValues.approval_date !== initialFormValues.approval_date
        ) {
            values.approval_date = moment(formValues.approval_date).format(
              DEFAULT_DATE_FORMAT
            );
        }


        return values;
    }

    static transformWFPUser = (formValues, field) => {
        const values = {};

        if (formValues[field] === null) {
            // To remove the value backend expect empty object
            values[field] = {};
        } else if (typeof formValues[field]?.value === 'object') {
            // For modification or creation we need to send the whole object to the backend
            values[field] = {
                email: formValues?.[field]?.value?.email,
                org_unit: formValues?.[field]?.value?.org_unit,
                position_title: formValues?.[field]?.value?.position_title,
                grade: formValues?.[field]?.value?.personal_grade,
                display_name: formValues?.[field]?.value?.display_name,
            }
        } else {
            // There is no modification, do not need to do anything, but the value always required to be presented
            values[field] = null;
        }

        return values;
    }

    static transformCoverage = (formValues) => {
        const coverageFormValue = formValues?.coverage?.value;

        if (!coverageFormValue) {
            return {
                coverage: [{
                    office: null,
                    country: null,
                }]
            }
        }

        return {
            coverage: [{
                office: formValues?.coverage?.entityType === COVERAGE_ENTITY_TYPE.OFFICES ? coverageFormValue : null,
                country: formValues?.coverage?.entityType === COVERAGE_ENTITY_TYPE.COUNTRIES ? coverageFormValue : null,
            }]
        }
    }

    static createAdaptedData = (values) => {
        return {
            ...Object.entries(values).reduce(emptyStringToNull, {}), // Substitute empty strings with null
            activity_categories: values.activity_categories?.map(ac => ({id: ac.value})) ?? [],
            external_commissioner: values.external_commissioner?.id ?? null,
            exercise: values.exercise?.value,
            joint_partners: values.joint_partners?.map(p => ({id: p.id, category: p.category})) ?? [],
            is_joint: values.is_joint === "is_joint_1",
            topics: values.topics?.map(t => ({id: t.value, name: t.label})) ?? [],
            sustainable_development_goals: values.sustainable_development_goals?.map(sdg => sdg.value) ?? [],
            request_by_donors: values.request_by_donors?.map(donor => donor.value) ?? [],
            windows: values.windows?.map(window => window.value) ?? [],
            funded_by_donors: values.funded_by_donors?.map(donor => donor.value) ?? [],
            crosscutting_priorities: values.crosscutting_priorities?.map(
                crosscutting_priorities => crosscutting_priorities.value
            ) ?? [],
            kind: values.kind?.value ?? ""
        };
    }

    static transformToRequestValue = (initialFormValues, formValues) => {
        let values = InfoFormTransformer.transformEBSession(formValues);

        values = {
            ...values,
            ...InfoFormTransformer.transformDates(initialFormValues, formValues),
        }

        const adaptedData = {
            ...InfoFormTransformer.createAdaptedData(values),
            ...InfoFormTransformer.transformCoverage(formValues),
            ...InfoFormTransformer.transformWFPUser(formValues, 'final_approver_onetoone'),
            ...InfoFormTransformer.transformWFPUser(formValues, 'chair_committee_onetoone'),
            ...InfoFormTransformer.transformWFPUser(formValues, 'qa2_onetoone'),
            ...InfoFormTransformer.transformWFPUser(formValues, 'research_analyst_wfp_onetoone'),
            ...InfoFormTransformer.transformWFPUser(formValues, 'manager_wfp_onetoone'),
        }
        delete adaptedData.status_code; // It's now handled by specialized endpoints

        return adaptedData
    }

    static transformToFormValue = (values) => {
        // NotImplemented
        return values
    }
}
