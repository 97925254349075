exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__toggleColumnsBtn___3pz6r{display:block;padding:0 0.5rem}.styles__evaluationLink___3fViP{color:#0a6eb4}.styles__cancelledEvaluationLink___RGgp7{color:#85b7da}.styles__dot___187iM{background:#dfe3e6;border-radius:1rem;font-weight:bold;font-size:0.7rem;margin:auto;padding:0.4rem}.styles__wfpModalOptions___DUY_U li{display:inline-block;margin-right:0.5em}.styles__filterField___2I7UB{align-items:flex-end;display:flex !important}.styles__filterField___2I7UB>fieldset{width:100%}.styles__removeFilter___2-hqK{background-color:#8c9ba5;border-radius:50%;cursor:pointer;flex:none;height:1rem;margin-left:6px;position:relative;bottom:2rem;width:1rem}.styles__removeFilter___2-hqK:hover{background-color:#0a6eb4}.styles__removeFilter___2-hqK::before,.styles__removeFilter___2-hqK::after{background-color:#fbfcfc;content:\"\";height:2px;left:25%;margin-top:-1px;position:absolute;top:50%;width:50%}.styles__removeFilter___2-hqK::before{transform:rotate(45deg)}.styles__removeFilter___2-hqK::after{transform:rotate(-45deg)}.styles__advancedFilterFieldset___3_wyY{margin-bottom:0 !important}.styles__advancedFilterFieldset___3_wyY .styles__filterField___2I7UB{margin-top:-1rem}.styles__checkboxAdvancedFilter___3IfXC{align-items:center;display:flex;margin-bottom:1rem}.styles__checkboxAdvancedFilter___3IfXC .styles__removeFilter___2-hqK{top:-0.5rem}\n", ""]);

// Exports
exports.locals = {
	"toggleColumnsBtn": "styles__toggleColumnsBtn___3pz6r",
	"evaluationLink": "styles__evaluationLink___3fViP",
	"cancelledEvaluationLink": "styles__cancelledEvaluationLink___RGgp7",
	"dot": "styles__dot___187iM",
	"wfpModalOptions": "styles__wfpModalOptions___DUY_U",
	"filterField": "styles__filterField___2I7UB",
	"removeFilter": "styles__removeFilter___2-hqK",
	"advancedFilterFieldset": "styles__advancedFilterFieldset___3_wyY",
	"checkboxAdvancedFilter": "styles__checkboxAdvancedFilter___3IfXC"
};