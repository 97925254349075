import React from "react";
import PropTypes from "prop-types";
import { Button } from "@wfp/ui";

import {
  OPEN_DOWN,
  OPEN_UP,
  ANCHOR_LEFT,
  ANCHOR_RIGHT,
  HORIZONTAL_ORIENTATION,
  VERTICAL_ORIENTATION
} from "react-dates/lib/constants";

import moment from "moment";

// Must import "react-dates/initialize" before any react-dates component
import "react-dates/initialize";
import { SingleDatePicker, isInclusivelyAfterDay } from "react-dates";
import Tooltip from "Tooltip";

import styled from "styled-components";

import { DEFAULT_DATE_FORMAT } from "../../../utils";
import dateFillHelper from "../dateFillHelper";
import {DatePicker} from "../DatePicker";
import { NavNext, NavPrev, CalendarTitle } from "../components";

const validationStyles = {
  display: "block",
  maxHeight: "12.5rem"
};

const StyledWrapper = styled.div.attrs(props => ({
  invalidStyles: props.invalid && "border-color: #c5192d"
}))`
   {
    .DateInput_input::placeholder {
      color: #8c9ba5;
    }
    .SingleDatePicker__block {
      width: 100%;
    }
    .SingleDatePickerInput__showClearDate {
      padding-right: 0;
    }
    // Validation styles
    .DateInput_input {
      ${props => props.invalidStyles};
    }
    // Read-only styles
    .DateInput_input__readOnly {
      border-color: #eff2f5 !important;
      outline: none !important;
      padding-left: 8px !important;

      .SingleDatePickerInput_calendarIcon {
        cursor: default;
      }
    }
    // Disabled styles
    .SingleDatePickerInput__disabled {
      .DateInput_input__disabled {
        cursor: not-allowed;
      }

      .SingleDatePickerInput_calendarIcon {
        cursor: not-allowed;
      }
    }
  }
`;

class DatePickerField extends DatePicker {
  constructor(props) {
    super(props);

    this.state = { focused: false };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    this.inputRef.current?.addEventListener('input', dateFillHelper);
  }

  onDateChange = (date) => {
    this.props.input.onChange(date);
  };

  /** Handle focus state changes */
  onFocusChange = ({ focused }) =>
    this.props.disabled || this.props.readOnly
      ? this.setState({ focused: false })
      : this.setState({ focused });

  /** Disable days outside a range in the date picker */
  isOutsideRange = day => {
    const datesRange = this.props.setDatesRange();
    if (datesRange !== null) {
      if (datesRange.length === 1) {
        return !isInclusivelyAfterDay(
          day,
          moment(datesRange[0], DEFAULT_DATE_FORMAT)
        );
      }
      const end = new Date(moment(datesRange[1], DEFAULT_DATE_FORMAT));
      end.setDate(end.getDate() + 1);
      return (
        !isInclusivelyAfterDay(
          day,
          moment(datesRange[0], DEFAULT_DATE_FORMAT)
        ) || isInclusivelyAfterDay(day, moment(end, DEFAULT_DATE_FORMAT))
      );
    }
    return false;
  };

  /** Set the initial visible month of the calendar */
  initialVisibleMonth = () =>
    this.props.initialVisibleMonth() && !this.props.input.value
      ? () => moment(this.props.initialVisibleMonth(), DEFAULT_DATE_FORMAT)
      : undefined;

  render() {
    const {
      input: { value },
      index,
      disabled,
      readOnly,
      label,
      renderCalendarInfo,
      invalid,
      invalidText,
      helpText,
      isTestMode,
      openDirection,
      anchorDirection,
      orientation,
      withPortal
    } = this.props;

    const date = moment(value, DEFAULT_DATE_FORMAT);
    const labelToRender =
      !isTestMode && helpText !== "" ? (
        <Tooltip message={helpText} label={label} formIndex={index} />
      ) : (
        <label className="wfp--label" htmlFor={index}>
          {label}
        </label>
      );

    const today = moment();

    return (
      <StyledWrapper
        className="wfp--form-item"
        readOnly={readOnly}
        disabled={disabled}
        style={{ opacity: disabled ? "0.5" : "1" }}
        invalid={invalid}
        ref={ref => {
          this.inputRef.current = ref?.querySelector('input');
        }}
      >
        {labelToRender}
        <SingleDatePicker
          id={index}
          date={value && date.isValid() ? date : null}
          focused={this.state.focused}
          onDateChange={this.onDateChange}
          onFocusChange={this.onFocusChange}
          placeholder={readOnly ? "" : "Date"}
          navPrev={
            <NavPrev onNavigateYear={this.onNavigateYear} />
          }
          navNext={
            <NavNext onNavigateYear={this.onNavigateYear} />
          }
          renderMonthElement={this.renderMonthElement}
          displayFormat={DEFAULT_DATE_FORMAT}
          firstDayOfWeek={1}
          isOutsideRange={this.isOutsideRange}
          initialVisibleMonth={this.initialVisibleMonth()}
          horizontalMonthPadding={50}
          block
          readOnly={readOnly}
          disabled={disabled}
          withPortal={withPortal}
          hideKeyboardShortcutsPanel
          showClearDate={!disabled && !readOnly}
          showDefaultInputIcon={!readOnly}
          inputIconPosition="after"
          openDirection={openDirection}
          anchorDirection={anchorDirection}
          orientation={orientation}
          // If DatePicker has no label set a custom renderCalendarInfo
          renderCalendarInfo={() => (
            <>
              <CalendarTitle>{label || renderCalendarInfo}</CalendarTitle>
              {!disabled && !readOnly && (
                <Button
                  data-testid="today-button"
                  kind="primary"
                  onClick={() => {
                    this.onDateChange(today);
                    this.onFocusChange(false);
                  }}
                  disabled={this.isOutsideRange(today)}
                  style={{
                    marginTop: "9px",
                    marginLeft: `${9 + 13}px`,
                  }}
                >
                  Today
                </Button>
              )}
            </>
          )}
          calendarInfoPosition="top"
        />
        {invalid && (
          <div className="wfp--form-requirement" style={validationStyles}>
            {invalidText}
          </div>
        )}
      </StyledWrapper>
    );
  }
}

DatePickerField.propTypes = {
  initialVisibleMonth: PropTypes.func,
  setDatesRange: PropTypes.func,
  index: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  renderCalendarInfo: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node
  ]),
  invalid: PropTypes.bool,
  invalidText: PropTypes.string,
  helpText: PropTypes.string,
  isTestMode: PropTypes.bool,
  withPortal: PropTypes.bool,
  anchorDirection: PropTypes.oneOf([ANCHOR_LEFT, ANCHOR_RIGHT]),
  openDirection: PropTypes.oneOf([OPEN_DOWN, OPEN_UP]),
  orientation: PropTypes.oneOf([HORIZONTAL_ORIENTATION, VERTICAL_ORIENTATION])
};

DatePickerField.defaultProps = {
  initialVisibleMonth: () => null,
  setDatesRange: () => null,
  renderCalendarInfo: null,
  disabled: false,
  readOnly: false,
  label: "",
  invalid: false,
  invalidText: "A valid date is required",
  helpText: "",
  isTestMode: false,
  withPortal: false,
  anchorDirection: ANCHOR_LEFT,
  openDirection: OPEN_DOWN,
  orientation: HORIZONTAL_ORIENTATION
};

export default DatePickerField;
