import { schema } from "normalizr";
import {change} from "redux-form";
import {toast} from "react-toastify";
import { phasesTypes, dePhasesTypes } from "../../types";
import Resource from "../resource";
import { phaseTransformer, milestoneTransformer } from "./savePhasesAndMilestones";
import {client} from "../../store";
import {COLOR_SUCCESS_TOAST} from "../../utils/constants";
import {deMilestonesActions} from "../milestones";
import {getEvaluationActions} from "../evaluations";
import { phaseShifter } from "./shiftPhasesRanges";

const phasesSchema = new schema.Entity("phases");
const dePhasesSchema = new schema.Entity("dePhases");


class DePhasesActions extends Resource {
    shiftPhasesRanges = (startPhaseId, forwardShiftingDays, backwardShiftingDays) => (dispatch, getState) => {
        const { form: { evPhases: {values: { phases } }}, entities: { milestones }} = getState();
        const shiftedPhases = phaseShifter(phases, milestones).shiftPhasesRanges(startPhaseId, forwardShiftingDays, backwardShiftingDays)

        dispatch(change("evPhases", `phases`, shiftedPhases));
    };

    savePhasesAndMilestones = (evaluationId, phases, category) => (dispatch) => {
        const params = {
          phases: phaseTransformer().transform(phases),
          milestones: milestoneTransformer().transform(phases),
        }

        client
            .put(`${category.toLowerCase()}_evaluations/${evaluationId}/update_phases_and_milestones/`, params)
            .then(() => {
                 toast.success("Update successful!", {
                     autoClose: 1000,
                     className: { background: COLOR_SUCCESS_TOAST }
                 });
                 dispatch(this.list({}, { pathParams: { evaluationId }, paginated: false }));
                 dispatch(deMilestonesActions.list({}, { pathParams: { evaluationId }, paginated: false, setItems: true }));
                 dispatch(getEvaluationActions(category).validation(evaluationId)).then(() => {
                     dispatch(getEvaluationActions(category).detail(evaluationId))
                 });
            })
            .catch(error => {
              this.handleError(error);
            })
    };
}

export const dePhasesActions = new DePhasesActions(
    "/evaluations/<evaluationId>/phases",
    dePhasesTypes,
    dePhasesSchema
);
export const phasesActions = new Resource("/phases", phasesTypes, phasesSchema);
