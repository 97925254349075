import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import MainNavigation from "MainNavigation";
import fetchProject from "../../actions/project";
import fetchUser from "../../actions/user";
import { logoutUser } from "../../actions/auth";
import { toggle, hide } from "../../actions/togglable";

/**
 *
 * Brutal copy of Header as a temporary patch for not authorized users.
 *
 */

export class HeaderContainer extends React.Component {
  componentDidMount() {
    this.props.fetchProject();
    this.props.fetchUser();
  }

  render() {
    return (
      <MainNavigation
        userUsername={this.props.userUsername}
        userName={this.props.userName}
        logoutAction={this.props.logUserOut}
        appLabel={this.props.appLabel}
        isUserSupport={false}
      />
    );
  }
}

HeaderContainer.propTypes = {
  appLabel: PropTypes.string,
  fetchUser: PropTypes.func.isRequired,
  fetchProject: PropTypes.func.isRequired,
  userName: PropTypes.string,
  userUsername: PropTypes.string,
  logUserOut: PropTypes.func.isRequired
};

HeaderContainer.defaultProps = {
  appLabel: "",
  userName: "",
  userUsername: "user"
};

// Exported for tests
export const mapStateToProps = state => {
  const page = state.pages.header;
  const userId = page.user && page.user[0] && page.user[0].user;
  const user =
    userId && state.entities.user[userId] ? state.entities.user[userId] : {};

  return {
    appLabel: page.projectInfo.appLabel,
    userName: user.first_name,
    userUsername: user.username,
    userEmail: user.email
  };
};

// Exported for tests
export const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUser()),
  logUserOut: () =>
    Promise.all([
      dispatch(logoutUser()),
      dispatch({ type: "CLEAR_ITEMS", resource: "user" })
    ]),
  fetchProject: () => dispatch(fetchProject()),
  toggleMenu: () =>
    Promise.all([
      dispatch(toggle("HEADER_MENU")),
      dispatch(hide("USER_BOX_SUBMENU")),
      dispatch(hide("MAIN_NAV_SUBMENU"))
    ]),
  toggleMainNavSubmenu: () =>
    Promise.all([
      dispatch(toggle("MAIN_NAV_SUBMENU")),
      dispatch(hide("USER_BOX_SUBMENU"))
    ]),
  toggleUserBoxSubmenu: () =>
    Promise.all([
      dispatch(toggle("USER_BOX_SUBMENU")),
      dispatch(hide("MAIN_NAV_SUBMENU"))
    ]),
  handleResize: () => dispatch({ type: "UPDATE_WINDOW_STATE" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderContainer);
