import React from "react";
import PropTypes from "prop-types";

import moment from "moment";
import get from "lodash/get";
import set from "lodash/set";

import { Button } from "@wfp/ui";
import { iconArrowLeft, iconArrowRight } from "@wfp/icons";

import Slider from "react-slick";

import Year from "./Year";
import Styles from "./styles.scss";
import { DEFAULT_DATE_FORMAT } from "../../../../../../../../utils/index";

/**
 * This takes a list of phases and transform it to an object formed this way:
 *   { yearNumber: { monthNumber: listOfPhases } }
 * Example:
 *   {
 *     2017: {
 *       3: [ { ...phaseObject } ],
 *     }
 *     2018: {
 *       1: [ { ...phaseObject }, { ..otherPhaseObject } ],
 *     }
 *   }
 * If a phase spans more than one month, each month is filled
 * with the corresponding phase
 */
export const reducePhases = (prev, curr) => {
  if (curr.start_date && curr.end_date) {
    const startDate = moment(curr.start_date, DEFAULT_DATE_FORMAT);
    const endDate = moment(curr.end_date, DEFAULT_DATE_FORMAT);
    if (startDate.isValid() && endDate.isValid()) {
      const startYear = startDate.year().toString();
      const startMonth = startDate.month().toString();
      let res = set(prev, `${startYear}.${startMonth}`, [
        ...get(prev, `${startYear}.${startMonth}`, []),
        { ...curr, label: true }
      ]);

      const date = endDate.clone();

      while (date.isSameOrAfter(startDate)) {
        const year = date.year().toString();
        const month = date.month().toString();
        res = set(prev, `${year}.${month}`, [
          ...get(prev, `${year}.${month}`, []),
          curr
        ]);
        date.subtract(1, "month");
      }
      return res;
    }
  }
  return prev;
};

/** Like `reducePhases` except we only take into account the starting date here */
const reduceMilestones = (prev, curr) => {
  if (curr.startDate || curr.eventDate) {
    const date = moment(
      curr.startDate || curr.eventDate,
      DEFAULT_DATE_FORMAT
    );
    if (date.isValid()) {
      const year = date.year().toString();
      const month = date.month().toString();
      return set(prev, `${year}.${month}`, [
        ...get(prev, `${year}.${month}`, []),
        curr
      ]);
    }
  }
  return prev;
};

/**
 * Figures out which years should be shown initially
 *
 * According to business requirements defined in:
 * http://codeassist.wfp.org/jira/browse/OEV-1184
 *
 * (All scenarios are tested, see tests)
 *
 * N.B. Slider understands initialSlide=array.length to mean "show the last items"
 */
export const getInitialSlide = (
  years,
  currentYear = new Date().getFullYear()
) => {
  if (!(years && years.length)) {
    return 0;
  }

  const latestYearIndex = years.length - 1;
  const latestYear = parseInt(years[latestYearIndex], 10);
  const isCurrentYear = year => year === currentYear.toString();
  const currentYearIndex = years.findIndex(isCurrentYear);

  if (
    years.length > 2 &&
    currentYearIndex !== -1 &&
    currentYearIndex !== latestYearIndex
  ) {
    return currentYearIndex;
  }

  if (
    currentYearIndex === latestYearIndex ||
    (currentYearIndex !== latestYearIndex && latestYear < currentYear)
  ) {
    return years.length;
  }

  return 0;
};

const CarouselPrevArrow = ({ onClick }) => (
  <div className={Styles.carouselPrevArrow} onClick={onClick}>
    <Button
      kind="secondary"
      icon={iconArrowLeft}
      iconReverse
      iconDescription="Navigate prev year"
      small
    >
      Prev Year
    </Button>
  </div>
);

const CarouselNextArrow = ({ onClick }) => (
  <div className={Styles.carouselNextArrow} onClick={onClick}>
    <Button
      kind="secondary"
      icon={iconArrowRight}
      iconDescription="Navigate next year"
      small
    >
      Next Year
    </Button>
  </div>
);


// TODO: refactor and move inside calendar component
const deMilestonesToPhases = (dePhases, deMilestones) => {
  // retrieve milestones without a date and append the related phase date
  const milestoneWithPhaseDate = [];
  // first of all retrieve the milestones outOfCalendar and with isDone set to `true` or `false`
  const milestonesWithoutDate = deMilestones.filter(
      m => m.outOfCalendar && m.isDone !== null
  );
  if (milestonesWithoutDate.length > 0) {
    // add start and endDate of the related phase
    milestonesWithoutDate.forEach(m => {
      const phase = dePhases.find(p => m.phase && p.phase === m.phase.id);
      if (phase !== undefined) {
        milestoneWithPhaseDate.push({
          ...m,
          startDate: phase.start_date,
          endDate: phase.end_date
        });
      }
    });
  }
  return deMilestones
      .filter(m => !m.outOfCalendar && m.isDone !== null)
      .concat(milestoneWithPhaseDate);
};

const Calendar = props => {
  const phasesByYear = props.phases.reduce(reducePhases, {});
  const milestones = deMilestonesToPhases(props.phases, props.milestones);

  const milestonesByYear = milestones.reduce(reduceMilestones, {});
  /** Array with all the unique years used in both phases and milestones */
  const years = [
    ...new Set([...Object.keys(phasesByYear), ...Object.keys(milestonesByYear)])
  ].sort();

  /** Array containing all evaluation years mapped to `Year` components */
  const yearComponents = years.map(year => (
    <Year
      key={year}
      year={year}
      phases={phasesByYear[year] || []}
      milestones={milestonesByYear[year] || []}
      onMilestoneClick={props.onMilestoneClick}
    />
  ));

  const initialSlide = getInitialSlide(years);

  return (
    <ul className={Styles.calendar}>
      <Slider
        infinite={false}
        initialSlide={initialSlide}
        prevArrow={<CarouselPrevArrow />}
        nextArrow={<CarouselNextArrow />}
        speed={500}
        slidesToShow={2}
        slidesToScroll={1}
      >
        {yearComponents}
      </Slider>
    </ul>
  );
};

CarouselNextArrow.propTypes = {
  /** Allows user to navigate next years */
  onClick: PropTypes.func
};

CarouselNextArrow.defaultProps = {
  onClick: () => null
};

CarouselPrevArrow.propTypes = {
  /** Allows user to navigate prev years */
  onClick: PropTypes.func
};

CarouselPrevArrow.defaultProps = {
  onClick: () => null
};

Calendar.propTypes = {
  onMilestoneClick: PropTypes.func.isRequired,
  phases: PropTypes.arrayOf(
    PropTypes.shape({
      phase_display: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      start_date: PropTypes.string.isRequired,
      end_date: PropTypes.string.isRequired
    })
  ).isRequired,
  milestones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      eventDate: PropTypes.string,
      milestone: PropTypes.number.isRequired,
      calendarDisplay: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Calendar;
