import { schema } from "normalizr";
import { activityCategoriesTypes } from "../types";
import Resource from "./resource";
import {client} from "../store";

export const activityCategoriesSchema = new schema.Entity("activityCategories");

export default new Resource(
  "/activity-categories",
  activityCategoriesTypes,
  activityCategoriesSchema
);

client.enableAutoCamelCase("/activity-categories");
