import { schema } from "normalizr";
import { reasonsForCancellationTypes } from "../types";
import Resource from "./resource";
import {client} from "../store";

export const reasonsForCancellationSchema = new schema.Entity(
    "reasonsForCancellation"
);

export default new Resource(
    "/reasons-for-cancellation",
    reasonsForCancellationTypes,
    reasonsForCancellationSchema,
);

client.enableAutoCamelCase('/reasons-for-cancellation');
