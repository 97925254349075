export const setExpandedStatuses = expandedStatuses => ({
  type: "SET_EXPANDED_STATUSES",
  expandedStatuses
});

/** Toggles status expansion (to true if previously unset) */
export const toggleStatusExpansion = statusCode => ({
  type: "TOGGLE_STATUS_EXPANSION",
  statusCode
});
