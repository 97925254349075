import {change, Field} from "redux-form";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select from "Select";
import {useIsMounted} from "hooks";
import {adaptInputValues} from "../../utils";
import {advancedFilterActions} from "../../../../actions/filters";
import officesActions from "../../../../actions/wfpOffices";
import divisionsActions from "../../../../actions/wfpDivisions";
import {
  BUREAU_HQ_DIVISIONS,
  BUREAU_LIAISON_OFFICES,
  BUREAU_OEV,
  OFFICE_TYPE_HQ,
  OFFICE_TYPE_LIAISON
} from "../../../../utils/constants";

const Commissioners = ({label, fieldName, isDisabled}) => {
  const dispatch = useDispatch();
  const {wfpOffices, wfpDivisions} = useSelector(state => state.entities);
  const bureauValue = useSelector(state => state.pages.evaluations.activeAdvancedFilters?.bureaux?.inputs?.value)
  const isMounted = useIsMounted();

  const transformOption = (option, group) => ({
    label: `${option.name} - ${option.code}`,
    value: option.id,
    group,
  })

  let offices = Object.values(wfpOffices).filter(office => office.type_code !== OFFICE_TYPE_HQ);
  let divisions = Object.values(wfpDivisions);

  // Bureau filter
  if (bureauValue) {
    if (bureauValue === BUREAU_HQ_DIVISIONS) {
      offices = []
      divisions = divisions.filter(division => division.code !== BUREAU_OEV)
    } else if (bureauValue === BUREAU_OEV) {
      offices = []
      divisions = divisions.filter(division => division.code === BUREAU_OEV)
    } else if (bureauValue === BUREAU_LIAISON_OFFICES) {
      offices = offices.filter(office => office.type_code === OFFICE_TYPE_LIAISON);
      divisions = [];
    } else {
      offices = offices.filter(office => office.parent === bureauValue || office.id === bureauValue);
      divisions = [];
    }
  }

  const options = [
    {
      label: 'Offices',
      options: offices.map(office => transformOption(office, 'office'))
    }, {
      label: 'Divisions',
      options: divisions?.map(division => transformOption(division, 'division'))
    }
  ]


  const onChange = inputs => {
    if (inputs !== null && inputs.length > 0) {
      const inputOffices = inputs.filter(input => input.group === 'office');
      const inputDivisions = inputs.filter(input => input.group === 'division');
      dispatch(advancedFilterActions.addFilter(fieldName, inputs, {
        commissioning_offices: adaptInputValues(inputOffices),
        commissioning_divisions: adaptInputValues(inputDivisions)
      }));
    } else {
      dispatch(advancedFilterActions.removeFilter(fieldName))
    }
  };

  useEffect(() => {
    dispatch(officesActions.list({}, {paginated: false}));
    dispatch(divisionsActions.list({}, {paginated: false}));
  }, [])

  useEffect(() => {
    if (isMounted) {
      dispatch(advancedFilterActions.removeFilter(fieldName));
      dispatch(change("newEvSearch", fieldName, null));
    }
  }, [bureauValue])

  return (
    <Field
      name={fieldName}
      label={label}
      component={Select}
      options={options}
      onChange={onChange}
      openMenuOnClick
      allowMultiple
      disabled={isDisabled}
    />
  );
}

export default Commissioners;