import React from "react";
import PropTypes from "prop-types";
import ClickOutside from "react-click-outside";

import ToggleTbColumns from "ToggleTbColumns";
import EditColumnsIcon from "./EditColumnsIcon";

import Styles from "./styles.scss";

const editColumnsButtonActiveStyles = `${Styles.editColumnsButton} ${Styles.isActive}`;

const EditColumns = props => (
  <div>
    {
      <button
        type="button"
        className={
          props.columnsListIsVisible
            ? editColumnsButtonActiveStyles
            : Styles.editColumnsButton
        }
        aria-label="Edit the columns of Evaluations list table"
        onClick={props.showColsList}
      >
        <EditColumnsIcon />
      </button>
    }

    {props.columnsListIsVisible === true ? (
      <ClickOutside onClickOutside={() => setTimeout(props.hideColsList, 50)}>
        <ToggleTbColumns
          data={props.options}
          dataVisible={props.visible}
          onToggleHandler={props.onToggleHandler}
          onSelectAll={props.onSelectAll}
          onSelectNone={props.onSelectNone}
          onSelectDefault={props.onSelectDefault}
          onInvertSelection={props.onInvertSelection}
        />
      </ClickOutside>
    ) : null}
  </div>
);

EditColumns.propTypes = {
  hideColsList: PropTypes.func.isRequired,
  showColsList: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  columnsListIsVisible: PropTypes.bool,
  visible: PropTypes.arrayOf(PropTypes.string),
  onToggleHandler: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectDefault: PropTypes.func,
  onSelectNone: PropTypes.func,
  onInvertSelection: PropTypes.func
};

EditColumns.defaultProps = {
  options: [],
  columnsListIsVisible: false,
  visible: [],
  onToggleHandler: undefined,
  onSelectAll: undefined,
  onSelectNone: undefined,
  onSelectDefault: undefined,
  onInvertSelection: undefined
};

export default EditColumns;
