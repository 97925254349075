import {dateFormatter} from "../../../utils";

/**
 * Prepares data in the proper structure for the API
 */
const milestoneTransformer = () => {
  const cleanMilestone = (milestone, milestoneId) => {
      if (!milestone) {
        return null;
      }

      return ({
        startDate: dateFormatter(milestone.datesRange?.startDate).toString(),
        endDate: dateFormatter(milestone.datesRange?.endDate).toString(),
        eventDate: dateFormatter(milestone.eventDate).toString(),
        isDone: milestone.isDone?.value ?? null,
        milestone: milestoneId,
        ...(milestone?.id ? {id: milestone.id} : {})
      })
  }


  return {
    transform: (phases) => {
      let milestones = [];
      phases
        .forEach(phase => {
           milestones = milestones.concat(phase.milestones
            .map((milestone, index) => cleanMilestone(milestone, index))
            .filter(milestone => milestone))
          }
        )
        return milestones;
    }
  }
}

export default milestoneTransformer;
