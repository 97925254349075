import { LOCATION_CHANGE } from "connected-react-router";
import { actionTypes as reduxFormActionTypes } from "redux-form";

export default (state = {}, action) => {
  switch (action.type) {
    /** Append action.errors to current errors */
    case "FIELD_ERRORS_ADD": {
      return {
        ...state,
        ...action.errors
      };
    }
    /** Reset errors. Used, for instance, on successful saving */
    case "FIELD_ERRORS_CLEAR": {
      return {};
    }
    /** Errors are bound to a page. Changing page should reset them */
    case LOCATION_CHANGE: {
      return {};
    }
    /**
     * Errors are bound to a form.
     * Initializing the form should reset them.
     *
     * TODO: be picky about WHICH form was initialized.
     */
    case reduxFormActionTypes.INITIALIZE: {
      return {};
    }
    /**
     * Errors are bound to a form.
     * Destroying the form should reset them.
     *
     * TODO: be picky about WHICH form was initialized.
     */
    case reduxFormActionTypes.DESTROY: {
      return {};
    }
    default:
      return state;
  }
};
