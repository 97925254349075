import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import CRUDLayout, {CRUDMapStateToPropsPropTypes, CRUDMapDispatchToProps, CRUDMapDispatchToPropsPropTypes, CRUDMapStateToProps} from "CRUDLayout";
import Windowactions from "../../actions/Windows";
import TableWithModalsMixin from "../../components/TableWithModalsMixin";
import WindowModal from "./WindowModal";
import columnsGenerator from "./tableColumns";


export class WindowListComponent extends TableWithModalsMixin {
    render = () => (
      <CRUDLayout
        {...this.props}
        title='Windows'
        entityName='Windows'
        columnsGenerator={columnsGenerator}
        addModalComponent={WindowModal}
        getItem={this.getItem}
        onSave={this.saveItem}
        handleDragEnd={this.handleDragEnd}
      />
    );
}

WindowListComponent.propTypes = {
  ...CRUDMapStateToPropsPropTypes,
  ...CRUDMapDispatchToPropsPropTypes,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      isDeprecated: PropTypes.bool
    })
  ).isRequired,
  selectedItem: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    isDeprecated: PropTypes.bool
  }),
};

export const mapStateToProps = state => {
    return (
        CRUDMapStateToProps(
            state,
            'Windows',
            'exercise.add_window',
            'exercise.change_window'
        )
    )
}

export const mapDispatchToProps = dispatch => (
  CRUDMapDispatchToProps(dispatch, Windowactions, 'Windows')
);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WindowListComponent)
);
