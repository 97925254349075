import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import CRUDLayout, {CRUDMapStateToPropsPropTypes, CRUDMapDispatchToProps, CRUDMapDispatchToPropsPropTypes, CRUDMapStateToProps} from "CRUDLayout";
import rfcActions from "../../actions/reasonsForCancellation";
import TableWithModalsMixin from "../../components/TableWithModalsMixin";
import RFCModal from "./RFCModal";
import columnsGenerator from "./tableColumns";


export class RFCListComponent extends TableWithModalsMixin {
    render = () => (
      <CRUDLayout
        {...this.props}
        title='Reasons for Cancellations'
        entityName='Reasons for Cancellation'
        columnsGenerator={columnsGenerator}
        addModalComponent={RFCModal}
        getItem={this.getItem}
        onSave={this.saveItem}
        handleDragEnd={this.handleDragEnd}
      />
    );
}

RFCListComponent.propTypes = {
  ...CRUDMapStateToPropsPropTypes,
  ...CRUDMapDispatchToPropsPropTypes,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      isDeprecated: PropTypes.bool
    })
  ).isRequired,
  selectedItem: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    isDeprecated: PropTypes.bool
  }),
};

export const mapStateToProps = state => (
  CRUDMapStateToProps(
    state,
    'reasonsForCancellation',
    'exercise.add_reasonforcancellation',
    'exercise.change_reasonforcancellation'
  )
)

export const mapDispatchToProps = dispatch => (
  CRUDMapDispatchToProps(dispatch, rfcActions, 'reasonsForCancellation')
);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RFCListComponent)
);
