import {dateFormatter} from "../../../utils";

/**
 * Prepares data in the proper structure for the API
 */
const phaseTransformer = () => {
  const cleanPhase = (phase) => ({
    startDate: dateFormatter(phase.dates_range?.startDate).toString(),
    endDate: dateFormatter(phase.dates_range?.endDate).toString(),
    phase: phase.phase,
    ...(phase?.id ? {id: phase.id} : {})
  })

  return {
    transform: (phases) => phases
      .map(phase => cleanPhase(phase))
      .filter(phase => phase.startDate || phase.endDate || phase.id),
  }
}

export default phaseTransformer;
