
export default (state = {}, action) => {
  if (action.type === "SET_FEATURE_FLAGS") {
    return {
      ...state,
      ...Object.values(action.payload).reduce((acc, obj) => ({...acc, [obj.key]: obj.value}), {})
    };
  }

  return state;
};
