import React from "react";
import PropTypes from "prop-types";

const ErrorToastBody = ({ label, descriptions }) => (
  <div>
    <p>{label}</p>
    {descriptions.map ? (
      descriptions.map(desc => <p>{desc}</p>)
    ) : (
      <p>{descriptions}</p>
    )}
  </div>
);

ErrorToastBody.propTypes = {
  label: PropTypes.string,
  descriptions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ])
};

ErrorToastBody.defaultProps = {
  label: "",
  descriptions: []
};

export default ErrorToastBody;
