import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SecondaryNavigation from "SecondaryNavigation";
import ActivityActions, { setActivityPage, setActivityPageSize } from "../../actions/enhancedActivity";
import ActivityTable from "../../components/ActivityTable";

class EnhancedActivity extends React.Component {
  componentDidMount() {
    this.props.fetchActivities();
  }



  render() {
    return (
      <div>
        <SecondaryNavigation title="Activity" />
        <div className="wfp-wrapper wfp-content">
          <ActivityTable
            items={this.props.items}
            totalItems={this.props.totalItems}
            isFetching={this.props.isFetching}
            page={this.props.page}
            pages={this.props.pageCount}
            pageSize={this.props.pageSize}
            onPageChange={(page) => this.props.setPage(page, this.props.pageSize)}
            onPageSizeChange={(pageSize) => this.props.setPage(0, pageSize)}
            manual
          />
        </div>
      </div>
    );
  }
}

EnhancedActivity.propTypes = {
  setPage: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  fetchActivities: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  totalItems: PropTypes.number
};

EnhancedActivity.defaultProps = {
  items: [],
  totalItems: undefined
};

// Exported for tests only
export const mapStateToProps = state => {
  const page = state.pages.enhancedActivity;
  const { entities } = state;

  const items = page.items.map(i => entities.enhancedActivity[i]);

  return {
    items,
    isFetching: page.isFetching,
    page: page.page,
    pageSize: page.pageSize,
    pageCount: page.pageCount,
    totalItems: page.totalItems
  };
};

// Exported for tests only
export const mapDispatchToProps = dispatch => ({
  fetchActivities: () =>
    dispatch(ActivityActions.list({})).then(res => {
      return Promise.all([
        dispatch({
          type: "SET_PAGE_COUNT",
          count: Math.ceil(res.value.data.count / 10)
        }),
        dispatch({ type: "SET_TOTAL_ITEMS", total: res.value.data.count })
      ])
    }

    ),
  // This will set the current paginator page
  setPage: (page, pageSize) =>
    Promise.all([
      dispatch(setActivityPage(page)),
      dispatch(setActivityPageSize(pageSize)),
      dispatch(ActivityActions.list({ page: page + 1, pageSize }))
    ]),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnhancedActivity);
