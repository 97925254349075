import { includes, filter } from "lodash";

/** Case-insensitive string inclusion check */
const iIncludes = (v, field) =>
  includes(String(field).toLowerCase(), String(v).toLowerCase());

/**
 * Returns an array of objects that,
 * within the fields specified in the options param,
 * contain _every_ word of the query string.
 *
 * @param val the query string (' '-separated)
 * @param data the data array
 * @param options an object that holds the keys by which the result should be searched for
 */
const search = (val, data, options, nestedField = "name") =>
  filter(
    data,
    // Ignore case when searching, also avoid crashing on null fields
    // and search on all available fields
    e => {
      // First separate the searched words
      const vals = val.split(" ");
      // Now for every field (option's value field) in our
      // evaluation, check if the field value contains each
      // of the searched words
      // XXX: This is a lot of stuff, optimize here or implement
      //      the search backend side if it turns out to be too heavy
      return (
        options
          .map(
            o =>
              e &&
              e[o.value] && // First check that the value is not null
              // Then call 'iIncludes' to check that each 'vals'
              // element is contained in the field
              vals.every(v => {
                let value = e[o.value];
                // If the value is an array of objects, we try
                // to look for one specific field in the object too
                // By default we look in the 'name' field, but this
                // can be changed if needed
                if (Array.isArray(value)) {
                  value = value
                    .map(n => n !== null && n[nestedField])
                    .join(" ");
                }
                return iIncludes(v, value);
              })
          )
          // If any result is true this method returns true
          // and the result is added to the filtered list
          .some(element => element === true)
      );
    }
  );

export default search;
