import { normalize, schema } from "normalizr";
import lodashMerge from "lodash/merge";
import get from "lodash/get";
import { merge, mergeItems } from "./entities";
import { client } from "../store";
import Resource from "./resource";
import { userTypes } from "../types";

const profileSchema = { user: new schema.Entity("user") };

class UserActions extends Resource {
  /** We override `detail` in order to store user permissions */
  detail = (id, _options) => {
    const options = _options
      ? lodashMerge(this.defaultOptions, _options)
      : this.defaultOptions;
    let path = options.altPath ? options.altPath : this.path;
    let additionalData = {};
    if (options.pathParams) {
      path = this.addParamsToPath(options.pathParams);
      additionalData = options.pathParams;
    }
    if (id !== undefined && id !== null && id !== "") {
      path = `${path}/${id}`;
    }
    return dispatch =>
      dispatch({
        type: this.types.detail.base,
        payload: client
          .get(`${path}/`)
          .then(response => {
            const data = normalize(
              { ...response.data, ...additionalData },
              this.schema
            );

            /** Store to localStorage whether user had > 0 permissions last time we checked */
            const permissions = get(data, "result.permissions.permission");
            const userHadPermissions =
              Array.isArray(permissions) && permissions.length > 0;
            localStorage.setItem("userHadPermissions", userHadPermissions);

            dispatch(mergeItems(data, this.types.name));
            dispatch(merge(data, this.types.name));
            return response;
          })
          .catch(error =>
            this.handleError(error, `Error retrieving ${this.types.name} data`)
          )
      });
  };
}

export const getUserActions = () =>
  new UserActions("/userprofile", userTypes, profileSchema);

const fetchUser = () => getUserActions().detail("");
export const userOptions = () => getUserActions().options();
export default fetchUser;
