import React from "react";
import PropTypes from "prop-types";
import {Button} from "@wfp/ui";
import {iconAdd} from "@wfp/icons";
import {RequirementCodes} from "enums";
import Styles from "./styles.scss";
import ColumnSeparator from "../ColumnSeparator";
import Select from "../Select";


const statusColorMap = {
    TENTATIVE: '#acf1d7',
    CONFIRMED: '#f5e1be',
    PREPARATION: '#fff5c3',
    ONGOING: '#e0e0e0',
    COMPLETED: '#f2dcf2',
    FINALIZED: '#dcf1f2',
}

const Status = props => {
  const getRowIndices = (categoryCode, statusCode) => {
    const categoryIndex = props.schema.findIndex(item => item.categoryCode === categoryCode);
    // eslint-disable-next-line react/prop-types
    const statusIndex = props.schema[categoryIndex].statuses.findIndex(item => item.statusCode === statusCode);
    return [categoryIndex, statusIndex]
  }

  const handleAdd = (categoryCode, statusCode) => {
      const [categoryIndex, statusIndex] = getRowIndices(categoryCode, statusCode);

      // eslint-disable-next-line react/prop-types
      props.schema[categoryIndex].statuses[statusIndex].requirements.push({
        'requirementName': null,
        'requirementClass': RequirementCodes.MILESTONE,
        'args': [],
        'kwargs': {
          'conditions': props.requirements[RequirementCodes.MILESTONE].defaultConditions,
        },
      })

      props.setSchema(props.schema)
  }


  return (
    <div className={Styles.container}>
      <div className={Styles.titleContainer} style={{backgroundColor: statusColorMap[props.statusCode]}}>
        <p className={Styles.title}>{props.statusCode}</p>
      </div>
      <ColumnSeparator />
      <div className={Styles.contentContainer}>
        <div className={Styles.headerContainer}>
          <Select value="Type" id='name-header' readOnly />
          <Select value="Field" id='field-header' readOnly />
          <Select value="Code" id='args-header' readOnly />
          <Select value="Conditions" id='conditions-header' readOnly />
          <div className={Styles.operationHeader} />
        </div>
        {props.children}
        <div className={Styles.buttonContainer}>
          <div className={Styles.addButtonContainer}>
            <Button
              data-testid="add-button"
              type="submit"
              icon={iconAdd}
              onClick={() => handleAdd(props.categoryCode, props.statusCode)}
              kind="secondary"
            />
          </div>
        </div>
      </div>
    </div>
  )
}


Status.propTypes = {
  categoryCode: PropTypes.string.isRequired,
  statusCode: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  schema: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSchema: PropTypes.func.isRequired,
  requirements: PropTypes.shape({}).isRequired,
};

Status.defaultProps = {};

export default Status;
