import { combineReducers } from "redux";
import { fetching, items, showHide } from "./resource";

const scoreReducer = (state = {}, action) => {
  if (action.type === "SET_TOTAL_SCORE") {
    return action.data;
  }
  return state;
};

export default combineReducers({
  totalScore: scoreReducer,
  isFetching: fetching("gender") || fetching("epiframeworks"),
  epiframeworks: items("epiframeworks"),
  showWarningModal: showHide("FRAMEWORK_WARNING_MODAL"),
  items: items("gender")
});
