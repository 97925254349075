import React, {useEffect, useMemo} from 'react';
import Select from "Select";
import {useDispatch, useSelector} from "react-redux";
import {groupByKey, sortByLabel} from "../../utils";
import countriesActions from "../../actions/countries";
import officesActions from "../../actions/wfpOffices";

export const COVERAGE_ENTITY_TYPE = {
    OFFICES: "OFFICES",
    COUNTRIES: "COUNTRIES",
}

const officeTypes = ['Regional Bureau', 'Liaison Office', 'HeadQuarters'];

export const CoverageLocationSelect = (props) => {
    const dispatch = useDispatch();
    const storedOffices = useSelector(store => store.entities?.wfpOffices);
    const storedCountries = useSelector(store => store.entities?.countries);

    const offices = useMemo(() => {
        const sorted = Object.values(storedOffices ?? {}).sort(sortByLabel);
        const grouped = groupByKey(sorted, 'type');

        return officeTypes.map(type => ({
            label: type,
            options: grouped?.[type]?.map(item => ({
                value: item.id,
                entityType: COVERAGE_ENTITY_TYPE.OFFICES,
                label: item.label
            }))
        }))
    }, [storedOffices]);

   const countries = useMemo(() => {
        return {
          label: "Countries",
          options: Object.values(storedCountries ?? {})
            .map(country => ({
              value: country.id,
              entityType: COVERAGE_ENTITY_TYPE.COUNTRIES,
              label: country.__unicode__
            }))
            .sort(sortByLabel)
        }
   }, [storedCountries])

    useEffect(() => {
        dispatch(officesActions.list({}, { paginated: false }));
        dispatch(countriesActions.list({}, { paginated: false }));
    }, []);

   const isOptionSelected = (option, selectedValue) => option.entityType === selectedValue?.entityType && option.value === selectedValue?.value;

    return (<Select 
      options={[...offices, countries]}
      isOptionSelected={(option, selectValue) => selectValue.some((value) => isOptionSelected(option, value))}
      {...props}
    />)
}

export default CoverageLocationSelect;