import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import PropTypes from "prop-types";
import { reset, change } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import get from "lodash/get";
import ReactTable from "react-table";
import { Button } from "@wfp/ui";
import { iconAddGlyph } from "@wfp/icons";
import { toast } from "react-toastify";

import DeleteModal from "Modal/DeleteModal";
import Loading from "Loading";
import TableWithModalsMixin from "TableWithModalsMixin";
import HelpMessageBox from "HelpMessageBox";

import { getEvaluationActions } from "../../../../../../actions/evaluations";
import { toggle, hide } from "../../../../../../actions/togglable";
import companiesActions from "../../../../../../actions/companies";
import contractTypesActions from "../../../../../../actions/contractTypes";
import countriesActions from "../../../../../../actions/countries";
import expertisesActions from "../../../../../../actions/expertises";
import membersActions from "../../../../../../actions/members";
import peopleActions from "../../../../../../actions/people";
import teamRolesActions from "../../../../../../actions/teamRoles";
import partnerActions from "../../../../../../actions/partners";
import servicesActions from "../../../../../../actions/services";
import {
  adaptPartnersOptions,
  capitalize,
  customToastError,
  getMessages,
  isCreate,
  parseDate
} from "../../../../../../utils";

import columnsGenerator from "./tableColumns";
import MemberAddModal from "./MemberAddModal";
import {MemberTotalCost} from "./components/MemberTotalCost";
import {OptionTransformer} from "../Info/OptionTransformer";
import {Categories} from "../../../../../../enums";
import partnersCategoriesActions from "../../../../../../actions/partnersCategories";

// export for test
export const isLta = code => code === "LTA" || code === "LTA OpEv";

export const parsePerson = (selectedPersonId, entities) => {
  const selectedPerson = entities.people[selectedPersonId];
  return selectedPerson
    ? {
        ...selectedPerson,
        nationality:
          selectedPerson.nationality &&
          selectedPerson.nationality.map(n => ({
            value: n.id,
            label: n.name
          })),
        sex: {
          value: selectedPerson.sex,
          label: selectedPerson.sex_display
        },
        development_status:
          selectedPerson.development_status &&
          capitalize(selectedPerson.development_status.toLowerCase())
      }
    : selectedPersonId;
};

export class MembersComponent extends TableWithModalsMixin {
  savePerson = () => {
    toast.dismiss();

    const { personValues } = this.props;
    const values = {
      ...personValues,
      nationality:
        (personValues &&
          personValues.nationality &&
          personValues.nationality.map(({ value }) => ({ id: value }))) ||
        [],
      sex: get(personValues, "sex.value", null)
    };
    let errors = false;

    if (!("name" in values)) {
      customToastError("Name: This field is required.");
      errors = true;
    }

    if (values.sex === null) {
      customToastError("Sex: This field is required.");
      errors = true;
    }

    if (!errors) {
      this.props.savePerson(values).then(() => this.props.fetchItems());
    }
  };

  saveFirm = () => {
    const { firmValues } = this.props;
    const values = {
      ...firmValues,
      expiry_date: firmValues.expiry_date && parseDate(firmValues.expiry_date),
      signature_date:
        firmValues.signature_date && parseDate(firmValues.signature_date),
      services: (firmValues.services || []).map(({ value }) => ({
        id: value
      }))
    };
    this.props.saveFirm(values);
  };

  /** used by TableWithModalsMixin */
  valuesAdapter = values => ({
    ...values,
    company: get(values, "company.value", null),
    contract_type: get(values, "contract_type.value", null),
    person: get(values, "person.value", null),
    role: get(values, "role.value", null),
    partner: get(values, "partner.value", null),
    expertises: (values.expertises || []).map(({ value }) => ({
      id: value
    }))
  });

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedPerson &&
      parseInt(this.props.selectedPerson, 10) > 0 &&
      prevProps.selectedPerson !== this.props.selectedPerson
    ) {
      this.props.fetchPerson(this.props.selectedPerson);
    }
    if (
      this.props.selectedFirm &&
      parseInt(this.props.selectedFirm, 10) > 0 &&
      prevProps.selectedFirm !== this.props.selectedFirm
    ) {
      this.props.fetchFirm(this.props.selectedFirm);
    }
  }

  /** Only save if the form is not empty */
  customSave = () => {
    toast.dismiss();

    if (this.props.formValues !== undefined) {
      if (
        !("person" in this.props.formValues) ||
        this.props.formValues.person === null
      ) {
        // Insufficient form, cannot save
        customToastError("Name is required");
        return null;
      }

      // Sane form, can save
      return this.saveItem(this.props.category);
    }
    // Empty form, cannot save
    customToastError("Name is required");
    return null;
  };

  customDelete = () => this.deleteItem(this.props.category);

  changeDailyRate = value => {
    this.props.changeDailyRate(value * this.props.days);
  };

  changeDays = value => {
    this.props.changeDailyRate(value * this.props.daily_rate);
  };

  /** If user selects an LTA contract, only LTA companies should be shown */
  contractSelect = value => {
    if (
      value !== null &&
      isLta(value.code) &&
      this.props.selectedCompany &&
      !this.props.selectedCompany.is_lta
    ) {
      this.props.clearCompany();
    }
  };

  /**
   * Hide "Could not validate Team members" messages.
   * They're needed to check if the status can be applied, but they must not be shown.
   */
  filteredWarningMessages = () =>
    this.props.messages &&
    this.props.messages.filter(m => !m.includes("Could not validate"));

  render() {
    const noRowsStyles = this.props.members.length === 0 ? "no_rows_found" : "";
    /* eslint-disable react/jsx-curly-brace-presence */

    return (
      <div>
        {this.props.canEdit && (
          <HelpMessageBox
            messageList={this.filteredWarningMessages()}
            title="Please update the following information"
          />
        )}
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          {this.props.canEdit && (
            <Button
              kind="secondary"
              type="submit"
              icon={iconAddGlyph}
              iconDescription="Add a new team member"
              onClick={() => this.getItem(null)}
              data-testid='new-team-member'
              disabled={
                !this.props.canEdit ||
                isCreate(this.props.match.params.evaluationId)
              }
            >
              New team member
            </Button>
          )}
          {!this.props.isSimplifiedVersion && <MemberTotalCost members={this.props.members} />}
        </div>
        <MemberAddModal
          // Modal props
          title={
            this.props.selectedItem === null
              ? "Add Team Member"
              : "Edit Team Member"
          }
          initialValues={this.props.selectedItem}
          testId={'team-member-editor-modal'}
          toggleModal={this.props.toggleAddModal}
          show={this.props.showMemberAddModal}
          onSubmit={this.customSave}
          contractTypes={this.props.contractTypes}
          companies={this.props.companies}
          teamRoles={this.props.teamRoles}
          partners={this.props.partners}
          expertises={this.props.expertises}
          people={this.props.people}
          onSearch={this.props.fetchPeople}
          clearPeople={this.props.clearPeople}
          // Extra forms (people)
          personFormInitialValues={this.props.selectedPerson}
          onPersonFormSubmit={this.savePerson}
          togglePersonForm={this.props.togglePersonForm}
          personFormVisible={this.props.personFormVisible}
          personFormEdit={this.props.personFormEdit}
          countries={this.props.countries}
          sex={this.props.sex}
          // Extra forms (firm)
          firmFormInitialValues={this.props.selectedFirm}
          onFirmFormSubmit={this.saveFirm}
          toggleFirmForm={this.props.toggleFirmForm}
          firmFormVisible={this.props.firmFormVisible}
          firmFormEdit={this.props.firmFormEdit}
          services={this.props.services}
          // Total fees calculation
          changeDailyRate={this.changeDailyRate}
          changeDays={this.changeDays}
          contractSelect={this.contractSelect}
          isSimplifiedVersion={this.props.isSimplifiedVersion}
        />
        <DeleteModal
          title="Are you sure you want to delete this team member?"
          show={this.props.showMemberDeleteModal}
          toggleModal={this.props.toggleDeleteModal}
          onDelete={this.customDelete}
          danger
        />
        {this.props.isFetching ? (
          <Loading />
        ) : (
          <ReactTable
            className={`-highlight wfp-table -border -striped ${noRowsStyles}`}
            data={this.props.members}
            columns={columnsGenerator(
              this.getItem,
              this.setItemToDelete,
              this.props.canEdit,
              this.props.countriesWithIsoCode,
              this.props.isSimplifiedVersion,
            )}
            defaultSorted={[{ id: 'teamRole', desc: false }]}
            minRows={1}
            defaultPageSize={99999}
            showPagination={false}
          />
        )}
      </div>
    );
  }
}

const MemberPropTypes = {
  id: PropTypes.number,
  daily_rate: PropTypes.string,
  days: PropTypes.string,
  total: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  remarks: PropTypes.string,
  position: PropTypes.string,
  person: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number
  }),
  person_display: PropTypes.string,
  role: PropTypes.numer,
  role_display: PropTypes.string,
  company: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  company_display: PropTypes.string,
  contract_type: PropTypes.shape({
    value: PropTypes.number,
    code: PropTypes.string,
    label: PropTypes.string
  }),
  contract_type_display: PropTypes.string,
  canEdit: PropTypes.bool
};

MembersComponent.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  fetchPeople: PropTypes.func.isRequired,
  clearPeople: PropTypes.func.isRequired,
  toggleAddModal: PropTypes.func.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  showMemberAddModal: PropTypes.bool.isRequired,
  showMemberDeleteModal: PropTypes.bool.isRequired,
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  people: PropTypes.arrayOf(PropTypes.object).isRequired,
  contractTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  teamRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  partners: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedItem: PropTypes.shape(MemberPropTypes)
};

MembersComponent.defaultProps = {
  selectedItem: null
};

// Exported for tests
export const mapStateToProps = (state, ownProps) => {
  const { entities } = state;
  const memberForm = state.form.evMembers;
  const peopleForm = state.form.people;
  const firmForm = state.form.companies;
  const page = state.pages.deMembers;

  // Adapt selected person value
  const selectedPersonId = get(memberForm, "values.person.value");
  const selectedPerson =
    entities && selectedPersonId && parsePerson(selectedPersonId, entities);

  // Adapt selected firm value
  const selectedFirmId = get(memberForm, "values.company.value");
  let selectedFirm = entities.companies[selectedFirmId];
  selectedFirm = selectedFirm
    ? {
        ...selectedFirm,
        services: selectedFirm.services.map(n => ({
          value: n.id,
          label: n.name
        }))
      }
    : selectedFirmId;

  // Adapt selected Member value
  let selectedItem = entities.members[page.selectedMember];
  selectedItem = selectedItem && {
    ...selectedItem,
    company: {
      value: selectedItem.company,
      label: selectedItem.company_display
    },
    role: { value: selectedItem.role, label: selectedItem.role_display },
    partner: { value: selectedItem.partner, label: selectedItem.partner_display },
    person: { value: selectedItem.person, label: selectedItem.person_display },
    contract_type: {
      value: selectedItem.contract_type,
      label: selectedItem.contract_type_display
    },
    expertises:
      selectedItem.expertises &&
      selectedItem.expertises.map(e => ({
        value: e.id,
        label: e.name
      }))
  };

  const people = page.people.map(id => ({
    value: id,
    label: get(entities, `people[${id}].name`, "")
  }));

  // list of companies that will be filtered based on contract type
  let companies = page.companies.map(id => ({
    value: id,
    label: entities.companies[id].name,
    isDeprecated: entities.companies[id].is_deprecated,
  }));

  // Selected company entity
  const selectedCompany =
    memberForm &&
    memberForm.values &&
    memberForm.values.company &&
    entities.companies[memberForm.values.company.value];

  // Selected contract entity
  const selectedContractType =
    memberForm &&
    memberForm.values &&
    memberForm.values.contract_type &&
    entities.contractTypes[memberForm.values.contract_type.value];

  // if user selects an LTA contract, only LTA companies should by shown
  if (selectedContractType && isLta(selectedContractType.code)) {
    companies = companies.filter(
      company => entities.companies[company.value].is_lta
    );
  }

  let partners = Object.values(entities.partners).filter(p => !p.is_deprecated);
  partners = adaptPartnersOptions(partners, Object.values(entities.partnersCategories));

  return {
    isFetching: page.isFetching,
    // Props needed by TableWithmodal
    selectedItem,
    formValues: memberForm && memberForm.values,
    // Person form
    personValues: peopleForm && peopleForm.values,
    personFormVisible: page.personForm,
    personFormEdit: get(memberForm, "values.person", false),
    selectedPerson,
    sex: get(page.options, "actions.POST.sex.choices", []).map(g => ({
      label: g.display_name,
      value: g.value
    })),
    countries: get(page, "countries", []).map(id => ({
      label: entities.countries[id].name,
      value: id
    })),
    countriesWithIsoCode: get(page, "countries", []).map(id => ({
      label: entities.countries[id].name,
      value: entities.countries[id].iso_code3
    })),
    // Firm form
    firmValues: firmForm && firmForm.values,
    firmFormVisible: page.firmForm,
    firmFormEdit: get(memberForm, "values.company.value"),
    selectedFirm,
    services: get(page, "services", []).map(id => ({
      label: entities.services[id].name,
      value: id
    })),
    expertises: get(page, "expertises", []).map(id => ({
      label: entities.expertises[id].name,
      value: id
    })),
    // Other props
    initialValues: entities.members[page.selectedItem],
    people,
    members: page.items
      .map(id => ({
        ...entities.members[id],
        teamRole: entities.teamRoles?.[entities.members[id].role],
        partner: entities.partners?.[entities.members[id].partner],
      }))
      .filter(m => m && !m.deleted),
    companies: companies.filter(company => !company.isDeprecated),
    contractTypes: page.contractTypes
      .filter(id => !entities.contractTypes[id].is_deprecated)
      .map(id => ({
        value: id,
        code: entities.contractTypes[id].code,
        label: entities.contractTypes[id].name
      })),
    teamRoles: new OptionTransformer(entities.teamRoles).transform(),
    partners,
    showMemberDeleteModal: page.deleteModal,
    showMemberAddModal: page.addModal,
    // Second parameter is used to show also keys of messages
    messages: getMessages(state.messages.team),
    // Total fees calculation
    days: memberForm && memberForm.values && memberForm.values.days,
    daily_rate: memberForm && memberForm.values && memberForm.values.daily_rate,
    // Selected company entity
    selectedCompany,
    isSimplifiedVersion: ownProps.category === Categories.IMPACT,
  };
};

// Exported for tests
export const mapDispatchToProps = dispatch => ({
  // Props needed by TableWithModalsMixin
  fetchItems: evaluationId =>
    dispatch(
      membersActions.list(
        {},
        { paginated: false, pathParams: { evaluationId } }
      )
    ),
  fetchData: () =>
    Promise.all([
      dispatch(companiesActions.list({ordering:"name"}, { paginated: false })),
      dispatch(contractTypesActions.list({}, { paginated: false })),
      dispatch(countriesActions.list({}, { paginated: false })),
      dispatch(expertisesActions.list({}, { paginated: false })),
      dispatch(peopleActions.options({}, {})),
      dispatch(teamRolesActions.list({}, { paginated: false })),
      dispatch(partnerActions.list({}, { paginated: false })),
      dispatch(servicesActions.list({}, { paginated: false })),
      dispatch(partnersCategoriesActions.list({}, {paginated: false})),
    ]),
  fetchPeople: query => {
    if (query.length >= 3) {
      dispatch(peopleActions.list({ search: query }, { paginated: false }));
    } else {
      dispatch({ type: "CLEAR_ITEMS", resource: "people" });
    }
  },
  fetchPerson: id => dispatch(peopleActions.detail(id, { mergeNoArray: true })),
  fetchFirm: id =>
    dispatch(companiesActions.detail(id, { mergeNoArray: true })),
  saveItem: (member, evaluationId, category) =>
    dispatch(
      membersActions.save(member, {
        mergeNoArrayNoItems: true,
        pathParams: { evaluationId }
      })
    ).then(() => {
        dispatch(getEvaluationActions(category).validation(evaluationId))
        dispatch(getEvaluationActions(category).detail(evaluationId))
    }),
  deleteItem: (memberId, evaluationId, category) =>
    dispatch(
      membersActions.destroy(memberId, { pathParams: { evaluationId } })
    ).then(() =>
      dispatch(getEvaluationActions(category).validation(evaluationId))
    ),
  savePerson: person =>
    dispatch(peopleActions.save(person, { mergeNoArray: true })).then(res =>
      Promise.all([
        dispatch(toggle("PEOPLE_EXTRA_FORM")),
        dispatch(
          change("evMembers", "person", {
            value: res.value.data.id,
            label: person.name
          })
        )
      ])
    ),
  saveFirm: firm =>
    dispatch(companiesActions.save(firm, { mergeNoArrayNoItems: true })).then(
      res =>
        Promise.all([
          dispatch(toggle("FIRM_EXTRA_FORM")),
          dispatch(
            change("evMembers", "company", {
              value: res.value.data.id,
              label: firm.name
            })
          )
        ])
    ),
  clearPeople: () => dispatch({ type: "CLEAR_ITEMS", resource: "people" }),
  resetForm: () =>
    Promise.all([
      dispatch(reset("evMembers")),
      dispatch({ type: "CLEAR_ITEMS", resource: "people" })
    ]),
  selectItem: id => dispatch({ type: "SELECT_MEMBER", id }),
  toggleAddModal: () =>
    Promise.all([
      dispatch(toggle("MEMBER_ADD_MODAL")),
      dispatch(hide("PEOPLE_EXTRA_FORM"))
    ]),
  toggleDeleteModal: () => dispatch(toggle("MEMBER_DELETE_MODAL")),
  togglePersonForm: () => dispatch(toggle("PEOPLE_EXTRA_FORM")),
  toggleFirmForm: () => dispatch(toggle("FIRM_EXTRA_FORM")),
  changeDailyRate: value => dispatch(change("evMembers", "total", value)),
  clearCompany: () => dispatch(change("evMembers", "company", null))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MembersComponent)
);
