import React from "react";

import { Loading } from "@wfp/ui";

import Styles from "./styles.scss";

const SplashScreen = () => (
  <div className={Styles.SplashScreen}>
    <div className={Styles.wfpLogo}>
      <img
        src="img/wfp-logo-vertical-white-en.svg"
        alt="World Food Programme logo"
      />
    </div>
    <h1 className={Styles.appName}>Office of Evaluations</h1>
    <div className={Styles.loading}>
      <Loading className={Styles.spinner} withOverlay={false} small />
    </div>
  </div>
);

export default SplashScreen;
