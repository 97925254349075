import {addDays, DEFAULT_DATE_FORMAT} from "../../../../../../utils";

/** Substitute all '' values with null */
export const emptyStringToNull = (map, obj) => ({
    ...map,
    [obj[0]]: obj[1] === "" ? null : obj[1]
});

/** Starting from a given phase, get the last inserted end date of previous phase */
export const getPreviousPhaseEndDate = (phases, phase) => {
    const slice = phases
        .slice(0, phases.findIndex(p => p.phase === phase.id))
        .reverse();
    const lastPhase = slice.find(
        p => p.end_date !== null && p.phase !== phase.id
    );
    const endDate = lastPhase
        ? addDays(lastPhase.end_date, 1).format(DEFAULT_DATE_FORMAT)
        : null;
    return endDate;
};

/** Starting from a given phase, get the last inserted start date of next phase */
export const getNextPhaseStartDate = (phases, phase) => {
    const slice = phases.slice(
        phases.findIndex(p => p.phase === phase.id),
        phases.length
    );
    const nextPhase = slice.find(
        p => p.start_date !== null && p.phase !== phase.id
    );
    const startDate = nextPhase ? nextPhase.start_date : null;
    return startDate;
};
