import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";

import ModalForm from "ModalForm";
import FirmForm from "./FirmForm";

const AddModal = props => {
  const onSubmit = e => {
    e.preventDefault();
    props.onSubmit();
  };

  return (
    <ModalForm
      title={props.initialValues.id ? "Edit Company" : "Add Company"}
      isVisible={props.show}
      onClose={props.toggleModal}
      onSubmit={onSubmit}
      onFormSubmit={onSubmit}
      buttonDisabled={props.disabled}
      hiddenButtonDisabled={props.disabled}
    >
      <fieldset disabled={props.disabled}>
        <FirmForm
          fieldErrors={props.fieldErrors}
          services={props.services}
          initialValues={props.initialValues}
          inModal
        />
      </fieldset>
    </ModalForm>
  );
};

AddModal.propTypes = {
  fieldErrors: PropTypes.shape({}).isRequired,
  show: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired,
  initialValues: PropTypes.shape({ id: PropTypes.any }), // eslint-disable-line
  options: PropTypes.object, // eslint-disable-line
  disabled: PropTypes.bool
};

AddModal.defaultProps = {
  show: false,
  initialValues: {},
  disabled: false
};

export default reduxForm({
  form: "companies",
  enableReinitialize: true
})(AddModal);
