import { schema } from "normalizr";
import { requirementsTypes } from "../types";
import Resource from "./resource";
import {client} from "../store";

export const requirementsSchema = new schema.Entity(
    "requirements",
  {},
  { idAttribute: 'code' }
);

export default new Resource(
    "/requirements",
    requirementsTypes,
    requirementsSchema,
);

client.enableAutoCamelCase('/requirements');
