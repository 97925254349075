import React from "react";
import PropTypes from "prop-types";

import Tooltip from "Tooltip";
import { TickIcon, CrossIcon } from "Icons";

import Styles from "./styles.scss";

const TooltipMessage = ({
  phaseName,
  milestoneName,
  startDate,
  endDate,
  eventDate,
  isDone,
  hasPeriod,
  hasDate,
  backgroundColor
}) => {
  let mDates;
  let mStatus;

  if (isDone !== null) {
    const status = isDone ? "Done" : "Not done";
    mStatus = (
      <span>
        Status:{" "}
        <span className={Styles.milestoneTooltipMessageBold}>{status}</span>
      </span>
    );
  }

  if (hasPeriod && startDate !== null && endDate !== null && isDone) {
    mDates = (
      <span>
        Start date:{" "}
        <span className={Styles.milestoneTooltipMessageBold}>{startDate}</span>{" "}
        - End date:{" "}
        <span className={Styles.milestoneTooltipMessageBold}>{endDate}</span>{" "}
      </span>
    );
  }

  if (hasDate && isDone && eventDate !== null) {
    mDates = (
      <span>
        Event date:{" "}
        <span className={Styles.milestoneTooltipMessageBold}>{eventDate}</span>{" "}
      </span>
    );
  }

  const info = (
    <p className={Styles.milestoneTooltipMessage}>
      Phase:{" "}
      <span className={Styles.milestoneTooltipMessageBold}>{phaseName}</span>
      <br />
      Milestone:{" "}
      <span className={Styles.milestoneTooltipMessageBold}>
        {milestoneName}
      </span>
      <br />
      {mStatus}
      <br />
      {mDates}
    </p>
  );

  return <Tooltip message={info} color={backgroundColor} iconType="info" />;
};

TooltipMessage.propTypes = {
  phaseName: PropTypes.string.isRequired,
  milestoneName: PropTypes.string.isRequired,
  isDone: PropTypes.bool.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  eventDate: PropTypes.string,
  hasPeriod: PropTypes.bool.isRequired,
  hasDate: PropTypes.bool.isRequired,
  backgroundColor: PropTypes.string.isRequired
};

TooltipMessage.defaultProps = {
  startDate: null,
  endDate: null,
  eventDate: null
};

const Milestone = ({
  milestone: {
    calendarDisplay,
    color,
    endDate,
    eventDate,
    hasDate,
    hasPeriod,
    hasTickbox,
    isDone,
    phase,
    startDate
  },
  onClick
}) => {
  let status = <span />;

  if (hasTickbox) {
    /* only show milestones with isDone set to `true` or `false`
       don't show a milestone with isDone set to `null`
       this prevents a wrong visualization */
    if (isDone !== null) {
      if (isDone) {
        status = (
          <span className={Styles.greenTick}>
            <TickIcon />
          </span>
        );
      } else {
        status = (
          <span className={Styles.redCross}>
            <CrossIcon />
          </span>
        );
      }
    }
  }
  return (
    <div // eslint-disable-line
      className={Styles.milestone}
      style={{
        backgroundColor: color,
        cursor: onClick && "pointer",
        margin: "1px"
      }}
      onClick={onClick}
    >
      <div className={Styles.milestoneContent}>
        <div>
          <span className={Styles.milestoneContentInfo}>{calendarDisplay}</span>
          {status}
        </div>
        <div className={Styles.milestoneTooltip}>
          <TooltipMessage
            phaseName={phase.name}
            milestoneName={calendarDisplay}
            startDate={startDate}
            endDate={endDate}
            eventDate={eventDate}
            hasPeriod={hasPeriod}
            hasDate={hasDate}
            isDone={isDone}
            backgroundColor={color}
          />
        </div>
      </div>
    </div>
  );
};

Milestone.propTypes = {
  milestone: PropTypes.shape({
    calendarDisplay: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    eventDate: PropTypes.string,
    hasDate: PropTypes.bool.isRequired,
    hasPeriod: PropTypes.bool.isRequired,
    hasTickbox: PropTypes.bool.isRequired,
    isDone: PropTypes.bool.isRequired,
    phase: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
    startDate: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func.isRequired
};

export default Milestone;
