import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Button } from "@wfp/ui";

import FirmForm from "../../../../../CompanyList/FirmForm";

import Styles from "./styles.scss";

const FirmCreateForm = props => (
  <div className={Styles.memberCreateForm}>
    <h6>Add Company</h6>
    <FirmForm
      initialValues={props.initialValues}
      fieldErrors={props.fieldErrors}
      className={Styles.personForm}
      services={props.services}
      inModal={props.inModal}
    />
    <div className={Styles.buttonContainer}>
      <Button
        small
        kind="secondary"
        type="submit"
        className={Styles.button}
        onClick={props.toggleFirmForm}
      >
        Cancel
      </Button>
      <Button small type="submit" className={Styles.button}>
        Save
      </Button>
    </div>
  </div>
);

FirmCreateForm.propTypes = {
  fieldErrors: PropTypes.shape({}).isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired,
  toggleFirmForm: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  inModal: PropTypes.bool
};

FirmCreateForm.defaultProps = {
  initialValues: {},
  inModal: false
};

const mapStateToProps = state => ({
  fieldErrors: state.fieldErrors
});

export default connect(mapStateToProps)(FirmCreateForm);
