import {combineReducers} from "redux";
import {
  fetching,
  items,
  togglable,
  options,
  listColumns,
  statuses,
  showHide
} from "./resource";

// Exported for test
export const filtersReducer = (
  state = {
    category: null,
    userEvaluations: false,
    editableEvaluations: false,
    recent: false,
    isJoint: false,
    includeCancelled: false
  },
  action
) => {
  if (action.type === "FILTER_TOGGLE") {
    if (action.data.id === "category") {
      const category = action.data.value;
      return {
        ...state,
        category: state.category === category ? null : category
      };
    }
    return {
      ...state,
      [action.data.id]: !state[action.data.id]
    };
  }
  if (action.type === "RESET_ALL_FILTERS") {
    return {
      ...state,
      category: null,
      userEvaluations: false,
      editableEvaluations: false,
      recent: false,
      isJoint: false,
      includeCancelled: false
    };
  }
  if (action.type === "SET_FILTER") {
    return {
      ...state,
      [action.filterName]: action.value
    };
  }
  return state;
};

const advancedFiltersReducerInitState = {
  evaluationSearch: {query: ""},
}

const advancedFiltersReducer = (state = advancedFiltersReducerInitState, action) => {
  if (action.type === "SHOW_ADVANCED_FILTER") {
    return {
      ...state,
      [action.filterName]: {...state[action.filterName], show: true}
    };
  }
  if (action.type === "HIDE_ADVANCED_FILTER") {
    return {
      ...state,
      [action.filterName]: {...state[action.filterName], show: false}
    };
  }
  if (action.type === "ADD_FILTER") {
    return {
      ...state,
      [action.filterName]: {
        ...state[action.filterName],
        queryParams: action.filter,
        inputs: action.inputs,
      }
    };
  }
  if (action.type === "REMOVE_FILTER") {
     return {
      ...state,
      [action.filterName]: {...state[action.filterName], queryParams: {}, inputs:[]}
    };
  }
  if (action.type === "ADD_TEXT_FILTER") {
    return {
      ...state,
      [action.filterName]: {...state[action.filterName], query: action.filter}
    };
  }

  if (action.type === "RESET_ALL_FILTERS") {
    return Object.keys(state).reduce((accumulator, key) => {
      if (key in advancedFiltersReducerInitState) {
        return accumulator
      }

      return ({...accumulator, [key]: { show: true }})
    }, advancedFiltersReducerInitState);
  }
  return state;
};

const reactTableReducer = (
  state = {
    columns: [],
    currentPage: 0,
    pageSize: 20
  },
  action
) => {
  if (action.type === "SORT_COLUMN") {
    return {...state, columns: action.data};
  }
  if (action.type === "SET_PAGE") {
    return {...state, currentPage: action.data};
  }
  if (action.type === "SET_PAGE_SIZE") {
    return {...state, pageSize: action.data};
  }

  return state;
};

const backgroundListRequests = (resource) => {
  const name = resource.toUpperCase();
  return (state = 0, action) => {
    switch (action.type) {
      case `${name}_LIST_PENDING`:
        return state + 1;
      case `${name}_LIST_FULFILLED`:
        return state - 1;
      default:
        return state;
    }
  };
}

export default combineReducers({
  isFetching:
    fetching("listEvaluations") || fetching("types") || fetching("partners"),
  backgroundListRequests: backgroundListRequests("listEvaluations"),
  isFetchingUser: fetching("user"),
  user: items("user"),
  items: items("listEvaluations", false),
  options: options("listEvaluations"),
  userOptions: items("user"),
  createModal: togglable("CREATE_EVALUATION_MODAL"),
  warningModal: togglable("WARNING_MODAL"),
  columnsList: listColumns("evaluation"),
  filters: filtersReducer,
  activeAdvancedFilters: advancedFiltersReducer,
  // TODO: Do we really need to store it in global state?
  showAdvancedFilters: togglable("SHOW_ADVANCED_FILTERS"),
  bureaux: items("bureaux"),
  countries: items("countries"),
  partners: items("partners"),
  partnersCategories: items("partnersCategories"),
  offices: items("wfpOffices"),
  topics: items("topics"),
  statuses,
  types: items("types"),
  filtersData: items("filtersData"),
  showExportWarningModal: showHide("EXPORT_WARNING_MODAL"),
  table: reactTableReducer
});
