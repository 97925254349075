import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { TablePagination } from "@wfp/ui";
import { Grid, Row, Col } from "Grid";
import Filter from "Filter";
import Loading from "Loading";
import SearchBar from "SearchBar";
import Styles from "./styles.scss";
import { SortableReactTable } from "../SortableReactTable";

const ListComponent = props => {
  const Table = props.enableDragAndDropSorting ? SortableReactTable : ReactTable;

  return (
    <div className="wfp-wrapper wfp-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            {!props.mocked && !props.noSearch && (
              <SearchBar onChange={props.searchOnChange} />
            )}
            <div className={Styles.filtersContainer}>
              {props.filters.length > 0 && (
                <span className={Styles.filtersContainerTitle}>
                  Quick filters:
                </span>
              )}
              {props.filters.map(filter => (
                <Filter
                  key={filter.label}
                  label={filter.label}
                  active={filter.active}
                  onClick={filter.onClick}
                />
              ))}
            </div>
            {props.fetching === true ? (
              <Loading />
            ) : (
              <Table
                manual={props.ACTIVITYPAGE}
                data={props.tableData}
                columns={props.columns}
                pageSize={props.pageSize}
                page={props.page}
                pages={props.pages}
                PaginationComponent={TablePagination}
                className="-highlight wfp-table -border -striped"
                minRows={props.pageSize}
                getTdProps={props.getTdProps}
                totalItems={props.totalItems}
                onPageChange={props.onPageChange}
                pageSizesDisabled={props.pageSizesDisabled}
                showPaginationTop
                getDraggableId={props.getDraggableId}
                handleDragEnd={props.handleDragEnd}
                pageSizeOptions={[5, 10, 20, 50, 100, 1000]}
              />
            )}
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

ListComponent.propTypes = {
  ACTIVITYPAGE: PropTypes.bool, // Temporary prop currently used only for activity page
  // Table basic props
  fetching: PropTypes.bool.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  // Table visualization management
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  getTdProps: PropTypes.func,
  // Modals props
  // Filters
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      active: PropTypes.bool,
      onClick: PropTypes.func.isRequired
    })
  ),
  // Params only used for testing purposes
  mocked: PropTypes.bool,
  noSearch: PropTypes.bool,

  page: PropTypes.number,
  pages: PropTypes.number,
  totalItems: PropTypes.number,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  pageSizesDisabled: PropTypes.bool,
  searchOnChange: PropTypes.func,
  enableDragAndDropSorting: PropTypes.bool,
  getDraggableId: PropTypes.func,
  handleDragEnd: PropTypes.func,
};

ListComponent.defaultProps = {
  ACTIVITYPAGE: false,
  filters: [],
  mocked: false,
  noSearch: false,
  getTdProps: undefined,

  page: undefined,
  pages: undefined,
  pageSize: undefined,
  pageSizesDisabled: false,
  totalItems: undefined,
  onPageChange: undefined,
  enableDragAndDropSorting: false,
  getDraggableId: (item) => item.id.toString(),
  searchOnChange: () => {},
  handleDragEnd: undefined,
};

export default ListComponent;
