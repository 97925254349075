import React from "react";
import PropTypes from "prop-types";
import { Modal as WFPModal } from "@wfp/ui";

import Styles from "./styles.scss";

/**
 * Modal props
 *
 * ### Usage ###
 *  The parent component using the Modal component should have a key-value pair
 *  in its state to set the initial visibility of the modal, e.g. modalIsVisible: false/true.
 *  It should also pass a function to change the value of this key-value pair to the exact opposite
 *  in order to toggle the visibility of the modal component.
 *
 *  Example:
 *
 *  {...}
 *
 *  constructor(props) {
 *    super(props);
 *    this.state = {
 *      modalIsVisible: false
 *    }
 *  }
 *
 *  {...}
 *
 *  toggleModal = () => {
 *    this.setState({
 *      modalIsVisible: !this.state.modalIsVisible
 *    })
 *  }
 *
 *  {...}
 *
 *  render() {
 *    return (
 *      <Modal
 *        title="Which kind of evaluation you want to create?"
 *        isVisible={ this.state.modalIsVisible }
 *        onClose={ this.toggleModal }
 *        onSubmit={() => {}}
 *        onFormSubmit={() => {}}
 *        buttonDisabled={ false }
 *        hiddenButtonDisabled={ false }
 *      />
 *    )
 *  }
 *
 */
const ModalForm = props => {
  if (!props.isVisible) {
    return null;
  }
  const modalContent = props.isModalForm ? (
    <form
      className={`${props.formClassName} wfp-form`}
      onSubmit={props.onFormSubmit}
    >
      {props.children}
      {/*
          We need to keep this button in order to let the user to submit the form
          by pressing the enter key. We cannot use the shouldSubmitOnEnter prop
          of the Modal because it replaces the enter key used by the user while is
          interacting with the select fields options of the form.
        */}
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <button
        type="submit"
        disabled={props.hiddenButtonDisabled}
        style={{ visibility: "hidden", position: "absolute" }}
      />
      {/* eslint-enable jsx-a11y/control-has-associated-label */}
    </form>
  ) : (
    <div>{props.children}</div>
  );
  return (
    <WFPModal
      data-testid={props.testId}
      className={Styles.ModalForm}
      open={props.isVisible}
      modalHeading={props.title}
      onRequestSubmit={props.onSubmit}
      onRequestClose={(evt, trigger) => {
        if (trigger !== "background") {
          return props.onClose(evt, trigger);
        }

        return null;
      }}
      primaryButtonText={props.confirmLabel}
      primaryButtonDisabled={props.buttonDisabled}
      secondaryButtonText={props.cancelLabel}
      shouldSubmitOnEnter={props.shouldSubmitOnEnter}
      selectorPrimaryFocus={false}
      danger={props.danger}
    >
      <div
        className="wfp--modal-content__text wfp--form-long"
        style={
          props.contentCentered && { display: "flex", justifyContent: "center" }
        }
      >
        {modalContent}
      </div>
    </WFPModal>
  );
};

/* Props and PropTypes to be passed to this component */
ModalForm.propTypes = {
  /** Whether include the form directly in the component */
  isModalForm: PropTypes.bool,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  shouldSubmitOnEnter: PropTypes.bool,
  danger: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  hiddenButtonDisabled: PropTypes.bool,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  contentCentered: PropTypes.bool,
  onFormSubmit: PropTypes.func,
  formClassName: PropTypes.string,
  children: PropTypes.any, // eslint-disable-line
  testId: PropTypes.string,
};

/* Default values for component props */
ModalForm.defaultProps = {
  isModalForm: true,
  isVisible: false,
  shouldSubmitOnEnter: false,
  danger: false,
  buttonDisabled: false,
  hiddenButtonDisabled: false,
  confirmLabel: "Save",
  cancelLabel: "Cancel",
  title: "Please insert a valid title",
  contentCentered: undefined,
  formClassName: "",
  children: undefined,
  onFormSubmit: () => {},
  onSubmit: () => {},
  testId: undefined,
};

export default ModalForm;
