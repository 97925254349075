import { combineReducers } from "redux";
import {
  fetching,
  submitting,
  options,
  items,
  togglable,
  pagination,
  selectable,
  statuses,
} from "./resource";

export default combineReducers({
  selectedStatusValidationSchemas: selectable("statusValidationSchemas"),
  isFetching: fetching("statusValidationSchemas"),
  isSubmitting: submitting("statusValidationSchemas"),
  options: options("statusValidationSchemas"),
  items: items("statusValidationSchemas"),
  addModal: togglable("STATUS_VALIDATION_SCHEMAS_ADD_MODAL"),
  deleteModal: togglable("STATUS_VALIDATION_SCHEMAS_DELETE_MODAL"),
  page: pagination("statusValidationSchemas"),
  statuses,
});
