import { combineReducers } from "redux";
import {
  fetching,
  submitting,
  items,
  togglable,
  selectable,
} from "./resource";

export default combineReducers({
  selectedItem: selectable("crosscuttingPriorities"),
  isFetching: fetching("crosscuttingPriorities"),
  isSubmitting: submitting("crosscuttingPriorities"),
  items: items("crosscuttingPriorities"),
  showAddModal: togglable("addModal"),
});
