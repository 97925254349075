import {Field} from "redux-form";
import React from "react";
import { Grid, Row, Col } from "Grid";
import TextInput from "TextInput";
import styles from "../styles.scss";

export const CriterionRow = ({itemName, disableLabels, formName, readOnly}) => (
  <div className="form-row">
    <Grid fluid>
      <Row>
        <Col xs={5}>
          <div className={styles.criteriaColumn}>
            <Field
              name={`${itemName}.criterion.name`}
              label={disableLabels ? false : "Criterion Name"}
              formName={formName}
              readOnly
              component={TextInput}
              tabIndex="-1"
            />
          </div>
        </Col>
        <Col xs={1} fixedWidth="98px">
          <div>
            <Field
              name={`${itemName}.score`}
              label={disableLabels ? false : "Score %"}
              formName={formName}
              component={TextInput}
              readOnly={readOnly}
                        />
          </div>
        </Col>
        <Col xs={4}>
          <div>
            <Field
              name={`${itemName}.rating`}
              label={disableLabels ? false : "Rating"}
              formName={formName}
              readOnly
              component={TextInput}
              tabIndex="-1"
            />
          </div>
        </Col>
      </Row>
    </Grid>
  </div>
)