import React from 'react';
import {Field, reduxForm} from "redux-form";
import { Grid, Row, Col } from "Grid";
import Select from "Select";
import PropTypes from "prop-types";

export const FrameworkSelect = ({options, onUncontrolledChange, readOnly, fieldName}) => {
    return (
      <div>
        <form className="wfp-form wfp--form-long relative">
          <Grid fluid>
            <Row>
              <Col md={3}>
                <Field
                  name={fieldName}
                  label="Framework"
                  component={Select}
                  isClearable={false}
                  onUncontrolledChange={onUncontrolledChange}
                  testId="framework-select"
                  options={options}
                  readOnly={readOnly}
                  openMenuOnClick
              />
              </Col>
            </Row>
          </Grid>
        </form>
      </div>
    )
}

export const FrameworkSelectForm = reduxForm()(FrameworkSelect);

FrameworkSelectForm.propTypes = {
  form: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
  })),
  initialValues: PropTypes.shape({}),
  readOnly: PropTypes.bool.isRequired,
  onUncontrolledChange: PropTypes.func.isRequired,
};

FrameworkSelectForm.defaultProps = {};