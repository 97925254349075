import { client } from "../store";

/** Generate the app label given payoff and version */
export const getAppLabel = (payoff, version) =>
  payoff !== "None" ? `${payoff}, ${version}` : `${version}`;

const fetchProjectInfo = () => dispatch =>
  dispatch({
    type: "PROJECT_INFO",
    payload: client
      .get("/project/")
      .then(
        ({
          data: {
            payoff,
            version,
            changelog,
          }
        }) => {
          dispatch({
            type: "SET_PROJECT_INFO",
            appLabel: getAppLabel(payoff, version),
            changelog
          });
        }
      )
  });

export default fetchProjectInfo;
