const processNavItems = (item, index, permissions) => {
    const children = item?.children?.map((child, childIndex) => {
        if (child.permission && !permissions.includes(child.permission)) {
            return null;
        }
        return {
            ...child,
            key: `nav-item-${index+1}-children-${childIndex+1}`,
        }
    }).filter(child => child) ?? [];

    if (children?.length === 0 && item.href === null) {
        return null;
    }
    return {
        key: `nav-item-${index+1}`,
        title: item.title,
        href: item.href,
        children,
    }
}

export const navItems = (navigationItems, permissions) => (navigationItems
    .map((item, index) => processNavItems(item, index, permissions))
    .filter(item => item)
);