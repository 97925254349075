import Select from "Select";
import {Field} from "redux-form";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {advancedFilterActions} from "../../../../actions/filters";
import {sortByLabel} from "../../../../utils";
import {BUREAU_HQ_DIVISIONS, BUREAU_LIAISON_OFFICES, BUREAU_OEV, OFFICE_TYPE_LIAISON} from "../../../../utils/constants";
import bureauxActions from "../../../../actions/bureaux";




const Bureaux = ({label, fieldName, isDisabled}) => {
  const dispatch = useDispatch();
  const regionalBureaus = Object.values(useSelector(state => state.entities.bureaux))
    .filter(entity => entity.type === "Regional Bureau")
    .map(entity => ({value: entity.id, label: entity.label}))
    .sort(sortByLabel)

  const bureauxGroups = [
    {
      label: "Regional Bureau",
      options: regionalBureaus,
    },
    {
      label: "Head Quarters",
      options: [
        {value: BUREAU_HQ_DIVISIONS, label: "HQ Division (not OEV)"},
        {value: BUREAU_OEV, label: "OEV"},
        {value: BUREAU_LIAISON_OFFICES, label: "Liaison Offices"}
      ]
    }
  ];

  const transformQueryParams = selectedBureau => {
    switch (selectedBureau) {
      case BUREAU_HQ_DIVISIONS:
        return {commissioning_division_group: selectedBureau};
      case BUREAU_OEV:
        return {commissioning_division: selectedBureau};
      case BUREAU_LIAISON_OFFICES:
        return {commissioning_office_type: OFFICE_TYPE_LIAISON};
      default:
        return {commissioning_bureau: selectedBureau};
    }
  };

  const onChange = input => {
    if (input !== null) {
      dispatch(advancedFilterActions.addFilter(fieldName, input, transformQueryParams(input.value)));
    } else {
      dispatch(advancedFilterActions.removeFilter(fieldName));
    }
  };

  useEffect(() => {
    dispatch(bureauxActions.list({search: ""}, {paginated: false}));
  }, [])

  return (
    <Field
      name={fieldName}
      label={label}
      component={Select}
      options={bureauxGroups}
      onChangeExtra={onChange}
      openMenuOnClick
      disabled={isDisabled}
    />
  );
}

export default Bureaux;