import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { reduxForm } from "redux-form";
import { Button, TablePagination, Loading as WFPLoading } from "@wfp/ui";
import moment from "moment";
import XLSX from "xlsx";
import DeleteModal from "Modal/DeleteModal";
import Loading from "Loading";
import Filter from "Filter";
import SearchBar from "SearchBar";
import ExportXslIcon from "./icons/ExportXslIcon";
import Styles from "./styles.scss";
import { customToastError, DEFAULT_DATETIME_FORMAT } from "../../../utils";
import AdvancedFilterSelector from "../AdvanceFilters/AdvancedFilterSelector";
import {ClearAllFilters} from "../AdvanceFilters/ClearAllFilters";
import AdvanceFilters from "../AdvanceFilters";

const formName = "evSearch";

const exportButtonDisabledStyles = `${Styles.exportButton} ${Styles.isDisabled}`;

export class ListComponentComponent extends React.Component {
  /**
   * Prepare all the things needed for export
   * returns an object that contains:
   * - filename: Name of the file.
   * - disclaimerMessage: message used when the warning is triggered.
   * - headers: columns names.
   * - data: evaluations that are in the table.
   */
  exportData = () => {
    let data = [];
    let headers = [];

    const date = moment().format(DEFAULT_DATETIME_FORMAT);
    const fileName = `export-evaluations-${date}.xlsx`;
    const disclaimerMessage = `This file is a snapshot of some of the evaluations in MIS as of ${date}.
                               Please always use MIS to obtain updated information.`;

    if (this.reactTable && this.reactTable.getResolvedState()) {
      data =
        this.reactTable.getResolvedState() &&
        this.reactTable.getResolvedState().resolvedData;
      const tableColumns =
        this.reactTable.getResolvedState() &&
        this.reactTable.getResolvedState().columns;


      // Delimiter for the following array must be`;`.
      data =
        data &&
        data.map(d => ({
          ...d,
          activity_categories_names:
            d.activity_categories_names &&
            d.activity_categories_names.join("; "),
          topics_names: d.topics_names && d.topics_names.join("; "),
          joint_partners_names:
            d.joint_partners_names && d.joint_partners_names.join("; "),
          sustainable_development_goals_names:
            d.sustainable_development_goals_names &&
            d.sustainable_development_goals_names.join("; "),
          request_by_donors_names:
            d.request_by_donors_names &&
            d.request_by_donors_names.join("; "),
          funded_by_donors_names:
            d.funded_by_donors_names &&
            d.funded_by_donors_names.join("; "),
          crosscutting_priorities_names:
            d.crosscutting_priorities_names &&
            d.crosscutting_priorities_names.join("; "),
          windows_names:
            d.windows_names &&
            d.windows_names.join("; "),
          reason_for_cancellation: d.reason_for_cancellation?.name
        }));

      headers =
        tableColumns &&
        tableColumns.map(col => {
          let label = col.Header;

          if (col.code === "category_code") {
            label = "Category";
          }

          return {
            key: col.code,
            label,
            hidden: !this.props.visibleColumns.some(vc => vc === col.code)
          };
        });
    }

    return {
      fileName,
      disclaimerMessage,
      data,
      headers
    };
  };

  export = () => {
    if (this.exportData().data.length > 0) {
      return this.props.showExportWarningModal();
    }

    const errorMsg =
      "There must be at least one evaluation in the list to use the export tool.";
    return customToastError(errorMsg);
  };

  exportFile = () => {
    const { fileName, data, headers } = this.exportData();
    const dataOutput = [
      headers.map(h => h.label),
      ...data.map(d => headers.map(h => d[h.key]))
    ];
    const ws = XLSX.utils.aoa_to_sheet(dataOutput);
    // ws['!cols'] = [];
    // headers.forEach((v, i) => {
    // ws['!cols'][i] = { hidden: v.hidden };
    // });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, fileName);
  };

  warningModal = () => (
    <DeleteModal
      confirmLabel="Export"
      title="Export the list of evaluations to Excel"
      message={`${this.exportData().disclaimerMessage}`}
      disclaimer="Important note: Any change in the file will not update MIS information."
      show={this.props.isExportWarningModalVisible}
      toggleModal={this.props.hideExportWarningModal}
      onDelete={() =>
        Promise.all([this.props.hideExportWarningModal(), this.exportFile()])
      }
    />
  );

  render() {
    return (
      <div className="wfp-wrapper wfp-content">
        <div className={Styles.mainActions}>
          {!this.props.mocked && !this.props.noSearch && (
            <SearchBar
              onChange={input =>
                this.props.addTextFilter("evaluationSearch", input.query)
              }
            />
          )}
          <Button
            className={
              (this.props.fetching || this.props.hasBackgroundListRequest)
                ? exportButtonDisabledStyles
                : Styles.exportButton
            }
            aria-label="Export the list of evaluations to Excel"
            kind="secondary"
            disabled={(this.props.fetching || this.props.hasBackgroundListRequest)}
            onClick={this.props.fetching ? undefined : this.export}
          >
            <ExportXslIcon />
          </Button>
        </div>
        <div className={Styles.filtersContainer}>
          {this.props.filters.length > 0 && (
            <span className={Styles.filtersContainerTitle}>Filters:</span>
          )}
          {this.props.filters.map(filter => (
            <Filter
              key={filter.label}
              label={filter.label}
              active={filter.active}
              onClick={filter.onClick}
            />
          ))}
          <AdvancedFilterSelector />
          <ClearAllFilters />
          {this.warningModal()}
        </div>
        <AdvanceFilters />
        {this.props.hasBackgroundListRequest && (
          <div style={{ display: 'flex', float: 'right', flexDirection: "row", alignItems: 'center' }}>
            <p>Loading more evaluations...</p>
            <WFPLoading small withOverlay={false} />
          </div>
        )}
        {this.props.fetching === true ? (
          <Loading withOverlay />
        ) : (
          <ReactTable
            data={this.props.tableData}
            ref={table => {
              this.reactTable = table;
            }}
            columns={this.props.columns}
            className={`-highlight wfp-table -striped -border pointable ${Styles.evaluationsListTable}`}
            getTdProps={this.props.getTdProps}
            PaginationComponent={TablePagination}
            page={this.props.currentPage}
            onPageChange={this.props.onPageChange}
            onPageSizeChange={this.props.onPageSizeChange}
            pageSize={this.props.pageSize}
            onSortedChange={this.props.onSortedChange}
            sorted={this.props.sortedColumns}
            showPaginationTop
          />
        )}
      </div>
    );
  }
}

ListComponentComponent.propTypes = {
  // Table basic stuff
  fetching: PropTypes.bool.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  // Table visualization management
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  getTdProps: PropTypes.func,
  // Modals stuff
  // Filters
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      active: PropTypes.bool,
      onClick: PropTypes.func.isRequired
    })
  ),
  // Params only used for testing purposes
  mocked: PropTypes.bool,
  noSearch: PropTypes.bool,
  onSortedChange: PropTypes.func.isRequired,
  sortedColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool
    })
  ).isRequired,

  showExportWarningModal: PropTypes.func.isRequired,
  hideExportWarningModal: PropTypes.func.isRequired,
  isExportWarningModalVisible: PropTypes.bool.isRequired,

  addTextFilter: PropTypes.func.isRequired,

  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,

  visibleColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasBackgroundListRequest: PropTypes.bool.isRequired,
};

ListComponentComponent.defaultProps = {
  filters: [],
  mocked: false,
  noSearch: false,
  getTdProps: undefined
};

export default reduxForm({
  form: formName,
  destroyOnUnmount: true,
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
  forceUnregisterOnUnmount: false,
  pure: false
})(ListComponentComponent);
