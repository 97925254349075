import {Field} from "redux-form";
import React from "react";
import CoverageLocationSelect, {COVERAGE_ENTITY_TYPE} from "CoverageLocationSelect";
import {useDispatch} from "react-redux";
import {advancedFilterActions} from "../../../../actions/filters";

const Coverages = ({label, fieldName, isDisabled}) => {
    const dispatch = useDispatch();

    const adaptInputValues = (input) => {
         return input.map(_input => _input.value).join(",");
    }

    const onChange = (inputs) => {
        if (inputs !== null && inputs.length > 0) {
          const inputOffices = inputs.filter(input => input.entityType === COVERAGE_ENTITY_TYPE.OFFICES);
          const inputCountries = inputs.filter(input => input.entityType === COVERAGE_ENTITY_TYPE.COUNTRIES);
          dispatch(advancedFilterActions.addFilter(fieldName, inputs, {
            coverage_offices: adaptInputValues(inputOffices),
            coverage_countries: adaptInputValues(inputCountries)
          }));
        } else {
          dispatch(advancedFilterActions.removeFilter(fieldName))
        }
      };

    return (
      <Field
        name={fieldName}
        label={label}
        component={CoverageLocationSelect}
        onChange={onChange}
        openMenuOnClick
        allowMultiple
        disabled={isDisabled}
    />
  );
}

export default Coverages;