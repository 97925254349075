import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import {Categories} from "enums";
import { COLOR_SUCCESS_TOAST } from "./constants";

export const MIS_EMAIL = "hq.mis@wfp.org";
export const MIS_MAIL_TO = `mailto:${MIS_EMAIL}`;
// eslint-disable-next-line import/no-cycle
export { default as dateFormatter } from './dateFormatter';

/**
 * Wrapper for `toast.error` that also tries to `toast.dismiss` potential duplicates
 *
 * Duplicate toasts are identified by having the same `toastId`.
 *
 * If `contents` is a string, it will automatically be used as toastId.
 *
 * `toastId` can also be explicitly  provided as an attribute of extraArguments.
 * In this case it will be passed to toast.error's own extraArguments argument.
 *
 * `contents` isn't a string and `toastId` isn't provided?
 * No automatic dismissal will happen.
 *
 * Returns the result of toast.error (a toastId. Either provided or autogenerated.)
 *
 * Prevents flooding the user with the same error:
 * http://codeassist.wfp.org/jira/browse/OEV-1095
 */
export const customToastError = (contents, extraArguments) => {
  let toastId;
  if (typeof contents === "string") {
    toastId = contents;
  }
  if (extraArguments && extraArguments.toastId) {
    toastId = extraArguments.toastId;
  }
  if (toastId) {
    toast.dismiss(toastId);
  }
  return toast.error(contents, { ...extraArguments, toastId });
};

/**
 * Wrapper for `toast.success` with a generic success message.
 *
 * Dismisses previous toasts as no longer relevant.
 */
export const customToastSuccess = text => {
  toast.success(text || "Update Successful", {
    autoClose: 2000,
    className: { background: COLOR_SUCCESS_TOAST }
  });
  setTimeout(() => toast.dismiss(), 2500);
};

/**
 * Check if the id is a creation one or not
 */
export const isCreate = evaluationId =>
  ["newCE", "newDE", "newIE"].includes(evaluationId);

/**
 * Get Evaluation category via url-derived Evaluation id
 */
export const getUrlCategory = evaluationId => {
  if (evaluationId === "newCE") return "CENTRALIZED";
  if (evaluationId === "newDE") return "DECENTRALIZED";
  if (evaluationId === "newIE") return "IMPACT";
  return undefined;
};

/** Date format used as default across the app */
export const DEFAULT_DATE_FORMAT = "DD-MM-YYYY";

/** Datetime format used as default across the app */
export const DEFAULT_DATETIME_FORMAT = "DD-MM-YYYY HH:mm:ss";

export const sortByDate = (a, b) =>
  moment(a, DEFAULT_DATETIME_FORMAT) > moment(b, DEFAULT_DATETIME_FORMAT)
    ? 1
    : -1;

export const parseDate = date => {
  if (moment.isMoment(date)) {
    return moment(date).format(DEFAULT_DATE_FORMAT);
  }

  return date;
};

/**
 * Add days to a given date, return a moment instance
 */
export const addDays = (date, daysToAdd) => {
  const clonedDate = date; // TODO this isn't cloning
  if (!moment.isMoment(clonedDate)) {
    return moment(clonedDate, DEFAULT_DATE_FORMAT).add(daysToAdd, "days");
  }
  return clonedDate.add(daysToAdd, "days");
};

/**
 * Flatten a list of lists
 */
export const flatten = arr =>
  arr.reduce(
    (flat, toFlatten) =>
      flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    []
  );

/**
 * Turn "this" into "This"
 *
 * NB: keeps "THIS" as "THIS"
 */
export const capitalize = s => s && `${s}`[0].toUpperCase() + `${s}`.slice(1);

export const getMessages = (page, withKeys = false) =>
  withKeys
    ? [].concat(
        flatten(
          Object.entries(page || {}).map(k =>
            k[1].map(v => `${capitalize(k[0])}: ${v}`)
          )
        )
      ) || []
    : [].concat(flatten(Object.values(page || {})).map(k => capitalize(k)));

const getCategoryPermission = (category) => {
    switch(category) {
      case Categories.DECENTRALIZED:
        return "exercise.change_decentralizedevaluation";
      case Categories.CENTRALIZED:
        return "exercise.change_centralizedevaluation";
      case Categories.IMPACT:
        return "exercise.change_impactevaluation";
      default:
        return null;
    }
}


/**
 * Returns true if `userPermissions` are sufficient to edit an evaluation of the
 * given `category`, with optional additional `detailPermissions`.
 */
export const getCanEdit = (category, userPermissions, detailPermissions) => {
  const objectPermission = getCategoryPermission(category);

  return userPermissions.includes(objectPermission) && detailPermissions.includes(objectPermission);
};

export const castToBool = value => {
  if (typeof value === "boolean") {
    return value;
  }

  let result;
  if (typeof value === "string") {
    if (value === "true") {
      result = true;
    } else {
      result = false;
    }
  }
  return result;
};

/**
 * https://redux-form.com/8.2.2/docs/api/field.md/#-code-parse-value-name-gt-parsedvalue-code-optional-
 *
 * parserToNumberOrNull is used in redux <Field> component.
 * Parses the value given from the field input component to the type that you want stored in the Redux store.
 */
export const parserToNumberOrNull = value =>
  Number.isNaN(parseInt(value, 10)) ? null : parseInt(value, 10);

/**
 * Sort a list of objects with a `label` attribute in alphabetical order
 */
export const sortByLabel = (a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  return 1;
};

export const groupByKey = (items, key) => {
    return items?.reduce((acc, curr) => {
        const type = curr[key];
        if (!(type in acc)){
            acc[type] = [];
        }

        return { ...acc, [type]: [...acc[type], curr]}
    }, {}) ?? {};
}

/**
 * Adapt partners by grouping the options.
 *
 * We are going to build an array like the following:
 *    [
 *        {
 *            label: categoryName
 *            options: categoryOptions
 *        },
 *        {
 *            label: categoryName2
 *            options: categoryOptions2
 *        }
 *    ]
 */
export const adaptPartnersOptions = (partners, partnerCategories) => {
  const groupedPartnersOptions = [];
  if (partners && partners.length > 0 && partnerCategories.length > 0) {
    for (let i = 0; i < partnerCategories.length; i += 1) {
      groupedPartnersOptions[i] = {
        key: partnerCategories[i].id,
        label: partnerCategories[i].name,
        options: []
      };
      for (let j = 0; j < partners.length; j += 1) {
        if (partnerCategories[i].id === partners[j].category) {
          groupedPartnersOptions[i].options.push({
            ...partners[j],
            value: partners[j].id,
            label: partners[j].name || partners[j].label
          });
        }
      }
    }
  }
  return groupedPartnersOptions;
};

/**
 * Input parameters may be null, the sort must put null values on top
 */
export const sortDates = (a, b) => {
  if (a === null) {
    return -1;
  }
  if (b === null) {
    return 1;
  }
  return moment(a, DEFAULT_DATETIME_FORMAT) > moment(b, DEFAULT_DATETIME_FORMAT)
    ? 1
    : -1;
};

/**
 * Wrapper/Boundary component used to catch frontend errors and send them to sentry.
 *
 * See https://docs.sentry.io/clients/javascript/integrations/react/
 */
export class Boundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, errorInfo) {
    this.setState({ error });
  }

  render() {
    return this.state.error ? (
      <div>
        <p>We are sorry — something has gone wrong.</p>
        <p>Our team has been notified, try reloading the page later.</p>
      </div>
    ) : (
      this.props.children
    );
  }
}

Boundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};
