import { applyMiddleware, createStore, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import axios from "axios";
import thunk from "redux-thunk";
import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import promise from "redux-promise-middleware";
import { createBrowserHistory } from "history";
import createRootReducer from "./reducers/index.js";
import { checkVersion } from "./middlewares";

const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const configureStore = preloadedState =>
  createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(applyMiddleware(promise, thunk, routerMiddleware(history)))
  );
const store = configureStore();


/**
 * An axios client with common config to be used across the app
 */
const client = axios.create({
  baseURL: process.env.SERVICE_URL,
  responseType: "json",
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});
client.autoCamelCaseEndpoints = [];
client.enableAutoCamelCase = (endpoint) => {client.autoCamelCaseEndpoints.push(endpoint)};

// Auto snake case conversion in request
client.interceptors.request.use((request) => {
  if (typeof request.data === 'object') {
    request.data = snakeCaseKeys(request.data, { deep: true });
  }

  if (typeof request.params === 'object') {
    request.params = snakeCaseKeys(request.params, { deep: true });
  }

  return request;
});

client.interceptors.response.use(
  response => {
    // Logout and set expired prop if session is expired
    checkVersion(store.dispatch, response.headers);

    // Partial camelcase transformation
    if (typeof response.data === 'object') {
      const path = response.config.url.replace(response.config.baseURL, '');
      if (client.autoCamelCaseEndpoints.some(a => path.match(a) !== null )) {
        response.data = camelcaseKeys(response.data, { deep: true });
      }
    }

    return response;
  },
  err => {
    if (err.response && err.response.status === 401) {
      store.dispatch({ type: "AUTH_USER_SESSION_EXPIRED" });
    } else {
      store.dispatch({ type: "AUTH_USER_FULFILLED" });
    }
    return Promise.reject(err);
  }
);

export { store, history, client };
