import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import CRUDLayout, {CRUDMapStateToPropsPropTypes, CRUDMapDispatchToProps, CRUDMapDispatchToPropsPropTypes, CRUDMapStateToProps} from "CRUDLayout";
import activityActions from "../../actions/activityCategories";
import TableWithModalsMixin from "../../components/TableWithModalsMixin";
import ActivityCategoryModal from "./ActivityCategoryModal";
import columnsGenerator from "./tableColumns";


export class ActivityCategoryListComponent extends TableWithModalsMixin {
    render = () => (
      <CRUDLayout
        {...this.props}
        title='Activity Categories'
        entityName='ActivityCategory'
        columnsGenerator={columnsGenerator}
        addModalComponent={ActivityCategoryModal}
        getItem={this.getItem}
        onSave={this.saveItem}
        handleDragEnd={this.handleDragEnd}
      />
    );
}

ActivityCategoryListComponent.propTypes = {
  ...CRUDMapStateToPropsPropTypes,
  ...CRUDMapDispatchToPropsPropTypes,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      isDeprecated: PropTypes.bool
    })
  ).isRequired,
  selectedItem: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    isDeprecated: PropTypes.bool
  }),
};

export const mapStateToProps = state => {
    return (
        CRUDMapStateToProps(
            state,
            'activityCategories',
            'exercise.add_activitycategory',
            'exercise.change_activitycategory'
        )
    )
}

export const mapDispatchToProps = dispatch => (
  CRUDMapDispatchToProps(dispatch, activityActions, 'activityCategories')
);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ActivityCategoryListComponent)
);
