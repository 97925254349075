import { combineReducers } from "redux";
import {
  fetching,
  items,
  options,
  pagination,
  selectable,
  submitting,
  togglable
} from "./resource";

const usersSearchTextReducer = (state = "", action) => {
  return action.type === "SET_USERS_SEARCH_TEXT" ? action.text || "" : state;
};

export default combineReducers({
  isFetching: fetching("wfpUsers"),
  isSubmitting: submitting("wfpUsers"),
  items: items("wfpUsers"),
  options: options("wfpUsers"),
  page: pagination("wfpUsers"),
  oevmisRoleOptions: items("oevmisRoleOptions"),
  selectedUser: selectable("wfpUser"),
  showEditModal: togglable("WFPUSERS_EDIT_MODAL"),
  showImportModal: togglable("WFPUSERS_IMPORT_MODAL"),
  usersSearchText: usersSearchTextReducer
});
