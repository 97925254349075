import React from "react";
import PropTypes from "prop-types";
import {iconDelete} from "@wfp/icons";
import {Button} from "@wfp/ui";
import Styles from "./styles.scss";
import Select from '../Select';


const StatusRequirement = props => {
  const getRowIndices = (categoryCode, statusCode) => {
    const categoryIndex = props.schema.findIndex(item => item.categoryCode === categoryCode);
    // eslint-disable-next-line react/prop-types
    const statusIndex = props.schema[categoryIndex].statuses.findIndex(item => item.statusCode === statusCode);
    return [categoryIndex, statusIndex]
  }

  const handleDelete = (categoryCode, statusCode, index) => {
      const [categoryIndex, statusIndex] = getRowIndices(categoryCode, statusCode);
      // eslint-disable-next-line react/prop-types
      props.schema[categoryIndex].statuses[statusIndex].requirements.splice(index, 1);
      props.setSchema(props.schema)
  }

  const handleConditionChange = (categoryCode, statusCode, index, values) => {
      const [categoryIndex, statusIndex] = getRowIndices(categoryCode, statusCode);
      // eslint-disable-next-line no-param-reassign,react/prop-types
      props.schema[categoryIndex].statuses[statusIndex].requirements[index].kwargs.conditions = values ? values.map(value => value.value) : [];

      props.setSchema(props.schema)
  }


  return (
    <div className={Styles.container}>
      <Select
        id={props.requirement.requirementClass}
        readOnly
        value={props.requirement.requirementClass}
        {...props.typeExtraProps}
      />
      <Select
        id={props.requirement.requirementClass + props.requirement.requirementName}
        readOnly
        value={props.requirement.requirementName}
        {...props.fieldExtraProps}
      />
      <Select
        id={`${props.requirement.requirementName + props.requirement.requirementClass}-args`}
        readOnly
        value={props.requirement.args}
      />
      <Select
        id={`${props.requirement.requirementName + props.requirement.requirementClass}-kwargs`}
        allowMultiple

        options={props.conditionOptions}
        onChange={(values) => handleConditionChange(props.categoryCode, props.statusCode, props.index, values)}
        value={props.requirement.kwargs?.conditions}
      />
      <div className={Styles.buttonContainer}>
        {props.enableDelete && <Button
          type="submit"
          data-testid="delete-button"
          icon={iconDelete}
          onClick={() => handleDelete(props.categoryCode, props.statusCode, props.index)}
          kind="danger"
         />}
      </div>
    </div>
  )
}

export const statusRequirementPropTypes = {
  schema: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSchema: PropTypes.func,
  requirement: PropTypes.shape({
    requirementClass: PropTypes.string.isRequired,
    requirementName: PropTypes.string,
    args: PropTypes.arrayOf(PropTypes.string),
    kwargs: PropTypes.shape({}),
  }).isRequired,
  typeExtraProps: PropTypes.shape({}),
  fieldExtraProps: PropTypes.shape({}),
  index: PropTypes.number,
  categoryCode: PropTypes.string,
  statusCode: PropTypes.string,
  enableDelete: PropTypes.bool,
  conditionOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export const statusRequirementDefaultProps = {
  typeExtraProps: {},
  fieldExtraProps: {},
  enableDelete: false,
  index: null,
  categoryCode: null,
  statusCode: null,
  setSchema: null,
};

StatusRequirement.propTypes = statusRequirementPropTypes;
StatusRequirement.defaultProps = statusRequirementDefaultProps;

export default StatusRequirement;
