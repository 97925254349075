import React from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import get from "lodash/get";

import { Button } from "@wfp/ui";
import { iconAddGlyph } from "@wfp/icons";

import SecondaryNavigation from "SecondaryNavigation";
import DeleteModal from "Modal/DeleteModal";
import ListComponent from "ListComponent";
import TableWithModalsMixin from "TableWithModalsMixin";
import servicesActions from "../../actions/services";
import companiesActions, { setCompaniesPage } from "../../actions/companies";
import { clearFieldErrors } from "../../actions/fieldErrors";
import { toggle } from "../../actions/togglable";
import fetchUser from "../../actions/user";
import search from "../../utils/search";
import { parseDate } from "../../utils/index.js";

import columnsGenerator from "./tableColumns";
import AddModal from "./AddModal";
import {getFieldKeyOptions} from "../../components/CRUDLayout/helpers";

class Companies extends TableWithModalsMixin {
  valuesAdapter = values => ({
    ...values,
    expiry_date: values.expiry_date && parseDate(values.expiry_date),
    signature_date: values.signature_date && parseDate(values.signature_date)
  });

  toggleActive(company, value) {
    if (this.props.canActivate) {
      this.props.updateActive(company.id, !value);
    }
  }

  render() {
    return (
      <div>
        <SecondaryNavigation
          additional={
            <Button
              type="submit"
              icon={iconAddGlyph}
              iconDescription="Add a new company"
              disabled={!this.props.canAdd}
              onClick={() => this.getItem(null)}
            >
              New Company
            </Button>
          }
          title="Companies"
        />
        <AddModal
          fieldErrors={this.props.fieldErrors}
          initialValues={this.props.selectedItem}
          toggleModal={this.props.toggleAddModal}
          show={this.props.showAddModal}
          onSubmit={this.saveItem}
          options={this.props.options}
          services={this.props.services}
          disabled={
            (!this.props.canAdd && !this.props.selectedItem.id) ||
            (!this.props.canEdit && this.props.selectedItem.id)
          }
        />
        <DeleteModal
          title="Are you sure you want to delete this company?"
          show={this.props.showDeleteModal}
          toggleModal={this.props.toggleDeleteModal}
          onDelete={this.deleteItem}
          disabled={!this.props.canDelete}
          danger
        />
        <ListComponent
          /** Pagination */
          onPageChange={this.props.setPage}
          page={this.props.page}
          pageCount={this.props.pageCount}
          /** Table props */
          tableData={this.props.items}
          columns={columnsGenerator(
            this.getItem,
            this.setItemToDelete,
            this.props.canEdit,
            this.props.canDelete,
            this.props.canActivate,
            this.toggleActive.bind(this),
          )}
          fetching={this.props.fetching}
          /** Columns list props */
          showColumnsList={false}
        />
      </div>
    );
  }
}

Companies.propTypes = {
  canAdd: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canActivate: PropTypes.bool.isRequired,
  fieldErrors: PropTypes.shape({}).isRequired,
  selectItem: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
  toggleAddModal: PropTypes.func.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  showAddModal: PropTypes.bool.isRequired,
  showDeleteModal: PropTypes.bool.isRequired,
  saveItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  options: PropTypes.shape({}),
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedItem: PropTypes.shape({}),
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  formValues: PropTypes.shape({})
};

Companies.defaultProps = {
  options: {},
  selectedItem: {},
  formValues: {}
};

export const mapStateToProps = state => {
  const page = state.pages.companies;
  const { entities } = state;
  const formValues = state.form.companies ? state.form.companies.values : {};

  const services = page.services.map(id => ({
    id,
    value: id,
    label: entities.services[id].name
  }));

  /** Search form value */
  const query =
    state.form.search && state.form.search.values
      ? state.form.search.values.query
      : "";

  const companies = page.items
    .map(id => entities.companies[id])
    .filter(p => !p.deleted);
  const items = search(query, companies, getFieldKeyOptions(companies?.[0]));

  const company = entities.companies[page.selectedCompany];
  const selectedItem = company && {
    ...company,
    services: get(company, "services", []).map(s => ({
      id: s.id,
      value: s.id,
      label: s.name
    }))
  };

  const permissions = get(state, "user[0].permissions.permission", []);
  const canAdd = permissions.includes("actors.add_person");
  const canEdit = permissions.includes("actors.change_person");
  const canDelete = permissions.includes("actors.delete_person");
  const canActivate = permissions.includes("actors.deprecate_company");

  return {
    canAdd,
    canEdit,
    canDelete,
    canActivate,
    fieldErrors: state.fieldErrors,
    formValues,
    services,
    items,
    selectedItem,
    fetching: page.isFetching,
    page: page.page,
    pageCount: items.length / 20,
    showDeleteModal: page.deleteModal,
    showAddModal: page.addModal
  };
};

export const mapDispatchToProps = dispatch => ({
  selectItem: id => dispatch({ type: "SELECT_COMPANY", id }),
  saveItem: item =>
    Promise.all([
      dispatch(companiesActions.save(item)),
      dispatch(clearFieldErrors())
    ]),
  updateActive: (id, value) => dispatch(companiesActions.updateActive(id, value)),
  deleteItem: itemId => dispatch(companiesActions.destroy(itemId)),
  fetchItems: () =>
    dispatch(
      companiesActions.list({ordering:"name"}, { mergeNoArrayNoItems: true, paginated: false })
    ),
  fetchData: () =>
    Promise.all([
      dispatch(fetchUser()),
      dispatch(companiesActions.options({}, {})),
      dispatch(servicesActions.list({}, { paginated: false }))
    ]),
  toggleAddModal: () =>
    Promise.all([
      dispatch(toggle("COMPANY_ADD_MODAL")),
      dispatch(clearFieldErrors())
    ]),
  toggleDeleteModal: () => dispatch(toggle("COMPANY_DELETE_MODAL")),
  resetForm: () => dispatch(reset("companies")),
  /** Set the current paginator page */
  setPage: page => dispatch(setCompaniesPage(page))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Companies)
);
