import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link as RouterLink } from "react-router-dom";
import {
  MainNavigation as WFPMainNavigation,
  MainNavigationItem,
  Link,
  User,
  SubNavigation,
  SubNavigationContent,
  SubNavigationItem
} from "@wfp/ui";

import styles from "./styles.scss";

const { LOGOUT_URL } = process.env;

const UserSubNavigation = props => (
  <SubNavigation>
    <SubNavigationContent>
      <SubNavigationItem>
        <Link href={LOGOUT_URL} onClick={props.logoutAction}>
          Logout
        </Link>
      </SubNavigationItem>
    </SubNavigationContent>
  </SubNavigation>
);

UserSubNavigation.propTypes = {
  logoutAction: PropTypes.func.isRequired
};

const MainNavigation = ({
  userName,
  userUsername,
  appLabel,
  isUserSupport,
  navItems,
  logoutAction
}) => {
  const username = userName ? `Hello, ${userName}` : userUsername;
  const logo = (
    <div className="logoWrapper">
      <RouterLink to="/evaluations">Office of Evaluation</RouterLink>
      <small className={styles.projectVersion}>
        {isUserSupport ? (
          <RouterLink to="/changelog">{appLabel}</RouterLink>
        ) : (
          <span>{appLabel}</span>
        )}
      </small>
    </div>
  );

  return (
    <WFPMainNavigation logo={logo} pageWidth="full">
      {navItems.map(item => (
        <MainNavigationItem
          key={item.key}
          className={
            item.children.length > 0 ? styles.subNavigation : undefined
          }
          subNavigation={
            item.children.length > 0 ? (
              <SubNavigation>
                <SubNavigationContent>
                  {item.children.map(children => (
                    <SubNavigationItem key={children.key}>
                      <RouterLink to={children.href} className="wfp--link">
                        {children.title}
                      </RouterLink>
                    </SubNavigationItem>
                  ))}
                </SubNavigationContent>
              </SubNavigation>
            ) : (
              undefined
            )
          }
        >
          {item.children.length > 0 ? (
            <span>{item.title}</span>
          ) : (
            <RouterLink to={item.href}>{item.title}</RouterLink>
          )}
        </MainNavigationItem>
      ))}
      <MainNavigationItem
        className={styles.subNavigation}
        subNavigation={<UserSubNavigation logoutAction={logoutAction} />}
      >
        <User alt="username" name={username} ellipsis />
      </MainNavigationItem>
    </WFPMainNavigation>
  );
};

MainNavigation.propTypes = {
  userName: PropTypes.string.isRequired,
  userUsername: PropTypes.string.isRequired,
  appLabel: PropTypes.string.isRequired,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          href: PropTypes.string
        })
      )
    })
  ),
  isUserSupport: PropTypes.bool.isRequired,
  logoutAction: PropTypes.func.isRequired
};

MainNavigation.defaultProps = {
  navItems: []
};

export default withRouter(MainNavigation);
