import {dateFormatter} from "../../../utils";

const phaseShifter = (phases, milestones) => {
    const shiftedPhases = [...phases];

    const isStartDateAfterEndDateBoundary = (forwardShiftingDays) => {
        return forwardShiftingDays > 0;
    }

    const isEndDateBeforeStartDateBoundary = (backwardShiftingDays) => {
        return backwardShiftingDays < 0;
    }

    const updateAvailableRange = (phase, startDateOffset, endDateOffset) => {
        const { start_date: startDate, end_date: endDate } = phase;

        return ({
            ...phase,
            start_date: startDate ? dateFormatter(startDate).addDays(startDateOffset).toString() : startDate,
            end_date: endDate ? dateFormatter(endDate).addDays(endDateOffset).toString() : endDate,
        });
    }

    const shiftMilestone = (milestone, numberOfOffset, id) => {
        const milestoneEntity = milestones[id];
        if (milestoneEntity.isValidationDisabled) {
            return milestone;
        }

        return ({
            ...milestone,
            datesRange: {
                startDate: dateFormatter(milestone?.datesRange?.startDate).addDays(numberOfOffset).toMoment(),
                endDate: dateFormatter(milestone?.datesRange?.endDate).addDays(numberOfOffset).toMoment(),
            },
            eventDate: dateFormatter(milestone?.eventDate).addDays(numberOfOffset).toString(),
        })
    }

    const shiftMilestones = (phase, numberOfOffset) => phase.milestones?.map((milestone, id) => shiftMilestone(milestone, numberOfOffset, id))


    const shiftPhase = (phaseIndex, numberOfOffset) => {
        shiftedPhases[phaseIndex] = {
            ...updateAvailableRange(shiftedPhases[phaseIndex], numberOfOffset, numberOfOffset),
            dates_range: {
                startDate: dateFormatter(shiftedPhases[phaseIndex].dates_range?.startDate).addDays(numberOfOffset).toMoment(),
                endDate: dateFormatter(shiftedPhases[phaseIndex].dates_range?.endDate).addDays(numberOfOffset).toMoment(),
            },
            milestones: shiftMilestones(shiftedPhases[phaseIndex], numberOfOffset)
        }
    };

    const updateSelectedPhaseBoundaries = (phase, forwardShiftingDays, backwardShiftingDays) => {
        let selectedPhase = null
        const endDateCorrection = isStartDateAfterEndDateBoundary(forwardShiftingDays) ? forwardShiftingDays : 0;
        selectedPhase = updateAvailableRange(phase, forwardShiftingDays, endDateCorrection);

        const startDateCorrection = isEndDateBeforeStartDateBoundary(backwardShiftingDays) ? backwardShiftingDays : 0;
        selectedPhase = updateAvailableRange(selectedPhase, backwardShiftingDays, startDateCorrection)

        return selectedPhase;
    }

    // eslint-disable-next-line no-unused-vars
    const updateSelectedPhaseMilestones = (phase, forwardShiftingDays, backwardShiftingDays) => {
        let selectedPhase = phase;

        if(isStartDateAfterEndDateBoundary(forwardShiftingDays)) {
            selectedPhase = {
                ...phase,
                milestones: shiftMilestones(phase, forwardShiftingDays)
            }
        }

        if(isEndDateBeforeStartDateBoundary(backwardShiftingDays)) {
            selectedPhase = {
                ...phase,
                milestones: shiftMilestones(phase, backwardShiftingDays)
            }
        }

        return selectedPhase;
    }

    return {
        shiftPhasesRanges: (startPhaseId, forwardShiftingDays, backwardShiftingDays) => {
            const selectedPhaseIndex = shiftedPhases.findIndex(p => p.phase === startPhaseId);

            shiftedPhases[selectedPhaseIndex] = updateSelectedPhaseBoundaries(
                shiftedPhases[selectedPhaseIndex],
                forwardShiftingDays,
                backwardShiftingDays
            )
            // Not required to shift milestones in which phase shifting happened
            // shiftedPhases[selectedPhaseIndex] = updateSelectedPhaseMilestones(
            //     shiftedPhases[selectedPhaseIndex],
            //     forwardShiftingDays,
            //     backwardShiftingDays
            // )

            for(let index = 0; index < shiftedPhases.length; index+=1) {
                if (!isStartDateAfterEndDateBoundary(forwardShiftingDays) && index < selectedPhaseIndex) {
                    shiftPhase(index, forwardShiftingDays)
                }
                if (isStartDateAfterEndDateBoundary(forwardShiftingDays) && index > selectedPhaseIndex) {
                    shiftPhase(index, forwardShiftingDays);
                }

                if (!isEndDateBeforeStartDateBoundary(backwardShiftingDays) && index > selectedPhaseIndex) {
                    shiftPhase(index, backwardShiftingDays)
                }
                if (isEndDateBeforeStartDateBoundary(backwardShiftingDays) && index < selectedPhaseIndex) {
                    shiftPhase(index, backwardShiftingDays)
                }
            }

            return shiftedPhases;
        }
    }
}

export default phaseShifter;
