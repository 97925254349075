import React from "react";

import { Icon } from "@wfp/ui";
import { iconEdit, iconDelete } from "@wfp/icons";

import { capitalize } from "../../utils";

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
const EditCell = (row, canEdit, setItem) =>
  canEdit ? (
    <Icon
      icon={iconEdit}
      width="19"
      height="19"
      description="Edit item"
      onClick={() => setItem(row.value)}
      style={{ cursor: "pointer" }}
    />
  ) : (
    <Icon
      icon={iconEdit}
      width="19"
      height="19"
      description="Edit item"
      style={{ cursor: "pointer", opacity: "0.5" }}
    />
  );

const DeleteCell = (row, canDelete, setItemToDelete) =>
  canDelete ? (
    <Icon
      icon={iconDelete}
      width="19"
      height="19"
      description="Delete item"
      onClick={() => setItemToDelete(row.value)}
      style={{ cursor: "pointer" }}
    />
  ) : (
    <Icon
      icon={iconDelete}
      width="19"
      height="19"
      description="Delete item"
      style={{ cursor: "pointer", opacity: "0.5" }}
    />
  );
/* eslint-enable */

const NationalityCell = field => (
  <span>{field.value.map(s => s.name).join(", ")}</span>
);

const columnsGenerator = (
  setItem,
  canEdit,
  setItemToDelete,
  canDelete,
) => [
  {
    Header: "Name",
    accessor: "name",
    maxWidth: 400
  },
  {
    Header: 'Sex',
    accessor: "sex_display",
    maxWidth: 175
  },
  {
    Header: 'Nationality',
    accessor: "nationality",
    Cell: NationalityCell
  },
  {
    Header: 'Development Status',
    accessor: "development_status",
    Cell: row =>
      row.original.development_status &&
      capitalize(row.original.development_status.toLowerCase()),
    maxWidth: 200
  },
  {
    Header: "",
    accessor: "id",
    /* eslint-disable */
    Cell: row => EditCell(row, canEdit, setItem),
    /* eslint-enable */
    resizable: false,
    sortable: false,
    width: 50,
    style: { justifyContent: "center" }
  },
  {
    Header: "",
    accessor: "id",
    /* eslint-disable */
    Cell: row => DeleteCell(row, canDelete, setItemToDelete),
    /* eslint-enable */
    resizable: false,
    sortable: false,
    width: 50,
    style: { justifyContent: "center" }
  }
];

export default columnsGenerator;
