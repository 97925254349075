exports = module.exports = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__criterionHeading___2K5w1{display:block;font-weight:600;font-size:.875rem;line-height:1.5;margin:0.5rem 0 0.25rem}.styles__criterion___2dvAf{align-items:center;display:flex;flex-wrap:wrap;min-height:40px;padding-left:8px;position:relative;top:2px}.styles__criterion___2dvAf p,.styles__criterion___2dvAf span{font-size:.875rem}.styles__criterion___2dvAf .styles__criterionFirstPart___1YNuu+div:not(.styles__criterionSecondPart___Bbanr){margin-left:-0.25rem}.styles__criterion___2dvAf>.styles__criterionFirstPart___1YNuu{margin-right:4px}.styles__criterion___2dvAf>.styles__criterionSecondPart___Bbanr{align-items:flex-end;display:flex}.styles__infoIcon___25jKe>svg{fill:#0a6eb4}.styles__scoreColumn___2QXSQ input[type=\"text\"]{text-align:right}.styles__readOnlyColumn___2mUz2 input[readonly]{border:none !important}.styles__totalScore___1mejJ input[type=\"text\"][readonly]{color:#031c2d !important;text-align:right}.styles__totalValue___1OaCp input[type=\"text\"][readonly]{color:#031c2d !important}\n", ""]);

// Exports
exports.locals = {
	"criterionHeading": "styles__criterionHeading___2K5w1",
	"criterion": "styles__criterion___2dvAf",
	"criterionFirstPart": "styles__criterionFirstPart___1YNuu",
	"criterionSecondPart": "styles__criterionSecondPart___Bbanr",
	"infoIcon": "styles__infoIcon___25jKe",
	"scoreColumn": "styles__scoreColumn___2QXSQ",
	"readOnlyColumn": "styles__readOnlyColumn___2mUz2",
	"totalScore": "styles__totalScore___1mejJ",
	"totalValue": "styles__totalValue___1OaCp"
};