import React from "react";
import PropTypes from "prop-types";

import Month from "./Month";

import Styles from "./styles.scss";

const Year = ({ year, phases, milestones, onMilestoneClick }) => (
  <li className={Styles.year}>
    <span className={Styles.yearName}>{year}</span>
    <ul className={Styles.calendarBorder}>
      {// Ugly expression used to generate an array with the first 12 numbers starting from 0
      // Equivalent to python's `list(range(12))`
      // Here used to have a list of months keys
      [...Array(12).keys()].map(month => (
        <Month
          key={month}
          month={month}
          year={year}
          phases={phases[month] || []}
          milestones={milestones[month] || []}
          onMilestoneClick={onMilestoneClick}
        />
      ))}
    </ul>
  </li>
);

Year.propTypes = {
  year: PropTypes.string.isRequired,
  phases: PropTypes.arrayOf(PropTypes.array).isRequired,
  milestones: PropTypes.arrayOf(PropTypes.array).isRequired,
  onMilestoneClick: PropTypes.func.isRequired
};

export default Year;
